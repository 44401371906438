<template>
  <div class="layout">
    <div class="center">
      <img class="logo" src="../assets/qs2.svg" alt="" />
      
      <div class="advertisement">
        <h2 class="title">¡Nuevo SCP!</h2>
        <p class="text">en</p>
        <!-- <p class="text">28 dias 23 horas 4 minutos y 22 segundos</p> -->
        <Countdown> </Countdown>
        <p class="text">Con motivo de la actualización la plataforma estará CERRADA desde el 24 al 28 de mayo</p>
      
        <a href="https://doctores.quicksmile.es/nuevo-scp/" target="_blank" rel="noopener noreferrer">
        <el-button
        type="primary"
        class="button"
        icon="el-icon-plus"
        >info</el-button
      >
        </a>
      </div>
      <router-link class="link-wrapper cases" to="/">
        <el-button class="section">
          <img class="section-img" src="../assets/file.svg" alt="" />
          <p class="section-text">
            Mis casos
          </p>
        </el-button></router-link
      >
      <router-link class="link-wrapper profile" to="/perfil"
        ><el-button class="section">
          <img class="section-img" src="../assets/profile.svg" alt="" />
          <p class="section-text">Mi perfil</p>
        </el-button></router-link
      >
    </div>
    <Footer class="footer-wrap" />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Countdown from '@/components/Countdown.vue';

export default {
  components: {
    Footer,
    Countdown 
  }
}
</script>

<style scoped lang="scss">
.layout {
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  justify-items: center;
  box-sizing: border-box;

  .center {
    box-sizing: border-box;
    width: 600px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-gap: 24px 0;
    padding: 32px 32px 72px 32px;

    .link-wrapper {
      width: 100%;
      height: 100%;
      text-decoration: none;
    }

    .cases {
      width: 100%;
      grid-column: 1 / 3;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .section {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &:hover,
      &:focus {
        background: #f5f5f5;
      }

      .section-img {
        width: 64px;
        margin-bottom: 8px;
      }

      .section-text {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }
    } 

    .logo {
      grid-column: 1 / 3;
      align-self: center;
      width: 65%;
      justify-self: center;
      align-self: flex-end;
      margin-bottom: 32px;
    }

    .advertisement {
      padding: 24px;
      border: 1px solid #b3b3b3;
      text-align: center;
      margin: 0 -116px;

      .title {
        font-weight: bolder;
        font-size: 32px;
        margin-bottom: 16px;
      }
      .text {
        font-size: 18px;
        margin-bottom: 8px;
      }

      .button {
        margin-top: 16px;
        padding: 16px 32px;
        font-size: 20px;
      }
    }
  }

  .footer-wrap {
    width: 100%;
  }
}

@media (max-width: 764px) {
  .layout {
    .center {
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 1fr;
      grid-row-gap: 24px;
      grid-column-gap: 0;
      .section {
        width: 100%;
      }

      .cases {
        grid-column: 1;
      }

      .profile {
        grid-column: 1;
        // grid-row: 3;
      }

      .shop {
        grid-column: 1;
        // grid-row: 5;
      }

    .advertisement {
      margin: 0;

      .title {
  
      }
      .text {
  
      }

      .button {
 
      }
    }

    }
    // height: 100vh;
    // display: grid;
    // grid-template-rows: 1fr auto;
    // grid-template-columns: 1fr;
    // justify-items: center;

    // .section {
    //   grid-column: ;
    // }
  }
}
</style>
