<template>
  <div class="files">
    <img v-if="files.unfit1Image" class="image" :src="files.unfit1Image" />
    <!-- <img v-else class="image" src="../assets/unfit.jpg" /> -->

    <img v-if="files.unfit2Image" class="image" :src="files.unfit2Image" />
    <!-- <img v-else class="image" src="../assets/unfit.jpg" /> -->

    <img v-if="files.xray1Image" class="image" :src="files.xray1Image" />
    <!-- <img v-else class="image" src="../assets/x_ray1.jpg" /> -->

    <img
      v-if="files.xray2Image"
      class="image respect-width"
      :src="files.xray2Image"
    />
    <!-- <img v-else class="image" src="../assets/x_ray2.jpg" /> -->

    <img
      v-if="files.lateralFacialImage"
      class="image"
      :src="files.lateralFacialImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_facial_lateral.png" /> -->

    <img
      v-if="files.smileFacialImage"
      class="image"
      :src="files.smileFacialImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_facial_frontal.png" /> -->

    <img
      v-if="files.frontalFacialImage"
      class="image"
      :src="files.frontalFacialImage"
      @click="clickElement('facialFrontal')"
    />
    <!-- <img v-else class="image" src="../assets/foto_facial_frontal.png" /> -->

    <img
      v-if="files.upperOcclusalImage"
      class="image"
      :src="files.upperOcclusalImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_facial_frontal.png" /> -->

    <img
      v-if="files.leftIntraoralImage"
      class="image"
      :src="files.leftIntraoralImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_intraoral_izquierda.png" /> -->

    <img
      v-if="files.centralIntraoralImage"
      class="image"
      :src="files.centralIntraoralImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_intraoral_frontal.png" /> -->

    <img
      v-if="files.rightIntraoralImage"
      class="image"
      :src="files.rightIntraoralImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_intraoral_derecha.png" /> -->

    <img
      v-if="files.lowerOcclusalImage"
      class="image"
      :src="files.lowerOcclusalImage"
    />
    <!-- <img v-else class="image" src="../assets/foto_oclusal_inferior.png" /> -->
  </div>
</template>

<script>
export default {
  name: 'AdminFiles',
  props: {
    files: Object
  }
}
</script>

<style lang="scss" scoped>
.files {
  width: 100%;

  .image {
    width: 100%;
    height: auto;
    margin-bottom: 4px;
  }
}
</style>
