<template>
  <div class="stages">
    <div
      @click="() => isAdmin && onClick('0')"
      :class="`stage ${getStyleForStage('0')}`"
    >
      <p>Evaluando registros</p>
    </div>
    <div
      v-if="!isRetention"
      @click="() => isAdmin && onClick('2')"
      :class="`stage ${getStyleForStage('2')}`"
    >
      <p>Desarrollando tratamiento</p>
    </div>

    <div
      v-if="!isRetention"
      @click="() => isAdmin && onClick('12')"
      :class="`stage ${getStyleForStage('12')}`"
    >
      <p>Revisar VTO</p>
    </div>

    <div
      v-if="isAdmin && !isRetention"
      @click="() => isAdmin && onClick('13')"
      :class="`stage ${getStyleForStage('13')}`"
    >
      <p>Secuenciar</p>
    </div>

    <div
      v-if="!isRetention"
      @click="() => isAdmin && onClick('3')"
      :class="`stage ${getStyleForStage('3')}`"
    >
      <p>Revisar QuickCheck</p>
    </div>

    <div
      @click="() => isAdmin && onClick('4')"
      :class="`stage ${getStyleForStage('4')}`"
    >
      <p v-if="isTreatment">Pendiente de pago</p>
      <p v-else-if="isRetention">Retención final aceptada</p>
      <p v-else>Refinamiento aceptado</p>
    </div>
    <div
      v-if="isAdmin || currentStage == 5"
      @click="() => onClick('5')"
      :class="`stage ${getStyleForStage('5')}`"
    >
      <p>CANCELADO</p>
    </div>
    <div
      @click="() => isAdmin && onClick('6')"
      :class="`stage ${getStyleForStage('6')}`"
    >
      <p>En producción</p>
    </div>
    <div
      @click="() => isAdmin && onClick('7')"
      :class="`stage ${getStyleForStage('7')}`"
    >
      <p>Enviado</p>
    </div>
    <div
      v-if="!isRetention"
      @click="() => isAdmin && onClick('9')"
      :class="`stage ${getStyleForStage('9')}`"
    >
      <p v-if="isTreatment">En tratamiento</p>
      <p v-else>En refinamiento</p>
    </div>
    <div
      v-if="!isRetention"
      @click="() => isAdmin && onClick('10')"
      :class="`stage ${getStyleForStage('10')}`"
    >
      <p v-if="isTreatment">En refinamiento</p>
      <p v-else>Siguiente refinamiento</p>
    </div>
    <div
      v-if="isRetention"
      @click="() => isAdmin && onClick('11')"
      :class="`stage ${getStyleForStage('11')}`"
    >
      <p>Tratamiento finalizado</p>
    </div>
    <div
      v-else
      @click="() => isAdmin && onClick('11')"
      :class="`stage ${getStyleForStage('11')}`"
    >
      <p>Finalizado</p>
    </div>
  </div>
</template>

<script>
import { getColorOfStage } from '../utils'

export default {
  name: 'CaseStages',
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    currentStage: {
      required: true,
      type: Number
    },
    caseType: {
      required: false,
      default: 'treatment',
      type: String
    }
  },
  computed: {
    isTreatment () {
      return this.caseType === 'treatment'
    },
    isRetention () {
      return this.caseType === 'retention'
    }
  },
  methods: {
    onClick (stageClicked) {
      this.$emit('click', stageClicked)
    },
    getStyleForStage (stage) {
      const stageNumber = parseInt(stage)
      let classes = ''

      const positions = {
        0: 1,
        1: 1,
        2: 2,
        12: 3,
        13: 4,
        3: 5,
        4: 6,
        5: 7,
        6: 8,
        7: 9,
        8: 10,
        9: 11,
        10: 12,
        11: 13
      }

      if (positions[stageNumber] < positions[this.currentStage]) {
      } else if (stageNumber === this.currentStage) {
        const color = getColorOfStage(this.currentStage, this.caseType)
        classes = `${classes} ${color}`
      } else {
        classes = `${classes} white`
      }
      classes = this.isAdmin ? `${classes} clickable` : classes

      return classes
    }
  }
}
</script>

<style scoped lang="scss">
.stages {
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap;

  .stage {
    width: 152px;
    height: 88px;
    display: flex;
    border-radius: 10px;
    background-color: #e4e4e4;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
    margin: 8px;

    &.white {
      border: 1px solid black;
      background-color: #ffffff;
    }

    &.red {
      background-color: #f4dadc;
      color: black;
    }

    &.yellow {
      background-color: #f7dfb8;
    }

    &.green {
      background-color: #d3edb7;
    }

    &.blue {
      background-color: #bbdefb;
    }

    &.yellow,
    &.green {
      p {
        color: black;
      }
    }

    &.grey {
      background-color: #aaaaaa;
      p {
        color: black;
      }
    }

    &.clickable {
      cursor: pointer;
    }

    p {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
  }
}
</style>
