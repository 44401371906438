import Vue from 'vue'
import Vuex from 'vuex'

import router from '@/router'

import { apolloProvider, onLogout, onLogin } from '@/vue-apollo'

import {
  formatDate,
  addYears,
  getLastStatus,
  getNameOfStage,
  getVTOFromCase,
  getQuickcheckFromCase,
  formatTreatmentType,
  getCaseType
} from '@/utils'
import {
  LOCAL_STORAGE_KEYS,
  DEFAULT_STAGE_FILTERS,
  DEFAULT_ADDRESS_FILTERS
} from '@/constants'

import UPDATE_NOTIFICATIONS from '@/graphql/UpdateNotifications.gql'
import DELETE_ADDRESS from '@/graphql/DeleteAddress.gql'
import UPDATE_DOCTOR_IMAGE from '@/graphql/UpdateDoctorImage.gql'
import SAVE_CLINICAL_PREFERENCES from '@/graphql/SaveClinicalPreferences.gql'
import UPDATE_ADDRESS from '@/graphql/UpdateAddress.gql'
import CREATE_ADDRESS from '@/graphql/AddAddress.gql'
import UPDATE_CASE from '@/graphql/UpdateCase.gql'
import CREATE_CASE from '@/graphql/CreateCase.gql'
import CREATE_REFINEMENT from '@/graphql/CreateRefinement.gql'
import UPDATE_REFINEMENT from '@/graphql/UpdateRefinement.gql'
import UPDATE_CASE_STATE from '@/graphql/UpdateCaseState.gql'
import UPDATE_RETENTION_STATE from '@/graphql/UpdateRetentionState.gql'
import UPDATE_REFINEMENT_STATE from '@/graphql/UpdateRefinementState.gql'
import UPDATE_RETENTION from '@/graphql/UpdateRetention.gql'
import CREATE_RETENTION from '@/graphql/CreateRetention.gql'
import SAVE_ADMIN_NOTES from '@/graphql/SaveAdminNotes.gql'
import SCHOOL_SIGNUP from '@/graphql/SchoolSignup.gql'
import SEND_MISSING_ALIGNMENTS_EMAIL from '@/graphql/SendMissingAlignmentsEmail.gql'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'

Vue.use(Vuex)

const apollo = apolloProvider.clients.defaultClient

export default new Vuex.Store({
  state: {
    cases: [],
    totalCases: 0,
    casesCurrentPage: 1,
    invoicesCurrentPage: 1,
    invoiceSearch: '',
    isLoading: false,
    search: '',
    me: {
      role: 'DOCTOR',
      image: '',
      name: '',
      firstSurname: '',
      fullName: '',
      email: '',
      addresses: [],
      username: '',
      discountsMemberGetMember: 0,
      discountsYearly: 0,
      discountFirstCase: 0,
      invoices: [],
      schoolRole: null,
      clinicalPreferences: null
    },
    notifications: [],
    currentCase: {
      treatmentType: '',
      id: null,
      patientPhoto: null,
      patientName: '',
      patientSurname: '',
      doctor: {
        clinicalPreferences: null
      },
      status: null,
      attachments: null,
      quickcheckHistory: [],
      vtoHistory: [],
      refinements: [],
      signature: '',
      merchantParameters: '',
      createdAt: null,
      messages: [],
      retention: null,
      isPost15N: false,
      v2: false
    },
    caseView: {
      tab: 'TREATMENT',
      activeTab: 'chat'
    },
    addresses: [],
    clinicalPreferencesUpdated: false,
    createdRefinementId: '',
    filter: null,
    panelInformation: {
      doctors: []
    },
    authorizationStatus: null,
    updateCaseNotificationError: null,
    selectedStageFilters:
      JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_STAGE_FILTERS)
      ) || DEFAULT_STAGE_FILTERS,
    selectedAddressFilters:
      JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_ADDRESS_FILTERS)
      ) || DEFAULT_ADDRESS_FILTERS,
    schoolSignupSuccess: null,
    clinicalPreferencesDoctorName: ""
  },
  getters: {
    getBillingAddresses: state =>
      state.me.addresses.filter(address => address.type === 'BILLING'),
    getShippingAddresses: state => {
      return state.me.addresses.filter(address => address.type === 'SHIPPING')
    },
    getCurrentCaseCreationDate: state =>
      state.currentCase.createdAt
        ? formatDate(state.currentCase.createdAt)
        : null,
    getCurrentCaseFinishDate: state => {
      if (state.currentCase.createdAt && state.currentCase.treatmentType) {
        let years
        if (state.currentCase.treatmentType.includes('lite')) {
          years = 2
        } else if (state.currentCase.treatmentType.includes('q8')) {
          years = 1
        } else {
          years = 5
        }

        return addYears(state.currentCase.createdAt, years)
      } else {
        return null
      }
    }
  },
  mutations: {
    setFilter (state, payload) {
      state.filter = payload.filter
    },
    setSelectedStageFilters (state, payload) {
      state.selectedStageFilters = payload.selectedStageFilters
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SELECTED_STAGE_FILTERS,
        JSON.stringify(payload.selectedStageFilters)
      )
      state.casesCurrentPage = 1
    },
    setSelectedAddressFilters (state, payload) {
      state.selectedAddressFilters = payload.selectedAddressFilters
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SELECTED_ADDRESS_FILTERS,
        JSON.stringify(payload.selectedAddressFilters)
      )
      state.casesCurrentPage = 1
    },
    updateMe (state, payload) {
      state.me = {
        ...state.me,
        ...payload
      }
    },
    deleteAddress (state, payload) {
      state.me.addresses = state.me.addresses.filter(
        address => address.id !== payload.id
      )
    },
    setTab (state, payload) {
      state.tab = payload.tab
    },
    setActiveTab (state, payload) {
      state.activeTab = payload.tab
    },
    setCases (state, payload) {
      state.cases = payload.cases.map(c => normalizeCase(c))
      state.totalCases = payload.total
    },
    setMe (state, payload) {
      if (
        payload.addresses &&
        state.me.addresses.length !== payload.addresses.length
      ) {
        Vue.set(state.me, 'addresses', payload.addresses)
      }

      state.me = {
        ...state.me,
        ...payload,
        fullName: `${payload.name} ${payload.firstSurname}`
      }
    },
    setNotifications (state, payload) {
      state.notifications = payload.notifications.map(notification => {
        return {
          ...notification,
          description:
            notification.kind === 'MESSAGE'
              ? 'Tienes un nuevo mensaje'
              : 'El caso ha sido actualizado'
        }
      })
    },
    showLoading (state) {
      state.isLoading = true
    },
    hideLoading (state) {
      state.isLoading = false
    },
    setCasesCurrentPage (state, payload) {
      state.casesCurrentPage = payload.page
    },
    setSearch (state, payload) {
      state.search = payload.search
      state.casesCurrentPage = 1
    },
    setAdminNotes (state, payload) {
      state.currentCase.adminNotes = payload.notes
    },
    setInvoiceSearch (state, payload) {
      state.invoiceSearch = payload.invoiceSearch
      state.invoicesCurrentPage = 1
    },
    setCurrentCase (state, payload) {
      const isPost15N =
        new Date(payload.case.createdAt) > new Date('2021-11-15')
      const computedTreatmentType = formatTreatmentType(
        payload.case.treatmentType,
        isPost15N
      )

      state.currentCase = { ...payload.case, computedTreatmentType, isPost15N }
    },
    setClinicalPreferencesUpdated (state, payload) {
      state.clinicalPreferencesUpdated = true
    },
    clearClinicalPreferencesUpdated (state, payload) {
      state.clinicalPreferencesUpdated = false
    },
    setCreatedRefinementId (state, payload) {
      state.createdRefinementId = payload.id
    },
    // FIXME: avoid re-render all addresses, only updated one
    updateAddress (state, payload) {
      const addressIndex = state.me.addresses.findIndex(
        address => address.id === payload.address.id
      )
      state.me.addresses[addressIndex] = payload.address
      state.me.addresses = [...state.me.addresses]
    },
    pushAddress (state, payload) {
      state.me.addresses.push(payload.address)
    },
    updateCaseState (state, payload) {
      if (payload.tab === 'TREATMENT') {
        state.currentCase.status = payload.state
      } else if (payload.tab === 'RETENTION') {
        state.currentCase.retention.status = payload.state
      } else {
        state.currentCase.refinements[payload.refinementIndex].status =
          payload.state
      }
    },
    clearCurrentCase (state, payload) {
      state.currentCase = {
        treatmentType: '',
        id: null,
        patientPhoto: null,
        doctor: {
          clinicalPreferences: null,
          name: '',
          firstSurname: ''
        },
        status: null,
        attachments: null,
        quickcheckHistory: [],
        vtoHistory: [],
        refinements: [],
        signature: '',
        merchantParameters: '',
        createdAt: null,
        messages: [],
        updateCaseNotificationError: null,
        adminNotes: ''
      }
    },
    deleteDraft (state, payload) {
      state.cases = state.cases.filter(c => c.id !== payload.id)
    },
    updateCurrentCaseId (state, payload) {
      state.currentCase = {
        ...state.currentCase,
        id: payload.id
      }
    },
    setPanelInformation (state, payload) {
      state.panelInformation = {
        doctors: payload.doctors.map(doctor => {
          return {
            ...doctor,
            fullName: `${doctor.name} ${doctor.firstSurname}`
          }
        })
      }
    },
    setDoctorDiscounts (state, payload) {
      state.panelInformation.doctors = [
        ...state.panelInformation.doctors.map(doctor => {
          if (doctor.username === payload.username) {
            return {
              ...doctor,
              discountsMemberGetMember: payload.discountsMemberGetMember,
              discountsYearly: payload.discountsYearly
            }
          }
          return doctor
        })
      ]
    },
    setAuthorizationNotification (state, payload) {
      state.authorizationStatus = payload
    },
    clearAuthorizationNotification (state) {
      state.authorizationStatus = null
    },
    setUpdateCaseNotificationError (state, payload) {
      state.updateCaseNotificationError = payload
    },
    clearUpdateCaseNotificationError (state) {
      state.updateCaseNotificationError = null
    },
    setInvoicesInformation (state, payload) {
      const invoices = payload.invoices.map(invoice => ({
        ...invoice,
        fullName: `${invoice.patientName} ${invoice.patientSurname}`
      }))
      state.me.invoices = invoices
    },
    clearInvoiceSearch (state) {
      state.invoiceSearch = ''
    },
    pushOptimisticMessage (state, payload) {
      state.currentCase.messages.push(payload)
    },
    addSchoolSignupSuccess (state, payload) {
      state.schoolSignupSuccess = true
    },
    removeSchoolSignupSuccess (state, payload) {
      state.schoolSignupSuccess = false
    },
    cleanClinicalPreferences(state) {
      state.me.clinicalPreferences = null
      state.clinicalPreferencesDoctor = null
    },

    setClinicalPreferencesDoctorName(state, payload) {
      state.clinicalPreferencesDoctorName = payload.name
    }
  },
  actions: {
    async deleteDraft (context, payload) {
      context.commit('showLoading')
      try {
        await apollo.mutate({
          mutation: require('@/graphql/DeleteDraft.gql'),
          variables: {
            id: payload.id
          }
        })

        context.commit('deleteDraft', { id: payload.id })
        context.commit('hideLoading')
      } catch (error) {
        // TODO: Handle error
      }
    },
    async fetchNewCaseViewInformation (context, payload = {}) {
      context.commit('showLoading')
      try {
        const query = await apollo.query({
          query: require('@/graphql/NewCaseView.gql'),
          variables: { caseId: payload.caseId },
          fetchPolicy: 'no-cache'
        })
        const me = query.data.me
        const cases = me.cases
        context.commit('setNotifications', { notifications: me.notifications })
        context.commit('setMe', me)
        if (payload.caseId) {
          context.commit('setCurrentCase', { case: cases.cases[0] })
        }
        context.commit('hideLoading')
      } catch (error) {
        context.commit('hideLoading')
        handleServerError(error)
      }
    },
    async fetchRefinement (context, payload) {
      context.commit('showLoading')
      try {
        const query = await apollo.query({
          query: require('@/graphql/RefinementView.gql'),
          variables: {
            caseId: payload.caseId
          },
          fetchPolicy: 'no-cache'
        })

        const me = query.data.me
        const cases = me.cases

        context.commit('setNotifications', { notifications: me.notifications })
        context.commit('setMe', me)
        context.commit('setCurrentCase', { case: cases.cases[0] })
        context.commit('hideLoading')
      } catch (error) {
        context.commit('hideLoading')
        handleServerError(error)
      }
    },
    async login (context, payload) {
      onLogin(apolloProvider.clients.defaultClient, {
        token: payload.token,
        firebaseToken: payload.firebaseToken
      })

      router.push('/lobby')
    },
    async createRefinement (context, payload) {
      try {
        const update = await apollo.mutate({
          mutation: CREATE_REFINEMENT,
          variables: payload.form
        })
        context.commit('setCreatedRefinementId', {
          id: update.data.createRefinement.id
        })
      } catch (error) {
        handleError(error)
      }
    },
    async updateRefinement (context, payload) {
      try {
        await apollo.mutate({
          mutation: UPDATE_REFINEMENT,
          variables: {
            ...payload.form,
            typeOfImpressionsAddress:
              payload.form.typeOfImpressions !== 'derivation-custom'
                ? null
                : payload.form.typeOfImpressionsAddress
          }
        })

        if (payload.form.created) {
          await context.dispatch('fetchCase', { caseId: payload.form.case })
          router.push(`/caso/${payload.form.case}`)
        }

        if (payload.reload) {
          context.dispatch('fetchCase', {
            caseId: context.state.currentCase.id
          })
        }
      } catch (error) {
        handleServerError(error)
      }
    },
    async createCase (context, payload) {
      try {
        const result = await apollo.mutate({
          mutation: CREATE_CASE,
          variables: { ...payload, id: payload.caseId }
        })
        const id = result.data.createCase.id
        context.commit('updateCurrentCaseId', { id })
      } catch (error) {
        handleServerError(error)
      }
    },
    async updateCase (context, payload) {
      try {
        await apollo
          .mutate({
            mutation: UPDATE_CASE,
            variables: {
              ...payload,
              id: payload.caseId,
              create: payload.create
            }
          })
          .then(res => {
            if (payload.create) {
              context.dispatch('fetchCases')
              router.push({ name: 'CaseListing' })
            }

            if (payload.reload) {
              context.dispatch('fetchCase', {
                caseId: context.state.currentCase.id
              })
            }
          })
      } catch (error) {
        handleServerError(error)
      }
    },
    async createAddress (context, payload) {
      try {
        const mutation = await apollo.mutate({
          mutation: CREATE_ADDRESS,
          variables: {
            clinicName: payload.clinicName,
            streetName1: payload.streetName1,
            province: payload.province,
            country: payload.country,
            postalCode: payload.postalCode,
            city: payload.city,
            phone: payload.phone,
            cif: payload.cif,
            email: payload.email,
            type: payload.type
          }
        })

        context.commit('pushAddress', {
          address: { ...payload, id: mutation.data.addAddress.id }
        })
      } catch (error) {
        handleServerError(error)
      }
    },

    async updateAddress (context, payload) {
      try {
        const variables = {
          id: payload.id,
          clinicName: payload.clinicName,
          streetName1: payload.streetName1,
          province: payload.province,
          country: payload.country,
          postalCode: payload.postalCode,
          city: payload.city,
          phone: payload.phone,
          cif: payload.cif,
          type: payload.type
        }

        if (payload.email) {
          variables.email = payload.email
        }

        await apollo.mutate({
          mutation: UPDATE_ADDRESS,
          variables
        })

        context.commit('updateAddress', { address: payload })
      } catch (error) {
        handleServerError(error)
      }
    },
    async saveClinicalPreferences (context, payload) {
      try {
        await apollo.mutate({
          mutation: SAVE_CLINICAL_PREFERENCES,
          variables: {
            ipr: payload.ipr,
            iprDelay: payload.iprDelay,
            attachmentsDelay: payload.attachmentsDelay,
            expansionForArcade: payload.expansionForArcade,
            pontics: payload.pontics,
            expansionOfArcade: payload.expansionOfArcade,
            arcadeLeveling: payload.arcadeLeveling,
            precisionCuts: payload.precisionCuts,
            notes: payload.notes
          },
          onDone: payload.onDone
        })

        context.commit('setClinicalPreferencesUpdated')
      } catch (error) {
        handleServerError(error)
      }
    },
    async fetchClinicalPreferences (context, payload) {
      try {
        let query, me
        if (payload.username) {
          query = await apollo.query({
            query: require('@/graphql/AdminClinicalPreferences.gql'),
            fetchPolicy: 'no-cache',
            variables: {
              username: payload.username
            },
          })
          me = query.data.me
          me.clinicalPreferences = query.data.doctor.clinicalPreferences
          const name = `${query.data.doctor.name} ${query.data.doctor.firstSurname}`.trim()
          context.commit('setClinicalPreferencesDoctorName',  { name })
        } else {
          query = await apollo.query({
            query: require('@/graphql/ClinicalPreferences.gql'),
            fetchPolicy: 'no-cache',
          })
          me = query.data.me
        }


        context.commit('setNotifications', { notifications: me.notifications })
        context.commit('setMe', me)
        context.commit('hideLoading')
      } catch (error) {
        handleServerError(error)
      }
    },
    async updateDoctorImage (context, payload) {
      try {
        await apollo.mutate({
          mutation: UPDATE_DOCTOR_IMAGE,
          variables: {
            image: payload.image
          }
        })

        context.commit('updateMe', payload)
      } catch (error) {
        handleServerError(error)
      }
    },
    async deleteAddress (context, payload) {
      try {
        await apollo.mutate({
          mutation: DELETE_ADDRESS,
          variables: {
            id: payload.id
          }
        })

        context.commit('deleteAddress', { id: payload.id })
      } catch (error) {
        handleServerError(error)
      }
    },
    async fetchDoctorInformation (context, payload) {
      try {
        context.commit('showLoading')
        const query = await apollo.query({
          query: require('@/graphql/DoctorInformation.gql'),
          variables: {
            cursor: (context.state.casesCurrentPage - 1).toString(),
            search: context.state.search
          },
          fetchPolicy: 'no-cache'
        })

        const me = query.data.me

        context.commit('setNotifications', { notifications: me.notifications })
        context.commit('setMe', me)
        context.commit('hideLoading')
      } catch (error) {
        handleServerError(error)
      }
    },
    async pushMessage (context, payload) {
      try {
        const a = await apollo.mutate({
          mutation: require('@/graphql/AddMessage.gql'),
          variables: {
            text: payload.message,
            id: context.state.currentCase.id
          }
        })
        context.commit('pushOptimisticMessage', a.data.createMessage)
      } catch (error) {
        // TODO: Handle error
      }
    },
    async changeCaseState (context, payload) {
      try {
        const getRefinementIdFromTab = refinementTab =>
          context.state.currentCase.refinements[refinementTab.split('_')[1]].id

        const tab = payload.currentTab
        const isRefinement = tab.includes('REFINEMENT')
        let mutation
        if (tab === 'TREATMENT') {
          mutation = UPDATE_CASE_STATE
        } else if (tab === 'RETENTION') {
          mutation = UPDATE_RETENTION_STATE
        } else {
          mutation = UPDATE_REFINEMENT_STATE
        }

        const id = isRefinement
          ? getRefinementIdFromTab(payload.currentTab)
          : context.state.currentCase.id

        await apollo.mutate({
          mutation,
          variables: {
            status: payload.state.toString(),
            id
          }
        })
        await context.dispatch('fetchCase', {
          caseId: context.state.currentCase.id
        })
        // context.dispatch('fetchCase', { caseId: context.state.currentCase.id })
      } catch (error) {
        handleServerError(error)
      }
    },

    async search (context, payload) {
      context.commit('setSearch', { search: payload.search })
      context.dispatch('fetchCases')
    },

    async searchInvoices (context, payload) {
      context.commit('setInvoiceSearch', payload)
      context.dispatch('fetchInvoicesPanelInformation')
    },
    setCasesCurrentPage (context, payload) {
      // set new current page
      context.commit('setCasesCurrentPage', { page: payload.page })
      // fetch cases of the new current page
      context.dispatch('fetchCases', {
        filter: payload.selectedStageFilters,
        sort: payload.sort,
        sortField: payload.sortField
      })
    },
    // TODO: handle selectedStageFilters
    async fetchCases (context, payload = {}) {
      const showLoading =
        typeof payload.loading === 'boolean' ? payload.loading : true

      try {
        // TODO: avoid reset cache. A better way of refresh cases after create one should be found
        if (showLoading) {
          context.commit('showLoading')
        }
        const query = await apollo.query({
          query: require('@/graphql/CaseListing.gql'),
          variables: {
            cursor: (payload.clearCurrentPage
              ? 0
              : context.state.casesCurrentPage - 1
            ).toString(),
            search: context.state.search,
            filter: context.state.selectedStageFilters,
            sort: payload.sort,
            sortField: payload.sortField,
            filterByAddress: context.state.selectedAddressFilters
          },
          fetchPolicy: 'no-cache'
          // FIXME: Assest why error is thrown instead of handled
          // error (_error) { },
        })
        const me = query.data.me
        const cases = me.cases
        if (payload.clearCurrentPage) {
          context.commit('setCasesCurrentPage', { page: 1 })
        }
        context.commit('setNotifications', { notifications: me.notifications })
        context.commit('setMe', me)
        context.commit('setCases', { cases: cases.cases, total: cases.total })
        // if (showLoading) {
        context.commit('hideLoading')
        // }
      } catch (error) {
        handleServerError(error)
      }
    },

    async fetchCase (context, payload) {
      // TODO: find a better way to get updated refinement
      try {
        if (!payload.hideLoading) {
          context.commit('showLoading')
        }
        const query = await apollo.query({
          query: require('@/graphql/Case.gql'),
          variables: {
            caseId: payload.caseId
          },
          fetchPolicy: 'no-cache'
        })
        const me = query.data.me
        const cases = me.cases
        context.commit('setNotifications', { notifications: me.notifications })
        // Only setMe if is refreshing and there is no information stored in vuex
        if (!context.state.fullName) {
          context.commit('setMe', me)
        }
        context.commit('setCurrentCase', { case: cases.cases[0] })

        context.commit('hideLoading')
      } catch (error) {
        // TODO: Refactor this to handle authentication errors in vue-apollo.js
        if (
          error.message.includes('GraphQL error: Not authenticated as doctor.')
        ) {
          onLogout(apolloProvider.clients.defaultClient)
          router.push('/')
        }
      }
    },
    async updateNotifications (context, payload) {
      try {
        const result = await apollo.mutate({
          mutation: UPDATE_NOTIFICATIONS,
          variables: {
            id: payload.id
          }
        })

        context.commit('setNotifications', {
          notifications: result.data.updateNotifications
        })
      } catch (error) {
        // TODO: Refactor this to handle authentication errors in vue-apollo.js
        if (
          error.message.includes('GraphQL error: Not authenticated as doctor.')
        ) {
          onLogout(apolloProvider.clients.defaultClient)
          router.push('/')
        }
      }
    },
    async fetchAdminPanelInformation (context, payload) {
      context.commit('showLoading')
      try {
        const query = await apollo.query({
          query: require('@/graphql/AdminPanel.gql'),
          fetchPolicy: 'no-cache'
        })
        const data = query.data

        context.commit('hideLoading')
        context.commit('setMe', data.me)
        context.commit('setNotifications', {
          notifications: data.me.notifications
        })

        context.commit('setPanelInformation', { doctors: data.doctors })
      } catch (error) {
        router.push('/')
      }
    },
    async modifyDoctorDiscounts (context, payload) {
      context.commit('showLoading')

      const fieldsToUpdate = {
        discountsMemberGetMember: payload.discountsMemberGetMember,
        discountsYearly: payload.discountsYearly
      }

      const haveFieldsToUpdate = Number.isInteger(
        Object.values(fieldsToUpdate).find(value => Number.isInteger(value))
      )

      if (haveFieldsToUpdate) {
        const variables = Object.entries(fieldsToUpdate).reduce(
          (obj, entry) => {
            if (Number.isInteger(entry[1])) {
              obj[entry[0]] = entry[1]
            }
            return obj
          },
          { username: payload.username }
        )

        const result = await apollo.mutate({
          mutation: require('@/graphql/setDoctorDiscounts.gql'),
          variables
        })

        if (result.data && result.data.setDoctorDiscounts) {
          context.commit('setDoctorDiscounts', result.data.setDoctorDiscounts)
        }
      }
      context.commit('hideLoading')
    },
    async authorize (context, payload) {
      const { collegiateNumber } = payload

      const result = await apollo.mutate({
        mutation: require('@/graphql/AuthorizeDoctor.gql'),
        variables: {
          collegiateNumber: payload.collegiateNumber
        }
      })

      if (result.data.allowDoctor) {
        context.commit('setAuthorizationNotification', {
          message: `El doctor ${collegiateNumber} ha sido autorizado`,
          title: 'Autorizado',
          type: 'success'
        })
      } else {
        context.commit('setAuthorizationNotification', {
          message: `El doctor ${collegiateNumber} ya ha sido autorizado`,
          title: 'Algo ha ido mal',
          type: 'warning'
        })
      }
    },
    async fetchInvoicesPanelInformation (context, payload) {
      context.commit('showLoading')
      try {
        const query = await apollo.query({
          query: require('@/graphql/InvoicesPanel.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            cursor: (context.state.invoicesCurrentPage - 1).toString(),
            invoiceSearch: context.state.invoiceSearch,
            filterByAddress: DEFAULT_ADDRESS_FILTERS,
            filter: DEFAULT_STAGE_FILTERS,
            invoice: true,
            invoicesCurrentPage: context.state.invoicesCurrentPage
          }
        })

        context.commit('hideLoading')
        context.commit('setMe', query.data.me)
        context.commit('setInvoicesInformation', {
          invoices: query.data.me.cases.cases
        })
      } catch (error) {
        router.push('/')
      }
    },
    async createRetention (context, payload) {
      try {
        await apollo.mutate({
          mutation: CREATE_RETENTION,
          variables: {
            ...payload.form,
            caseId: payload.form.case
          }
        })
        // context.commit('setCreatedRefinementId', { id: update.data.createRefinement.id })
      } catch (error) {
        handleServerError(error)

      }
    },
    async updateRetention (context, payload) {
      try {
        await apollo.mutate({
          mutation: UPDATE_RETENTION,
          variables: {
            ...payload.form,
            typeOfImpressionsAddress:
              payload.form.typeOfImpressions !== 'derivation-custom'
                ? null
                : payload.form.typeOfImpressionsAddress
          }
        })

        if (payload.form.created) {
          await context.dispatch('fetchCase', { caseId: payload.form.case })
          router.push(`/caso/${payload.form.case}`)
        }

        if (payload.reload) {
          context.dispatch('fetchCase', {
            caseId: context.state.currentCase.id
          })
        }
      } catch (error) {
        handleServerError(error)

      }
    },
    async fetchRetention (context, payload) {
      context.commit('showLoading')
      try {
        const query = await apollo.query({
          query: require('@/graphql/RetentionForm.gql'),
          variables: {
            caseId: payload.caseId
          },
          fetchPolicy: 'no-cache'
        })

        const me = query.data.me
        const cases = me.cases

        context.commit('setNotifications', { notifications: me.notifications })
        context.commit('setMe', me)
        context.commit('setCurrentCase', { case: cases.cases[0] })
        context.commit('hideLoading')
      } catch (error) {
        context.commit('hideLoading')
        // TODO: Refactor this to handle authentication errors in vue-apollo.js
        if (
          error.message.includes('GraphQL error: Not authenticated as doctor.')
        ) {
          onLogout(apolloProvider.clients.defaultClient)
          router.push('/')
        }
      }
    },
    async saveAdminNotes (context, payload) {
      context.commit('setAdminNotes', { notes: payload.notes })
      await apollo.mutate({
        mutation: SAVE_ADMIN_NOTES,
        variables: { ...payload }
      })
    },
    async schoolSignup (context, payload) {
      try {
        await apollo.mutate({
          mutation: SCHOOL_SIGNUP,
          variables: {
            teacherUsername: payload.teacherUsername,
            teacherEmail: payload.teacherEmail,
            teacherPassword: payload.teacherPassword,
            teacherSpecialism: payload.teacherSpecialism,
            teacherName: payload.teacherName,
            teacherFirstSurname: payload.teacherFirstSurname,
            studentUsername: payload.studentUsername,
            studentEmail: payload.studentEmail,
            studentPassword: payload.studentPassword,
            studentSpecialism: payload.studentSpecialism,
            studentName: payload.studentName,
            studentFirstSurname: payload.studentFirstSurname
          }
        })
        context.commit('addSchoolSignupSuccess')
      } catch (err) {
        // TODO: handle error
      }
    },
    async sendMissingAlignmentsEmail (context, payload) {
      await apollo.mutate({
        mutation: SEND_MISSING_ALIGNMENTS_EMAIL,
        variables: {
          id: payload.id
        }
      })
      // TODO: return case in mutation
      return await context.dispatch('fetchCase', { caseId: payload.id })
    }
  }
})

function normalizeCase (c) {
  const isRetention = c.retention && c.retention.createdAtProduction
  const isRefinement =
    !isRetention && c.refinements && !!c.refinements.find(x => x.created)
  const lastStatus = getLastStatus(c)
  const caseType = getCaseType(c)
  const isPost15N = new Date(c.createdAt) > new Date('2021-11-15')
  return {
    ...c,
    lastStatus,
    patientFullName: `${c.patientName} ${c.patientSurname}`,
    computedTreatmentType: formatTreatmentType(c.treatmentType, isPost15N),
    computedCreatedAt: formatDate(c.createdAt),
    isRefinement,
    isRetention,
    caseType,
    quickcheck: getQuickcheckFromCase(c),
    vto: getVTOFromCase(c),
    doctorName: `${c.doctor.name} ${c.doctor.firstSurname}`,
    status: c.created ? getNameOfStage(lastStatus, caseType) : 'Borrador'
  }
}

function handleServerError(error) {
  if (
    error.message.includes('Not authenticated as doctor')
  ) {
    onLogout(apolloProvider.clients.defaultClient)
    router.push('/')
  }
}
