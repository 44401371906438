var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Fieldset',{class:{ 'sagital-title': _vm.centerTitle },attrs:{"label":"Solución para mejorar la relación sagital"}},[_c('div',{staticClass:"sagitals"},[_c('div',{staticClass:"sagital"},[_c('div',[_c('el-checkbox-group',{model:{value:(_vm.rightSagitalRelationshipSolutionInternal),callback:function ($$v) {_vm.rightSagitalRelationshipSolutionInternal=$$v},expression:"rightSagitalRelationshipSolutionInternal"}},[_c('el-checkbox',{attrs:{"label":"ipr","disabled":_vm.disabled}},[_c('span',{staticClass:"radio-label"},[_vm._v("IPR")])]),_c('el-checkbox',{attrs:{"label":"extrude","disabled":_vm.disabled}},[_c('span',{staticClass:"radio-label"},[_vm._v("Elásticos Clase II / III")])]),_c('el-checkbox',{attrs:{"label":"distance","disabled":_vm.disabled}},[_c('span',{staticClass:"radio-label"},[_vm._v("Distalar molares (Uso de elásticos intermaxilares si es necesario)")])])],1)],1)]),_c('div',{staticClass:"sagital"},[_c('div',[_c('el-checkbox-group',{model:{value:(_vm.leftSagitalRelationshipSolutionInternal),callback:function ($$v) {_vm.leftSagitalRelationshipSolutionInternal=$$v},expression:"leftSagitalRelationshipSolutionInternal"}},[_c('el-checkbox',{attrs:{"label":"ipr","disabled":_vm.disabled}},[_c('span',{staticClass:"radio-label"},[_vm._v("IPR")])]),_c('el-checkbox',{attrs:{"label":"extrude","disabled":_vm.disabled}},[_c('span',{staticClass:"radio-label"},[_vm._v("Elásticos Clase II / III")])]),_c('el-checkbox',{attrs:{"label":"distance","disabled":_vm.disabled}},[_c('span',{staticClass:"radio-label"},[_vm._v("Distalar molares (Uso de elásticos intermaxilares si es necesario)")])])],1)],1)])]),(
      _vm.leftSagitalRelationshipSolutionInternal.includes('extrude') ||
        _vm.leftSagitalRelationshipSolutionInternal.includes('distance') ||
        _vm.rightSagitalRelationshipSolutionInternal.includes('extrude') ||
        _vm.rightSagitalRelationshipSolutionInternal.includes('distance')
    )?_c('div',[_c('p',[_vm._v(" Seleccione donde desea botón "),_c('span',{staticClass:"bold"},[_vm._v("\"B\"")]),_vm._v(" o recorte "),_c('span',{staticClass:"bold"},[_vm._v("RC")]),_vm._v(" para solucionar la relación sagital: ")]),_c('div',{staticClass:"teeth"},[_c('div',{staticClass:"teeth-row"},[_c('div',{staticClass:"quart"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up"},[_c('el-select',{class:{
                marked: _vm.sagitalRelationshipSolutionExtraInternal[n - 1]
              },attrs:{"size":"mini","placeholder":("1." + (9 - n)),"disabled":_vm.disabled},model:{value:(_vm.sagitalRelationshipSolutionExtraInternal[n - 1]),callback:function ($$v) {_vm.$set(_vm.sagitalRelationshipSolutionExtraInternal, n - 1, $$v)},expression:"sagitalRelationshipSolutionExtraInternal[n - 1]"}},[_c('el-option',{key:"-",attrs:{"label":("1." + (9 - n)),"value":""}},[_vm._v("-")]),_c('el-option',{key:"B",attrs:{"label":"B","value":"B"}}),_c('el-option',{key:"RC",attrs:{"label":"RC","value":"RC"}})],1)],1)}),0),_c('div',{staticClass:"quart"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspacerightup',staticClass:"tooth up"},[_c('el-select',{class:{
                marked: _vm.sagitalRelationshipSolutionExtraInternal[8 + n - 1]
              },attrs:{"size":"mini","placeholder":("2." + n),"disabled":_vm.disabled},model:{value:(_vm.sagitalRelationshipSolutionExtraInternal[8 + n - 1]),callback:function ($$v) {_vm.$set(_vm.sagitalRelationshipSolutionExtraInternal, 8 + n - 1, $$v)},expression:"sagitalRelationshipSolutionExtraInternal[8 + n - 1]"}},[_c('el-option',{key:"-",attrs:{"label":("2." + n),"value":""}},[_vm._v("-")]),_c('el-option',{key:"B",attrs:{"label":"B","value":"B"}}),_c('el-option',{key:"RC",attrs:{"label":"RC","value":"RC"}})],1)],1)}),0)]),_c('div',{staticClass:"teeth-row"},[_c('div',{staticClass:"quart"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspaceleftdown',staticClass:"tooth down"},[_c('el-select',{class:{
                marked: _vm.sagitalRelationshipSolutionExtraInternal[16 + n - 1]
              },attrs:{"size":"mini","placeholder":("4." + (9 - n)),"disabled":_vm.disabled},model:{value:(_vm.sagitalRelationshipSolutionExtraInternal[16 + n - 1]),callback:function ($$v) {_vm.$set(_vm.sagitalRelationshipSolutionExtraInternal, 16 + n - 1, $$v)},expression:"sagitalRelationshipSolutionExtraInternal[16 + n - 1]"}},[_c('el-option',{key:"-",attrs:{"label":("4." + (9 - n)),"value":""}},[_vm._v("-")]),_c('el-option',{key:"B",attrs:{"label":"B","value":"B"}}),_c('el-option',{key:"RC",attrs:{"label":"RC","value":"RC"}})],1)],1)}),0),_c('div',{staticClass:"quart"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspacerightdown',staticClass:"tooth down"},[_c('el-select',{class:{
                marked: _vm.sagitalRelationshipSolutionExtraInternal[24 + n - 1]
              },attrs:{"size":"mini","placeholder":("3." + n),"disabled":_vm.disabled},model:{value:(_vm.sagitalRelationshipSolutionExtraInternal[24 + n - 1]),callback:function ($$v) {_vm.$set(_vm.sagitalRelationshipSolutionExtraInternal, 24 + n - 1, $$v)},expression:"sagitalRelationshipSolutionExtraInternal[24 + n - 1]"}},[_c('el-option',{key:"-",attrs:{"label":("3." + n),"value":""}},[_vm._v("-")]),_c('el-option',{key:"B",attrs:{"label":"B","value":"B"}}),_c('el-option',{key:"RC",attrs:{"label":"RC","value":"RC"}})],1)],1)}),0)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }