<template>
  <div>
    Success
  </div>
</template>

<script>
export default {
  name: 'Success'
}
</script>

<style></style>
