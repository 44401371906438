<template>
  <Fieldset label="Fotografías del desajuste">
    <input
      :style="{ display: 'none' }"
      ref="localUnfit1Image"
      v-on:change="e => onUploadImage(e, 'localUnfit1Image')"
      type="file"
    />
    <input
      :style="{ display: 'none' }"
      ref="localUnfit2Image"
      v-on:change="e => onUploadImage(e, 'localUnfit2Image')"
      type="file"
    />

    <div class="grid-uploader">
      <div class="item">
        <img
          src="../assets/unfit.jpg"
          v-if="!localUnfit1Image"
          @click="() => isAdmin && triggerUpload('localUnfit1Image')"
          ref="localUnfit1Icon"
          class="big-uploader"
        />
        <img
          v-show="localUnfit1Image"
          class="big-uploader"
          :class="{ clickable: isAdmin }"
          ref="localUnfit1ImageImage"
          @click="() => isAdmin && triggerUpload('localUnfit1Image')"
          :src="localUnfit1Image"
        />
        <el-form-item label prop="localUnfit1Image">
          <el-input
            :style="{ display: 'none' }"
            v-model="localUnfit1Image"
            ref="localUnfit1ImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>

      <div class="item">
        <img
          src="../assets/unfit.jpg"
          v-if="!localUnfit2Image"
          @click="() => isAdmin && triggerUpload('localUnfit2Image')"
          ref="localUnfit1Icon"
          class="big-uploader"
        />
        <img
          v-show="localUnfit2Image"
          class="big-uploader"
          :class="{ clickable: isAdmin }"
          ref="localUnfit2ImageImage"
          @click="() => isAdmin && triggerUpload('localUnfit2Image')"
          :src="localUnfit2Image"
        />
        <el-form-item label prop="localUnfit2Image">
          <el-input
            :style="{ display: 'none' }"
            v-model="localUnfit2Image"
            ref="localUnfit2ImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
    </div>
  </Fieldset>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from '@/components/Fieldset'

export default {
  name: 'FieldUnfitImages',
  components: {
    Fieldset
  },
  props: {
    isAdmin: {
      required: false,
      default: true
    },
    unfit1Image: {
      required: false,
      type: String
    },
    unfit2Image: {
      required: false,
      type: String
    }
  },
  computed: {
    localUnfit1Image: {
      get () {
        return this.unfit1Image
      },
      set (inputValue) {
        this.$emit('input-unfit-1', inputValue)
      }
    },
    localUnfit2Image: {
      get () {
        return this.unfit2Image
      },
      set (inputValue) {
        this.$emit('input-unfit-2', inputValue)
      }
    }
  },
  methods: {
    triggerUpload (name) {
      this.$refs[name].click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]

      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      }

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''

      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
          this[fieldName] = downloadURL

          if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.grid-uploader {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 24px;
}

.big-uploader {
  width: 208px;
  height: 104px;
  font-size: 25px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);

  &.clickable {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .grid-uploader {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-content: flex-start;

    .item {
      display: flex;
      justify-self: center;
      margin-bottom: 8px;
    }
  }
}
</style>
