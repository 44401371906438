<template>
  <div>
    <div class="footer">
      <div class="links">
        <el-link
          class="link"
          href="https://quicksmile.es/aviso-legal/"
          target="_blank"
          rel="noreferrer"
          >Aviso legal</el-link
        >
        <el-link
          class="link"
          href="https://quicksmile.es/tycdoctores"
          target="_blank"
          rel="noreferrer"
          >Términos y condiciones</el-link
        >
      </div>

      <div class="contact">
        <p class="contact-item">
          Email:
          <a class="mail" href="mailto:casos@quicksmile.es"
            >casos@quicksmile.es</a
          >
        </p>
        <p class="contact-item slider">|</p>
        <p class="contact-item">
          Tlf: <a class="telf" href="tel:915288773">91 528 87 73</a>
        </p>
      </div>
      <p class="copyright">QuickSmile, S.L. Copyright {{ currentYear }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
.contact {
  display: flex;
  .mail,
  .telf {
    text-decoration: none;
    color: black;
    white-space: nowrap;
  }
  .contact-item {
    margin-right: 8px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid black;
  padding: 16px 16px 16px 16px;

  .links {
    display: flex;
    align-content: center;

    .link {
      margin-right: 24px;
      font-size: 16px;
      font-weight: 500;
      color: black;
    }
  }

  .copyright {
    padding: 0;
    margin: 0;
    font-weight: 600;
  }
}

@media (max-width: 1000px) {
  .footer {
    .contact {
      .contact-item {
        margin: 4px;
        text-align: center;
      }
      flex-direction: column;
      .slider {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 32px;
    display: flex;
    align-items: center;
    border-top: 1px solid black;
    flex-direction: column;

    .links {
      align-items: center;
      flex-direction: column;

      .link {
        margin-bottom: 16px;
      }
    }

    .contact {
      .contact-item {
        margin: 0 8px 16px 0;
        text-align: center;
      }
    }
  }
}
</style>
