<template>
  <div class="wrapper">
    <el-timeline class="timeline" :reverse="false">
      <el-timeline-item
        size="large"
        type="primary"
        v-for="(vto, index) in vtos"
        :key="index"
        :timestamp="vto.date ? formatDate(vto.date) : null"
      >
        <a
          :href="vto.vto"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
          >VTO #{{ index + 1 }}</a
        >
      </el-timeline-item>
    </el-timeline>

    <el-timeline class="timeline" :reverse="false">
      <el-timeline-item
        size="large"
        type="primary"
        v-for="(quickcheck, index) in quickchecks"
        :key="index"
        :timestamp="quickcheck.date ? formatDate(quickcheck.date) : null"
      >
        <a
          :href="quickcheck.quickcheck"
          class="link"
          target="_blank"
          rel="noopener noreferrer"
          >QuickCheck #{{ index + 1 }}</a
        >
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { formatDate } from '@/utils'
export default {
  name: 'VtoQuickcheckRecord',
  props: {
    quickchecks: Array,
    vtos: Array
  },
  methods: {
    formatDate
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: center;

  .timeline {
    margin-top: 24px;
    margin-right: 36px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .link {
      color: black;
      text-decoration: none;
    }
  }
}
</style>
