<template>
  <div class="signup">
    <el-link
      class="login"
      type="primary"
      :underlined="true"
      @click="() => $router.push('/inicio-sesion')"
      >Login</el-link
    >

    <div class="content">
      <img class="logo" src="../assets/qs1.svg" />
      <p class="title">Recupera tu contraseña</p>

      <ApolloMutation
        class="wrapper"
        :mutation="require('../graphql/SendPasswordResetEmail.gql')"
        :variables="{
          email: this.login
        }"
        :update="onDone"
      >
        <template v-slot="{ mutate, loading, error }">
          <form class="form" v-on:submit.prevent="formValid && mutate()">
            <input
              class="field"
              v-model="login"
              placeholder="Correo electrónico"
              id="email"
            />
            <el-button class="send" type="primary" native-type="submit"
              >Enviar</el-button
            >
            <p v-if="error" class="error">
              Ha ocurrido un error al recuperar la contraseña
            </p>
            <p v-if="submited && !error">Revise su bandeja de entrada</p>
          </form>
        </template>
      </ApolloMutation>
    </div>

    <Footer class="footer-wrapper" />
  </div>
</template>

<script>
import Footer from '@/components/Footer'

export default {
  name: 'Signup',
  components: {
    Footer
  },
  data () {
    return {
      login: '',
      submited: false
    }
  },

  computed: {
    formValid () {
      return true
    }
  },
  methods: {
    onDone (ev, result) {
      if (result.data.sendPasswordResetEmail) {
        this.submited = true
      }
    },
    goToNextStep () {
      this.step += 1
    },
    updateToken (store, response) {
      // pass token and firebaseToken
      this.$store.dispatch('login', response.data.signin)
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  color: #f56c6c;
}

.signup {
  box-sizing: border-box;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 24px;

  .login {
    width: min-content;
    justify-self: flex-end;
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      height: 170px;
    }

    .title {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
    }

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 288px;
        align-self: center;
        justify-self: center;

        .field {
          margin-bottom: 16px;

          &:last-of-type {
            margin-bottom: 8px;
          }
        }

        .password-recovery {
          width: max-content;
          margin-bottom: 16px;
          display: block;
          color: black;
          font-size: 14px;
        }

        .send {
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
