<template>
  <Fieldset
    label="Solución para mejorar la relación sagital"
    :class="{ 'sagital-title': centerTitle }"
  >
    <div class="sagitals">
      <div class="sagital">
        <div>
          <el-checkbox-group v-model="rightSagitalRelationshipSolutionInternal">
            <el-checkbox label="ipr" :disabled="disabled">
              <span class="radio-label">IPR</span>
            </el-checkbox>
            <el-checkbox label="extrude" :disabled="disabled">
              <span class="radio-label">Elásticos Clase II / III</span>
            </el-checkbox>
            <el-checkbox label="distance" :disabled="disabled">
              <span class="radio-label"
                >Distalar molares (Uso de elásticos intermaxilares si es
                necesario)</span
              >
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="sagital">
        <div>
          <el-checkbox-group v-model="leftSagitalRelationshipSolutionInternal">
            <el-checkbox label="ipr" :disabled="disabled">
              <span class="radio-label">IPR</span>
            </el-checkbox>
            <el-checkbox label="extrude" :disabled="disabled">
              <span class="radio-label">Elásticos Clase II / III</span>
            </el-checkbox>
            <el-checkbox label="distance" :disabled="disabled">
              <span class="radio-label"
                >Distalar molares (Uso de elásticos intermaxilares si es
                necesario)</span
              >
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <div
      v-if="
        leftSagitalRelationshipSolutionInternal.includes('extrude') ||
          leftSagitalRelationshipSolutionInternal.includes('distance') ||
          rightSagitalRelationshipSolutionInternal.includes('extrude') ||
          rightSagitalRelationshipSolutionInternal.includes('distance')
      "
    >
      <p>
        Seleccione donde desea botón <span class="bold">"B"</span> o recorte
        <span class="bold">RC</span> para solucionar la relación sagital:
      </p>

      <div class="teeth">
        <div class="teeth-row">
          <div class="quart">
            <div v-for="n in 8" :key="n + 'teethspaceleftup'" class="tooth up">
              <el-select
                size="mini"
                v-model="sagitalRelationshipSolutionExtraInternal[n - 1]"
                :placeholder="`1.${9 - n}`"
                :disabled="disabled"
                :class="{
                  marked: sagitalRelationshipSolutionExtraInternal[n - 1]
                }"
              >
                <el-option key="-" :label="`1.${9 - n}`" value="">-</el-option>
                <el-option key="B" label="B" value="B"> </el-option>
                <el-option key="RC" label="RC" value="RC"></el-option>
              </el-select>
            </div>
          </div>

          <div class="quart">
            <div v-for="n in 8" :key="n + 'teethspacerightup'" class="tooth up">
              <el-select
                size="mini"
                v-model="sagitalRelationshipSolutionExtraInternal[8 + n - 1]"
                :placeholder="`2.${n}`"
                :disabled="disabled"
                :class="{
                  marked: sagitalRelationshipSolutionExtraInternal[8 + n - 1]
                }"
              >
                <el-option key="-" :label="`2.${n}`" value="">-</el-option>
                <el-option key="B" label="B" value="B"> </el-option>
                <el-option key="RC" label="RC" value="RC"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="teeth-row">
          <div class="quart">
            <div
              v-for="n in 8"
              :key="n + 'teethspaceleftdown'"
              class="tooth down"
            >
              <el-select
                size="mini"
                v-model="sagitalRelationshipSolutionExtraInternal[16 + n - 1]"
                :placeholder="`4.${9 - n}`"
                :disabled="disabled"
                :class="{
                  marked: sagitalRelationshipSolutionExtraInternal[16 + n - 1]
                }"
              >
                <el-option key="-" :label="`4.${9 - n}`" value="">-</el-option>
                <el-option key="B" label="B" value="B"> </el-option>
                <el-option key="RC" label="RC" value="RC"></el-option>
              </el-select>
            </div>
          </div>

          <div class="quart">
            <div
              v-for="n in 8"
              :key="n + 'teethspacerightdown'"
              class="tooth down"
            >
              <el-select
                size="mini"
                v-model="sagitalRelationshipSolutionExtraInternal[24 + n - 1]"
                :placeholder="`3.${n}`"
                :disabled="disabled"
                :class="{
                  marked: sagitalRelationshipSolutionExtraInternal[24 + n - 1]
                }"
              >
                <el-option key="-" :label="`3.${n}`" value="">-</el-option>
                <el-option key="B" label="B" value="B"> </el-option>
                <el-option key="RC" label="RC" value="RC"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fieldset>
</template>

<script>
import Fieldset from '../Fieldset.vue'

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    rightSagitalRelationshipSolution: {
      required: false
    },
    leftSagitalRelationshipSolution: {
      required: false
    },
    sagitalRelationshipSolutionExtra: {
      required: true
    },
    centerTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rightSagitalRelationshipSolutionInternal: {
      get () {
        return this.rightSagitalRelationshipSolution || ''
      },
      set (val) {
        this.$emit('rightSagitalRelationshipSolution', val)
      }
    },
    leftSagitalRelationshipSolutionInternal: {
      get () {
        return this.leftSagitalRelationshipSolution || ''
      },
      set (val) {
        this.$emit('leftSagitalRelationshipSolution', val)
      }
    },
    sagitalRelationshipSolutionExtraInternal: {
      get () {
        return this.sagitalRelationshipSolutionExtra || ''
      },
      set (val) {
        this.$emit('sagitalRelationshipSolutionExtra', val)
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss">
.sagital-title {
  text-align: center;
}

.marked {
  input {
    border: 2px solid black;
  }
}

.sagitals {
  min-width: 470px;
  text-align: initial;
  display: flex;
  .sagital {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .el-checkbox {
      font-size: 18px;
      display: flex;
      white-space: initial;
      margin-bottom: 8px;
      .el-radio__label {
        font-size: 18px;
      }
    }

    .image {
      width: 200px;
    }

    .radio-options {
      display: flex;
      flex-direction: column;
    }
  }

  .sagital:first-of-type {
    border-right: 1px solid black;
  }

  .sagital:last-of-type {
    padding-left: 15px;
  }
}

.teeth {
  .teeth-row {
    display: flex;
    width: min-content;

    .quart {
      display: flex;
      .tooth {
        align-items: center;
        display: flex;
        margin: 0;
        margin-right: 8px;

        .el-select {
          padding-bottom: 8px;
          padding-top: 8px;
        }
        .el-input {
          font-size: 11px;
          width: 42px;

          .el-input__inner {
            padding-left: 5px;
            padding-right: 20px;
          }
          .el-input__suffix {
            right: 0;
          }
        }

        &.up {
          flex-direction: column;
        }

        .number {
          margin: -10px;
          font-weight: 500;
          font-size: 10px;
        }

        .checkbox {
          display: flex;

          &.up {
            flex-direction: column-reverse;
          }

          &.down {
            padding-top: 8px;
            flex-direction: column;
          }
        }
      }
    }

    .quart:first-of-type {
      border-right: 1px solid black;
      margin-right: 8px;
    }
  }
  .teeth-row:first-of-type {
    border-bottom: 1px solid black;
  }
}
</style>
