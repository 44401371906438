<template>
  <Fieldset label="Dejar espacio en los siguientes dientes">
    <el-radio
      :label="false"
      v-model="hasTeethSpaceInternal"
      :disabled="disabled"
    >
      <span class="radio-label">No dejar espacio</span>
    </el-radio>
    <el-radio
      :label="true"
      v-model="hasTeethSpaceInternal"
      :disabled="disabled"
    >
      <span class="radio-label">Dejar espacio en los siguientes dientes:</span>
    </el-radio>
    <div class="teeth-double">
      <Fieldset label="">
        <!-- 1 -->
        <div class="teeth-row" :class="{ disabled: !hasTeethSpaceInternal }">
          <div class="quart" :class="{ disabled: !hasTeethSpaceInternal }">
            <div class="sub">
              <div
                v-for="n in 7"
                :key="n + 'teethspaceleftup'"
                class="tooth up"
              >
                <el-input
                  v-model="teethSpaceInternal[n - 1]"
                  :placeholder="`${9 - n}-${8 - n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    marked:
                      teethSpaceInternal[n - 1] &&
                      teethSpaceInternal[n - 1] !== `${9 - n}-${8 - n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="3"
                />
              </div>
            </div>
            <div class="sub">
              <div
                v-for="n in 8"
                :key="n + 'teethspaceleftup'"
                class="tooth up br mb pt"
                :class="{ disabled: !hasTeethSpaceInternal }"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 6]"
                  :placeholder="`1.${9 - n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    marked:
                      teethSpaceInternal[n + 6] &&
                      teethSpaceInternal[n + 6] !== `1.${9 - n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="quart" :class="{ disabled: !hasTeethSpaceInternal }">
            <div class="sub">
              <div
                v-for="n in 7"
                :key="n + 'teethspaceleftup'"
                class="tooth up"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 14]"
                  :placeholder="`${n}-${n + 1}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    disabled: !hasTeethSpaceInternal,
                    marked:
                      teethSpaceInternal[n + 14] &&
                      teethSpaceInternal[n + 14] !== `${n}-${n + 1}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
            <div class="sub">
              <div
                v-for="n in 8"
                :key="n + 'teethspaceleftup'"
                class="tooth up br pt"
                :class="{ disabled: !hasTeethSpaceInternal }"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 21]"
                  :placeholder="`2.${n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    marked:
                      teethSpaceInternal[n + 21] &&
                      teethSpaceInternal[n + 21] !== `2.${n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="teeth-row" :class="{ disabled: !hasTeethSpaceInternal }">
          <!-- 3 -->
          <div class="quart" :class="{ disabled: !hasTeethSpaceInternal }">
            <div class="sub">
              <div
                v-for="n in 8"
                :key="n + 'teethspaceleftup'"
                class="tooth up br mt pb"
                :class="{ disabled: !hasTeethSpaceInternal }"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 29]"
                  :placeholder="`4.${9 - n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    marked:
                      teethSpaceInternal[n + 29] &&
                      teethSpaceInternal[n + 29] !== `4.${9 - n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
            <div class="sub">
              <div
                v-for="n in 7"
                :key="n + 'teethspaceleftup'"
                class="tooth up"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 37]"
                  :placeholder="`${9 - n}-${8 - n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    disabled: !hasTeethSpaceInternal,
                    marked:
                      teethSpaceInternal[n + 37] &&
                      teethSpaceInternal[n + 37] !== `${9 - n}-${8 - n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
          <!--  4-->
          <div class="quart">
            <div class="sub">
              <div
                v-for="n in 8"
                :key="n + 'teethspaceleftup'"
                :class="{ disabled: !hasTeethSpaceInternal }"
                class="tooth up br mt pb"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 44]"
                  :placeholder="`3.${n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    marked:
                      teethSpaceInternal[n + 44] &&
                      teethSpaceInternal[n + 44] !== `3.${n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
            <div class="sub">
              <div
                v-for="n in 7"
                :key="n + 'teethspaceleftup'"
                class="tooth up"
              >
                <el-input
                  v-model="teethSpaceInternal[n + 52]"
                  :placeholder="`${n}-${1 + n}`"
                  size="mini"
                  class="mini-input"
                  :class="{
                    disabled: !hasTeethSpaceInternal,
                    marked:
                      teethSpaceInternal[n + 52] &&
                      teethSpaceInternal[n + 52] !== `${n}-${1 + n}`
                  }"
                  :disabled="!hasTeethSpaceInternal || disabled"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
    </div>
  </Fieldset>
</template>

<script>
import Fieldset from '@/components/Fieldset'

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    teethSpace: {
      required: true
    },
    hasTeethSpace: {
      required: true
    }
  },
  computed: {
    hasTeethSpaceInternal: {
      get () {
        return this.hasTeethSpace || false
      },
      set (val) {
        this.$emit('hasTeethSpace', val)
      }
    },
    teethSpaceInternal: {
      get () {
        return this.teethSpace || []
      },
      set (val) {
        this.$emit('teethSpace', val)
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss">
.el-radio {
  margin-bottom: 8px;
}
.el-radio__label {
  font-size: 18px;
}

.mini-input {
  width: 30px !important;
  .el-input__inner {
    padding: 5px !important;
    width: 30px !important;
  }
}

.marked {
  input {
    border: 2px solid black;
  }
}

.teeth-double {
  .teeth-row {
    display: flex;
    width: min-content;

    .quart {
      display: flex;
      flex-direction: column;

      .sub {
        justify-content: center;
        display: flex;
        .tooth {
          align-items: center;
          display: flex;
          margin: 0;
          margin-right: 8px;

          &.pb {
            padding-bottom: 4px;
          }

          &.pt {
            padding-top: 4px;
          }
          &.mb {
            margin-bottom: 8px;
          }

          &.up {
            flex-direction: column;
            padding-right: 4px;
            margin-right: 4px;
          }
          &.mt {
            margin-top: 8px;
          }
          &.br {
            border-right: 1px solid #aaa;
          }
          &.br:last-of-type {
            border-right: none;
          }

          &.br {
            &.disabled {
              border-color: #e4e7ed;
            }
          }

          .number {
            margin: -10px;
            font-weight: 500;
            font-size: 10px;
          }

          .checkbox {
            display: flex;

            &.up {
              flex-direction: column-reverse;
            }

            &.down {
              padding-top: 8px;
              flex-direction: column;
            }
          }
        }
      }
    }

    .quart:first-of-type {
      border-right: 1px solid black;
      margin-right: 8px;

      &.disabled {
        border-color: #e4e7ed !important;
      }
    }
  }
  .teeth-row:first-of-type {
    border-bottom: 1px solid black;
    &.disabled {
      border-color: #e4e7ed !important;
    }
  }
}

@media (max-width: 890px) {
  .teeth-double {
    .teeth-row {
      display: flex;
      flex-direction: column;

      .quart {
        border-right: none !important;
      }
      .quart:last-of-type {
        padding-bottom: 8px;
      }
    }
  }
}
</style>
