<template>
  <div
    @click="e => $emit('click', e)"
    class="address"
    :class="{ editable, picked: selected, pickable: selectable }"
  >
    <div class="field">
      <p v-if="type === 'billing'" class="title">Razón social</p>
      <p v-else class="title">Clínica</p>
      <p class="value">{{ clinicName }}</p>
    </div>
    <div class="field">
      <p class="title">Direccion</p>
      <p class="value">{{ streetName }}</p>
    </div>
    <div class="field">
      <p class="title">País</p>
      <p class="value">{{ country }}</p>
    </div>
    <div class="field">
      <p class="title">Provincia</p>
      <p class="value">{{ province }}</p>
    </div>
    <div class="field">
      <p class="title">Código postal</p>
      <p class="value">{{ postalCode }}</p>
    </div>
    <div class="field">
      <p class="title">Ciudad</p>
      <p></p>
      <p class="value">{{ city }}</p>
    </div>
    <div class="field">
      <p class="title">Teléfono</p>
      <p class="value">{{ phone }}</p>
    </div>
    <div v-if="showEmail" class="field">
      <p class="title">Email</p>
      <p class="value">{{ email || 'Sin especificar' }}</p>
    </div>
    <div v-if="cif" class="field">
      <p class="title">CIF / VAT number</p>
      <p class="value">{{ cif }}</p>
    </div>
    <div v-if="editable" class="field options">
      <el-button
        type="primary"
        @click="$emit('delete')"
        class="delete"
        size="small"
        >Eliminar</el-button
      >
      <el-button
        type="primary"
        @click="$emit('update')"
        class="update"
        size="small "
        >Editar</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressCard',
  props: {
    type: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    clinicName: {
      type: String,
      required: true
    },
    streetName: {
      type: String,
      required: true
    },
    city: {
      type: String,
      required: true
    },
    country: {
      type: String,
      required: true
    },
    province: {
      type: String,
      required: true
    },
    postalCode: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    cif: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    },
    editable: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    showEmail () {
      return this.type === 'billing'
    }
  }
}
</script>

<style scoped lang="scss">
.address {
  width: 296px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border: 4px solid transparent;

  .field {
    padding: 0 16px;
    color: #000000;
    border-bottom: 1px solid lightgrey;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .value {
      font-size: 14px;
    }

    &.options {
      border: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      padding-top: 16px;
    }

    &:last-of-type {
      border: none;
    }
  }

  &.editable {
    .field {
      &:nth-last-of-type(2) {
        border: none;
      }
    }
  }

  &.picked {
    border: 4px solid black;
  }

  &.pickable {
    cursor: pointer;

    &:hover {
      border: 4px solid black;
    }
  }
}
</style>
