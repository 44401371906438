<template>
  <Fieldset label="Objetivos el refinamiento">
    <el-input v-model="inputValue" rows="8" type="textarea" resize="none" />
  </Fieldset>
</template>

<script>
import Fieldset from '@/components/Fieldset'

export default {
  name: 'RefinementGoals',
  components: {
    Fieldset
  },
  props: {
    value: {
      required: false,
      type: String
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (inputValue) {
        this.$emit('input', inputValue)
      }
    }
  }
}
</script>

<style></style>
