<template>
  <el-form-item ref="patientName" prop="patientName">
    <el-input
      v-on="$listeners"
      v-bind="$attrs"
      :value="value"
      placeholder="Nombre"
    />
  </el-form-item>
</template>

<script>
export default {
  props: {
    value: {
      required: false
    }
  }
}
</script>
