import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { apolloProvider } from './vue-apollo'
import './plugins/element.js'
import { getStorage, ref } from "firebase/storage";
import { initializeApp } from 'firebase/app';



import VueDataTables from 'vue-data-tables'
import store from './store'

Vue.config.devtools = true

Vue.use(VueDataTables)
Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: 'AIzaSyAe7hGGSroO9lMvCwV8paOftk4JOe43Gjo',
  authDomain: 'quicksmile-555e3.firebaseapp.com',
  databaseURL: 'https://quicksmile-555e3.firebaseio.com',
  projectId: 'quicksmile-555e3',
  storageBucket: 'quicksmile-555e3.appspot.com',
  messagingSenderId: '618689354329',
  appId: '1:618689354329:web:f933f1a395ee79fe'
}
// Initialize Firebase
initializeApp(firebaseConfig)

const storage = getStorage();
const storageRef = (id, image) => ref(storage, `/${id}/${image}`)

Vue.prototype.$firebase = storageRef

new Vue({
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
