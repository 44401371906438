<template>
  <Fieldset label="Solución para mejorar la línea media">
    <div class="height">
      <p>ARCADA SUPERIOR</p>

      <!-- Form validation error in this field is handle using element el-form-item and rules prop in parent  -->
      <el-form-item
        class="radios"
        ref="upperMiddlelineSolution"
        prop="upperMiddlelineSolution"
      >
        <div class="sides">
          <div class="side">
            <p class="subtitle">Derecha</p>
            <div class="radios">
              <el-radio
                label="rsshort"
                v-model="upperMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">1-2mm</span>
              </el-radio>
              <el-radio
                label="rslong"
                v-model="upperMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">>2mm</span>
              </el-radio>
            </div>
          </div>

          <div class="side">
            <p class="subtitle">Izquierda</p>
            <div class="radios">
              <el-radio
                label="lsshort "
                v-model="upperMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">1-2mm</span>
              </el-radio>
              <el-radio
                label="lslong"
                v-model="upperMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">>2mm</span>
              </el-radio>
            </div>
          </div>
        </div>
      </el-form-item>
    </div>
    <div class="height">
      <p>ARCADA INFERIOR</p>

      <!-- Form validation error in this field is handle using element el-form-item and rules prop in parent  -->
      <el-form-item
        class="radios"
        ref="lowerMiddlelineSolution"
        prop="lowerMiddlelineSolution"
      >
        <div class="sides">
          <div class="side">
            <p class="subtitle">Derecha</p>
            <div class="radios">
              <el-radio
                label="rishort"
                v-model="lowerMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">1-2mm</span>
              </el-radio>
              <el-radio
                label="rilong"
                v-model="lowerMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">>2mm</span>
              </el-radio>
            </div>
          </div>

          <div class="side">
            <p class="subtitle">Izquierda</p>
            <div class="radios">
              <el-radio
                label="lishort"
                v-model="lowerMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">1-2mm</span>
              </el-radio>
              <el-radio
                label="lilong"
                v-model="lowerMiddlelineSolutionInternal"
                :disabled="disabled"
              >
                <span class="radio-label">>2mm</span>
              </el-radio>
            </div>
          </div>
        </div>
      </el-form-item>
    </div>
  </Fieldset>
</template>

<script>
import Fieldset from '../Fieldset.vue'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    upperMiddlelineSolution: {
      required: false
    },
    lowerMiddlelineSolution: {
      required: false
    }
  },
  computed: {
    lowerMiddlelineSolutionInternal: {
      get () {
        return this.lowerMiddlelineSolution
      },
      set (val) {
        this.$emit('lowerMiddlelineSolution', val)
      }
    },
    upperMiddlelineSolutionInternal: {
      get () {
        return this.upperMiddlelineSolution
      },
      set (val) {
        this.$emit('upperMiddlelineSolution', val)
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss" scoped>
.height {
  display: flex;
  flex-direction: column;
  text-align: center;

  .sides {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .side {
      display: flex;
      flex-direction: column;

      .subtitle {
        font-size: 18px;
      }

      .radios {
        display: flex;
        flex-direction: row;
      }
    }
    .side:first-of-type {
      padding-right: 16px;
      margin-right: 16px;
      border-right: 1px solid black;
    }
  }
}
.height:first-of-type {
  margin-bottom: 32px;
}
</style>
