<template>
  <el-dialog
    class="dialog"
    title="Actualizar estado"
    :visible.sync="isDialogVisible"
    :width="isSmallWindow ? '80%' : '30%'"
    :show-close="false"
  >
    <div class="confirmation">
      <p>
        Ha añadido un {{ elementToReview === 'vto' ? 'VTO' : 'QuickCheck' }}.
        ¿Quiere pasar el caso al estado "Revisar
        {{ elementToReview === 'vto' ? 'VTO' : 'QuickCheck' }}"?
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">No</el-button>
        <el-button type="primary" @click="() => (updateCase = true)"
          >Actualizar estado</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    elementToReview: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isDialogVisible: true,
      updateCase: false
    }
  },
  watch: {
    isDialogVisible () {
      if (!this.isDialogVisible) {
        this.$emit('close')
      }
    },
    updateCase () {
      if (this.updateCase) {
        const stage = this.elementToReview === 'vto' ? 12 : 3
        this.$emit('update-status', stage)
      }
    }
  },
  computed: {
    isSmallWindow () {
      return this.clientWidth < 768
    }
  },
  methods: {
    closeDialog () {
      this.isDialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .dialog-footer {
    text-align: right;
  }
}
</style>
