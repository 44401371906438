<template>
  <Fieldset class="fieldset" :label="label || 'Cargar fotografías'">
    <input
      class="none"
      ref="lateralFacialImage"
      v-on:change="e => onUploadImage(e, 'lateralFacialImage')"
      type="file"
    />

    <input
      class="none"
      ref="frontalFacialImage"
      v-on:change="e => onUploadImage(e, 'frontalFacialImage')"
      type="file"
    />

    <input
      class="none"
      ref="smileFacialImage"
      v-on:change="e => onUploadImage(e, 'smileFacialImage')"
      type="file"
    />

    <input
      class="none"
      ref="upperOcclusalImage"
      v-on:change="e => onUploadImage(e, 'upperOcclusalImage')"
      type="file"
    />

    <input
      class="none"
      ref="lowerOcclusalImage"
      v-on:change="e => onUploadImage(e, 'lowerOcclusalImage')"
      type="file"
    />

    <input
      class="none"
      ref="rightIntraoralImage"
      v-on:change="e => onUploadImage(e, 'rightIntraoralImage')"
      type="file"
    />

    <input
      class="none"
      ref="centralIntraoralImage"
      v-on:change="e => onUploadImage(e, 'centralIntraoralImage')"
      type="file"
    />

    <input
      class="none"
      ref="leftIntraoralImage"
      v-on:change="e => onUploadImage(e, 'leftIntraoralImage')"
      type="file"
    />

    <div class="grid-uploader">
      <div class="item">
        <img
          src="../../assets/foto_facial_lateral.png"
          v-if="!lateralFacialImage"
          @click="() => triggerUpload('lateralFacialImage')"
          ref="lateralFacialImageIcon"
          class="big-uploader"
        />
        <img
          class="image"
          v-show="lateralFacialImage"
          ref="lateralFacialImageImage"
          @click="() => triggerUpload('lateralFacialImage')"
          :src="lateralFacialImage"
        />
        <el-form-item label class prop="lateralFacialImage">
          <el-input
            class="none"
            v-model="lateralFacialImage"
            ref="lateralFacialImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
      <div class="item">
        <img
          src="../../assets/foto_facial_frontal.png"
          v-if="!frontalFacialImage"
          @click="() => triggerUpload('frontalFacialImage')"
          ref="frontalFacialImageIcon"
          class="big-uploader"
        />
        <img
          v-show="frontalFacialImage"
          class="image"
          ref="frontalFacialImageImage"
          @click="() => triggerUpload('frontalFacialImage')"
          :src="frontalFacialImage"
        />
        <el-form-item label class prop="frontalFacialImage">
          <el-input
            class="none"
            v-model="frontalFacialImage"
            ref="frontalFacialImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
      <div class="item">
        <img
          src="../../assets/foto_facial_sonrisa.png"
          v-if="!smileFacialImage"
          @click="() => triggerUpload('smileFacialImage')"
          ref="smileFacialImageIcon"
          class="big-uploader"
        />
        <img
          v-show="smileFacialImage"
          class="image"
          ref="smileFacialImageImage"
          @click="() => triggerUpload('smileFacialImage')"
          :src="smileFacialImage"
        />
        <el-form-item label class prop="smileFacialImage">
          <el-input
            class="none"
            v-model="smileFacialImage"
            ref="smileFacialImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
      <div class="item">
        <img
          src="../../assets/foto_oclusal_superior.png"
          v-if="!upperOcclusalImage"
          @click="() => triggerUpload('upperOcclusalImage')"
          ref="upperOcclusalImageIcon"
          class="big-uploader"
        />
        <img
          v-show="upperOcclusalImage"
          class="image"
          ref="upperOcclusalImageImage"
          @click="() => triggerUpload('upperOcclusalImage')"
          :src="upperOcclusalImage"
        />
        <el-form-item label class prop="upperOcclusalImage">
          <el-input
            class="none"
            v-model="upperOcclusalImage"
            ref="upperOcclusalImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>

      <div class="item">
        <div
          src="../../assets/qs1.svg"
          @click="askToClearUploads"
          ref="lowerOcclusalImageIcon"
          class="big-uploader-text"
        >
          <p class="text">
            Haga doble click aquí para eliminar todas las fotografías
          </p>
        </div>
      </div>

      <div class="item">
        <img
          src="../../assets/foto_oclusal_inferior.png"
          v-if="!lowerOcclusalImage"
          @click="() => triggerUpload('lowerOcclusalImage')"
          ref="lowerOcclusalImageIcon"
          class="big-uploader"
        />
        <img
          v-show="lowerOcclusalImage"
          class="image"
          ref="lowerOcclusalImageImage"
          @click="() => triggerUpload('lowerOcclusalImage')"
          :src="lowerOcclusalImage"
        />
        <el-form-item label class prop="lowerOcclusalImage">
          <el-input
            class="none"
            v-model="lowerOcclusalImage"
            ref="lowerOcclusalImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
      <div class="item">
        <img
          src="../../assets/foto_intraoral_derecha.png"
          v-if="!rightIntraoralImage"
          @click="() => triggerUpload('rightIntraoralImage')"
          ref="rightIntraoralImageIcon"
          class="big-uploader"
        />
        <img
          v-show="rightIntraoralImage"
          class="image"
          ref="rightIntraoralImageImage"
          @click="() => triggerUpload('rightIntraoralImage')"
          :src="rightIntraoralImage"
        />
        <el-form-item label class prop="rightIntraoralImage">
          <el-input
            class="none"
            v-model="rightIntraoralImage"
            ref="rightIntraoralImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
      <div class="item">
        <img
          src="../../assets/foto_intraoral_frontal.png"
          v-if="!centralIntraoralImage"
          @click="() => triggerUpload('centralIntraoralImage')"
          ref="centralIntraoralImageIcon"
          class="big-uploader"
        />
        <img
          v-show="centralIntraoralImage"
          class="image"
          ref="centralIntraoralImageImage"
          @click="() => triggerUpload('centralIntraoralImage')"
          :src="centralIntraoralImage"
        />
        <el-form-item label class prop="centralIntraoralImage">
          <el-input
            class="none"
            v-model="centralIntraoralImage"
            ref="centralIntraoralImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
      <div class="item">
        <img
          src="../../assets/foto_intraoral_izquierda.png"
          v-if="!leftIntraoralImage"
          @click="() => triggerUpload('leftIntraoralImage')"
          ref="leftIntraoralImageIcon"
          class="big-uploader"
        />
        <img
          v-show="leftIntraoralImage"
          class="image"
          ref="leftIntraoralImageImage"
          @click="() => triggerUpload('leftIntraoralImage')"
          :src="leftIntraoralImage"
        />
        <el-form-item label class prop="leftIntraoralImage">
          <el-input
            class="none"
            v-model="leftIntraoralImage"
            ref="leftIntraoralImageUrl"
            type="text"
            placeholder="url"
          />
        </el-form-item>
      </div>
    </div>

    <ClearPhotographiesModal
      :visible="showModal"
      v-on:cancel="toggleModal"
      v-on:confirm="clearUploads"
    />
  </Fieldset>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import ClearPhotographiesModal from '@/components/ClearPhotographiesModal'

export default {
  components: {
    ClearPhotographiesModal
  },
  props: {
    label: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    membershipNumber: {
      // TODO
      required: false,
      type: String
    },
    lateralFacialImage: { required: true, type: String },
    frontalFacialImage: { required: true, type: String },
    smileFacialImage: { required: true, type: String },
    upperOcclusalImage: { required: true, type: String },
    lowerOcclusalImage: { required: true, type: String },
    rightIntraoralImage: { required: true, type: String },
    centralIntraoralImage: { required: true, type: String },
    leftIntraoralImage: { required: true, type: String }
  },
  data () {
    return {
      clickCounter: 0,
      showModal: false
    }
  },
  methods: {
    clearUploads () {
      const uploadNames = [
        'lateralFacialImage',
        'frontalFacialImage',
        'smileFacialImage',
        'upperOcclusalImage',
        'lowerOcclusalImage',
        'rightIntraoralImage',
        'centralIntraoralImage',
        'leftIntraoralImage'
      ]
      uploadNames.forEach(name => {
        this.$emit(name, '')
      })

      this.toggleModal()
    },
    toggleModal () {
      this.showModal = !this.showModal
    },
    askToClearUploads () {
      this.clickCounter += 1
      if (this.clickCounter % 2 === 0) {
        this.toggleModal()
      }
    },
    triggerUpload (name) {
      if (!this.disabled) {
        this.$refs[name].click()
      }
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.$emit(fieldName, downloadURL)
            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.fieldset {
  border: none;

  .image {
    width: 208px;
    height: 104px;
    cursor: pointer;
  }

  .none {
    display: none;
  }

  .grid-uploader {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 24px;

    .big-uploader {
      width: 208px;
      height: 104px;
      font-size: 25px;
      border: 1px solid #aaa;
      border-radius: 10px;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }

    .big-uploader-text {
      display: flex;
      justify-items: center;
      align-items: center;
      text-align: center;
      width: 208px;
      height: 104px;
      font-size: 14px;
      border: 1px solid #aaa;
      border-radius: 10px;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;

      .text {
        padding: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .grid-uploader {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    .item {
      display: flex;
      justify-self: center;
      margin-bottom: 8px;
    }
  }
}
</style>
