<template>
  <Fieldset class="fieldset" label="Selección de tipo de registros">
    <div class="radio-options">
      <el-radio
        v-model="typeOfImpressionsInternal"
        label="intraoralScanner"
        :disabled="disabled"
      >
        <span class="radio-label">Escaneado digital (STL's)</span>
      </el-radio>
      <el-radio
        v-model="typeOfImpressionsInternal"
        label="platform"
        :disabled="disabled"
      >
        <span class="radio-label"
          >Enviar a través de plataforma (MeditLink, 3Shape, iTero, Shining
          3D)</span
        >
      </el-radio>
      <el-radio
        v-model="typeOfImpressionsInternal"
        label="pvsPrinting"
        :disabled="disabled"
      >
        <span class="radio-label">Enviar impresiones PVS (Sólo Canarias, Ceuta y Melilla)</span>
      </el-radio>

      <el-radio
        v-if="
          typeOfImpressionsInternal === 'derivation' ||
            typeOfImpressionsInternal !== 'derivation-custom'
        "
        v-model="typeOfImpressionsInternal"
        label="derivation"
        :disabled="disabled"
      >
        <span class="radio-label">Recoger impresiones PVS</span>
      </el-radio>
      <el-radio
        v-else
        v-model="typeOfImpressionsInternal"
        label="derivation-custom"
        :disabled="disabled"
      >
        <span class="radio-label">Recoger impresiones PVS</span>
      </el-radio>
    </div>
    <div v-if="typeOfImpressionsInternal === 'intraoralScanner'">
      <div class="intraoral-scanner-grid">
        <div
          v-for="field in intraoralScannerFields"
          :key="field.value"
          class="item"
        >
          <p>{{ field.label }}</p>
          <input
            :style="{ display: 'none' }"
            :ref="field.ref"
            v-on:change="e => onUploadImage(e, field.value)"
            type="file"
            :disabled="disabled"
          />
          <div>
            <img
              :src="field.img"
              @click="() => triggerUpload(field.value)"
              class="big-uploader"
            />
          </div>
          <el-form-item prop="upperIntraoralScanner">
            <el-input
              :style="{ display: 'none' }"
              v-model="field.value"
              :ref="field.urlRef"
              type="text"
              placeholder="url"
              :disabled="disabled"
            />
          </el-form-item>

          <div v-if="field.nameValue" class="patient-photo-upload">
            <p class="name">{{ field.nameValue }}</p>
            <i
              @click="() => removePhoto(field.value)"
              class="cross el-icon-close"
            ></i>
          </div>

            <a
              :ref="field.value+'download'"
              :href="field.fieldValue"
              target="_blank"
              rel="noopener noreferrer"
            />
          <el-button v-if="field.fieldValue" type="primary" size="mini" @click="() => openLink(field.value)">Descargar</el-button>
        </div>
      </div>
      <p v-if="showIntraoralScannerError" class="custom-error">
        Debe subir el escaneado digital Superior e Inferior
      </p>
    </div>

    <div v-if="typeOfImpressionsInternal === 'platform'" class="platform">
      <p class="text">
        Conecte con QuickSmile como laboratorio dentro de las plataformas de
        <span class="bold">iTero</span>, <span class="bold">3Shape</span>,
        <span class="bold">Shining 3D</span> y
        <span class="bold">MeditLink</span> para conectarse directamente con
        nosotros y enviarnos sus STL de forma directa.
      </p>
      <img src="../../assets/airplane.png" class="img" />
    </div>

    <div v-if="typeOfImpressionsInternal === 'pvsPrinting'" class="send">
      <ImpressionsAddress class="address" />
      <ImpressionsShippingSheet class="download" />

      <p class="warning">
        Las impresiones deberán estar correctamente identificadas dentro de la
        caja (nombre del doctor + nombre del paciente)
      </p>
    </div>

    <div
      v-if="
        typeOfImpressionsInternal === 'derivation' ||
          typeOfImpressionsInternal === 'derivation-custom'
      "
      class="derivation"
    >
      <p class="bold">
        (Opción gratuita para recogidas dentro de la península)
      </p>

      <p class="subtitle">Dirección de recogida</p>
      <div class="derivation-inputs">
        <el-radio
          label="derivation"
          v-model="typeOfImpressionsInternal"
          :disabled="disabled"
        >
          <span class="radio-label">Misma dirección de envio que el caso</span>
        </el-radio>
        <el-radio
          label="derivation-custom"
          v-model="typeOfImpressionsInternal"
          :disabled="disabled"
        >
          <span class="radio-label">Otra dirección </span>
        </el-radio>
      </div>
      <!-- FIXME: Add error state with showTypeOfImpressionsError is true -->
      <el-input
        class="derivation-custom other-input"
        type="textarea"
        resize="none"
        :rows="2"
        v-model="typeOfImpressionsAddressInternal"
        :maxlength="140"
        :disabled="
          typeOfImpressionsInternal !== 'derivation-custom' || disabled
        "
        placeholder="Escriba aquí la nueva dirección"
      />
      <p class="custom-error" v-if="this.showTypeOfImpressionsError">
        Debe escribir una dirección
      </p>

      <p class="warning">
        Las impresiones deberán estar correctamente identificadas dentro de la
        caja (nombre del doctor + nombre del paciente)
      </p>
    </div>
  </Fieldset>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Vue from 'vue'
import Fieldset from '../Fieldset.vue'
import ImpressionsAddress from '../ImpressionsAddress.vue'
import ImpressionsShippingSheet from '../ImpressionsShippingSheet.vue'

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    typeOfImpressions: {
      required: false
    },
    lowerIntraoralScanner: {
      required: true
    },
    lowerIntraoralScannerName: {
      required: true
    },
    upperIntraoralScanner: {
      required: true
    },
    upperIntraoralScannerName: {
      required: true
    },
    occlusalIntraoralScanner: {
      required: true
    },
    occlusalIntraoralScannerName: {
      required: true
    },
    showTypeOfImpressionsError: {
      required: false
    },
    showIntraoralScannerError: {
      required: false
    },
    typeOfImpressionsAddress: {
      required: false
    }
  },
  computed: {
    typeOfImpressionsInternal: {
      get () {
        return this.typeOfImpressions
      },
      set (val) {
        this.$emit('typeOfImpressions', val)
        if (this.typeOfImpressions === 'intraoralScanner') {
          Vue.nextTick(() => {
            this.validateIntraoralScanner()
          })
        }
      }
    },
    typeOfImpressionsAddressInternal: {
      get () {
        return this.typeOfImpressionsAddress
      },
      set (val) {
        this.$emit('typeOfImpressionsAddress', val)
        Vue.nextTick(() => {
          this.validateTypeOfImpressionsAddress()
        })
      }
    },
    intraoralScannerFields () {
      return [
        {
          label: 'Superior',
          value: 'upperIntraoralScanner',
          ref: 'upperIntraoralScanner',
          name: 'upperIntraoralScannerName',
          nameValue: this.upperIntraoralScanner
            ? this.upperIntraoralScannerName || 'superior'
            : this.upperIntraoralScanner,
          fieldValue: this.upperIntraoralScanner,
          urlRef: 'upperIntraoralScannerUrl',
          img: require('../../assets/foto_oclusal_superior.png')
        },
        {
          label: 'Inferior',
          value: 'lowerIntraoralScanner',
          ref: 'lowerIntraoralScanner',
          name: 'lowerIntraoralScannerName',
          nameValue: this.lowerIntraoralScanner
            ? this.lowerIntraoralScannerName || 'inferior'
            : this.lowerIntraoralScanner,
          fieldValue: this.lowerIntraoralScanner,
          urlRef: 'lowerIntraoralScannerUrl',
          img: require('../../assets/foto_oclusal_inferior.png')
        },
        {
          label: 'Oclusal (opcional)',
          value: 'occlusalIntraoralScanner',
          ref: 'occlusalIntraoralScanner',
          name: 'occlusalIntraoralScannerName',
          nameValue: this.occlusalIntraoralScanner
            ? this.occlusalIntraoralScannerName || 'oclusal'
            : this.occlusalIntraoralScanner,
          fieldValue: this.occlusalIntraoralScanner,
          urlRef: 'occlusalIntraoralScannerUrl',
          img: require('../../assets/foto_intraoral_frontal.png')
        }
      ]
    }
  },
  watch: {
    upperIntraoralScanner () {
      this.validateIntraoralScanner()
    },
    lowerIntraoralScanner () {
      this.validateIntraoralScanner()
    },
    typeOfImpressions: {
      immediate: true,
      handler () {
        this.validateTypeOfImpressionsAddress()
        this.validateIntraoralScanner()
      }
    }
  },
  methods: {
    openLink (ref) {
      this.$refs[ref + 'download'][0].click()
    },
    removePhoto (img) {
      this.$emit(img, '')
      this.$emit(`${img}Name`, null)
    },
    validateTypeOfImpressionsAddress () {
      if (
        !this.typeOfImpressions ||
        (this.typeOfImpressions === 'derivation-custom' &&
          !this.typeOfImpressionsAddress)
      ) {
        this.$emit('typeOfImpressionsError', true)
      } else {
        this.$emit('typeOfImpressionsError', false)
      }
    },
    validateIntraoralScanner () {
      if (this.typeOfImpressions === 'intraoralScanner') {
        this.$emit(
          'intraoralScannerError',
          !(this.upperIntraoralScanner && this.lowerIntraoralScanner)
        )
      } else {
        this.$emit('intraoralScannerError', false)
      }
    },
    triggerUpload (name) {
      if (!this.disabled) {
        this.$refs[name][0].click()
      }
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      if (fieldName === 'lowerIntraoralScanner') {
        this.$emit('lowerIntraoralScannerName', file.name)
      } else if (fieldName === 'upperIntraoralScanner') {
        this.$emit('upperIntraoralScannerName', file.name)
      } else if (fieldName === 'occlusalIntraoralScanner') {
        this.$emit('occlusalIntraoralScannerName', file.name)
      }

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.$emit(fieldName, downloadURL)
            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    }
  },
  components: { Fieldset, ImpressionsAddress, ImpressionsShippingSheet }
}
</script>

<style lang="scss">
.el-radio {
  display: flex !important;
  white-space: initial !important;
}

.patient-photo-upload {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .name {
    font-size: 12px;
    margin: 0 4px 0 0;
  }
}
.warning {
  color: #f56c6c;
  text-align: center;
}
.custom-error {
  color: #f56c6c;
  text-align: start;
}
.radio-options {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}
.big-uploader {
  width: 104px;
  height: 57px;
  font-size: 25px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.intraoral-scanner-grid {
  display: grid;
  grid-template-columns: 150px 150px 150px;
  column-gap: 8px;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.platform {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    text-align: center;
    font-size: 14px;
    margin-bottom: 16px;
    max-width: 392px;
  }
  .img {
    width: 114px;
  }
}

.send {
  text-align: center;
  font-size: 14px;
  max-width: 392px;

  > * {
    margin-bottom: 24px;
  }
}

.derivation {
  font-size: 14px;
  max-width: 392px;
  .subtitle {
    text-align: start;
  }

  .derivation-inputs {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .intraoral-scanner-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
</style>
