import Vue from 'vue'
import Router from 'vue-router'

import Lobby from './views/Lobby.vue'
import CaseListing from './views/CaseListing.vue'
import Refinement from './views/Refinement.vue'
import PasswordReset from './views/PasswordReset.vue'
import Signup from './views/Signup.vue'
import Login from './views/Login.vue'
import PasswordRecovery from './views/PasswordRecovery.vue'
import ClinicalPreferences from './views/ClinicalPreferences'
import DoctorInformation from './components/DoctorInformation'
import Case from './views/Case'
import NewCase from './views/NewCase'
import AddressForm from './components/AddressForm'
import AdminPanel from './views/AdminPanel'
import InvoicesPanel from './views/InvoicesPanel'
import Retention from './views/Retention'

import Success from './views/Success'
import Error from './views/Error'
import { AUTH_TOKEN, STORAGE_TOKEN } from './vue-apollo'

Vue.use(Router)


export const NOT_GUARDED_ROUTES = [
      'signup',
      'login',
      'PasswordRecovery',
      'PasswordReset',
      'success',
      'error',
]

export function isRouteGuarded (routeName) {
  return !NOT_GUARDED_ROUTES.includes(routeName)
}

const guard = async (to, from, next) => {
    const authToken = localStorage.getItem(AUTH_TOKEN)
    const storageToken = localStorage.getItem(STORAGE_TOKEN)

  if (authToken && storageToken) {
    return next()
  } else {
    return next('/inicio-sesion')
  }
}

export default new Router({
  routes: [
    {
      path: '/',
      beforeEnter: guard,
      name: 'CaseListing',
      props: true,
      component: CaseListing
    },
    {
      path: '/lobby',
      beforeEnter: guard,
      name: 'Lobby',
      component: Lobby
    },
    {
      path: '/perfil',
      beforeEnter: guard,
      name: 'Information',
      component: DoctorInformation
    },
    {
      path: '/facturas',
      beforeEnter: guard,
      name: 'Invoices',
      component: InvoicesPanel
    },
    {
      path: '/preferencias-clinicas',
      beforeEnter: guard,
      name: 'ClinicalPreferences',
      component: ClinicalPreferences
    },
    {
      path: '/crear-direccion',
      name: 'CreateAddress',
      beforeEnter: guard,
      component: AddressForm
    },
    {
      path: '/modificar-direccion/:id',
      name: 'UpdateAddress',
      beforeEnter: guard,
      component: AddressForm
    },
    {
      path: '/nuevo-caso',
      name: 'NewCase',
      beforeEnter: guard,
      component: NewCase
    },
    {
      path: '/refinamiento/:id',
      name: 'Refinement',
      beforeEnter: guard,
      component: Refinement
    },
    {
      path: '/caso/:id',
      name: 'Case',
      beforeEnter: guard,
      component: Case
    },
    {
      path: '/retencion/:id',
      name: 'Retention',
      beforeEnter: guard,
      component: Retention
    },
    {
      path: '/admin',
      name: 'admin',
      beforeEnter: guard,
      component: AdminPanel
    },
    {
      path: '/registro',
      name: 'signup',
      component: Signup
    },
    {
      path: '/inicio-sesion',
      name: 'login',
      component: Login
    },
    {
      path: '/recuperar-contrasena',
      name: 'PasswordRecovery',
      component: PasswordRecovery
    },
    {
      path: '/resetear-contrasena/:user/:token',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/success',
      name: 'success',
      component: Success
    },
    {
      path: '/error',
      name: 'error',
      component: Error
    }
  ]
})
