<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />
    <div class="panel">
      <h2 class="title">Facturas</h2>
      <Invoices class="module" :invoices="invoices" />
    </div>
    <Footer class="footer-wrapper" />
    <Loading :active="loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import Footer from '@/components/Footer.vue'
import Invoices from '@/components/Invoices.vue'
export default {
  name: 'NewCase',
  components: {
    Header,
    Footer,
    Loading,
    Invoices
  },
  beforeMount () {
    this.$store.dispatch('fetchInvoicesPanelInformation')
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      loading: state => state.isLoading,
      invoices: state => state.me.invoices,
      schoolRole: state => state.me.schoolRole
    })
  },
  watch: {
    schoolRole (role) {
      if (role === 'STUDENT') {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-rows: 160px 1fr auto;
  min-height: 100vh;

  .title {
    padding-left: 24px;
  }

  .module {
    margin-bottom: 36px;
  }

  // .footer-wrapper {
  //   padding: 0 24px 24px 24px;
  // }
}
</style>
