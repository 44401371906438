<template>
  <Fieldset :class="{ 'sagital-title': centerTitle }" label="Relación sagital">
    <div class="sagitals">
      <div class="sagital">
        <img src="../../assets/foto_intraoral_derecha.png" class="image" />
        <p class="title">Derecha</p>
        <div class="radio-options">
          <el-radio
            v-model="rightSagitalRelationshipInternal"
            label="0"
            :disabled="disabled"
          >
            <span class="radio-label"
              >Mantener la relación canina y molar actual</span
            >
          </el-radio>

          <el-radio
            v-model="rightSagitalRelationshipInternal"
            label="1"
            :disabled="disabled"
          >
            <span class="radio-label">
              Mantener la relación molar y solo mejorar la relación canina
            </span>
          </el-radio>

          <el-radio
            v-model="rightSagitalRelationshipInternal"
            label="2"
            :disabled="disabled"
          >
            <span class="radio-label">
              Mejorar la relación canina y molar dejando ambas en Clase I
            </span>
          </el-radio>
        </div>
      </div>

      <div class="sagital">
        <img src="../../assets/foto_intraoral_izquierda.png" class="image" />
        <p class="title">Izquierda</p>
        <div class="radio-options">
          <el-radio
            v-model="leftSagitalRelationshipInternal"
            label="0"
            :disabled="disabled"
          >
            <span class="radio-label"
              >Mantener la relación canina y molar actual</span
            >
          </el-radio>

          <el-radio
            v-model="leftSagitalRelationshipInternal"
            label="1"
            :disabled="disabled"
          >
            <span class="radio-label">
              Mantener la relación molar y solo mejorar la relación canina
            </span>
          </el-radio>

          <el-radio
            v-model="leftSagitalRelationshipInternal"
            label="2"
            :disabled="disabled"
          >
            <span class="radio-label">
              Mejorar la relación canina y molar dejando ambas en Clase I
            </span>
          </el-radio>
        </div>
      </div>
    </div>
  </Fieldset>
</template>

<script>
import Fieldset from '../Fieldset.vue'

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    rightSagitalRelationship: {
      required: false
    },
    leftSagitalRelationship: {
      required: false
    },
    centerTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rightSagitalRelationshipInternal: {
      get () {
        return this.rightSagitalRelationship || ''
      },
      set (val) {
        this.$emit('rightSagitalRelationship', val)
      }
    },
    leftSagitalRelationshipInternal: {
      get () {
        return this.leftSagitalRelationship || ''
      },
      set (val) {
        this.$emit('leftSagitalRelationship', val)
      }
    },
    sagitals () {
      return [
        {
          side: 'Derecha',
          value: this.rightSagitalRelationshipInternal
        },
        {
          side: 'Izquierda',
          value: this.leftSagitalRelationshipInternal
        }
      ]
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss">
.sagital-title {
  text-align: center;
}
.sagitals {
  text-align: initial;
  display: flex;
  .sagital {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-right: 15px;
    // width: 400px;
    .el-radio {
      font-size: 18px;
      display: flex;
      white-space: initial;
      margin-bottom: 8px;
      .el-radio__label {
        font-size: 18px;
      }
    }

    .image {
      width: 200px;
    }

    .radio-options {
      display: flex;
      flex-direction: column;
    }
  }

  .sagital:first-of-type {
    border-right: 1px solid black;
  }

  .sagital:last-of-type {
    padding-left: 15px;
  }

  .sagital:first-of-type {
    padding-right: 15px;
  }
}
</style>
