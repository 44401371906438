<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />
    <div class="profile">
      <div class="header">
        <input
          :style="{ display: 'none' }"
          v-model="newDoctorImage"
          type="text"
          placeholder="url"
        />
        <input
          :style="{ display: 'none' }"
          ref="newDoctorImage"
          v-on:change="e => onUploadImage(e, 'newDoctorImage')"
          type="file"
        />

        <div
          class="doctor-image"
          @click="() => triggerUpload('newDoctorImage')"
          ref="doctorPhotoIcon"
        >
          <img
            v-if="newDoctorImage || doctorImage"
            class="layer-bottom"
            :src="newDoctorImage || doctorImage"
          />
          <img v-else class="layer-bottom" src="../assets/qs1.svg" />
          <div class="layer-top">
            <p class="text">Editar</p>
          </div>
        </div>
        <div class="settings">
          <p class="name">{{ doctorName }}</p>
          <div class="buttons">
            <el-button
              class="preferences"
              type="primary"
              @click="() => $router.push('/preferencias-clinicas')"
              >Pref. clinicas</el-button
            >

            <!-- <el-button
              class="invoices"
              type="primary"
              @click="() => $router.push('/facturas')"
            >Mis facturas</el-button> -->

            <el-dropdown>
              <el-button class="dropdown" type="primary">
                Descargas <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.CONSENT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Consentimiento informado
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.ADVERTISING"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contrato de publicidad QuickSmile
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.TREATMENT_ENDING"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Finalización del tratamiento
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.MEASURES"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hoja de envío de medidas
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.TIMING"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tiempos de Trabajo
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.TRANSFER"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Transferencia de paciente
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.TYC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Términos y condiciones del contrato
                  </a>
                </el-dropdown-item>

                <el-dropdown-item>
                  <a
                    class="link"
                    :href="PDF_DOWNLOAD_URL.DOMICILIATION"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Orden de domiciliación
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>

      <DoctorInformationDiscounts
        v-if="discountsMemberGetMember || discountsYearly || discountFirstCase"
        class="discounts"
        :discountsMemberGetMember="discountsMemberGetMember"
        :discountsYearly="discountsYearly"
        :discountFirstCase="discountFirstCase"
      />

      <div>
        <p class="title">Direcciones de envío</p>
        <div class="empty" v-if="!shippingAddresses.length">
          <p class="message">
            Todavía no tienes ninguna dirección de envío. Haz click debajo para
            crear una.
          </p>
          <i
            v-if="!showShippingForm"
            @click="() => showAddressForm('SHIPPING')"
            class="el-icon-circle-plus"
          ></i>
          <AddressForm
            v-if="showShippingForm"
            type="SHIPPING"
            v-on:close-address-form="() => hideAddressForm('SHIPPING')"
            v-on:address-created="onAddressCreated"
          />
        </div>
        <div v-else class="addresses">
          <div
            v-for="(address, index) in shippingAddresses"
            :key="address.key"
            class="address"
          >
            <AddressCard
              v-if="updatingShippingAddress !== index"
              type="shipping"
              :clinicName="address.clinicName"
              :streetName="address.streetName1"
              :city="address.city"
              :province="address.province"
              :country="address.country"
              :postalCode="address.postalCode"
              :phone="address.phone"
              v-on:delete="() => deleteAddress(address.id)"
              v-on:update="() => (updatingShippingAddress = index)"
            />

            <AddressForm
              v-else
              type="SHIPPING"
              :address="address"
              v-on:address-updated="() => onAddressUpdated('SHIPPING')"
              v-on:close-address-form="
                () => {
                  updatingShippingAddress = null
                }
              "
            />
          </div>

          <AddressForm
            v-if="showShippingForm"
            type="SHIPPING"
            v-on:close-address-form="() => hideAddressForm('SHIPPING')"
            v-on:address-created="onAddressCreated"
          />
          <i
            v-if="!showShippingForm"
            @click="showAddressForm('SHIPPING')"
            class="el-icon-circle-plus"
          ></i>
        </div>
      </div>

      <div>
        <p class="title">Direcciones de facturación</p>
        <div class="empty" v-if="!billingAddresses.length">
          <p class="message">
            Todavía no tienes ninguna dirección de facturación. Haz click debajo
            para crear una.
          </p>
          <i
            v-if="!showBillingForm"
            @click="showAddressForm('BILLING')"
            class="el-icon-circle-plus"
          ></i>
          <AddressForm
            v-if="showBillingForm"
            type="BILLING"
            v-on:close-address-form="() => hideAddressForm('BILLING')"
            v-on:address-created="onAddressCreated"
          />
        </div>
        <div v-else class="addresses">
          <div
            v-for="(address, index) in billingAddresses"
            :key="address.key"
            class="address"
          >
            <AddressCard
              v-if="updatingBillingAddress !== index"
              type="billing"
              :clinicName="address.clinicName"
              :streetName="address.streetName1"
              :city="address.city"
              :province="address.province"
              :country="address.country"
              :postalCode="address.postalCode"
              :phone="address.phone"
              :cif="address.cif"
              :email="address.email"
              v-on:delete="() => deleteAddress(address.id)"
              v-on:update="() => (updatingBillingAddress = index)"
            />
            <AddressForm
              v-else
              type="BILLING"
              :address="address"
              v-on:address-updated="() => onAddressUpdated('BILLING')"
              v-on:close-address-form="
                () => {
                  updatingBillingAddress = null
                }
              "
            />
          </div>
          <AddressForm
            v-if="showBillingForm"
            type="BILLING"
            v-on:close-address-form="() => hideAddressForm('BILLING')"
            v-on:address-created="onAddressCreated"
          />
          <i
            v-if="!showBillingForm"
            @click="showAddressForm('BILLING')"
            class="el-icon-circle-plus"
          ></i>
        </div>
      </div>
    </div>
    <Loading :active="loading" />
    <Footer class="footer-wrapper" />
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import { mapState, mapGetters } from 'vuex'

import Header from '@/components/Header'
import Loading from '@/components/Loading'
import AddressForm from '@/components/AddressForm'
import AddressCard from '@/components/AddressCard'
import Footer from '@/components/Footer'

import { PDF_DOWNLOAD_URL } from '@/constants'

export default {
  name: 'DoctorInformation',
  components: {
    Header,
    Loading,
    AddressForm,
    AddressCard,
    Footer,
    DoctorInformationDiscounts: () =>
      import('@/components/DoctorInformationDiscounts')
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      discountsMemberGetMember: state => state.me.discountsMemberGetMember,
      discountsYearly: state => state.me.discountsYearly,
      discountFirstCase: state => state.me.discountFirstCase,
      membershipNumber: state => state.me.username,
      loading: state => state.loading
    }),
    ...mapGetters({
      billingAddresses: 'getBillingAddresses',
      shippingAddresses: 'getShippingAddresses'
    })
  },
  beforeMount () {
    this.$store.dispatch('fetchDoctorInformation')
  },
  methods: {
    hideAddressForm (type) {
      if (type === 'SHIPPING') {
        this.showShippingForm = false
      } else if (type === 'BILLING') {
        this.showBillingForm = false
      }
    },
    onAddressCreated () {
      this.showShippingForm = false
      this.showBillingForm = false
    },
    onAddressUpdated (type) {
      if (type === 'SHIPPING') {
        this.updatingShippingAddress = null
      } else if (type === 'BILLING') {
        this.updatingBillingAddress = null
      }
    },
    async showAddressForm (type) {
      if (type === 'SHIPPING') {
        this.showShippingForm = true
      } else if (type === 'BILLING') {
        this.showBillingForm = true
      }
    },
    deleteAddress (id) {
      this.$store.dispatch('deleteAddress', { id })
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    async updateImage () {
      this.$store.dispatch('updateDoctorImage', { image: this.newDoctorImage })
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
        .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this[fieldName] = downloadURL
            this.$refs[fieldName].src = downloadURL
            this.updateImage()
          })
        }
      )
    }
  },
  data () {
    return {
      PDF_DOWNLOAD_URL,
      updatingShippingAddress: null,
      updatingBillingAddress: null,
      showBillingForm: false,
      showShippingForm: false,
      newDoctorImage: ''
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;

  .profile {
    padding: 12px;

    .discounts {
      margin-bottom: 24px;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      margin: 16px 0 16px 0;
    }

    .header {
      display: flex;
      flex-direction: row;

      .doctor-image,
      .doctor-image * {
        cursor: pointer;
        box-sizing: border-box;
        width: 152px;
        border-radius: 50%;
      }
      .doctor-image {
        margin-right: 40px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        max-width: 100%;
        height: auto;
      }
      .doctor-image .layer-bottom {
        height: 152px;
        width: 152px;
        display: block;
      }

      .doctor-image .layer-top {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 15px;
        -moz-transition: all 0.4s ease-in-out 0s;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -ms-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
      }
      .doctor-image:hover .layer-top,
      .doctor-image.active .layer-top {
        opacity: 1;
      }
      .doctor-image .text {
        text-align: center;
        font-size: 18px;
        display: inline-block;
        position: absolute;
        top: 70%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      .settings {
        display: flex;
        justify-content: space-around;
        max-width: 350px;
        display: flex;
        flex-direction: column;

        .buttons {
          display: flex;

          .invoices,
          .preferences {
            display: flex;
            align-items: flex-end;
          }

          .dropdown {
            margin-left: 8px;
            height: 40px;
          }
        }

        .name {
          height: 28px;
          font-size: 24px;
          font-weight: 500;
          margin: 0 0 8px 0;
        }

        .edit {
          text-align: left;
          margin: 0 0 16px;
          width: 92px;
          font-size: 18px;
        }
      }
    }

    .addresses {
      text-align: left;
      .address {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .credit-card {
      width: 384px;
      height: 224px;
    }

    .billing {
      box-sizing: border-box;
      display: grid;
      grid-auto-columns: auto;
      grid-column-gap: 24px;
      grid-row-gap: 32px;
      grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
    }
  }

  // .footer-wrapper {
  //   padding: 0 24px 24px 24px;
  // }
}

.update {
  font-size: 18px;
}

.delete {
  font-size: 18px;
  margin-right: 16px;
}

.empty {
  display: flex;
  flex-direction: column;

  .message {
    margin-bottom: 16px;
  }
}

.el-icon-circle-plus {
  font-size: 40px;
  cursor: pointer;
  color: black;
}

@media (max-width: 768px) {
  .container {
    .profile {
      .title {
        margin: 0 0 16px 0;
      }

      .header {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 32px;

        .doctor-image {
          align-self: center;
          margin: 0;
          min-height: 152px;
        }

        .settings {
          max-width: none;
        }
      }

      .addresses {
        margin-bottom: 32px;
      }
    }
  }
}

.link {
  text-decoration: none;
  color: #606266;
  width: 100%;
  display: inline-block;
}
</style>
