<template>
    <p class="countdown red">
        <span class="bold red">{{ days }}</span> días <span class="bold">{{ hours }}</span> horas <span class="bold">{{ minutes }}</span> minutos y
        <span class="bold red">{{ seconds }}</span> segundos
    </p>
</template>

<script>
export default {
    mounted() {
        this.start();
    },

    data() {
        const countDownDate = new Date("May 29, 2024 00:00:00").getTime();

        const now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return {
            countDownDate,
            days,
            hours,
            minutes,
            seconds,
            interval: null,
        };
    },
    methods: {
        start() {
            this.interval = setInterval(() => {
                const now = new Date().getTime();

                // Find the distance between now and the count down date
                const distance = this.countDownDate - now;

                this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                this.hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Display the result in the element with id="demo"
                if (distance < 0) {
                    clearInterval(x);
                }
            }, 1000);
        },
    },
};
</script>

<style scoped lang="scss">
.red {
    color: #ee220c;
}

.countdown {
    margin: 0;
    font-size: 18px;
    text-align: center;

    .bolder {
        font-weight: bolder;
        font-size: 20px;
}
}

</style>