<template>
  <div class="authorization">
    <h3>Crear credenciales escolares</h3>

    <el-form ref="form" :rules="rules" :model="form">
      <div class="grid-wrapper">
        <el-form-item
          v-for="field in fields"
          :key="field.prop"
          class="field"
          :label="field.label"
          :prop="field.prop"
        >
          <el-input
            v-if="field.element === 'input'"
            class="input"
            v-model="form[field.prop]"
            :placeholder="field.placeholder"
            :type="field.type || 'text'"
          />

          <el-select
            v-else-if="field.element === 'select'"
            class="input"
            v-model="form[field.prop]"
            :placeholder="field.placeholder"
          >
            <el-option
              v-for="option in field.options"
              :key="option"
              :label="option"
              :value="option"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-button
        class="button"
        type="primary"
        submit
        native-type="button"
        @click="submitForm"
        >Autorizar doctor</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AdminPanelSchool',
  data () {
    const specialisms = [
      'Ortodoncia',
      'Periodoncia',
      'Endodoncia',
      'Rehabilitación oral',
      'Implantología',
      'Odontología preventiva',
      'Odontopediatría',
      'Otro'
    ]
    const requiredFieldRule = {
      required: true,
      message: 'Campo requerido',
      trigger: 'blur'
    }
    return {
      specialisms,
      rules: {
        teacherUsername: [requiredFieldRule],
        teacherEmail: [
          requiredFieldRule,
          {
            type: 'email',
            message: 'Introduzca un correo válido',
            trigger: ['blur', 'change']
          }
        ],
        teacherPassword: [
          require,
          {
            min: 8,
            message: 'Introduzca una contraseña de al menos 8 caracteres',
            trigger: 'blur'
          },
          {
            max: 42,
            message: 'Introduzca una contraseña de 42 caracteres máximo',
            trigger: 'blur'
          }
        ],
        teacherSpecialism: [requiredFieldRule],
        teacherName: [requiredFieldRule],
        teacherFirstSurname: [requiredFieldRule],
        studentUsername: [requiredFieldRule],
        studentEmail: [
          requiredFieldRule,
          {
            type: 'email',
            message: 'Introduzca un correo válido',
            trigger: ['blur', 'change']
          }
        ],
        studentPassword: [
          requiredFieldRule,
          {
            min: 8,
            message: 'Introduzca una contraseña de al menos 8 caracteres',
            trigger: 'blur'
          },
          {
            max: 42,
            message: 'Introduzca una contraseña de 42 caracteres máximo',
            trigger: 'blur'
          }
        ],
        studentSpecialism: [requiredFieldRule],
        studentName: [requiredFieldRule],
        studentFirstSurname: [requiredFieldRule]
      },
      fields: [
        {
          element: 'input',
          prop: 'teacherUsername',
          placeholder: 'Introduzca el número de colegiado',
          label: 'Número colegiado profesor',
          value: ''
        },
        {
          element: 'input',
          prop: 'teacherEmail',
          placeholder: 'Introduzca el email',
          label: 'Email del profesor',
          value: ''
        },
        {
          element: 'input',
          prop: 'teacherPassword',
          placeholder: 'Introduzca la contraseña',
          label: 'Contraseña profesor',
          value: '',
          type: 'password'
        },
        {
          element: 'select',
          prop: 'teacherSpecialism',
          placeholder: 'Introduzca la especialidad',
          label: 'Especialidad profesor',
          value: '',
          options: specialisms
        },
        {
          element: 'input',
          prop: 'teacherName',
          placeholder: 'Introduzca el nombre',
          label: 'Nombre profesor',
          value: ''
        },
        {
          element: 'input',
          prop: 'teacherFirstSurname',
          placeholder: 'Introduzca los apellidos',
          label: 'Apellidos profesor',
          value: ''
        },
        {
          element: 'input',
          prop: 'studentUsername',
          placeholder: 'Introduzca el número de colegiado',
          label: 'Número de colegiado estudiante'
        },
        {
          element: 'input',
          prop: 'studentEmail',
          placeholder: 'Introduzca el email del estudiante',
          label: 'Email de estudiante',
          value: ''
        },
        {
          element: 'input',
          prop: 'studentPassword',
          placeholder: 'Introduzca la contraseña',
          label: 'Contraseña estudiante',
          value: '',
          type: 'password'
        },
        {
          element: 'select',
          prop: 'studentSpecialism',
          placeholder: 'Introduzca la especialidad',
          label: 'Especialidad estudiante',
          value: '',
          options: specialisms
        },
        {
          element: 'input',
          prop: 'studentName',
          placeholder: 'Introduzca el nombre',
          label: 'Nombre estudiante',
          value: ''
        },
        {
          element: 'input',
          prop: 'studentFirstSurname',
          placeholder: 'Introduzca los apellidos',
          label: 'Apellidos estudiante',
          value: ''
        }
      ],
      form: {
        teacherUsername: '',
        teacherEmail: '',
        teacherPassword: '',
        teacherSpecialism: '',
        teacherName: '',
        teacherFirstSurname: '',
        studentUsername: '',
        studentEmail: '',
        studentPassword: '',
        studentSpecialism: '',
        studentName: '',
        studentFirstSurname: ''
      }
    }
  },
  methods: {
    submitForm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.createSchoolCredentials()
        } else {
          return false
        }
      })
    },
    clearInputs () {
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
    },
    createSchoolCredentials () {
      this.$emit('create', this.form)
    }
  },
  computed: {
    ...mapState({
      schoolSignupSuccess: state => state.schoolSignupSuccess
    })
  },
  watch: {
    schoolSignupSuccess (val, oldVal) {
      if (val) {
        this.clearInputs()
        this.$notify({
          title: 'Éxito',
          message: 'Credenciales escolares creadas',
          type: 'success'
        })
        this.$store.commit('removeSchoolSignupSuccess')
      }
    }
  }
}
</script>

<style lang="scss">
.el-form-item__label {
  text-align: left;
}

.authorization {
  padding: 0 24px;

  .grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 0 16px;
  }

  .field {
    display: flex;
    flex-direction: column;
    width: min-content;
    text-align: inherit;

    .input {
      width: 300px;
    }

    .button {
      margin-left: 8px;
    }
  }
}

@media (max-width: 700px) {
  .authorization {
    .grid-wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(12, 1fr);
    }
  }
}
</style>
