import Vue from 'vue'
import VueApollo from 'vue-apollo'
// import { onError } from 'apollo-link-error'
import { getAuth, signInWithCustomToken } from "firebase/auth";

// Name of the localStorage item
export const AUTH_TOKEN = 'apollo-token'
export const STORAGE_TOKEN = 'storatge-token'

// // Http endpoint
// const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:3000/graphql'
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:3000/graphql'

// // Files URL root
// export const filesRoot =
//   process.env.VUE_APP_FILES_ROOT ||
//   httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

// Vue.prototype.$filesRoot = filesRoot

// const link = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${JSON.stringify(
//           message,
//           null,
//           4
//         )}, Location: ${JSON.stringify(
//           locations,
//           null,
//           4
//         )}, Path: ${JSON.stringify(path, null, 4)}`
//       )
//     )
//   }
//   if (networkError) console.log(`[Network error]: ${networkError}`)
// })

// // Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // Production
  wsEndpoint: null,

  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: link,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: () => {
    console.log('0000000')
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(AUTH_TOKEN)
    // return the headers to the context so httpLink can read them
    if (token) {
      return token
    } else {
      return ''
    }
  }
  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// // Call this in the Vue app file
// export function createProvider (options = {}) {
//   // Create apollo client
//   const { apolloClient, wsClient } = createApolloClient({
//     ...defaultOptions,
//     ...options
//   })
//   apolloClient.wsClient = wsClient

//   // Create vue apollo provider
//   const apolloProvider = new VueApollo({
//     defaultClient: apolloClient,
//     defaultOptions: {
//       $query: {
//         // fetchPolicy: 'cache-and-network',
//       }
//     },
//     errorHandler (error) {
//       // eslint-disable-next-line no-console
//       console.log(
//         '%cError',
//         'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
//         error.message
//       )
//     }
//   })

//   return apolloProvider
// }

// export const apolloProvider = createProvider()

export async function loginIntoFirebase(firebaseToken) {
  const auth = getAuth()
  return signInWithCustomToken(auth, firebaseToken)
}

// Manually call this when user log in
export async function onLogin (apolloClient, { token, firebaseToken }) {
  loginIntoFirebase(firebaseToken)
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
    localStorage.setItem(STORAGE_TOKEN, firebaseToken)
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)

  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
    window.location.reload(false)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}

/////////////
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client/core'


// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:3000/graphql',
})


const authMiddleware = new ApolloLink((operation, forward) => {

  // add the authorization to the headers

    const token = localStorage.getItem(AUTH_TOKEN)

  operation.setContext({

    headers: {
      authorization: token ? `Bearer ${token}` : "",

    },

  });

  return forward(operation);

});

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,

  ...defaultOptions

})
import { createApolloProvider } from '@vue/apollo-option'

export const apolloProvider = createApolloProvider({
  // Multiple clients support
  // Use the 'client' option inside queries
  // or '$client' on the apollo definition
  // clients: {
  //   a: apolloClientA,
  // },
  // Default client
  defaultClient: apolloClient,
  // Default 'apollo' definition
  defaultOptions: {
    // See 'apollo' definition
    // For example: default query options
    $query: {
      loadingKey: 'loading',
      fetchPolicy: 'cache-and-network',
    },
  },
  // Watch loading state for all queries
  // See 'Smart Query > options > watchLoading' for detail
  // watchLoading (isLoading, countModifier) {
  //   loading += countModifier
  //   console.log('Global loading', loading, countModifier)
  // },
  // Global error handler for all smart queries and subscriptions
  errorHandler (error) {
    console.log('Global error handler')
    console.error(error)
  },
  // Globally turn off prefetch ssr
  prefetch: Boolean,
})

Vue.use(VueApollo)
