<template>
  <div class="fieldset">
    <p class="title">{{ label }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Fieldset',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldset {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0 16px 0;
  }
}
</style>
