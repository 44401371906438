<template>
  <Alert
    v-if="isVisible"
    :type="type"
    :title="title"
    :description="description"
  />
</template>

<script>
import Alert from '@/components/Alert'
import { getParameterByName } from '../utils'

export default {
  name: 'PaymentAlert',
  components: {
    Alert
  },
  computed: {
    paymentParameter () {
      return getParameterByName('payment')
    },
    success () {
      return this.paymentParameter === 'success'
    },
    isVisible () {
      return !!this.paymentParameter
    },
    type () {
      return this.success ? 'success' : 'error'
    },
    title () {
      const SUCCESS_TITTLE = 'Pago realizado con éxito'
      const ERROR_TITLE = 'Error procesando el pago'

      return this.success ? SUCCESS_TITTLE : ERROR_TITLE
    },
    description () {
      const SUCCESS_MESSAGE =
        'El pago se ha realizado con éxito y el caso a sido actualizado.'
      const ERROR_MESSAGE =
        'El pago no se ha realizado y el caso no ha sido actualizado.'

      return this.success ? SUCCESS_MESSAGE : ERROR_MESSAGE
    }
  }
}
</script>
