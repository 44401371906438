export const PAGINATION_PAGER_NUMBER = 11
export const PAGINATION_PAGER_NUMBER_SMALL = 5
export const PAGINATION_ITEMS_PER_PAGE = 40
// CASE or LIST
export const DEFAULT_CASE_VIEW = 'CARD'

export const PDF_DOWNLOAD_URL = {
  CONSENT:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/descargas%2Fconsentimiento_informado.pdf?alt=media&token=a12a7423-0a33-4a9d-8ac7-a9918b9cf6d2',
  ADVERTISING:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/descargas%2Fcontrato_%20publicidad.pdf?alt=media&token=dd3abb2a-4643-484e-9ce8-9222ea8e4ca3',
  TREATMENT_ENDING:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/descargas%2Ffinalizacion_de_tratamiento2022.pdf?alt=media&token=a71696a6-3c48-4971-bb3f-df43a215a03b',
  MEASURES:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/Etiqueta%20recogida%20QuickSmile.pdf?alt=media&token=fa1e93d1-f0a9-4257-bc74-8bef98a8b1ba',
  TIMING:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/descargas%2Ftiempos_trabajo.jpg?alt=media&token=f81b4b7b-dcb7-420d-bf7d-455da821c42a',
  TRANSFER:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/descargas%2Ftransferencia_%20paciente.pdf?alt=media&token=bf9795f2-4744-4e24-a951-288c075a7283',
  TYC:
    'https://quicksmile.es/tycdoctores',
  DOMICILIATION:
    'https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/descargas%2Fb2bmodelo-mandato-sepa-QuickSmile-editable.pdf?alt=media&token=8f55aca3-6877-4fb8-a9ae-3badf9e6bb19',
}

export const CASE_FILTERS_BY_STATE = [
  {
    label: 'Borrador',
    value: 'DRAFT'
  },
  {
    label: 'Evaluando Registros',
    value: 'EVALUATE_REGISTRY'
  },
  {
    label: 'Desarrollando tratamiento',
    value: 'DEVELOPING_TREATMENT'
  },
  {
    label: 'Revisar VTO',
    value: 'CHECK_VTO'
  },
  {
    label: 'Pendiente de pago',
    value: 'PAYMENT_PENDING'
  },
  {
    label: 'Producción',
    value: 'PRODUCTION'
  },
  {
    label: 'Enviado',
    value: 'SENT'
  },
  {
    label: 'En tratamiento',
    value: 'IN_TREATMENT_STATE'
  },
  {
    label: 'En refinamiento',
    value: 'IN_REFINEMENT_STATE'
  },
  {
    label: 'Finalizado',
    value: 'FINISHED'
  }
]

export const CASE_FILTERS_BY_KIND = [
  {
    value: 'IN_TREATMENT_TYPE',
    label: 'Fase de tratamiento'
  },
  {
    value: 'IN_REFINEMENT_TYPE',
    label: 'Fase de refinamiento'
  }
]

export const LOCAL_STORAGE_KEYS = {
  SELECTED_STAGE_FILTERS: 'SELECTED_STAGE_FILTERS',
  SELECTED_ADDRESS_FILTERS: 'SELECTED_ADDRESS_FILTERS'
}

export const DEFAULT_STAGE_FILTERS = [
  // 'DRAFT',
  // 'EVALUATE_REGISTRY',
  // 'DEVELOPING_TREATMENT',
  // 'CHECK_VTO',
  // 'PAYMENT_PENDING',
  // 'PRODUCTION',
  // 'SENT',
  // 'IN_TREATMENT_STATE',
  // 'IN_REFINEMENT_STATE',
  // 'FINISHED',
  // 'IN_TREATMENT_TYPE',
  // 'IN_REFINEMENT_TYPE',
]

export const DEFAULT_ADDRESS_FILTERS = []

export const PROVINCES = [
  'Álava',
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias',
  'Ávila',
  'Badajoz',
  'Barcelona',
  'Burgos',
  'Cáceres',
  'Cádiz',
  'Cantabria',
  'Castellón',
  'Ceuta',
  'Ciudad Real',
  'Córdoba',
  'La Coruña',
  'Cuenca',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Guipúzcoa',
  'Huelva',
  'Huesca',
  'Baleares',
  'Jaén',
  'León',
  'Lérida',
  'Lugo',
  'Madrid',
  'Málaga',
  'Melilla',
  'Murcia',
  'Navarra',
  'Ourense',
  'Palencia',
  'Las Palmas',
  'Pontevedra',
  'La Rioja',
  'Salamanca',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Santa Cruz de Tenerife',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza'
]

export const COUNTRIES = [
  'Alemania',
  'Grecia',
  'Austria',
  'Hungría',
  'Bélgica',
  'Irlanda',
  'Bulgaria',
  'Italia',
  'Chequia',
  'Letonia',
  'Chipre',
  'Lituania',
  'Croacia',
  'Luxemburgo',
  'Dinamarca',
  'Malta',
  'Eslovaquia',
  'Países Bajos',
  'Eslovenia',
  'Polonia',
  'España',
  'Portugal',
  'Estonia',
  'Rumanía',
  'Finlandia',
  'Suecia',
  'Francia'
]

export const GENDERS = ['Masculino', 'Femenino']
