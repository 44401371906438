<template>
  <div>
    <Fieldset label="Maloclusiones que desea mejorar" class="maloclusions">
      <el-checkbox-group
        v-model="improveMaloclusionsInternal"
        class="maloclusions"
      >
        <el-checkbox label="edgetoedge" :disabled="disabled">
          <span class="radio-label">Oclusión borde a borde anterior</span>
        </el-checkbox>
        <el-checkbox label="anteriorcrossbite" :disabled="disabled">
          <span class="radio-label">Mordida cruzada anterior</span>
        </el-checkbox>
        <el-checkbox label="posteriorcrossbite" :disabled="disabled">
          <span class="radio-label">Mordida cruzada posterior</span>
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>

    <Fieldset
      v-if="improveMaloclusionsInternal.includes('edgetoedge')"
      label="Oclusión borde a borde anterior"
    >
      <el-checkbox-group
        v-model="anteriorEdgeToEdgeOcclusionInternal"
        class="maloclusions"
      >
        <el-checkbox label="bulgeanterosuperior" :disabled="disabled">
          <span class="radio-label">Protruir dientes anterosuperiores</span>
        </el-checkbox>
        <el-checkbox label="bulgeanteroinferior" :disabled="disabled">
          <span class="radio-label">Retruir dientes anteroinferiores</span>
        </el-checkbox>
        <el-checkbox label="extrudeanterosuperior" :disabled="disabled">
          <span class="radio-label">Extruir dientes anterosuperiores</span>
        </el-checkbox>
        <el-checkbox label="extrudeanteroinferior" :disabled="disabled">
          <span class="radio-label">Extruir dientes anteroinferiores</span>
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>

    <Fieldset
      v-if="improveMaloclusionsInternal.includes('anteriorcrossbite')"
      label="Mordida cruzada anterior"
    >
      <el-checkbox-group
        v-model="anteriorCrossbiteInternal"
        class="maloclusions"
      >
        <el-checkbox label="anterosuperiores" :disabled="disabled">
          <span class="radio-label">Protruir dientes anterosuperiores</span>
        </el-checkbox>
        <el-checkbox label="anteroinferiores" :disabled="disabled">
          <span class="radio-label">Retruir dientes anteroinferiores</span>
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>

    <Fieldset
      v-if="improveMaloclusionsInternal.includes('posteriorcrossbite')"
      label="Mordida cruzada posterior"
    >
      <el-checkbox-group
        v-model="posteriorCrossbiteInternal"
        class="maloclusions"
      >
        <el-checkbox label="superior" :disabled="disabled">
          <span class="radio-label"
            >Corregir preferentemente expandiendo arcada superior</span
          >
        </el-checkbox>
        <el-checkbox label="inferior" :disabled="disabled">
          <span class="radio-label"
            >Corregir preferentemente constriñendo arcada inferior</span
          >
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>
  </div>
</template>

<script>
import Fieldset from '../Fieldset.vue'
export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    improveMaloclusions: {
      required: false
    },
    anteriorEdgeToEdgeOcclusion: {
      required: false
    },
    anteriorCrossbite: {
      required: false
    },
    posteriorCrossbite: {
      required: false
    }
  },
  computed: {
    improveMaloclusionsInternal: {
      get () {
        return this.improveMaloclusions || ''
      },
      set (val) {
        this.$emit('improveMaloclusions', val)
      }
    },
    anteriorEdgeToEdgeOcclusionInternal: {
      get () {
        return this.anteriorEdgeToEdgeOcclusion || ''
      },
      set (val) {
        this.$emit('anteriorEdgeToEdgeOcclusion', val)
      }
    },
    anteriorCrossbiteInternal: {
      get () {
        return this.anteriorCrossbite || ''
      },
      set (val) {
        this.$emit('anteriorCrossbite', val)
      }
    },
    posteriorCrossbiteInternal: {
      get () {
        return this.posteriorCrossbite || ''
      },
      set (val) {
        this.$emit('posteriorCrossbite', val)
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss">
.radio-label,
.checkbox-label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.maloclusions {
  display: flex;
  flex-direction: column;
}
</style>
