<template>
  <el-dialog
    title="Eliminar fotografías"
    :visible.sync="internalVisible"
    width="30%"
    :show-close="false"
  >
    <div class="dialog">
      <span>¿Está seguro/a de que quiere eliminar todas las fotografías?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('cancel')">Cancelar</el-button>
        <el-button type="primary" @click="$emit('confirm')"
          >Confirmar</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean
  },
  computed: {
    internalVisible: {
      set () {
        this.$emit('cancel')
      },
      get () {
        return this.visible
      }
    }
  }
}
</script>
