<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />
    <RetentionForm
      v-if="doctorName"
      :retention="retention"
      :treatmentType="treatmentType"
      :membershipNumber="membershipNumber"
    />
    <Loading :active="loading" />

    <Footer class="footer-wrapper" />
    <Loading :active="loading" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import RetentionForm from '@/components/RetentionForm.vue'
import Loading from '@/components/Loading.vue'
import Footer from '@/components/Footer.vue'
import { mapState } from 'vuex'

export default {
  name: 'NewCase',
  components: {
    Header,
    Loading,
    Footer,
    RetentionForm
  },
  mounted () {
    this.$store.dispatch('fetchRetention', { caseId: this.id })
  },
  computed: {
    ...mapState({
      treatmentType: state => state.currentCase.treatmentType,
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      retention: state => state.currentCase.retention,
      loading: state => state.isLoading,
      membershipNumber: state => state.me.username
    })
  },
  data () {
    return {
      id: this.$route.params.id
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-rows: 160px 1fr;
  min-height: 100vh;

  // .footer-wrapper {
  //   padding: 0 24px 24px 24px;
  // }
}
</style>
