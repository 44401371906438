<template>
  <div class="form-address">
    <div class="form">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        :hide-required-asterisk="true"
        @submit.native.prevent="submitForm"
      >
        <div class="address">
          <div class="row">
            <p v-if="type === 'BILLING'" class="title">Razón social</p>
            <p v-else class="title">Clinica</p>
            <el-form-item class="input-wrapper" label="" prop="clinicName">
              <el-input
                v-model="form.clinicName"
                size="mini"
                placeholder="Nombre de la clínica"
              />
            </el-form-item>
          </div>
          <div class="row">
            <p class="title">Dirección</p>
            <el-form-item class="input-wrapper" label="" prop="streetName1">
              <el-input
                v-model="form.streetName1"
                size="mini"
                :maxlength="140"
                placeholder="Escriba aquí el nombre de su calle, número, portal..."
              />
            </el-form-item>
          </div>

          <div class="row">
            <p class="title">País</p>
            <el-form-item class="input-wrapper" label="" prop="country">
              <el-select
                v-model="form.country"
                size="mini"
                placeholder="Seleccione su país"
              >
                <el-option
                  v-for="country in countries"
                  :key="country"
                  :value="country"
                  :label="country"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="row">
            <p class="title">Provincia</p>
            <el-form-item class="input-wrapper" label="" prop="province">
              <el-select
                v-if="form.country === 'España'"
                v-model="form.province"
                size="mini"
                placeholder="Seleccione su provincia"
              >
                <el-option
                  v-for="province in provinces"
                  :key="province"
                  :value="province"
                  :label="province"
                />
              </el-select>

              <el-input
                v-else
                v-model="form.province"
                size="mini"
                placeholder="Escriba aquí su provincia o región"
              />
            </el-form-item>
          </div>

          <div class="row">
            <p class="title">Código postal</p>
            <el-form-item class="input-wrapper" label="" prop="postalCode">
              <el-input
                v-model="form.postalCode"
                size="mini"
                placeholder="Escriba aquí su código postal"
              />
            </el-form-item>
          </div>

          <div class="row">
            <p class="title">Ciudad</p>
            <el-form-item class="input-wrapper" label="" prop="city">
              <el-input
                v-model="form.city"
                size="mini"
                placeholder="Escriba aquí su ciudad"
              />
            </el-form-item>
          </div>

          <div class="row">
            <p class="title">Teléfono</p>
            <el-form-item class="input-wrapper" label="" prop="phone">
              <el-input
                v-model="form.phone"
                size="mini"
                placeholder="Escriba aquí su teléfono de contacto"
              />
            </el-form-item>
          </div>

          <div v-if="type === 'BILLING'" class="row">
            <p class="title">Email</p>
            <el-form-item class="input-wrapper" label="" prop="email">
              <el-input
                v-model="form.email"
                size="mini"
                placeholder="Introduzca su email"
              />
            </el-form-item>
          </div>

          <div v-if="type === 'BILLING'" class="row">
            <p class="title">CIF / VAT number</p>
            <el-form-item class="input-wrapper" label="" prop="cif">
              <el-input
                v-model="form.cif"
                size="mini"
                placeholder="Introduzca su CIF"
              />
            </el-form-item>
          </div>

          <div class="row options">
            <el-button
              @click="emitCloseAddressFormEvent"
              type="secondary"
              size="small"
              >Cancelar</el-button
            >
            <el-button v-if="id" @click="submitForm" type="primary" size="small"
              >Actualizar dirección</el-button
            >
            <el-button v-else @click="submitForm" type="primary" size="small"
              >Crear dirección</el-button
            >
          </div>
        </div>
        <button
          ref="submit"
          type="submit"
          :style="{ display: 'none' }"
        ></button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PROVINCES } from '@/constants'
import { COUNTRIES } from '../constants'
export default {
  name: 'AddressForm',
  computed: {
    ...mapState({
      doctorName: state => state.me.fullName,
      doctorImage: state => state.me.image
    })
  },
  methods: {
    emitCloseAddressFormEvent () {
      this.$emit('close-address-form')
    },
    async submitForm () {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await this.$store.dispatch(
            this.id ? 'updateAddress' : 'createAddress',
            {
              id: this.id,
              clinicName: this.form.clinicName,
              streetName1: this.form.streetName1,
              province: this.form.province,
              country: this.form.country,
              postalCode: this.form.postalCode,
              city: this.form.city,
              phone: this.form.phone,
              cif: this.form.cif,
              email: this.form.email,
              type: this.type
            }
          )

          if (this.id) {
            this.$emit('address-updated')
          } else if (this.id) {
            this.$emit('address-created')
          }

          this.emitCloseAddressFormEvent()
        }
      })
    }
  },
  props: {
    address: {
      required: false,
      type: Object
    },
    type: {
      required: true,
      type: String
    }
  },
  data () {
    const form = this.address
      ? {
          clinicName: this.address.clinicName || '',
          streetName1: this.address.streetName1 || '',
          province: this.address.province || '',
          country: this.address.country || '',
          postalCode: this.address.postalCode || '',
          city: this.address.city || '',
          phone: this.address.phone || '',
          cif: this.address.cif || '',
          email: this.address.email || ''
        }
      : {
          clinicName: '',
          streetName1: '',
          province: '',
          country: 'España',
          postalCode: '',
          city: '',
          phone: '',
          cif: '',
          email: ''
        }

    return {
      loading: true,
      id: this.address ? this.address.id || '' : '',
      countries: COUNTRIES,
      provinces: PROVINCES,
      form,
      rules: {
        clinicName: [
          {
            required: true,
            message: 'Introduce el nombre de la clínica',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'La longitud mínima es de 2 caracteres',
            trigger: 'blur'
          },
          {
            max: 120,
            message: 'La longitud máxima es de 70 caracteres',
            trigger: 'blur'
          }
        ],
        streetName1: [
          {
            required: true,
            message: 'Introduce el nombre de la vía',
            trigger: 'blur'
          },
          {
            max: 140,
            message: 'La longitud máxima es de 140 caracteres',
            trigger: 'blur'
          }
        ],
        province: [
          { required: true, message: 'Introduce la provincia', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'Seleccione el país', trigger: 'blur' }
        ],
        postalCode: [
          {
            required: true,
            message: 'Introduce el código postal',
            trigger: 'blur'
          }
        ],
        city: [
          { required: true, message: 'Introduce la población', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Introduce el teléfono', trigger: 'blur' }
        ],
        cif: [{ required: true, message: 'Introduce el cif', trigger: 'blur' }],
        email: [
          { required: true, message: 'Introduce el email', trigger: 'blur' }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-address {
  display: inline-block;

  .form {
    margin-right: 8px;
    .address {
      width: 300px;
      border-radius: 15px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      margin-bottom: 8px;

      .row {
        padding: 0 16px;
        color: #000000;
        border-bottom: 1px solid lightgrey;

        .title {
          margin-top: 23px;
          margin-bottom: 6px;
          font-weight: bold;
        }

        .input-wrapper {
          // margin: 0;
        }

        .value {
          font-size: 14px;
        }

        &:first-of-type {
          padding-top: 1px;
        }

        &:nth-last-of-type(2) {
          border: none;
        }

        &.options {
          border: none;
          display: flex;
          justify-content: space-between;
          padding-bottom: 12px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .form-address {
    display: block;
    .form {
      margin: 0;
    }
  }
}
</style>
