<template>
  <div>
    <Fieldset label="Mordida" class="column">
      <el-radio label="show" v-model="overbiteInternal" :disabled="disabled">
        <span class="radio-label"
          >Mostrar la mordida resultante después de la simulación virtual</span
        >
      </el-radio>
      <el-radio label="keep" v-model="overbiteInternal" :disabled="disabled">
        <span class="radio-label">Mantener</span>
      </el-radio>
      <el-radio
        label="fixopenbite"
        v-model="overbiteInternal"
        :disabled="disabled"
      >
        <span class="radio-label">Corregir la mordida abierta</span>
      </el-radio>
      <el-radio
        label="fixdeepbite"
        v-model="overbiteInternal"
        :disabled="disabled"
      >
        <span class="radio-label">Corregir la mordida profunda</span>
      </el-radio>
    </Fieldset>

    <Fieldset
      v-if="overbiteInternal === 'fixopenbite'"
      :class="[useBigTitle ? 'normal-title' : 'smaller-title']"
      label="Solución para la mordida abierta"
    >
      <el-checkbox-group v-model="openbiteSolutionInternal">
        <el-checkbox label="anterosuperior" :disabled="disabled">
          <span class="radio-label">Extruir anterosuperior</span>
        </el-checkbox>
        <el-checkbox label="anteroinferior" :disabled="disabled">
          <span class="radio-label">Extruir anteroinferior</span>
        </el-checkbox>
        <el-checkbox label="posterosuperiores" :disabled="disabled">
          <span class="radio-label"
            >Intruir sectores posterosuperiores (puede requerir el uso de
            minitornillos)</span
          >
        </el-checkbox>
        <el-checkbox
          :label="otherOpenbiteSolutionInternal"
          :disabled="disabled"
        >
          <span class="radio-label other" ref="otherOpenbiteSolution">
            Otro
            <el-input
              type="textarea"
              resize="none"
              :rows="2"
              class="other-input"
              v-model="otherOpenbiteSolutionInternal"
              :maxlength="140"
              :disabled="disabled"
            />
          </span>
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>

    <Fieldset
      v-if="overbiteInternal === 'fixdeepbite'"
      :class="[useBigTitle ? 'normal-title' : 'smaller-title']"
      label="Soluciones para la mordida profunda"
    >
      <el-checkbox-group v-model="deepbiteSolutionInternal">
        <el-checkbox label="anterosuperior" :disabled="disabled">
          <span class="radio-label"
            >Intruir solo sector anterosuperior (puede requerir el uso de
            minitornillos)</span
          >
        </el-checkbox>
        <el-checkbox label="anteroinferior" :disabled="disabled">
          <span class="radio-label"
            >Intruir solo sector anteroinferior (puede requerir el uso de
            minitornillos)</span
          >
        </el-checkbox>
        <el-checkbox label="posterosuperiores" :disabled="disabled">
          <span class="radio-label"
            >Intruir sector anterosuperior y extruir sectores
            posterosuperiores</span
          >
        </el-checkbox>
        <el-checkbox label="posteroinferiores" :disabled="disabled">
          <span class="radio-label"
            >Intruir sector anteroinferior y extruir sectores
            posteroinferiores</span
          >
        </el-checkbox>
        <el-checkbox label="plane" :disabled="disabled">
          <span class="radio-label">Plano de levante anterior</span>
        </el-checkbox>
        <el-checkbox
          :label="otherDeepbiteSolutionInternal"
          :disabled="disabled"
        >
          <span class="radio-label other" ref="otherDeepbiteSolution">
            Otro
            <el-input
              type="textarea"
              resize="none"
              :rows="2"
              class="other-input"
              v-model="otherDeepbiteSolutionInternal"
              :maxlength="140"
              :disabled="disabled"
            />
          </span>
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>

    <Fieldset
      v-if="deepbiteSolutionInternal.includes('plane')"
      :class="[useBigTitle ? 'normal-title' : 'smaller-title']"
      label="Plano de levante anterior"
    >
      <el-checkbox-group v-model="anteriorLiftPlaneInternal">
        <el-checkbox label="centrales" :disabled="disabled">
          <span class="radio-label"
            >Rampas en cara palatina de incisivos centrales superiores</span
          >
        </el-checkbox>
        <el-checkbox label="laterales" :disabled="disabled">
          <span class="radio-label"
            >Rampas en cara palatina de incisivos laterales superiores</span
          >
        </el-checkbox>
        <el-checkbox label="caninos" :disabled="disabled">
          <span class="radio-label"
            >Rampas en cara palatina de caninos superiores</span
          >
        </el-checkbox>
      </el-checkbox-group>
    </Fieldset>
  </div>
</template>

<script>
import Fieldset from '../Fieldset.vue'

export default {
  props: {
    useBigTitle: {
      required: false,
      type: Boolean
    },
    disabled: {
      required: false,
      type: Boolean
    },
    overbite: {
      required: false
    },
    openbiteSolution: {
      required: false
    },
    otherOpenbiteSolution: {
      required: false
    },
    deepbiteSolution: {
      required: false
    },
    anteriorLiftPlane: {
      required: false
    },
    otherDeepbiteSolution: {
      required: false
    }
  },
  computed: {
    overbiteInternal: {
      get () {
        return this.overbite || ''
      },
      set (val) {
        this.$emit('overbite', val)
      }
    },
    openbiteSolutionInternal: {
      get () {
        return this.openbiteSolution || ''
      },
      set (val) {
        this.$emit('openbiteSolution', val)
      }
    },
    otherOpenbiteSolutionInternal: {
      get () {
        return this.otherOpenbiteSolution || ''
      },
      set (val) {
        this.$emit('otherOpenbiteSolution', val)
      }
    },
    deepbiteSolutionInternal: {
      get () {
        return this.deepbiteSolution || ''
      },
      set (val) {
        this.$emit('deepbiteSolution', val)
      }
    },
    otherDeepbiteSolutionInternal: {
      get () {
        return this.otherDeepbiteSolution || ''
      },
      set (val) {
        this.$emit('otherDeepbiteSolution', val)
      }
    },
    anteriorLiftPlaneInternal: {
      get () {
        return this.anteriorLiftPlane || ''
      },
      set (val) {
        this.$emit('anteriorLiftPlane', val)
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss">
.radio-label,
.checkbox-label,
.el-checkbox__label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.other {
  display: flex;
  textarea {
    margin-left: 4px;
  }
}

.smaller-title {
  .title {
    font-size: 18px !important;
    text-align: center;
  }

  div {
    display: flex;
    flex-direction: column;
  }
}
</style>
