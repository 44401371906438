<template>
  <textarea
    class="adminNotes"
    auto-complete=""
    placeholder="Añada notas para otro administrador"
    v-model="auxNotes"
  />
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'AdminNotes',
  props: {
    notes: String
  },
  computed: {
    auxNotes: {
      get () {
        return this.notes
      },
      set (val) {
        this.setNotes(val)
      }
    }
  },
  methods: {
    emitSaveEvent: debounce(function (val) {
      this.$store.dispatch('saveAdminNotes', {
        id: this.$route.params.id,
        notes: val
      })
    }, 1000),
    setNotes: function (val) {
      this.emitSaveEvent(val)
    }
  }
}
</script>

<style>
.admin-notes {
  height: 100%;
  width: 100%;
}
</style>
