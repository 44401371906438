<template>
  <div class="form-container">
    <div class="form-wrapper">
      <el-form
        :model="form"
        ref="form"
        :hide-required-asterisk="true"
        class="form"
      >
        <button :style="{ display: 'none' }" type="submit" ref="submit">
          submit
        </button>

        <p class="title">Retención final</p>

        <div v-if="step === 0" class="step">
          <Fieldset label="¿Cómo desea realizar la retención final?">
            <el-radio label="lastStage" v-model="form.finalRetentionType">
              <span class="radio-label">Última etapa</span>
            </el-radio>
            <p>
              Realizaremos la retención en base a la última etapa de la
              simulación. Sin retención fija.
            </p>
            <el-radio label="newMeasures" v-model="form.finalRetentionType">
              <span class="radio-label">Nuevas medidas</span>
            </el-radio>
            <p>
              Realizaremos la retención en base a nuevas medidas.
              <br />
              Elija esta opción si desea colocar retención fija.
            </p>
          </Fieldset>

          <Fieldset label="Observaciones">
            <el-input
              class="derivation-custom other-input"
              type="textarea"
              resize="none"
              :rows="2"
              v-model="form.notes"
              :maxlength="140"
            />
          </Fieldset>
        </div>

        <div v-if="step === 1" class="step">
          <RegistryTypeSelection
            :typeOfImpressions="form.typeOfImpressions"
            @typeOfImpressions="e => (form.typeOfImpressions = e)"
            :upperIntraoralScanner="form.upperIntraoralScanner"
            @upperIntraoralScanner="e => (form.upperIntraoralScanner = e)"
            :lowerIntraoralScanner="form.lowerIntraoralScanner"
            @lowerIntraoralScanner="e => (form.lowerIntraoralScanner = e)"
            :occlusalIntraoralScanner="form.occlusalIntraoralScanner"
            @occlusalIntraoralScanner="e => (form.occlusalIntraoralScanner = e)"
            :lowerIntraoralScannerName="form.lowerIntraoralScannerName"
            @lowerIntraoralScannerName="
              e => (form.lowerIntraoralScannerName = e)
            "
            :upperIntraoralScannerName="form.upperIntraoralScannerName"
            @upperIntraoralScannerName="
              e => (form.upperIntraoralScannerName = e)
            "
            :occlusalIntraoralScannerName="form.occlusalIntraoralScannerName"
            @occlusalIntraoralScannerName="
              e => (form.occlusalIntraoralScannerName = e)
            "
            :typeOfImpressionsAddress="form.typeOfImpressionsAddress"
            @typeOfImpressionsAddress="e => (form.typeOfImpressionsAddress = e)"
          />
        </div>

        <div v-if="step === 2" class="step">
          <Fieldset label="Finalización de tratamiento">
            <el-checkbox
              class="el-checkbox derivation-custom other-input"
              v-model="form.endingConfirmation"
              label="Marcando esta casilla confirmo que el tratamiento ortodóntico realizado con QuickSmile a este paciente ha finalizado."
            />
            <p>Condiciones de finalización QuickSmile:</p>
            <p>
              - QuickSmile proveerá al Doctor de
              {{
                treatmentType.includes('8') ? 'un (1) juego' : 'dos (2) juegos'
              }}
              de retenedores finales sin coste. Si se necesitasen más juegos de
              retenedores, éstos tendrán un coste.
            </p>
            <p>
              - Tras la finalización del tratamiento y una vez que se haga
              entrega de los retenedores finales, QuickSmile no facilitará
              nuevos refinamientos. Si el paciente requiere extender su
              tratamiento se considerará como un nuevo caso.
            </p>
          </Fieldset>
        </div>
      </el-form>
    </div>
    <div class="navigation">
      <el-button v-if="step !== 0" type="primary" @click="goToPreviousStep"
        >Atrás</el-button
      >
      <div v-else></div>
      <el-button
        v-if="step === 2"
        type="primary"
        @click="form.endingConfirmation && tryCreateRetention()"
        :disabled="!form.endingConfirmation"
        >Crear retención final</el-button
      >
      <el-button v-else type="primary" @click="goToNextStep"
        >Guardar y continuar</el-button
      >
    </div>

    <el-dialog
      class="dialog"
      title="Crear retentión final"
      :visible.sync="isDialogVisible"
      :width="isSmallWindow ? '80%' : '30%'"
      :show-close="false"
    >
      <div v-if="!showFormValidationError" class="confirmation">
        <p>
          Si hace click en Confirmar se creará la retención final ¿Está
          seguro/a?
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">Cancelar</el-button>
          <el-button type="primary" @click="() => updateRetention(true)"
            >Confirmar</el-button
          >
        </span>
      </div>

      <div v-else class="confirmation">
        <p>
          Debes rellenar todos los campos. El refinamiento se ha guardado como
          borrador.
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeFormValidationError"
            >Aceptar</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from '@/components/Fieldset'
import RegistryTypeSelection from '@/components/form/RegistryTypeSelection'
import { PDF_DOWNLOAD_URL } from '@/constants'

export default {
  name: 'RetentionForm',
  props: {
    treatmentType: {
      type: String,
      required: true
    },
    retention: {
      type: Object,
      required: false
    }
  },
  data () {
    const caseId = this.$route.params.id
    const form = this.retention
      ? {
          finalRetentionType: this.retention.finalRetentionType || 'lastStage',
          notes: this.retention.notes || '',
          endingConfirmation: this.retention.endingConfirmation || false,
          caseId: caseId,
          case: this.$route.params.id,
          created: this.retention.created || false,
          typeOfImpressions:
            this.retention.typeOfImpressions || 'intraoralScanner',
          typeOfImpressionsAddress:
            this.retention.typeOfImpressionsAddress || '',
          upperIntraoralScanner: '' || this.retention.upperIntraoralScanner,
          lowerIntraoralScanner: '' || this.retention.lowerIntraoralScanner
        }
      : {
          finalRetentionType: 'lastStage',
          notes: '',
          endingConfirmation: false,
          caseId: caseId,
          case: this.$route.params.id,
          created: false,
          typeOfImpressions: 'intraoralScanner',
          typeOfImpressionsAddress: '',
          upperIntraoralScanner: '',
          lowerIntraoralScanner: ''
        }

    return {
      shippingSheetPdf: PDF_DOWNLOAD_URL.MEASURES,
      clientWidth: window.innerWidth,
      showFormValidationError: null,
      step: 0,
      isDialogVisible: false,
      draftCreated: !!this.retention,
      form,
      lowerIntraoralScannerName: '',
      upperIntraoralScannerName: ''
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    isSmallWindow () {
      return this.clientWidth < 768
    }
  },
  methods: {
    closeFormValidationError () {
      this.closeDialog()
      this.updateRetention()
    },
    handleResize (event) {
      this.clientWidth = window.innerWidth
    },
    tryCreateRetention () {
      if (this.validateForm()) {
        this.showFormValidationError = false
        this.isDialogVisible = true
      } else {
        this.showFormValidationError = true
        this.isDialogVisible = true
      }
    },
    validateForm () {
      return true
    },
    closeDialog () {
      this.isDialogVisible = false
    },
    openDialog () {
      this.isDialogVisible = true
    },
    async updateRetention (isCreating) {
      this.form.created = !!isCreating
      if (this.retention || this.draftCreated) {
        await this.$store.dispatch('updateRetention', { form: this.form })
      } else {
        this.$store.dispatch('createRetention', { form: this.form })
      }
      this.draftCreated = true
      if (isCreating) {
        this.$router.push(`/caso/${this.form.case}`)
      }
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      }
      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.form[fieldName] = downloadURL

            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    goToNextStep () {
      this.updateRetention()

      if (this.form.finalRetentionType === 'lastStage' && this.step === 0) {
        this.step += 2
      } else {
        this.step += 1
      }
    },
    goToPreviousStep () {
      if (this.form.finalRetentionType === 'lastStage' && this.step === 2) {
        this.step -= 2
      } else {
        this.step -= 1
      }
    }
  },
  components: {
    Fieldset,
    RegistryTypeSelection
  }
}
</script>

<style lang="scss" scoped>
.link {
  color: black;
}
.derivation-custom {
  .radio-label {
    white-space: initial !important;
  }
}

.step {
  width: 392px;
}

.el-radio {
  display: flex;
  margin-bottom: 8px;
}
.el-checkbox {
  white-space: break-spaces;
}

.el-checkbox,
.el-radio {
  display: flex;
  .el-checkbox__label {
    white-space: break-spaces;
  }
}

.radio-label {
  margin-right: 8px;
  display: flex;
}

.form-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .title {
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }

  .navigation {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .uploader {
    font-size: 25px;
    padding: 4px 24px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .big-uploader {
    width: 208px;
    height: 104px;
    font-size: 25px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .big-uploader-text {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 208px;
    height: 104px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .text {
      padding: 16px;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .dialog-footer {
    text-align: right;
  }
}

.intraoral-scanner-grid {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.custom-error {
  margin-top: 0;
  color: #f56c6c;
  font-size: 12px;
}

.custom-message {
  color: #f56c6c;
  max-width: 300px;
  color: #f56c6c;
}

.margin {
  &-bottom {
    margin-bottom: 16px;
  }

  &-top {
    margin-top: 16px;
  }

  &-right {
    margin-right: 16px;
  }

  &-left {
    margin-left: 16px;
  }
}

.patient-photo-upload {
  // width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 0 8px;
  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 120px;
  }

  &:hover {
    .cross {
      color: black;
      cursor: pointer;
      // font-weight: bold;
    }
  }
}

@media (max-width: 768px) {
  .radio-button {
    display: none !important;
  }

  .form-container {
    padding: 8px;
    .form-wrapper {
      width: 100%;
      .form {
        .step {
          width: 100%;

          .el-checkbox,
          .el-radio {
            display: flex;
            .el-checkbox__label,
            .radio-label {
              white-space: break-spaces;
            }
          }

          .patient-photo-upload {
            width: auto;
          }
        }
      }
    }

    .navigation {
      margin-top: 8px;

      .back {
        width: max-content;
      }

      .next {
        width: max-content;
      }
    }
  }
}
</style>
