import dayjs from 'dayjs'

export function setCookie (name, value, expirationDays) {
  const d = new Date()
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + '' + expires + 'path=/'
}

export function formatTreatmentType (treatmentType, isPost15N) {
  const treatmentsPost15N = {
    lite1: 'Q-22 (1 arcada)',
    lite2: 'Q-22 (2 arcadas)',
    full1: 'Q-Full (1 arcada)',
    full2: 'Q-Full (2 arcadas)',
    q81: 'Q-12 (1 arcada)',
    q82: 'Q-12 (2 arcadas)'
  }

  const treatmentsPre15N = {
    lite1: 'Q-Lite (1 arcada)',
    lite2: 'Q-Lite (2 arcadas)',
    full1: 'Q-Full (1 arcada)',
    full2: 'Q-Full (2 arcadas)',
    q81: 'Q-8 (1 arcada)',
    q82: 'Q-8 (2 arcadas)'
  }
  const treatments = isPost15N ? treatmentsPost15N : treatmentsPre15N

  return treatments[treatmentType]
}

export function formatDate (date) {
  return dayjs(date).format('DD-MM-YYYY')
}

export function addYears (date, years) {
  return dayjs(date)
    .add(years, 'y')
    .format('DD-MM-YYYY')
}

export function getCookie (name) {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift()
}

export function deleteCookie (name, path, domain) {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}
// TODO: move to backend
export function getNameOfStage (number, type) {
  const isRetention = type === 'retention'
  const isRefinement = type === 'refinement'

  const names = {
    0: 'Evaluando registros',
    1: 'Evaluando registros',
    2: 'Desarrollando tratamiento',
    3: 'Revisar QuickCheck',
    4: isRetention
      ? 'Retención aceptada'
      : isRefinement
      ? 'Refinamiento aceptado'
      : 'Pendiente de pago',
    5: 'CANCELADO',
    6: 'En producción',
    7: 'Enviado',
    8: 'Recibido',
    9: isRetention
      ? 'En retención'
      : isRefinement
      ? 'En refinamiento'
      : 'En tratamiento',
    10: isRefinement ? 'En refinamiento' : 'En retención',
    11: 'Finalizado',
    12: 'Revisar VTO',
    13: 'Desarrollando secuenciación'
  }
  return names[number]
}

export function getStageNumber (name) {
  const stages = {
    'Evaluando registros': 1,
    'Desarrollando tratamiento': 2,
    'Revisar QuickCheck': 3,
    'Pendiente de pago': 4,
    'Refinamiento aceptado': 4,
    CANCELADO: 5,
    'En producción': 6,
    Enviado: 7,
    Recibido: 8,
    'En tratamiento': 9,
    'En refinamiento': 10,
    Finalizado: 11,
    'Revisar VTO': 12,
    'Desarrollando secuenciación': 13,
    'En retención': 9,
    'Retención aceptada': 4
  }
  return stages[name]
}

export function getColorOfStage (stage, type) {
  const isRetention = type === 'retention'
  const isRefinement = type === 'refinement'

  const stageNumber = isNaN(stage) ? getStageNumber(stage) : stage
  const colors = {
    0: 'green',
    1: 'green',
    2: 'green',
    3: 'yellow',
    4: isRefinement || isRetention ? 'green' : 'red',
    5: 'grey',
    6: 'blue',
    7: 'green',
    8: 'yellow',
    9: 'blue',
    10: 'green',
    11: 'green',

    12: 'yellow',
    13: 'yellow'
  }

  return colors[stageNumber]
}

export function getParameterByName (name, url) {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) {
    return null
  } else if (!results[2]) {
    return ''
  } else {
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
}

export function getQuickcheckFromCase (c) {
  const isRetention = c.retention && c.retention.created
  const isRefinement =
    !isRetention && c.refinements && !!c.refinements.find(x => x.created)

  if (isRefinement) {
    const createdRefinements = c.refinements.filter(
      refinement => refinement.created
    )
    const latestRefinement = createdRefinements.length
      ? createdRefinements[createdRefinements.length - 1]
      : null

    return latestRefinement &&
      latestRefinement.quickcheckHistory &&
      latestRefinement.quickcheckHistory.length
      ? latestRefinement.quickcheckHistory[
          latestRefinement.quickcheckHistory.length - 1
        ].quickcheck
      : null
  } else if (isRetention) {
    return c.retention.quickcheckHistory && c.retention.quickcheckHistory.length
      ? c.retention.quickcheckHistory[c.retention.quickcheckHistory.length - 1]
          .quickcheck
      : null
  } else {
    return c.quickcheckHistory && c.quickcheckHistory.length
      ? c.quickcheckHistory[c.quickcheckHistory.length - 1].quickcheck
      : null
  }
}

export function getVTOFromCase (c) {
  const isRetention = c.retention && c.retention.created
  const isRefinement =
    !isRetention && c.refinements && !!c.refinements.find(x => x.created)

  if (isRefinement) {
    const createdRefinements = c.refinements.filter(
      refinement => refinement.created
    )
    const latestRefinement = createdRefinements.length
      ? createdRefinements[createdRefinements.length - 1]
      : null

    return latestRefinement &&
      latestRefinement.vtoHistory &&
      latestRefinement.vtoHistory.length
      ? latestRefinement.vtoHistory[latestRefinement.vtoHistory.length - 1].vto
      : null
  } else if (isRetention) {
    return c.retention.vtoHistory && c.retention.vtoHistory.length
      ? c.retention.vtoHistory[c.retention.vtoHistory.length - 1].vto
      : null
  } else {
    return c.vtoHistory && c.vtoHistory.length
      ? c.vtoHistory[c.vtoHistory.length - 1].vto
      : null
  }
}

export function getLastStatus (c) {
  const createdRefinements = (c.refinements || []).filter(
    refinement => refinement.created
  )
  if (c.retention && c.retention.created) {
    return c.retention.status
  } else if (createdRefinements.length) {
    return createdRefinements[createdRefinements.length - 1].status
  } else {
    return c.status
  }
}

export function findCaseBillingAddress (kase, addresses = []) {
  return addresses.findIndex(addr => {
    return (
      kase.billingCity === addr.city &&
      kase.billingClinicName === addr.clinicName &&
      kase.billingPhone === addr.phone &&
      kase.billingPostalCode === addr.postalCode &&
      kase.billingProvince === addr.province &&
      kase.billingCountry === addr.country &&
      kase.billingStreetName1 === addr.streetName1
    )
  })
}

export function findCaseShippingAddress (kase, addresses = []) {
  return addresses.findIndex(addr => {
    return (
      kase.shippingCity === addr.city &&
      kase.shippingClinicName === addr.clinicName &&
      kase.shippingPhone === addr.phone &&
      kase.shippingPostalCode === addr.postalCode &&
      kase.shippingProvince === addr.province &&
      kase.shippingCountry === addr.country &&
      kase.shippingStreetName1 === addr.streetName1
    )
  })
}

export function isAddressIncluded (addresses, addressName) {
  return (
    addresses.length &&
    addresses.findIndex(address => addressName === address.clinicName) >= 0
  )
}

export function getCaseType (c) {
  const isRefinement = c.refinements && !!c.refinements.find(x => x.created)
  const isRetention = c.retention && c.retention.created

  if (isRetention) {
    return 'retention'
  } else if (isRefinement) {
    return 'refinement'
  } else {
    return 'treatment'
  }
}
