<template>
  <div>
    <el-icon class="loading" v-loading.fullscreen.lock="active" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    active: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.loading {
  position: absolute;
}
</style>
