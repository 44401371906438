<template>
  <el-alert
    class="alert"
    :title="title"
    :description="description"
    :type="type"
    show-icon
  >
  </el-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    title: String,
    type: String,
    description: String
  }
}
</script>

<style lang="scss">
.el-alert {
  position: absolute !important;
  .el-alert__content {
    padding: 16px;
    .el-alert__title {
      font-size: 18px;
    }
    .el-alert__description {
      font-size: 16px;
    }
  }
}
</style>
