<template>
  <div>
    <p class="label">Foto del paciente</p>
    <input
      :style="{ display: 'none' }"
      ref="patientPhoto"
      v-on:change="e => onUploadImage(e, 'patientPhoto')"
      type="file"
    />
    <div v-if="!isPatientPhotoUploaded">
      <i
        @click="triggerUpload"
        ref="patientPhotoIcon"
        class="el-icon-picture uploader"
      ></i>
    </div>

    <el-form-item class="margin-bottom" prop="patientPhoto">
      <el-input
        :style="{ display: 'none' }"
        ref="patientPhotoUrl"
        type="text"
        placeholder="url"
        v-on="$listeners"
        v-bind="$attrs"
        :value="value"
      />
    </el-form-item>
    <img
      v-if="isPatientPhotoUploaded"
      :style="{ width: '100px', display: '' }"
      ref="patientPhotoImage"
      :src="value"
    />
    <div v-if="isPatientPhotoUploaded" class="patient-photo-upload">
      <p class="name">Eliminar fotografía</p>
      <i @click="removePatientPhoto" class="cross el-icon-close"></i>
    </div>
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: 'PatientPhoto',
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    value: {
      type: String,
      required: false
    },
    membershipNumber: {
      // TODO
      type: String,
      required: false
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.$emit('clearValidationError')
      }
    }
  },
  computed: {
    isPatientPhotoUploaded () {
      return !!this.value
    }
  },
  methods: {
    async onUploadImage (event) {
      const file = event.target.files[0]

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, 'patientPhoto' + Date.now() + extension), file)
      .then( (snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref).then(downloadURL => {
          this.$emit('input', downloadURL)
          const ref = this.$refs['patientPhotoImage']
          if (ref) {
            ref.style.display = 'auto'
            ref.src = downloadURL
          }
        })
      })
        .catch(error => {
          console.log('Error uploading file', error)
        });
    },
    triggerUpload () {
      if (!this.disabled) {
        this.$refs.patientPhoto.click()
      }
    },
    removePatientPhoto () {
      if (!this.disabled) {
        this.$emit('input', '')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.uploader {
  font-size: 25px;
  padding: 4px 24px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.patient-photo-upload {
  // width: 220px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 24px;
  padding: 4px 8px 0 0;
  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
  }
  .cross {
    margin-left: 8px;
  }

  &:hover {
    .cross {
      color: black;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
