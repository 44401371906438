<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />
    <div v-if="isAdmin" class="panel">
      <h2 class="title">Panel de administración</h2>
      <AdminPanelDiscounts
        class="module"
        @modify-discounts="modifyDiscounts"
        :doctors="doctors"
      />
      <AdminPanelAuthorization class="module" @authorize="authorize" />
      <AdminPanelSchool class="module" @create="schoolSignup" />
    </div>
    <Footer class="footer-wrapper" />
    <Loading :active="loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import Footer from '@/components/Footer.vue'
import AdminPanelSchool from '@/components/AdminPanelSchool'
export default {
  name: 'NewCase',
  components: {
    AdminPanelDiscounts: () => import('@/components/AdminPanelDiscounts'),
    AdminPanelAuthorization: () =>
      import('@/components/AdminPanelAuthorization'),
    AdminPanelSchool,
    Header,
    Footer,
    Loading
  },
  beforeMount () {
    this.$store.dispatch('fetchAdminPanelInformation')
  },
  computed: {
    ...mapState({
      role: state => state.me.role,
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      doctors: state => state.panelInformation.doctors,
      loading: state => state.isLoading,
      authorizationStatus: state => state.authorizationStatus
    }),
    isAdmin () {
      return this.role.toLowerCase() === 'admin'
    }
  },
  methods: {
    authorize (collegiateNumber) {
      this.$store.dispatch('authorize', {
        collegiateNumber
      })
    },
    schoolSignup (signupData) {
      this.$store.dispatch('schoolSignup', signupData)
    },
    modifyDiscounts (event) {
      this.$store.dispatch('modifyDoctorDiscounts', {
        username: event.username,
        discountsMemberGetMember: event.discountsMemberGetMember,
        discountsYearly: event.discountsYearly
      })
    },
  },
  data () {
    return {
      isModifyDiscountsFormVisible: false,
      doctorInformationToModify: {
        discountsMemberGetMember: null,
        discountsYearly: null,
        fullName: '',
        username: ''
      }
    }
  },
  watch: {
    role (val) {
      if (val.toLowerCase() !== 'admin') {
        this.$router.push('/')
      }
    },
    authorizationStatus (val) {
      if (val) {
        this.$notify({
          title: val.title,
          message: val.message,
          type: val.type
        })
        this.$store.commit('clearAuthorizationNotification')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-rows: 160px 1fr auto;
  min-height: 100vh;

  .title {
    padding-left: 24px;
  }

  .module {
    margin-bottom: 36px;
  }

  // .footer-wrapper {
  //   padding: 0 24px 24px 24px;
  // }
}
</style>
