<template>
  <div class="summary">
    <ModalAskForUpdateStatus
      v-if="askForUpdateToReviewStatus"
      @close="closeModal"
      @update-status="updateStatus"
      :elementToReview="askForUpdateToReviewStatus"
    />
    <el-form
      class="form"
      :model="form"
      ref="form"
      :hide-required-asterisk="true"
    >
      <el-button v-if="isAdmin" type="primary" @click="updateRefinement"
        >Actualizar refinamiento</el-button
      >

      <p class="title">Refinamiento</p>
      <div class="fields">
        <span v-if="isAdmin">
          <span
            v-for="(vto, index) of form.vtoHistory"
            :key="vto.vto"
            class="quickcheck-input"
          >
            <el-form-item label="" class="margin-bottom">
              <el-input
                v-model.trim="vto.vto"
                placeholder=""
                :disabled="true"
              />
            </el-form-item>
            <i class="el-icon-close" @click="deleteVto(index)"></i>
          </span>
        </span>

        <el-form-item v-if="isAdmin" label="" class="margin-bottom">
          <el-input v-model.trim="newVto" placeholder="Nuevo vto" />
        </el-form-item>

        <span v-if="isAdmin">
          <span
            v-for="(quickcheck, index) of form.quickcheckHistory"
            :key="quickcheck.quickCheck"
            class="quickcheck-input"
          >
            <el-form-item label="" class="margin-bottom">
              <el-input
                v-model.trim="quickcheck.quickcheck"
                placeholder=""
                :disabled="true"
              />
            </el-form-item>
            <i class="el-icon-close" @click="deleteQuickcheck(index)"></i>
          </span>
        </span>

        <el-form-item v-if="isAdmin" label="" class="margin-bottom">
          <el-input
            v-model.trim="newQuickcheck"
            placeholder="Nuevo quickCheck"
          />
        </el-form-item>

        <el-form-item
          v-if="isAdmin"
          label=""
          class="margin-bottom"
          ref="attachments"
          prop="attachments"
        >
          <p class="label">Plantilla de attachments</p>
          <input
            v-on:change="e => onUploadImage(e, 'attachments')"
            type="file"
          />
          <el-input
            v-model.trim="form.attachments"
            placeholder="Plantilla de attachments"
            :disabled="true"
          />
        </el-form-item>

        <Fieldset class="fieldset" label="Objetivos el refinamiento">
          <el-input
            v-model="form.goals"
            rows="8"
            type="textarea"
            resize="none"
            :disabled="!isAdmin"
          />
        </Fieldset>

        <FieldUnfitImages
          :isAdmin="isAdmin"
          :unfit1Image="form.unfit1Image"
          :unfit2Image="form.unfit2Image"
          v-on:input-unfit-1="x => (form.unfit1Image = x)"
          v-on:input-unfit-2="x => (form.unfit2Image = x)"
        />

        <Fieldset class="fieldset big" label="Fotografías">
          <input
            class="none"
            ref="lateralFacialImage"
            v-on:change="e => isAdmin && onUploadImage(e, 'lateralFacialImage')"
            type="file"
          />

          <input
            class="none"
            ref="frontalFacialImage"
            v-on:change="e => isAdmin && onUploadImage(e, 'frontalFacialImage')"
            type="file"
          />

          <input
            class="none"
            ref="smileFacialImage"
            v-on:change="e => isAdmin && onUploadImage(e, 'smileFacialImage')"
            type="file"
          />

          <input
            class="none"
            ref="upperOcclusalImage"
            v-on:change="e => isAdmin && onUploadImage(e, 'upperOcclusalImage')"
            type="file"
          />

          <input
            class="none"
            ref="lowerOcclusalImage"
            v-on:change="e => isAdmin && onUploadImage(e, 'lowerOcclusalImage')"
            type="file"
          />

          <input
            class="none"
            ref="rightIntraoralImage"
            v-on:change="
              e => isAdmin && onUploadImage(e, 'rightIntraoralImage')
            "
            type="file"
          />

          <input
            class="none"
            ref="centralIntraoralImage"
            v-on:change="
              e => isAdmin && onUploadImage(e, 'centralIntraoralImage')
            "
            type="file"
          />

          <input
            class="none"
            ref="leftIntraoralImage"
            v-on:change="e => isAdmin && onUploadImage(e, 'leftIntraoralImage')"
            type="file"
          />

          <div class="grid-uploader">
            <div class="item">
              <img
                src="../assets/foto_facial_lateral.png"
                v-if="!form.lateralFacialImage"
                @click="() => isAdmin && triggerUpload('lateralFacialImage')"
                ref="lateralFacialImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.lateralFacialImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="lateralFacialImageImage"
                @click="() => isAdmin && triggerUpload('lateralFacialImage')"
                :src="form.lateralFacialImage"
              />
              <el-form-item label class prop="lateralFacialImage">
                <el-input
                  class="none"
                  v-model="form.lateralFacialImage"
                  ref="lateralFacialImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/foto_facial_frontal.png"
                v-if="!form.frontalFacialImage"
                @click="() => isAdmin && triggerUpload('frontalFacialImage')"
                ref="frontalFacialImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.frontalFacialImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="frontalFacialImageImage"
                @click="() => isAdmin && triggerUpload('frontalFacialImage')"
                :src="form.frontalFacialImage"
              />
              <el-form-item label class prop="frontalFacialImage">
                <el-input
                  class="none"
                  v-model="form.frontalFacialImage"
                  ref="frontalFacialImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/foto_facial_sonrisa.png"
                v-if="!form.smileFacialImage"
                @click="() => isAdmin && triggerUpload('smileFacialImage')"
                ref="smileFacialImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.smileFacialImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="smileFacialImageImage"
                @click="() => isAdmin && triggerUpload('smileFacialImage')"
                :src="form.smileFacialImage"
              />
              <el-form-item label class prop="smileFacialImage">
                <el-input
                  class="none"
                  v-model="form.smileFacialImage"
                  ref="smileFacialImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/foto_oclusal_superior.png"
                v-if="!form.upperOcclusalImage"
                @click="() => isAdmin && triggerUpload('upperOcclusalImage')"
                ref="upperOcclusalImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.upperOcclusalImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="upperOcclusalImageImage"
                @click="() => isAdmin && triggerUpload('upperOcclusalImage')"
                :src="form.upperOcclusalImage"
              />
              <el-form-item label class prop="upperOcclusalImage">
                <el-input
                  class="none"
                  v-model="form.upperOcclusalImage"
                  ref="upperOcclusalImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/qs1.svg"
                @click="
                  () =>
                    isAdmin
                      ? clearUploads([
                          'lateralFacialImage',
                          'frontalFacialImage',
                          'smileFacialImage',
                          'upperOcclusalImage',
                          'lowerOcclusalImage',
                          'rightIntraoralImage',
                          'centralIntraoralImage',
                          'leftIntraoralImage'
                        ])
                      : ''
                "
                :disabled="!isAdmin"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
              />
            </div>

            <div class="item">
              <img
                src="../assets/foto_oclusal_inferior.png"
                v-if="!form.lowerOcclusalImage"
                @click="() => isAdmin && triggerUpload('lowerOcclusalImage')"
                ref="lowerOcclusalImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.lowerOcclusalImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="lowerOcclusalImageImage"
                @click="() => isAdmin && triggerUpload('lowerOcclusalImage')"
                :src="form.lowerOcclusalImage"
              />
              <el-form-item label class prop="lowerOcclusalImage">
                <el-input
                  class="none"
                  v-model="form.lowerOcclusalImage"
                  ref="lowerOcclusalImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/foto_intraoral_derecha.png"
                v-if="!form.rightIntraoralImage"
                @click="() => isAdmin && triggerUpload('rightIntraoralImage')"
                ref="rightIntraoralImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.rightIntraoralImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="rightIntraoralImageImage"
                @click="() => isAdmin && triggerUpload('rightIntraoralImage')"
                :src="form.rightIntraoralImage"
              />
              <el-form-item label class prop="rightIntraoralImage">
                <el-input
                  class="none"
                  v-model="form.rightIntraoralImage"
                  ref="rightIntraoralImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/foto_intraoral_frontal.png"
                v-if="!form.centralIntraoralImage"
                @click="() => isAdmin && triggerUpload('centralIntraoralImage')"
                ref="centralIntraoralImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.centralIntraoralImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="centralIntraoralImageImage"
                @click="() => isAdmin && triggerUpload('centralIntraoralImage')"
                :src="form.centralIntraoralImage"
              />
              <el-form-item label class prop="centralIntraoralImage">
                <el-input
                  class="none"
                  v-model="form.centralIntraoralImage"
                  ref="centralIntraoralImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/foto_intraoral_izquierda.png"
                v-if="!form.leftIntraoralImage"
                @click="() => isAdmin && triggerUpload('leftIntraoralImage')"
                ref="leftIntraoralImageIcon"
                class="big-uploader"
              />
              <img
                v-show="form.leftIntraoralImage"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="leftIntraoralImageImage"
                @click="() => isAdmin && triggerUpload('leftIntraoralImage')"
                :src="form.leftIntraoralImage"
              />
              <el-form-item label class prop="leftIntraoralImage">
                <el-input
                  class="none"
                  v-model="form.leftIntraoralImage"
                  ref="leftIntraoralImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>
          </div>
        </Fieldset>

        <Fieldset class="fieldset big" label="Cargar radiografías">
          <input
            class="none"
            ref="xray1Image"
            v-on:change="e => onUploadImage(e, 'xray1Image')"
            type="file"
          />
          <input
            class="none"
            ref="xray2Image"
            v-on:change="e => onUploadImage(e, 'xray2Image')"
            type="file"
          />

          <div class="grid-uploader">
            <div class="item">
              <img
                src="../assets/x_ray1.jpg"
                v-if="!form.xray1Image"
                @click="() => isAdmin && triggerUpload('xray1Image')"
                ref="xray1Icon"
                class="big-uploader"
              />
              <img
                v-show="form.xray1Image"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="xray1ImageImage"
                @click="() => isAdmin && triggerUpload('xray1Image')"
                :src="form.xray1Image"
              />
              <el-form-item label class prop="xray1Image">
                <el-input
                  class="none"
                  v-model="form.xray1Image"
                  ref="xray1ImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/x_ray2.jpg"
                v-if="!form.xray2Image"
                @click="() => isAdmin && triggerUpload('xray2Image')"
                ref="xray1Icon"
                class="big-uploader"
              />
              <img
                v-show="form.xray2Image"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="xray2ImageImage"
                @click="() => isAdmin && triggerUpload('xray2Image')"
                :src="form.xray2Image"
              />
              <el-form-item label class prop="xray2Image">
                <el-input
                  class="none"
                  v-model="form.xray2Image"
                  ref="xray2ImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>
          </div>
        </Fieldset>

        <Fieldset
          v-if="isOldDigitalScan"
          class="fieldset"
          label="Cargar escaneado digital"
        >
          <el-radio
            label="intraoralScanner"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Escaner intraoral(STL)</span>
          </el-radio>
          <div class="intraoral-scanner-grid">
            <div class="item margin-right">
              <p>Superior</p>
              <input
                :style="{ display: 'none' }"
                ref="upperIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'upperIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="
                    () => isAdmin && triggerUpload('upperIntraoralScanner')
                  "
                  ref="superiorIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="upperIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.upperIntraoralScanner"
                  ref="upperIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
                <a
                  v-if="form.upperIntraoralScanner"
                  class="link"
                  :href="form.upperIntraoralScanner"
                  target="_blank"
                  download="escaner_intraoral_superior"
                  >Descargar</a
                >
              </el-form-item>
              <div
                v-if="upperIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p>{{ upperIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('upperIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
            <div class="item margin-right">
              <p>Inferior</p>
              <input
                :style="{ display: 'none' }"
                ref="lowerIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'lowerIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="
                    () => isAdmin && triggerUpload('lowerIntraoralScanner')
                  "
                  ref="lowerIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="lowerIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.lowerIntraoralScanner"
                  ref="lowerIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
                <a
                  v-if="form.lowerIntraoralScanner"
                  class="link"
                  :href="form.lowerIntraoralScanner"
                  target="_blank"
                  download="escaner_intraoral_inferior"
                  >Descargar</a
                >
              </el-form-item>
              <div
                v-if="lowerIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p>{{ lowerIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('lowerIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
          </div>
        </Fieldset>

        <Fieldset
          v-if="isOldDigitalScan"
          class="fieldset"
          label="Enviar impresiones PVS"
        >
          <el-radio
            label="pvsPrinting"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Impresión PVS (enviar por mensajería)</span
            >
            <div>
              <p>QuickSmile</p>
              <p>c/Casas de Miravete 24C 3º1B</p>
              <p>28031 Madrid</p>
            </div>

            <a
              :style="{ display: 'none' }"
              ref="shippingSheetLink"
              target="_blank"
              href="https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/Hoja%20PVS.pdf?alt=media&token=88424b40-99ba-4d73-9f93-b150171059da"
            />
            <el-button type="primary" @click="downloadShippingSheet"
              >Hoja de envío</el-button
            >
          </el-radio>
        </Fieldset>

        <Fieldset
          v-if="isOldDigitalScan"
          class="fieldset"
          label="Recoger impresiones PVS"
        >
          <p class="cost">
            (Opción gratuita para recogidas dentro de la península)
          </p>
          <p>Dirección de recogida</p>
          <el-radio
            label="derivation"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Misma dirección de envio que el caso</span
            >
          </el-radio>
          <el-radio
            class="derivation-custom"
            label="derivation-custom"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label">
              Otra dirección
            </span>
            <el-input
              class="other-input"
              type="textarea"
              resize="none"
              :rows="2"
              v-model="form.typeOfImpressionsAddress"
              :maxlength="140"
              :disabled="form.typeOfImpressions !== 'derivation-custom'"
            />
          </el-radio>
        </Fieldset>

        <Fieldset v-if="!isOldDigitalScan" label="Escaneado digital (STL):">
          <el-radio
            label="intraoralScanner"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Subir archivos</span>
          </el-radio>

          <div class="intraoral-scanner-grid">
            <div class="item margin-right">
              <p>Superior</p>
              <input
                :style="{ display: 'none' }"
                ref="upperIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'upperIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="
                    () => isAdmin && triggerUpload('upperIntraoralScanner')
                  "
                  ref="superiorIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="upperIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.upperIntraoralScanner"
                  ref="upperIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>

              <div
                v-if="upperIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p class="name">{{ upperIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('upperIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
            <div class="item margin-right">
              <p>Inferior</p>
              <input
                :style="{ display: 'none' }"
                ref="lowerIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'lowerIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="
                    () => isAdmin && triggerUpload('lowerIntraoralScanner')
                  "
                  ref="lowerIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="lowerIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.lowerIntraoralScanner"
                  ref="lowerIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
              <div
                v-if="lowerIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p class="name">{{ lowerIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('lowerIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
          </div>

          <el-radio
            label="email"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Los enviaré por correo electrónico a&nbsp;<a
                class="link"
                href="mailto:casos@quicksmile.es"
                >casos@quicksmile.es</a
              >
            </span>
          </el-radio>

          <el-radio
            label="meditlink"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Los enviaré por MeditLink (QuickSmile)</span
            >
          </el-radio>
        </Fieldset>

        <Fieldset v-if="!isOldDigitalScan" label="Impresiones (PVS)">
          <el-radio
            label="pickup"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label break"
              >{{ pickupText }}<br />
              <span class="quote"
                >(opción gratuita para península ibérica y baleares)</span
              ></span
            >
          </el-radio>
        </Fieldset>
      </div>
    </el-form>
    <el-button
      v-if="isAdmin"
      class="submit"
      type="primary"
      @click="updateRefinement"
      >Actualizar refinamiento</el-button
    >
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from '@/components/Fieldset'
import FieldUnfitImages from '@/components/FieldUnfitImages'
import ModalAskForUpdateStatus from '@/components/ModalAskForUpdateStatus'
import { refinementStatusMap } from '../constants'
export default {
  name: 'RefinementForm',
  props: {
    refinement: {
      type: Object,
      required: false
    },
    isAdmin: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      pickupText:
        'Solicitaré la recogida de las medidas PVS a través de WhatsApp o llamando al teléfono 680 433 194',
      clickCounter: 0,
      isDialogVisible: false,
      lowerIntraoralScannerName: '',
      upperIntraoralScannerName: '',
      newQuickcheck: '',
      newVto: '',
      form: {
        attachments: '',
        ...this.refinement,
        quickcheckHistory:
          this.refinement && this.refinement.quickcheckHistory
            ? this.refinement.quickcheckHistory.map(x => ({
                date: x.date,
                quickcheck: x.quickcheck
              }))
            : [],
        vtoHistory:
          this.refinement && this.refinement.vtoHistory
            ? this.refinement.vtoHistory.map(x => ({
                date: x.date,
                vto: x.vto
              }))
            : [],
        case: this.$route.params.id
      },
      askForUpdateToReviewStatus: false
    }
  },
  computed: {
    isOldDigitalScan () {
      return (
        new Date(this.refinement.createdAtProduction).getTime() <
        process.env.VUE_APP_NEW_DIGITAL_SCAN_TIMESTAMP
      )
    }
  },
  methods: {
    closeModal () {
      this.askForUpdateToReviewStatus = false
    },
    updateStatus (reviewStatus) {
      this.$emit('change-case-state', reviewStatus)
      this.askForUpdateToReviewStatus = false
    },
    deleteQuickcheck (index) {
      this.form.quickcheckHistory.splice(index, 1)
      this.updateRefinement()
    },
    deleteVto (index) {
      this.form.vtoHistory.splice(index, 1)
      this.updateRefinement()
    },
    closeDialog () {
      this.isDialogVisible = false
    },
    tryUpdateRefinement () {
      this.isDialogVisible = true
    },
    updateRefinement () {
      delete this.form.created
      if (this.newQuickcheck) {
        this.form.quickcheckHistory.push({
          quickcheck: this.newQuickcheck,
          date: new Date()
        })
      }
      if (this.newVto) {
        this.form.vtoHistory.push({ vto: this.newVto, date: new Date() })
      }
      this.$store.dispatch('updateRefinement', {
        reload: true,
        form: this.form
      })
      if (this.newQuickcheck) {
        this.askForUpdateToReviewStatus = 'quickcheck'
      }
      if (this.newVto) {
        this.askForUpdateToReviewStatus = 'vto'
      }
      this.newQuickcheck = ''
      this.newVto = ''
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    clearUploads (names) {
      this.clickCounter += 1
      if (this.clickCounter % 2 === 0) {
        names.forEach(name => {
          this.form[name] = ''
        })
      }
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      } else if (fieldName === 'attachments') {
        this.attachments = file.name
      }
      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.form[fieldName] = downloadURL
            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    goToNextStep () {
      this.step += 1
      this.updateRefinement()
    },
    goToPreviousStep () {
      this.step -= 1
    }
  },
  watch: {
    refinement (val) {
      this.form = {
        ...this.form,
        ...val,
        quickcheckHistory:
          val && val.quickcheckHistory
            ? val.quickcheckHistory.map(x => ({
                date: x.date,
                quickcheck: x.quickcheck
              }))
            : [],
        vtoHistory:
          val && val.vtoHistory
            ? val.vtoHistory.map(x => ({ date: x.date, vto: x.vto }))
            : []
      }
    }
  },
  components: {
    FieldUnfitImages,
    Fieldset,
    ModalAskForUpdateStatus
  }
}
</script>

<style lang="scss" scoped>
.none {
  display: none;
}

.submit {
  margin-top: 32px;
}

.clickable {
  cursor: pointer;
}

.step {
  width: 392px;
}

.radio-label {
  display: flex;
}

.radio-label,
.checkbox-label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.el-checkbox,
.el-radio {
  display: flex;

  .el-checkbox__label,
  .radio-label {
    white-space: break-spaces;
  }
}

.summary {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .form {
    .fields {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      // width: 392px;

      .fieldset {
        width: 392px;

        &.big {
          width: fit-content;
        }
      }

      .quickcheck-input {
        display: flex;
        flex-direction: row;

        .el-icon-close {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }

    .derivation-custom {
      .radio-label {
        white-space: initial;
      }
    }
  }

  .title {
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }

  .navigation {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .grid-uploader {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 24px;
  }

  .uploader {
    font-size: 25px;
    padding: 4px 24px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  }

  .big-uploader {
    width: 208px;
    height: 104px;
    font-size: 25px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  }

  .big-uploader-text {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 208px;
    height: 104px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .text {
      padding: 16px;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .dialog-footer {
    text-align: right;
  }
}

.intraoral-scanner-grid {
  display: flex;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.custom-error {
  color: #f56c6c;
  font-size: 12px;
}

.margin {
  &-bottom {
    margin-bottom: 16px;
  }

  &-top {
    margin-top: 16px;
  }

  &-right {
    margin-right: 16px;
  }

  &-left {
    margin-left: 16px;
  }
}

@media (max-width: 768px) {
  .radio-button-replacement {
    display: flex !important;
    margin: 0 0 8px 0 !important;
  }

  .radio-button {
    display: none !important;
  }

  .summary {
    // width: 100%;

    .form {
      // width: 100%;

      .fields {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        // width: 392px;

        .derivation-custom {
          .radio-label {
            white-space: initial;
          }
        }

        .teeth {
          .teeth-row {
            flex-direction: column;
            margin-bottom: 24px;
          }
        }

        .fieldset-address {
          display: flex;

          .card {
            align-self: center;
            max-width: 96%;
          }
        }

        .wrapper {
          display: flex;
        }
      }
    }

    .grid-uploader {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;

      .item {
        display: flex;
        justify-self: center;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
