<template>
  <div class="summary">
    <el-form
      class="form"
      :model="form"
      ref="form"
      :hide-required-asterisk="true"
    >
      <el-button v-if="isAdmin" type="primary" @click="updateRetention"
        >Actualizar retención final</el-button
      >

      <p class="title">Retención final</p>
      <div class="fields">
        <el-form-item
          v-if="isAdmin"
          label=""
          class="margin-bottom"
          ref="attachments"
          prop="attachments"
        >
          <p class="label">Plantilla de attachments</p>
          <input
            v-on:change="e => onUploadImage(e, 'attachments')"
            type="file"
          />
          <el-input
            v-model.trim="form.attachments"
            placeholder="Plantilla de attachments"
            :disabled="true"
          />
        </el-form-item>

        <Fieldset label="¿Cómo desea realizar la retención final?">
          <el-radio
            label="lastStage"
            v-model="form.finalRetentionType"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Última etapa</span>
          </el-radio>
          <p>
            Realizaremos la retención en base a la última etapa de la
            simulación. Sin retención fija.
          </p>
          <el-radio
            label="newMeasures"
            v-model="form.finalRetentionType"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Nuevas medidas</span>
          </el-radio>
          <p>
            Realizaremos la retención en base a nuevas medidas.
            <br />
            Elija esta opción si desea colocar retención fija.
          </p>
        </Fieldset>

        <Fieldset label="Observaciones">
          <el-input
            class="derivation-custom other-input"
            type="textarea"
            resize="none"
            :rows="2"
            v-model="form.notes"
            :maxlength="140"
            :disabled="!isAdmin"
          />
        </Fieldset>

        <Fieldset
          v-if="isOldDigitalScan"
          class="fieldset"
          label="Cargar escaneado digital"
        >
          <el-radio
            label="intraoralScanner"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Escaner intraoral(STL)</span>
          </el-radio>
          <div class="intraoral-scanner-grid">
            <div class="item margin-right">
              <p>Superior</p>
              <input
                :style="{ display: 'none' }"
                ref="upperIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'upperIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="
                    () => isAdmin && triggerUpload('upperIntraoralScanner')
                  "
                  ref="superiorIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="upperIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.upperIntraoralScanner"
                  ref="upperIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
                <a
                  v-if="form.upperIntraoralScanner"
                  class="link"
                  :href="form.upperIntraoralScanner"
                  target="_blank"
                  download="escaner_intraoral_superior"
                  >Descargar</a
                >
              </el-form-item>
              <div
                v-if="upperIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p>{{ upperIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('upperIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
            <div class="item margin-right">
              <p>Inferior</p>
              <input
                :style="{ display: 'none' }"
                ref="lowerIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'lowerIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="
                    () => isAdmin && triggerUpload('lowerIntraoralScanner')
                  "
                  ref="lowerIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="lowerIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.lowerIntraoralScanner"
                  ref="lowerIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
                <a
                  v-if="form.lowerIntraoralScanner"
                  class="link"
                  :href="form.lowerIntraoralScanner"
                  target="_blank"
                  download="escaner_intraoral_inferior"
                  >Descargar</a
                >
              </el-form-item>
              <div
                v-if="lowerIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p>{{ lowerIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('lowerIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
          </div>
        </Fieldset>

        <Fieldset
          v-if="isOldDigitalScan"
          class="fieldset"
          label="Enviar impresiones PVS"
        >
          <el-radio
            label="pvsPrinting"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Impresión PVS (enviar por mensajería)</span
            >
            <div>
              <p>QuickSmile</p>
              <p>c/Casas de Miravete 24C 3º1B</p>
              <p>28031 Madrid</p>
            </div>

            <a
              :style="{ display: 'none' }"
              ref="shippingSheetLink"
              target="_blank"
              href="https://firebasestorage.googleapis.com/v0/b/quicksmile-555e3.appspot.com/o/Hoja%20PVS.pdf?alt=media&token=88424b40-99ba-4d73-9f93-b150171059da"
            />
            <el-button type="primary" @click="downloadShippingSheet"
              >Hoja de envío</el-button
            >
          </el-radio>
        </Fieldset>

        <Fieldset
          v-if="isOldDigitalScan"
          class="fieldset"
          label="Recoger impresiones PVS"
        >
          <p class="cost">
            (Opción gratuita para recogidas dentro de la península)
          </p>
          <p>Dirección de recogida</p>
          <el-radio
            label="derivation"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Misma dirección de envio que el caso</span
            >
          </el-radio>
          <el-radio
            class="derivation-custom"
            label="derivation-custom"
            v-model="form.typeOfImpressions"
            :disabled="!isAdmin"
          >
            <span class="radio-label">
              Otra dirección
            </span>
            <el-input
              class="other-input"
              type="textarea"
              resize="none"
              :rows="2"
              v-model="form.typeOfImpressionsAddress"
              :maxlength="140"
              :disabled="form.typeOfImpressions !== 'derivation-custom'"
            />
          </el-radio>
        </Fieldset>

        <Fieldset v-if="!isOldDigitalScan" label="Escaneado digital (STL):">
          <el-radio label="intraoralScanner" v-model="form.typeOfImpressions">
            <span class="radio-label">Subir archivos</span>
          </el-radio>

          <div class="intraoral-scanner-grid">
            <div class="item margin-right">
              <p>Superior</p>
              <input
                :style="{ display: 'none' }"
                ref="upperIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'upperIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="() => triggerUpload('upperIntraoralScanner')"
                  ref="superiorIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="upperIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.upperIntraoralScanner"
                  ref="upperIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>

              <div
                v-if="upperIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p class="name">{{ upperIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('upperIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
            <div class="item margin-right">
              <p>Inferior</p>
              <input
                :style="{ display: 'none' }"
                ref="lowerIntraoralScanner"
                v-on:change="e => onUploadImage(e, 'lowerIntraoralScanner')"
                type="file"
              />
              <div>
                <i
                  @click="() => triggerUpload('lowerIntraoralScanner')"
                  ref="lowerIntraoralScannerIcon"
                  class="el-icon-picture uploader"
                ></i>
              </div>
              <el-form-item label class prop="lowerIntraoralScanner">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.lowerIntraoralScanner"
                  ref="lowerIntraoralScannerUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
              <div
                v-if="lowerIntraoralScannerName"
                class="patient-photo-upload"
              >
                <p class="name">{{ lowerIntraoralScannerName }}</p>
                <i
                  @click="() => removePhoto('lowerIntraoralScanner')"
                  class="cross el-icon-close"
                ></i>
              </div>
            </div>
          </div>

          <el-radio label="email" v-model="form.typeOfImpressions">
            <span class="radio-label"
              >Los enviaré por correo electrónico a&nbsp;<a
                class="link"
                href="mailto:casos@quicksmile.es"
                >casos@quicksmile.es</a
              >
            </span>
          </el-radio>

          <el-radio label="meditlink" v-model="form.typeOfImpressions">
            <span class="radio-label"
              >Los enviaré por MeditLink (QuickSmile)</span
            >
          </el-radio>
        </Fieldset>

        <Fieldset v-if="!isOldDigitalScan" label="Impresiones (PVS)">
          <el-radio label="pickup" v-model="form.typeOfImpressions">
            <span class="radio-label break"
              >{{ pickupText }}<br />
              <span class="quote"
                >(opción gratuita para península ibérica y baleares)</span
              ></span
            >
          </el-radio>
        </Fieldset>

        <Fieldset label="Finalización de tratamiento">
          <el-checkbox
            class="el-checkbox custom other-input"
            v-model="form.endingConfirmation"
            label="Marcando esta casilla confirmo que el tratamiento ortodóntico realizado con QuickSmile a este paciente ha finalizado."
          />
          <p>Condiciones de finalización QuickSmile:</p>
          <p>
            - QuickSmile proveerá al Doctor de
            {{
              treatmentType.includes('8') ? 'un (1) juego' : 'dos (2) juegos'
            }}
            de retenedores finales sin coste. Si se necesitasen más juegos de
            retenedores, éstos tendrán un coste.
          </p>
          <p>
            - Tras la finalización del tratamiento y una vez que se haga entrega
            de los retenedores finales, QuickSmile no facilitará nuevos
            refinamientos. Si el paciente requiere extender su tratamiento se
            considerará como un nuevo caso.
          </p>
        </Fieldset>
      </div>
    </el-form>
    <el-button
      v-if="isAdmin"
      class="submit"
      type="primary"
      @click="updateRetention"
      >Actualizar retención final</el-button
    >
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from '@/components/Fieldset'
export default {
  name: 'RetentionForm',
  props: {
    treatmentType: {
      type: String,
      required: true
    },
    retention: {
      type: Object,
      required: false
    },
    isAdmin: {
      type: Boolean,
      required: false
    }
  },
  data () {
    const caseId = this.$route.params.id
    return {
      pickupText:
        'Solicitaré la recogida de las medidas PVS a través de WhatsApp o llamando al teléfono 680 433 194',
      clientWidth: window.innerWidth,
      showFormValidationError: null,
      step: 0,
      isDialogVisible: false,
      draftCreated: !!this.retention,
      lowerIntraoralScannerName: '',
      upperIntraoralScannerName: '',
      form: {
        attachments: '',
        ...this.retention,
        caseId: caseId
      }
    }
  },
  computed: {
    isOldDigitalScan () {
      return (
        new Date(this.retention.createdAtProduction).getTime() <
        process.env.VUE_APP_NEW_DIGITAL_SCAN_TIMESTAMP
      )
    }
  },
  methods: {
    closeDialog () {
      this.isDialogVisible = false
    },
    tryUpdateRetention () {
      this.isDialogVisible = true
    },
    updateRetention () {
      delete this.form.created
      this.$store.dispatch('updateRetention', { reload: true, form: this.form })
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    clearUploads (names) {
      this.clickCounter += 1
      if (this.clickCounter % 2 === 0) {
        names.forEach(name => {
          this.form[name] = ''
        })
      }
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      } else if (fieldName === 'attachments') {
        this.attachments = file.name
      }
      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.form[fieldName] = downloadURL
            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    goToNextStep () {
      this.step += 1
      this.updateRetention()
    },
    goToPreviousStep () {
      this.step -= 1
    }
  },
  watch: {
    retention (val) {
      this.form = {
        ...this.form,
        ...val,
        caseId: this.$route.params.id
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss" scoped>
.link {
  color: black;
}
.none {
  display: none;
}
.submit {
  margin-top: 32px;
}
.clickable {
  cursor: pointer;
}
.step {
  width: 392px;
}
.radio-label {
  display: flex;
  flex-direction: column;
}
.radio-label,
.checkbox-label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.el-checkbox,
.el-radio {
  display: flex;
  .el-checkbox__label,
  .radio-label {
    white-space: break-spaces;
  }
}
.el-checkbox {
  white-space: break-spaces;
}
.summary {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .form {
    .fields {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 392px;
    }
  }
  .derivation-custom {
    .radio-label {
      white-space: initial !important;
    }
  }
  .title {
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }
  .navigation {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .grid-uploader {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 24px;
  }
  .uploader {
    font-size: 25px;
    padding: 4px 24px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  }
  .big-uploader {
    width: 208px;
    height: 104px;
    font-size: 25px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  }
  .big-uploader-text {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 208px;
    height: 104px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    .text {
      padding: 16px;
    }
  }
}
.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  .dialog-footer {
    text-align: right;
  }
}
.intraoral-scanner-grid {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.custom-error {
  color: #f56c6c;
  font-size: 12px;
}
.margin {
  &-bottom {
    margin-bottom: 16px;
  }
  &-top {
    margin-top: 16px;
  }
  &-right {
    margin-right: 16px;
  }
  &-left {
    margin-left: 16px;
  }
}
@media (max-width: 768px) {
  .radio-button-replacement {
    display: flex !important;
    margin: 0 0 8px 0 !important;
  }
  .radio-button {
    display: none !important;
  }
  .summary {
    // width: 100%;
    .form {
      // width: 100%;
      .fields {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 392px;
        .derivation-custom {
          .radio-label {
            white-space: initial;
          }
        }
        .teeth {
          .teeth-row {
            flex-direction: column;
            margin-bottom: 24px;
            .quart {
              .tooth {
                &.up {
                }
                .number {
                }
                .checkbox {
                  &.up {
                  }
                  &.down {
                  }
                }
              }
            }
          }
        }
        .fieldset-address {
          display: flex;
          .card {
            align-self: center;
            max-width: 96%;
          }
        }
        .wrapper {
          display: flex;
        }
      }
    }
    .grid-uploader {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      .item {
        display: flex;
        justify-self: center;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
