<template>
  <div class="form-container">
    <div class="form-wrapper">
      <el-form
        :model="form"
        :rules="rules"
        class="form"
        ref="form"
        :hide-required-asterisk="true"
      >
        <button :style="{ display: 'none' }" type="submit" ref="submit">
          submit
        </button>
        <p v-if="step === 22" class="title">Revisión final</p>

        <p v-if="prefilled" class="title">Resumen</p>
        <p v-else-if="formTitle" class="title">{{ formTitle }}</p>
        <p v-else class="title">Nuevo Caso</p>

        <div v-if="step === 0" class="step step-0">
          <Fieldset label="Datos del paciente">
            <el-form-item
              label
              class="margin-bottom"
              ref="patientName"
              prop="patientName"
            >
              <el-input
                v-model="form.patientName"
                placeholder="Nombre"
                @blur="writePatientName"
              />
            </el-form-item>
            <el-form-item label class="margin-bottom" prop="patientSurname">
              <el-input
                v-model="form.patientSurname"
                class
                placeholder="Apellidos"
                @blur="writePatientName"
              />
            </el-form-item>
            <el-form-item label class="margin-bottom" prop="patientGender">
              <el-select
                v-model="form.patientGender"
                class
                placeholder="Género"
              >
                <el-option
                  v-for="gender in genders"
                  :key="gender"
                  :label="gender"
                  :value="gender"
                />
              </el-select>
            </el-form-item>

            <el-form-item label class="margin-bottom" prop="patientBirthdate">
              <el-date-picker
                class="birthdate"
                v-model="form.patientBirthdate"
                format="dd/MM/yyyy"
                value-format="dd/MM/yyyy"
                range-separator="/"
                type="date"
                placeholder="Fecha de nacimiento"
              >
              </el-date-picker>
            </el-form-item>

            <p class="label">Foto del paciente</p>
            <input
              :style="{ display: 'none' }"
              ref="patientPhoto"
              v-on:change="e => onUploadImage(e, 'patientPhoto')"
              type="file"
            />
            <div v-if="!isPatientPhotoUploaded">
              <i
                @click="() => triggerUpload('patientPhoto')"
                ref="patientPhotoIcon"
                class="el-icon-picture uploader"
              ></i>
            </div>

            <el-form-item label class="margin-bottom" prop="patientPhoto">
              <el-input
                :style="{ display: 'none' }"
                v-model="form.patientPhoto"
                ref="patientPhotoUrl"
                type="text"
                placeholder="url"
              />
            </el-form-item>
            <img
              v-if="isPatientPhotoUploaded"
              :style="{ width: '100px', display: '' }"
              ref="patientPhotoImage"
              :src="form.patientPhoto"
            />
            <div v-if="isPatientPhotoUploaded" class="patient-photo-upload">
              <p class="name">{{ patientPhotoName }}</p>
              <i @click="removePatientPhoto" class="cross el-icon-close"></i>
            </div>
          </Fieldset>
        </div>

        <div v-if="step === 1" class="step step-3">
          <Fieldset label="Cargar fotografías">
            <input
              :style="{ display: 'none' }"
              ref="lateralFacialImage"
              v-on:change="e => onUploadImage(e, 'lateralFacialImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="frontalFacialImage"
              v-on:change="e => onUploadImage(e, 'frontalFacialImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="smileFacialImage"
              v-on:change="e => onUploadImage(e, 'smileFacialImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="upperOcclusalImage"
              v-on:change="e => onUploadImage(e, 'upperOcclusalImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="lowerOcclusalImage"
              v-on:change="e => onUploadImage(e, 'lowerOcclusalImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="rightIntraoralImage"
              v-on:change="e => onUploadImage(e, 'rightIntraoralImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="centralIntraoralImage"
              v-on:change="e => onUploadImage(e, 'centralIntraoralImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="leftIntraoralImage"
              v-on:change="e => onUploadImage(e, 'leftIntraoralImage')"
              type="file"
            />

            <div class="grid-uploader">
              <div class="item">
                <img
                  src="../assets/foto_facial_lateral.png"
                  v-if="!form.lateralFacialImage"
                  @click="() => triggerUpload('lateralFacialImage')"
                  ref="lateralFacialImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.lateralFacialImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="lateralFacialImageImage"
                  @click="() => triggerUpload('lateralFacialImage')"
                  :src="form.lateralFacialImage"
                />
                <el-form-item label class prop="lateralFacialImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.lateralFacialImage"
                    ref="lateralFacialImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_facial_frontal.png"
                  v-if="!form.frontalFacialImage"
                  @click="() => triggerUpload('frontalFacialImage')"
                  ref="frontalFacialImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.frontalFacialImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="frontalFacialImageImage"
                  @click="() => triggerUpload('frontalFacialImage')"
                  :src="form.frontalFacialImage"
                />
                <el-form-item label class prop="frontalFacialImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.frontalFacialImage"
                    ref="frontalFacialImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_facial_sonrisa.png"
                  v-if="!form.smileFacialImage"
                  @click="() => triggerUpload('smileFacialImage')"
                  ref="smileFacialImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.smileFacialImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="smileFacialImageImage"
                  @click="() => triggerUpload('smileFacialImage')"
                  :src="form.smileFacialImage"
                />
                <el-form-item label class prop="smileFacialImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.smileFacialImage"
                    ref="smileFacialImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_oclusal_superior.png"
                  v-if="!form.upperOcclusalImage"
                  @click="() => triggerUpload('upperOcclusalImage')"
                  ref="upperOcclusalImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.upperOcclusalImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="upperOcclusalImageImage"
                  @click="() => triggerUpload('upperOcclusalImage')"
                  :src="form.upperOcclusalImage"
                />
                <el-form-item label class prop="upperOcclusalImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.upperOcclusalImage"
                    ref="upperOcclusalImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>

              <div class="item">
                <div
                  src="../assets/qs1.svg"
                  @click="
                    () =>
                      clearUploads([
                        'lateralFacialImage',
                        'frontalFacialImage',
                        'smileFacialImage',
                        'upperOcclusalImage',
                        'lowerOcclusalImage',
                        'rightIntraoralImage',
                        'centralIntraoralImage',
                        'leftIntraoralImage'
                      ])
                  "
                  ref="lowerOcclusalImageIcon"
                  class="big-uploader-text"
                >
                  <p class="text">
                    Haga doble click aquí para eliminar todas las fotografías
                  </p>
                </div>
              </div>

              <div class="item">
                <img
                  src="../assets/foto_oclusal_inferior.png"
                  v-if="!form.lowerOcclusalImage"
                  @click="() => triggerUpload('lowerOcclusalImage')"
                  ref="lowerOcclusalImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.lowerOcclusalImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="lowerOcclusalImageImage"
                  @click="() => triggerUpload('lowerOcclusalImage')"
                  :src="form.lowerOcclusalImage"
                />
                <el-form-item label class prop="lowerOcclusalImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.lowerOcclusalImage"
                    ref="lowerOcclusalImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_intraoral_derecha.png"
                  v-if="!form.rightIntraoralImage"
                  @click="() => triggerUpload('rightIntraoralImage')"
                  ref="rightIntraoralImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.rightIntraoralImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="rightIntraoralImageImage"
                  @click="() => triggerUpload('rightIntraoralImage')"
                  :src="form.rightIntraoralImage"
                />
                <el-form-item label class prop="rightIntraoralImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.rightIntraoralImage"
                    ref="rightIntraoralImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_intraoral_frontal.png"
                  v-if="!form.centralIntraoralImage"
                  @click="() => triggerUpload('centralIntraoralImage')"
                  ref="centralIntraoralImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.centralIntraoralImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="centralIntraoralImageImage"
                  @click="() => triggerUpload('centralIntraoralImage')"
                  :src="form.centralIntraoralImage"
                />
                <el-form-item label class prop="centralIntraoralImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.centralIntraoralImage"
                    ref="centralIntraoralImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_intraoral_izquierda.png"
                  v-if="!form.leftIntraoralImage"
                  @click="() => triggerUpload('leftIntraoralImage')"
                  ref="leftIntraoralImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.leftIntraoralImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="leftIntraoralImageImage"
                  @click="() => triggerUpload('leftIntraoralImage')"
                  :src="form.leftIntraoralImage"
                />
                <el-form-item label class prop="leftIntraoralImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.leftIntraoralImage"
                    ref="leftIntraoralImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
            </div>
          </Fieldset>
        </div>

        <div v-if="step === 2" class="step step-4">
          <Fieldset label="Cargar radiografías">
            <input
              :style="{ display: 'none' }"
              ref="xray1Image"
              v-on:change="e => onUploadImage(e, 'xray1Image')"
              type="file"
            />
            <input
              :style="{ display: 'none' }"
              ref="xray2Image"
              v-on:change="e => onUploadImage(e, 'xray2Image')"
              type="file"
            />

            <div class="grid-uploader">
              <div class="item">
                <img
                  src="../assets/x_ray1.jpg"
                  v-if="!form.xray1Image"
                  @click="() => triggerUpload('xray1Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray1Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray1ImageImage"
                  @click="() => triggerUpload('xray1Image')"
                  :src="form.xray1Image"
                />
                <el-form-item label class prop="xray1Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray1Image"
                    ref="xray1ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>

              <div class="item">
                <img
                  src="../assets/x_ray2.jpg"
                  v-if="!form.xray2Image"
                  @click="() => triggerUpload('xray2Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray2Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray2ImageImage"
                  @click="() => triggerUpload('xray2Image')"
                  :src="form.xray2Image"
                />
                <el-form-item label class prop="xray2Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray2Image"
                    ref="xray2ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
            </div>

            <div class="warning">
              <p class="text">AVISO:</p>
              <p class="text">
                Tanto la radiografía lateral como la panorámica son OBLIGATORIAS
                para la realización de la simulación digital del caso.
              </p>
              <p class="text">
                En caso de no recibirlas NO se procederá al diseño del caso
              </p>
              <p class="text">Gracias por su comprensión.</p>
            </div>
          </Fieldset>
        </div>

        <div v-if="step === 3" class="step step-4">
          <Fieldset label="Cargar escaneado digital">
            <el-radio label="intraoralScanner" v-model="form.typeOfImpressions">
              <span class="radio-label">Escaner intraoral(STL)</span>
            </el-radio>
            <div class="intraoral-scanner-grid">
              <div class="item margin-right">
                <p>Superior</p>
                <input
                  :style="{ display: 'none' }"
                  ref="upperIntraoralScanner"
                  v-on:change="e => onUploadImage(e, 'upperIntraoralScanner')"
                  type="file"
                />
                <div>
                  <i
                    @click="() => triggerUpload('upperIntraoralScanner')"
                    ref="superiorIntraoralScannerIcon"
                    class="el-icon-picture uploader"
                  ></i>
                </div>
                <el-form-item label class prop="upperIntraoralScanner">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.upperIntraoralScanner"
                    ref="upperIntraoralScannerUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>

                <div
                  v-if="upperIntraoralScannerName"
                  class="patient-photo-upload"
                >
                  <p class="name">{{ upperIntraoralScannerName }}</p>
                  <i
                    @click="() => removePhoto('upperIntraoralScanner')"
                    class="cross el-icon-close"
                  ></i>
                </div>
              </div>
              <div class="item margin-right">
                <p>Inferior</p>
                <input
                  :style="{ display: 'none' }"
                  ref="lowerIntraoralScanner"
                  v-on:change="e => onUploadImage(e, 'lowerIntraoralScanner')"
                  type="file"
                />
                <div>
                  <i
                    @click="() => triggerUpload('lowerIntraoralScanner')"
                    ref="lowerIntraoralScannerIcon"
                    class="el-icon-picture uploader"
                  ></i>
                </div>
                <el-form-item label class prop="lowerIntraoralScanner">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.lowerIntraoralScanner"
                    ref="lowerIntraoralScannerUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
                <div
                  v-if="lowerIntraoralScannerName"
                  class="patient-photo-upload"
                >
                  <p class="name">{{ lowerIntraoralScannerName }}</p>
                  <i
                    @click="() => removePhoto('lowerIntraoralScanner')"
                    class="cross el-icon-close"
                  ></i>
                </div>
              </div>
            </div>
          </Fieldset>

          <Fieldset label="Enviar impresiones PVS">
            <el-radio label="pvsPrinting" v-model="form.typeOfImpressions">
              <span class="radio-label"
                >Impresión PVS (enviar por mensajería)</span
              >
              <div>
                <p>QuickSmile</p>
                <p>c/Casas de Miravete 24C 3º1B</p>
                <p>28031 Madrid</p>
              </div>

              <a
                :style="{ display: 'none' }"
                ref="shippingSheetLink"
                target="_blank"
                :href="shippingSheetPdf"
              />
              <el-button type="primary" @click="downloadShippingSheet">
                Hoja de envío
              </el-button>
            </el-radio>
          </Fieldset>

          <Fieldset label="Recoger impresiones PVS">
            <p class="cost">
              (Opción gratuita para recogidas dentro de la península)
            </p>
            <p>Dirección de recogida</p>
            <el-radio label="derivation" v-model="form.typeOfImpressions">
              <span class="radio-label"
                >Misma dirección de envio que el caso</span
              >
            </el-radio>
            <el-radio
              label="derivation-custom"
              v-model="form.typeOfImpressions"
            >
              <span class="radio-label">
                Otra dirección
              </span>
              <el-input
                class="derivation-custom other-input"
                type="textarea"
                resize="none"
                :rows="2"
                v-model="form.typeOfImpressionsAddress"
                :maxlength="140"
                :disabled="form.typeOfImpressions !== 'derivation-custom'"
              />
            </el-radio>
          </Fieldset>
          <p class="custom-message">
            Las impresiones deberán estar correctamente identificadas dentro de
            la caja (nombre del doctor + nombre del paciente)
          </p>
          <p
            class="custom-error"
            v-if="
              this.showTypeOfImpressionsError && !this.form.typeOfImpressions
            "
          >
            Seleccione una opción
          </p>
          <p class="custom-error" v-else-if="this.showTypeOfImpressionsError">
            Debe escribir una dirección
          </p>
        </div>

        <div v-if="step === 4" class="step step-1">
          <div>
            <p class="title">Direcciones de envío</p>
            <div class="empty" v-if="!shippingAddresses.length">
              <p class="message">
                Todavía no tienes ninguna dirección de envío. Haz click debajo
                para crear una.
              </p>
              <i
                v-if="!showShippingForm"
                @click="() => showAddressForm('SHIPPING')"
                class="el-icon-circle-plus add-address"
              ></i>
              <AddressForm
                v-if="showShippingForm"
                type="SHIPPING"
                v-on:close-address-form="() => hideAddressForm('SHIPPING')"
                v-on:address-updated="() => onAddressUpdated('SHIPPING')"
                v-on:address-created="onAddressCreated"
              />
            </div>
            <div v-else class="addresses row">
              <div
                v-for="(address, index) in shippingAddresses"
                :key="address.key"
                class
              >
                <AddressCard
                  v-if="updatingShippingAddress !== index"
                  type="shipping"
                  @click="shippingAddressIndex = index"
                  :selectable="true"
                  :selected="shippingAddressIndex === index"
                  :clinicName="address.clinicName"
                  :streetName="address.streetName1"
                  :city="address.city"
                  :country="address.country"
                  :province="address.province"
                  :postalCode="address.postalCode"
                  :phone="address.phone"
                  v-on:delete="() => deleteAddress(address.id)"
                  v-on:update="() => (updatingShippingAddress = index)"
                />

                <AddressForm
                  v-else
                  type="SHIPPING"
                  :address="address"
                  v-on:address-updated="() => onAddressUpdated('SHIPPING')"
                  v-on:close-address-form="
                    () => {
                      updatingShippingAddress = null
                    }
                  "
                />
              </div>

              <AddressForm
                v-if="showShippingForm"
                type="SHIPPING"
                v-on:close-address-form="() => hideAddressForm('SHIPPING')"
                v-on:address-updated="() => onAddressUpdated('SHIPPING')"
              />
              <i
                v-if="!showShippingForm"
                @click="showAddressForm('SHIPPING')"
                class="el-icon-circle-plus"
              ></i>
            </div>
          </div>
        </div>

        <div v-if="step === 5" class="step step-1">
          <div>
            <p class="title">Direcciones de facturación</p>
            <div class="empty" v-if="!billingAddresses.length">
              <p class="message">
                Todavía no tienes ninguna dirección de facturación. Haz click
                debajo para crear una.
              </p>
              <i
                v-if="!showBillingForm"
                @click="() => showAddressForm('BILLING')"
                class="el-icon-circle-plus"
              ></i>
              <AddressForm
                v-if="showBillingForm"
                type="BILLING"
                v-on:close-address-form="() => hideAddressForm('BILLING')"
                v-on:address-updated="() => onAddressUpdated('SHIPPING')"
                v-on:address-created="onAddressCreated"
              />
            </div>
            <div v-else class="addresses row">
              <div
                v-for="(address, index) in billingAddresses"
                :key="address.key"
                class
              >
                <AddressCard
                  v-if="updatingBillingAddress !== index"
                  class="address"
                  type="billing"
                  @click="billingAddressIndex = index"
                  :selected="billingAddressIndex === index"
                  :selectable="true"
                  :clinicName="address.clinicName"
                  :streetName="address.streetName1"
                  :city="address.city"
                  :country="address.country"
                  :province="address.province"
                  :postalCode="address.postalCode"
                  :phone="address.phone"
                  :email="address.email"
                  :cif="address.cif"
                  v-on:delete="() => deleteAddress(address.id)"
                  v-on:update="() => (updatingBillingAddress = index)"
                />

                <AddressForm
                  v-else
                  type="BILLING"
                  :address="address"
                  v-on:address-updated="() => onAddressUpdated('BILLING')"
                  v-on:close-address-form="
                    () => {
                      updatingBillingAddress = null
                    }
                  "
                />
              </div>

              <AddressForm
                v-if="showBillingForm"
                type="BILLING"
                v-on:close-address-form="() => hideAddressForm('BILLING')"
                v-on:address-created="onAddressCreated"
                v-on:address-updated="() => onAddressUpdated('BILLING')"
              />
              <i
                v-if="!showBillingForm"
                @click="showAddressForm('BILLING')"
                class="el-icon-circle-plus"
              ></i>
            </div>
          </div>
        </div>

        <div v-if="step === 6" class="step step-5">
          <Fieldset label="Tipo de mordida (relación molar)">
            <el-form-item label prop="otherBiteType">
              <div class="column">
                <el-radio label="clase1" v-model="form.biteType">
                  <span class="radio-label">Clase I</span>
                </el-radio>
                <el-radio label="clase2" v-model="form.biteType">
                  <span class="radio-label">Clase II</span>
                </el-radio>
                <el-radio label="clase3" v-model="form.biteType">
                  <span class="radio-label">Clase III</span>
                </el-radio>
                <el-radio :label="form.otherBiteType" v-model="form.biteType">
                  <span class="radio-label other">
                    Otro
                    <el-input
                      type="textarea"
                      resize="none"
                      :rows="2"
                      class="other-input"
                      v-model="form.otherBiteType"
                      :maxlength="140"
                    />
                  </span>
                </el-radio>
              </div>
            </el-form-item>
          </Fieldset>

          <Fieldset label="Diagnóstico del paciente">
            <el-checkbox-group
              class="checkbox-group"
              v-model="form.patientDiagnostic"
            >
              <el-checkbox label="crowding">
                <span class="radio-label">Apiñamiento</span>
              </el-checkbox>
              <el-checkbox label="diastemas">
                <span class="radio-label">Diastemas</span>
              </el-checkbox>
              <el-checkbox label="bulge">
                <span class="radio-label">Protrusión</span>
              </el-checkbox>
              <el-checkbox label="openbite">
                <span class="radio-label">Mordida abierta</span>
              </el-checkbox>
              <el-checkbox label="anteriorcrossbite">
                <span class="radio-label">Mordida cruzada anterior</span>
              </el-checkbox>
              <el-checkbox label="posteriorcrossbite">
                <span class="radio-label">Mordida cruzada posterior</span>
              </el-checkbox>
              <el-checkbox label="deephighlight">
                <span class="radio-label">Resalte profundo</span>
              </el-checkbox>
              <el-checkbox label="deepoverbite">
                <span class="radio-label">Mordida profunda</span>
              </el-checkbox>
              <el-checkbox :label="form.otherPatientDiagnostic">
                <span class="radio-label other" ref="otherPatientDiagnostic">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherPatientDiagnostic"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 7" class="step step-5">
          <Fieldset label="Inquietudes del paciente">
            <el-checkbox-group
              class="checkbox-group"
              v-model="form.patientConcerns"
            >
              <el-checkbox label="crowding">
                <span class="radio-label">Apiñamiento</span>
              </el-checkbox>
              <el-checkbox label="diastemas">
                <span class="radio-label">Diastemas</span>
              </el-checkbox>
              <el-checkbox label="bulge">
                <span class="radio-label">Protrusión</span>
              </el-checkbox>
              <el-checkbox label="crossbite">
                <span class="radio-label">Mordida cruzada</span>
              </el-checkbox>
              <el-checkbox :label="form.otherPatientConcern">
                <span class="radio-label other" ref="otherPatientConcern">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherPatientConcern"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Principales objetivos">
            <el-checkbox-group class="checkbox-group" v-model="form.goals">
              <el-checkbox label="align">
                <span class="radio-label">Alinear</span>
              </el-checkbox>
              <el-checkbox label="closediastemas">
                <span class="radio-label">Cerrar diastemas</span>
              </el-checkbox>
              <el-checkbox label="improvebite">
                <span class="radio-label">Mejorar la mordida</span>
              </el-checkbox>
              <el-checkbox label="fixcrossbite">
                <span class="radio-label">Corregir mordida cruzada</span>
              </el-checkbox>
              <el-checkbox :label="form.otherGoal">
                <span class="radio-label other" ref="otherGoal">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherGoal"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 8" class="step step-5">
          <Fieldset label="Arcadas a corregir">
            <el-radio v-model="form.arcadeToTreat" label="both">
              <span class="radio-label">Ambas</span>
            </el-radio>
            <el-radio v-model="form.arcadeToTreat" label="higher">
              <span class="radio-label">Superior</span>
            </el-radio>
            <el-radio v-model="form.arcadeToTreat" label="lower">
              <span class="radio-label">Inferior</span>
            </el-radio>
          </Fieldset>

          <Fieldset label="Ausencia de dientes">
            <el-radio :label="false" v-model="form.hasLackOfTeeth">
              <span class="radio-label">Todos los dientes presentes</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasLackOfTeeth">
              <span class="radio-label">Ausencia de dientes</span>
            </el-radio>

            <el-checkbox-group class="margin-top" v-model="form.lackOfTeeth">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 9" class="step step-5">
          <Fieldset label="Dientes temporales">
            <el-radio :label="false" v-model="form.hasTemporaryTeeth">
              <span class="radio-label">Sin dientes temporales</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasTemporaryTeeth">
              <span class="radio-label">Con dientes temporales</span>
            </el-radio>
            <el-checkbox-group class="margin-top" v-model="form.temporaryTeeth">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'temporaryteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'temporaryteethrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'tempraryteethleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'tempraryteethrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Dientes que no se van a mover">
            <el-radio :label="false" v-model="form.hasFixedTeeth">
              <span class="radio-label">Todos los dientes se van a mover</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasFixedTeeth">
              <span class="radio-label"
                >Los siguientes dientes no se van a mover</span
              >
            </el-radio>

            <el-checkbox-group class="margin-top" v-model="form.fixedTeeth">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 10" class="step step-5">
          <Fieldset label="Dientes en los que no se pueden poner attachments">
            <el-radio :label="false" v-model="form.hasAttachmentsRestriction">
              <span class="radio-label"
                >Poner attachments en todos los dientes</span
              >
            </el-radio>
            <el-radio :label="true" v-model="form.hasAttachmentsRestriction">
              <span class="radio-label"
                >No poner atachments en los siguientes dientes</span
              >
            </el-radio>
            <el-checkbox-group
              class="margin-top"
              v-model="form.attachmentRestriction"
            >
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Tipo de perfil facial">
            <el-checkbox-group v-model="form.facialProfile">
              <el-checkbox label="0">
                <span class="radio-label">Mantener</span>
              </el-checkbox>
              <el-checkbox label="1">
                <span class="radio-label">Mejorar la protrusión maxilar</span>
              </el-checkbox>
              <el-checkbox label="2">
                <span class="radio-label"
                  >Mejorar la protrusión mandibular</span
                >
              </el-checkbox>
              <el-checkbox label="3">
                <span class="radio-label">Mejorar la retraccion maxilar</span>
              </el-checkbox>
              <el-checkbox label="4">
                <span class="radio-label"
                  >Mejorar la retraccion mandibular</span
                >
              </el-checkbox>
            </el-checkbox-group>
            <p class="custom-error" v-if="showFacialProfileError">
              Seleccione al menos una opción
            </p>
          </Fieldset>
        </div>

        <div v-if="step === 11" class="step step-5">
          <Fieldset label="Relación sagital">
            <div class="lettered-group">
              <div
                @click="() => setValueOnClick('leftSagitalRelationship', '0')"
                :class="
                  `radio-lettered ${
                    form.leftSagitalRelationship === '0' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">I</p>
              </div>
              <div
                @click="() => setValueOnClick('rightSagitalRelationship', '0')"
                :class="
                  `radio-lettered ${
                    form.rightSagitalRelationship === '0' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">D</p>
              </div>
              <p class="radio-label">
                Mantener la relación canina y molar actual
              </p>
            </div>
            <div class="lettered-group">
              <div
                @click="() => setValueOnClick('leftSagitalRelationship', '1')"
                :class="
                  `radio-lettered ${
                    form.leftSagitalRelationship === '1' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">I</p>
              </div>
              <div
                @click="() => setValueOnClick('rightSagitalRelationship', '1')"
                :class="
                  `radio-lettered ${
                    form.rightSagitalRelationship === '1' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">D</p>
              </div>
              <p class="radio-label">
                Mantener la relación molar y solo mejorar la relación canina
              </p>
            </div>
            <div class="lettered-group">
              <div
                @click="() => setValueOnClick('leftSagitalRelationship', '2')"
                :class="
                  `radio-lettered ${
                    form.leftSagitalRelationship === '2' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">I</p>
              </div>
              <div
                @click="() => setValueOnClick('rightSagitalRelationship', '2')"
                :class="
                  `radio-lettered ${
                    form.rightSagitalRelationship === '2' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">D</p>
              </div>
              <p class="radio-label">
                Mejorar la relación canina y molar dejando ambas en Clase I
              </p>
            </div>
            <p class="custom-error" v-if="showSagitalRelationshipError">
              Seleccione una opción
            </p>
          </Fieldset>

          <Fieldset label="Solución para mejorar la relación sagital derecha">
            <el-checkbox-group v-model="form.rightSagitalRelationshipSolution">
              <el-checkbox label="ipr">
                <span class="radio-label">IPR</span>
              </el-checkbox>
              <el-checkbox label="extrude">
                <span class="radio-label">Elásticos Clase II / III</span>
              </el-checkbox>
              <el-checkbox label="distance">
                <span class="radio-label"
                  >Distalar molares (Uso de elásticos intermaxilares si es
                  necesario)</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Solución para mejorar la relación sagital izquierda">
            <el-checkbox-group v-model="form.leftSagitalRelationshipSolution">
              <el-checkbox label="ipr">
                <span class="radio-label">IPR</span>
              </el-checkbox>
              <el-checkbox label="extrude">
                <span class="radio-label">Elásticos Clase II / III</span>
              </el-checkbox>
              <el-checkbox label="distance">
                <span class="radio-label"
                  >Distalar molares (Uso de elásticos intermaxilares si es
                  necesario)</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 12" class="step step-5">
          <Fieldset label="Resalte">
            <el-radio label="show" v-model="form.standout">
              <span class="radio-label"
                >Mostrar el resalte resultante después de la simulación
                virtual</span
              >
            </el-radio>
            <el-radio label="keep" v-model="form.standout">
              <span class="radio-label">Mantener</span>
            </el-radio>
            <el-radio label="improve" v-model="form.standout">
              <span class="radio-label">Mejorar</span>
            </el-radio>
          </Fieldset>

          <Fieldset label="Mordida">
            <el-radio label="show" v-model="form.overbite">
              <span class="radio-label"
                >Mostrar la mordida resultante después de la simulación
                virtual</span
              >
            </el-radio>
            <el-radio label="keep" v-model="form.overbite">
              <span class="radio-label">Mantener</span>
            </el-radio>
            <el-radio label="fixopenbite" v-model="form.overbite">
              <span class="radio-label">Corregir la mordida abierta</span>
            </el-radio>
            <el-radio label="fixdeepbite" v-model="form.overbite">
              <span class="radio-label">Corregir la mordida profunda</span>
            </el-radio>
          </Fieldset>
        </div>

        <div v-if="step === 13" class="step step-5">
          <Fieldset label="Solución para la mordida abierta">
            <el-checkbox-group v-model="form.openbiteSolution">
              <el-checkbox label="anterosuperior">
                <span class="radio-label">Extruir anterosuperior</span>
              </el-checkbox>
              <el-checkbox label="anteroinferior">
                <span class="radio-label">Extruir anteroinferior</span>
              </el-checkbox>
              <el-checkbox label="posterosuperiores">
                <span class="radio-label"
                  >Intruir sectores posterosuperiores (puede requerir el uso de
                  minitornillos)</span
                >
              </el-checkbox>
              <el-checkbox :label="form.otherOpenbiteSolution">
                <span class="radio-label other" ref="otherOpenbiteSolution">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherOpenbiteSolution"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Soluciones para la mordida profunda">
            <el-checkbox-group v-model="form.deepbiteSolution">
              <el-checkbox label="anterosuperior">
                <span class="radio-label"
                  >Intruir solo sector anterosuperior (puede requerir el uso de
                  minitornillos)</span
                >
              </el-checkbox>
              <el-checkbox label="anteroinferior">
                <span class="radio-label"
                  >Intruir solo sector anteroinferior (puede requerir el uso de
                  minitornillos)</span
                >
              </el-checkbox>
              <el-checkbox label="posterosuperiores">
                <span class="radio-label"
                  >Intruir sector anterosuperior y extruir sectores
                  posterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="posteroinferiores">
                <span class="radio-label"
                  >Intruir sector anteroinferior y extruir sectores
                  posteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="plane">
                <span class="radio-label">Plano de levante anterior</span>
              </el-checkbox>
              <el-checkbox :label="form.otherDeepbiteSolution">
                <span class="radio-label other" ref="otherDeepbiteSolution">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherDeepbiteSolution"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 14" class="step step-5">
          <Fieldset label="Plano de levante anterior">
            <el-checkbox-group v-model="form.anteriorLiftPlane">
              <el-checkbox label="centrales">
                <span class="radio-label"
                  >Rampas en cara palatina de incisivos centrales
                  superiores</span
                >
              </el-checkbox>
              <el-checkbox label="laterales">
                <span class="radio-label"
                  >Rampas en cara palatina de incisivos laterales
                  superiores</span
                >
              </el-checkbox>
              <el-checkbox label="caninos">
                <span class="radio-label"
                  >Rampas en cara palatina de caninos superiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Mordida cruzada anterior">
            <el-checkbox-group v-model="form.anteriorCrossbite">
              <el-checkbox label="keep">
                <span class="radio-label">No corregir</span>
              </el-checkbox>
              <el-checkbox label="anterosuperiores">
                <span class="radio-label"
                  >Protruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="anteroinferiores">
                <span class="radio-label"
                  >Retruir dientes anteroinferiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 15" class="step step-5">
          <Fieldset label="Oclusión borde a borde anterior">
            <el-checkbox-group v-model="form.anteriorEdgeToEdgeOcclusion">
              <el-checkbox label="keep">
                <span class="radio-label">No corregir</span>
              </el-checkbox>
              <el-checkbox label="bulgeanterosuperior">
                <span class="radio-label"
                  >Protruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="bulgeanteroinferior">
                <span class="radio-label"
                  >Retruir dientes anteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="extrudeanterosuperior">
                <span class="radio-label"
                  >Extruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="extrudeanteroinferior">
                <span class="radio-label"
                  >Extruir dientes anteroinferiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Mordida cruzada posterior">
            <el-checkbox-group v-model="form.posteriorCrossbite">
              <el-checkbox label="keep">
                <span class="radio-label">No corregir</span>
              </el-checkbox>
              <el-checkbox label="superior">
                <span class="radio-label"
                  >Corregir preferentemente expandiendo arcada superior</span
                >
              </el-checkbox>
              <el-checkbox label="inferior">
                <span class="radio-label"
                  >Corregir preferentemente constriñendo arcada inferior</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Línea media">
            <el-radio v-model="form.middleline" label="show">
              <span class="radio-label"
                >Mostrar la línea media resultante después de la simulación
                virtual</span
              >
            </el-radio>
            <el-radio v-model="form.middleline" label="keep">
              <span class="radio-label">Mantener la línea media</span>
            </el-radio>
            <el-radio v-model="form.middleline" label="improve">
              <span class="radio-label"
                >Mejorar la línea media (puede requerir IPR)</span
              >
            </el-radio>
          </Fieldset>
        </div>

        <div v-if="step === 16" class="step step-5 laststep">
          <Fieldset label="Solución para mejorar la línea media">
            <el-checkbox-group v-model="form.middlelineSolution">
              <el-checkbox label="rsshort">
                <span class="radio-label"
                  >Línea media superior a la derecha del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="rslong">
                <span class="radio-label"
                  >Línea media superior a la derecha del paciente más de
                  2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lsshort">
                <span class="radio-label"
                  >Línea media superior a la izquierda del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lslong">
                <span class="radio-label"
                  >Línea media superior a la izquierda del paciente más de
                  2mm</span
                >
              </el-checkbox>
              <el-checkbox label="rishort">
                <span class="radio-label"
                  >Línea media inferior a la derecha del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="rilong">
                <span class="radio-label"
                  >Línea media inferior a la derecha del paciente más de
                  2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lishort">
                <span class="radio-label"
                  >Línea media inferior a la izquierda del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lilong">
                <span class="radio-label"
                  >Línea media inferior a la izquierda del paciente más de
                  2mm</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Corrección del espaciado">
            <el-checkbox-group v-model="form.spacingCorrection">
              <el-checkbox label="0">
                <span class="radio-label"
                  >Mostrar el espaciado resultante después de la simulación
                  virtual (sin espaciado)</span
                >
              </el-checkbox>
              <el-checkbox label="1">
                <span class="radio-label"
                  >Extruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="2">
                <span class="radio-label"
                  >Extruir dientes anterosuperiores y mesializar
                  posterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="3">
                <span class="radio-label"
                  >Mesializar dientes posterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="4">
                <span class="radio-label"
                  >Extruir dientes anteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="5">
                <span class="radio-label"
                  >Extruir dientes anteroinferiores y mesializar
                  posteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="6">
                <span class="radio-label"
                  >Mesializar dientes posteroinferiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 17" class="step step-5">
          <Fieldset label="Dejar espacio en los siguientes dientes">
            <el-radio :label="false" v-model="form.hasTeethSpace">
              <span class="radio-label">No dejar espacio</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasTeethSpace">
              <span class="radio-label"
                >Dejar espacio en los siguientes dientes:</span
              >
            </el-radio>
            <el-checkbox-group class="margin-top" v-model="form.teethSpace">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspaceleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspacerightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspaceleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`4.${n}`"
                        class="checkbox down"
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspacerightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`3.${n}`"
                        class="checkbox down"
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Extracción de piezas">
            <el-radio :label="false" v-model="form.hasTeethExtraction">
              <span class="radio-label">Ninguna extracción</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasTeethExtraction">
              <span class="radio-label"
                >Extracciones en los siguientes dientes</span
              >
            </el-radio>
            <el-checkbox-group
              class="margin-top"
              v-model="form.extractionTeeth"
            >
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'extractionteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      class="tooth up"
                      :key="n + 'extractionteethrightup'"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      class="tooth down"
                      :key="n + 'extractionteethleftdown'"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      class="tooth down"
                      :key="n + 'extractionteethrightdown'"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>
        </div>

        <div v-if="step === 18" class="step step-10">
          <Fieldset label="Resolver apiñamiento superior">
            <p class="multiple-checkbox-label">Expansión</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingExpansion"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">Protruir sector anterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingBulge"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR sector anterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingAnteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR sector posterior derecha</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingRightPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">
              IPR sector posterior izquierda
            </p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingLeftPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>
          </Fieldset>
        </div>

        <div v-if="step === 19" class="step step-3">
          <Fieldset label="Resolver Apiñamiento inferior">
            <p class="multiple-checkbox-label">Expandir</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingExpansion"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">Proclinar</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingBulge"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR - anterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingAnteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR - derecha posterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingRightPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR - izquierda posterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingLeftPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>
          </Fieldset>
        </div>

        <div v-if="step === 20" class="step step-5">
          <Fieldset label="Elegir tratamiento">
            <el-radio label="q81" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.q81 }}</span>
            </el-radio>
            <el-radio label="q82" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.q82 }}</span>
            </el-radio>
            <el-radio label="lite1" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qlite1 }}</span>
            </el-radio>
            <el-radio label="lite2" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qlite2 }}</span>
            </el-radio>
            <el-radio label="full1" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qfull1 }}</span>
            </el-radio>
            <el-radio label="full2" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qfull2 }}</span>
            </el-radio>

            <p class="custom-error" v-if="showTreatmentTypeError">
              Seleccione una opción
            </p>
          </Fieldset>
          <Fieldset label="Instrucciones especiales">
            <el-input
              v-model="form.specialInstructions"
              type="textarea"
              resize="none"
              :rows="8"
            />
          </Fieldset>
        </div>

        <div v-if="step === 21" class="step step-12">
          <Fieldset
            v-if="hasDiscounts"
            class="discounts"
            label="Usar cupones de descuento"
          >
            <el-form-item label>
              <el-radio label="none" v-model="discounts">
                <span class="radio-label">No usar ningún cupón</span>
              </el-radio>

              <!-- Member get member -->
              <el-radio
                v-if="discountsMemberGetMember"
                label="memberGetMember"
                v-model="discounts"
              >
                <span class="radio-label"
                  >Usar cupón Member Get Member (-200€)</span
                >
              </el-radio>
              <p v-if="discountsMemberGetMember" class="quote">
                Dispone de {{ discountsMemberGetMember }} Member Get Member
              </p>

              <!-- Yearly -->
              <el-radio
                v-if="discountsYearly"
                label="yearly"
                v-model="discounts"
              >
                <span class="radio-label"
                  >Usar cupón de personal de clínica (-15%)</span
                >
              </el-radio>
              <p v-if="discountsYearly" class="quote">
                Dispone de {{ discountsYearly }} de personal de clínica
              </p>
            </el-form-item>
          </Fieldset>
        </div>

        <div v-if="step === 22" class="step step-12">
          <Fieldset label="Datos del paciente">
            <el-form-item
              label
              class="margin-bottom"
              ref="patientName"
              prop="patientName"
            >
              <el-input
                v-model.trim="form.patientName"
                placeholder="Nombre"
                @blur="writePatientName"
              />
            </el-form-item>
            <el-form-item label class="margin-bottom" prop="patientSurname">
              <el-input
                v-model="form.patientSurname"
                class
                placeholder="Apellidos"
                @blur="writePatientName"
              />
            </el-form-item>
            <el-form-item label class="margin-bottom" prop="patientGender">
              <el-select
                v-model="form.patientGender"
                class
                placeholder="Género"
              >
                <el-option
                  v-for="gender in genders"
                  :key="gender"
                  :label="gender"
                  :value="gender"
                />
              </el-select>
            </el-form-item>

            <el-form-item label class="margin-bottom" prop="patientBirthdate">
              <el-date-picker
                class="birthdate"
                format="dd/MM/yyyy"
                value-format="dd/MM/yyyy"
                v-model="form.patientBirthdate"
                range-separator="/"
                type="date"
                placeholder="Fecha de nacimiento"
              >
              </el-date-picker>
            </el-form-item>

            <p class="label">Foto del paciente</p>
            <input
              :style="{ display: 'none' }"
              ref="patientPhoto"
              v-on:change="e => onUploadImage(e, 'patientPhoto')"
              type="file"
            />
            <div v-if="!isPatientPhotoUploaded">
              <i
                @click="() => triggerUpload('patientPhoto')"
                ref="patientPhotoIcon"
                class="el-icon-picture uploader"
              ></i>
            </div>

            <el-form-item label class="margin-bottom" prop="patientPhoto">
              <el-input
                :style="{ display: 'none' }"
                v-model="form.patientPhoto"
                ref="patientPhotoUrl"
                type="text"
                placeholder="url"
              />
            </el-form-item>
            <img
              v-if="isPatientPhotoUploaded"
              :style="{ width: '100px', display: '' }"
              ref="patientPhotoImage"
              :src="form.patientPhoto"
            />
            <div v-if="isPatientPhotoUploaded" class="patient-photo-upload">
              <p class="name">{{ patientPhotoName }}</p>
              <i @click="removePatientPhoto" class="cross el-icon-close"></i>
            </div>
          </Fieldset>

          <Fieldset label="Cargar fotografías">
            <input
              :style="{ display: 'none' }"
              ref="lateralFacialImage"
              v-on:change="e => onUploadImage(e, 'lateralFacialImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="frontalFacialImage"
              v-on:change="e => onUploadImage(e, 'frontalFacialImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="smileFacialImage"
              v-on:change="e => onUploadImage(e, 'smileFacialImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="upperOcclusalImage"
              v-on:change="e => onUploadImage(e, 'upperOcclusalImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="lowerOcclusalImage"
              v-on:change="e => onUploadImage(e, 'lowerOcclusalImage')"
              type="file"
            />

            <input
              :style="{ display: 'none' }"
              ref="rightIntraoralImage"
              v-on:change="e => onUploadImage(e, 'rightIntraoralImage')"
              type="file"
            />
            <input
              :style="{ display: 'none' }"
              ref="centralIntraoralImage"
              v-on:change="e => onUploadImage(e, 'centralIntraoralImage')"
              type="file"
            />
            <input
              :style="{ display: 'none' }"
              ref="leftIntraoralImage"
              v-on:change="e => onUploadImage(e, 'leftIntraoralImage')"
              type="file"
            />
            <div class="grid-uploader">
              <div class="item">
                <img
                  src="../assets/foto_facial_lateral.png"
                  v-if="!form.lateralFacialImage"
                  @click="() => triggerUpload('lateralFacialImage')"
                  ref="lateralFacialImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.lateralFacialImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="lateralFacialImageImage"
                  @click="() => triggerUpload('lateralFacialImage')"
                  :src="form.lateralFacialImage"
                />
                <el-form-item label class prop="lateralFacialImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.lateralFacialImage"
                    ref="lateralFacialImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_facial_frontal.png"
                  v-if="!form.frontalFacialImage"
                  @click="() => triggerUpload('frontalFacialImage')"
                  ref="frontalFacialImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.frontalFacialImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="frontalFacialImageImage"
                  @click="() => triggerUpload('frontalFacialImage')"
                  :src="form.frontalFacialImage"
                />
                <el-form-item label class prop="frontalFacialImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.frontalFacialImage"
                    ref="frontalFacialImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_facial_sonrisa.png"
                  v-if="!form.smileFacialImage"
                  @click="() => triggerUpload('smileFacialImage')"
                  ref="smileFacialImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.smileFacialImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="smileFacialImageImage"
                  @click="() => triggerUpload('smileFacialImage')"
                  :src="form.smileFacialImage"
                />
                <el-form-item label class prop="smileFacialImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.smileFacialImage"
                    ref="smileFacialImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_oclusal_superior.png"
                  v-if="!form.upperOcclusalImage"
                  @click="() => triggerUpload('upperOcclusalImage')"
                  ref="upperOcclusalImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.upperOcclusalImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="upperOcclusalImageImage"
                  @click="() => triggerUpload('upperOcclusalImage')"
                  :src="form.upperOcclusalImage"
                />
                <el-form-item label class prop="upperOcclusalImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.upperOcclusalImage"
                    ref="upperOcclusalImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>

              <div class="item">
                <div
                  src="../assets/qs1.svg"
                  @click="
                    () =>
                      clearUploads([
                        'lateralFacialImage',
                        'frontalFacialImage',
                        'smileFacialImage',
                        'upperOcclusalImage',
                        'lowerOcclusalImage',
                        'rightIntraoralImage',
                        'centralIntraoralImage',
                        'leftIntraoralImage'
                      ])
                  "
                  ref="lowerOcclusalImageIcon"
                  class="big-uploader-text"
                >
                  <p class="text">
                    Haga doble click aquí para eliminar todas las fotografías
                  </p>
                </div>
              </div>

              <div class="item">
                <img
                  src="../assets/foto_oclusal_inferior.png"
                  v-if="!form.lowerOcclusalImage"
                  @click="() => triggerUpload('lowerOcclusalImage')"
                  ref="lowerOcclusalImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.lowerOcclusalImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="lowerOcclusalImageImage"
                  @click="() => triggerUpload('lowerOcclusalImage')"
                  :src="form.lowerOcclusalImage"
                />
                <el-form-item label class prop="lowerOcclusalImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.lowerOcclusalImage"
                    ref="lowerOcclusalImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_intraoral_derecha.png"
                  v-if="!form.rightIntraoralImage"
                  @click="() => triggerUpload('rightIntraoralImage')"
                  ref="rightIntraoralImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.rightIntraoralImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="rightIntraoralImageImage"
                  @click="() => triggerUpload('rightIntraoralImage')"
                  :src="form.rightIntraoralImage"
                />
                <el-form-item label class prop="rightIntraoralImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.rightIntraoralImage"
                    ref="rightIntraoralImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_intraoral_frontal.png"
                  v-if="!form.centralIntraoralImage"
                  @click="() => triggerUpload('centralIntraoralImage')"
                  ref="centralIntraoralImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.centralIntraoralImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="centralIntraoralImageImage"
                  @click="() => triggerUpload('centralIntraoralImage')"
                  :src="form.centralIntraoralImage"
                />
                <el-form-item label class prop="centralIntraoralImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.centralIntraoralImage"
                    ref="centralIntraoralImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
              <div class="item">
                <img
                  src="../assets/foto_intraoral_izquierda.png"
                  v-if="!form.leftIntraoralImage"
                  @click="() => triggerUpload('leftIntraoralImage')"
                  ref="leftIntraoralImageIcon"
                  class="big-uploader"
                />
                <img
                  v-show="form.leftIntraoralImage"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="leftIntraoralImageImage"
                  @click="() => triggerUpload('leftIntraoralImage')"
                  :src="form.leftIntraoralImage"
                />
                <el-form-item label class prop="leftIntraoralImage">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.leftIntraoralImage"
                    ref="leftIntraoralImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
            </div>

            <p class="custom-error" v-if="showPhotoError">
              Debe subir todas las fotografías
            </p>
          </Fieldset>

          <Fieldset label="Cargar radiografías">
            <input
              :style="{ display: 'none' }"
              ref="xray1Image"
              v-on:change="e => onUploadImage(e, 'xray1Image')"
              type="file"
            />
            <input
              :style="{ display: 'none' }"
              ref="xray2Image"
              v-on:change="e => onUploadImage(e, 'xray2Image')"
              type="file"
            />

            <div class="grid-uploader">
              <div class="item">
                <img
                  src="../assets/x_ray1.jpg"
                  v-if="!form.xray1Image"
                  @click="() => triggerUpload('xray1Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray1Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray1ImageImage"
                  @click="() => triggerUpload('xray1Image')"
                  :src="form.xray1Image"
                />
                <el-form-item label class prop="xray1Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray1Image"
                    ref="xray1ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>

              <div class="item">
                <img
                  src="../assets/x_ray2.jpg"
                  v-if="!form.xray2Image"
                  @click="() => triggerUpload('xray2Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray2Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray2ImageImage"
                  @click="() => triggerUpload('xray2Image')"
                  :src="form.xray2Image"
                />
                <el-form-item label class prop="xray2Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray2Image"
                    ref="xray2ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
            </div>

            <p class="custom-error" v-if="showRadiographyError">
              Debe subir todas las radiografías
            </p>
          </Fieldset>

          <Fieldset label="Cargar escaneado digital">
            <el-radio label="intraoralScanner" v-model="form.typeOfImpressions">
              <span class="radio-label">Escaner intraoral(STL)</span>
            </el-radio>
            <div class="intraoral-scanner-grid">
              <div class="item margin-right">
                <p>Superior</p>
                <input
                  :style="{ display: 'none' }"
                  ref="upperIntraoralScanner"
                  v-on:change="e => onUploadImage(e, 'upperIntraoralScanner')"
                  type="file"
                />
                <div>
                  <i
                    @click="() => triggerUpload('upperIntraoralScanner')"
                    ref="superiorIntraoralScannerIcon"
                    class="el-icon-picture uploader"
                  ></i>
                </div>
                <el-form-item label class prop="upperIntraoralScanner">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.upperIntraoralScanner"
                    ref="upperIntraoralScannerUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>

                <div
                  v-if="upperIntraoralScannerName"
                  class="patient-photo-upload"
                >
                  <p class="name">{{ upperIntraoralScannerName }}</p>
                  <i
                    @click="() => removePhoto('upperIntraoralScanner')"
                    class="cross el-icon-close"
                  ></i>
                </div>
              </div>
              <div class="item margin-right">
                <p>Inferior</p>
                <input
                  :style="{ display: 'none' }"
                  ref="lowerIntraoralScanner"
                  v-on:change="e => onUploadImage(e, 'lowerIntraoralScanner')"
                  type="file"
                />
                <div>
                  <i
                    @click="() => triggerUpload('lowerIntraoralScanner')"
                    ref="lowerIntraoralScannerIcon"
                    class="el-icon-picture uploader"
                  ></i>
                </div>
                <el-form-item label class prop="lowerIntraoralScanner">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.lowerIntraoralScanner"
                    ref="lowerIntraoralScannerUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
                <div
                  v-if="lowerIntraoralScannerName"
                  class="patient-photo-upload"
                >
                  <p class="name">{{ lowerIntraoralScannerName }}</p>
                  <i
                    @click="() => removePhoto('lowerIntraoralScanner')"
                    class="cross el-icon-close"
                  ></i>
                </div>
              </div>
            </div>

            <p class="custom-error" v-if="showIntraoralScannerError">
              Al seleccionar está opción debe subir los archivos STL
            </p>
          </Fieldset>

          <Fieldset label="Dirección de envío">
            <div>
              <div class="empty" v-if="!shippingAddresses.length">
                <p class="message">
                  Todavía no tienes ninguna dirección de envío. Haz click debajo
                  para crear una.
                </p>
                <i
                  v-if="!showShippingForm"
                  @click="() => showAddressForm('SHIPPING')"
                  class="el-icon-circle-plus"
                ></i>
                <AddressForm
                  v-if="showShippingForm"
                  type="SHIPPING"
                  v-on:close-address-form="() => hideAddressForm('SHIPPING')"
                  v-on:address-created="onAddressCreated"
                />
              </div>
              <div v-else class="addresses table">
                <div
                  v-for="(address, index) in shippingAddresses"
                  :key="address.key"
                  class
                >
                  <AddressCard
                    v-if="updatingShippingAddress !== index"
                    type="shipping"
                    @click="shippingAddressIndex = index"
                    :selected="shippingAddressIndex === index"
                    :selectable="true"
                    :clinicName="address.clinicName"
                    :streetName="address.streetName1"
                    :city="address.city"
                    :country="address.country"
                    :province="address.province"
                    :postalCode="address.postalCode"
                    :phone="address.phone"
                    v-on:delete="() => deleteAddress(address.id)"
                    v-on:update="() => (updatingShippingAddress = index)"
                  />

                  <AddressForm
                    v-else
                    type="SHIPPING"
                    :address="address"
                    v-on:address-updated="() => onAddressUpdated('SHIPPING')"
                    v-on:close-address-form="
                      () => {
                        updatingShippingAddress = null
                      }
                    "
                  />
                </div>

                <AddressForm
                  v-if="showShippingForm"
                  type="SHIPPING"
                  v-on:close-address-form="() => hideAddressForm('SHIPPING')"
                  v-on:address-created="onAddressCreated"
                  v-on:address-updated="() => onAddressUpdated('SHIPPING')"
                />
                <i
                  v-if="!showShippingForm"
                  @click="showAddressForm('SHIPPING')"
                  class="el-icon-circle-plus"
                ></i>
              </div>
            </div>

            <p class="custom-error" v-if="showShippingAddressError">
              Seleccione una dirección de envío válida
            </p>
          </Fieldset>

          <Fieldset label="Dirección de facturación">
            <div>
              <div class="empty" v-if="!billingAddresses.length">
                <p class="message">
                  Todavía no tienes ninguna dirección de facturación. Haz click
                  debajo para crear una.
                </p>
                <i
                  v-if="!showBillingForm"
                  @click="() => showAddressForm('BILLING')"
                  class="el-icon-circle-plus"
                ></i>
                <AddressForm
                  v-if="showBillingForm"
                  type="BILLING"
                  v-on:close-address-form="() => hideAddressForm('BILLING')"
                  v-on:address-created="onAddressCreated"
                  v-on:address-updated="() => onAddressUpdated('BILLING')"
                />
              </div>
              <div v-else class="addresses table">
                <div
                  v-for="(address, index) in billingAddresses"
                  :key="address.key"
                  class
                >
                  <AddressCard
                    v-if="updatingBillingAddress !== index"
                    @click="billingAddressIndex = index"
                    type="billing"
                    :selected="billingAddressIndex === index"
                    :selectable="true"
                    :clinicName="address.clinicName"
                    :streetName="address.streetName1"
                    :city="address.city"
                    :country="address.country"
                    :province="address.province"
                    :postalCode="address.postalCode"
                    :phone="address.phone"
                    :cif="address.cif"
                    :email="address.email"
                    v-on:delete="() => deleteAddress(address.id)"
                    v-on:update="() => (updatingShippingAddress = index)"
                  />

                  <AddressForm
                    v-else
                    type="BILLING"
                    :address="address"
                    v-on:address-updated="() => onAddressUpdated('BILLING')"
                    v-on:close-address-form="
                      () => {
                        updatingBillingAddress = null
                      }
                    "
                  />
                </div>

                <AddressForm
                  v-if="showBillingForm"
                  type="BILLING"
                  v-on:close-address-form="() => hideAddressForm('BILLING')"
                  v-on:address-updated="() => onAddressUpdated('BILLING')"
                  v-on:address-created="onAddressCreated"
                />
                <i
                  v-if="!showBillingForm"
                  @click="showAddressForm('BILLING')"
                  class="el-icon-circle-plus"
                ></i>
              </div>
            </div>

            <p class="custom-error" v-if="showBillingAddressError">
              Seleccione una dirección de facturación válida
            </p>
          </Fieldset>

          <Fieldset label="Tipo de mordida (relación molar)">
            <el-form-item label prop="otherBiteType">
              <el-radio label="clase1" v-model="form.biteType">
                <span class="radio-label">Clase I</span>
              </el-radio>
              <el-radio label="clase2" v-model="form.biteType">
                <span class="radio-label">Clase II</span>
              </el-radio>
              <el-radio label="clase3" v-model="form.biteType">
                <span class="radio-label">Clase III</span>
              </el-radio>
              <el-radio :label="form.otherBiteType" v-model="form.biteType">
                <span class="radio-label other">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherBiteType"
                    :maxlength="140"
                  />
                </span>
              </el-radio>
            </el-form-item>
          </Fieldset>

          <Fieldset label="Diagnósico del paciente">
            <el-checkbox-group
              class="checkbox-group"
              v-model="form.patientDiagnostic"
            >
              <el-checkbox label="crowding">
                <span class="radio-label">Apiñamiento</span>
              </el-checkbox>
              <el-checkbox label="diastemas">
                <span class="radio-label">Diastemas</span>
              </el-checkbox>
              <el-checkbox label="bulge">
                <span class="radio-label">Protrusión</span>
              </el-checkbox>
              <el-checkbox label="openbite">
                <span class="radio-label">Mordida abierta</span>
              </el-checkbox>
              <el-checkbox label="anteriorcrossbite">
                <span class="radio-label">Mordida cruzada anterior</span>
              </el-checkbox>
              <el-checkbox label="posteriorcrossbite">
                <span class="radio-label">Mordida cruzada posterior</span>
              </el-checkbox>
              <el-checkbox label="deephighlight">
                <span class="radio-label">Resalte profundo</span>
              </el-checkbox>
              <el-checkbox label="deepoverbite">
                <span class="radio-label">Mordida profunda</span>
              </el-checkbox>
              <el-checkbox :label="form.otherPatientDiagnostic">
                <span class="radio-label other" ref="otherPatientDiagnostic">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherPatientDiagnostic"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Inquietudes del paciente">
            <el-checkbox-group
              class="checkbox-group"
              v-model="form.patientConcerns"
            >
              <el-checkbox label="crowding">
                <span class="radio-label">Apiñamiento</span>
              </el-checkbox>
              <el-checkbox label="diastemas">
                <span class="radio-label">Diastemas</span>
              </el-checkbox>
              <el-checkbox label="bulge">
                <span class="radio-label">Protrusión</span>
              </el-checkbox>
              <el-checkbox label="crossbite">
                <span class="radio-label">Mordida cruzada</span>
              </el-checkbox>
              <el-checkbox :label="form.otherPatientConcern">
                <span class="radio-label other" ref="otherPatientConcern">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherPatientConcern"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Principales objetivos">
            <el-checkbox-group class="checkbox-group" v-model="form.goals">
              <el-checkbox label="align">
                <span class="radio-label">Alinear</span>
              </el-checkbox>
              <el-checkbox label="closediastemas">
                <span class="radio-label">Cerrar diastemas</span>
              </el-checkbox>
              <el-checkbox label="improvebite">
                <span class="radio-label">Mejorar la mordida</span>
              </el-checkbox>
              <el-checkbox label="fixcrossbite">
                <span class="radio-label">Corregir mordida cruzada</span>
              </el-checkbox>
              <el-checkbox :label="form.otherGoal">
                <span class="radio-label other" ref="otherGoal">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherGoal"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Arcadas a corregir">
            <el-radio v-model="form.arcadeToTreat" label="both">
              <span class="radio-label">Ambas</span>
            </el-radio>
            <el-radio v-model="form.arcadeToTreat" label="higher">
              <span class="radio-label">Superior</span>
            </el-radio>
            <el-radio v-model="form.arcadeToTreat" label="lower">
              <span class="radio-label">Inferior</span>
            </el-radio>
          </Fieldset>

          <Fieldset label="Ausencia de dientes">
            <el-radio :label="false" v-model="form.hasLackOfTeeth">
              <span class="radio-label">Todos los dientes presentes</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasLackOfTeeth">
              <span class="radio-label">Ausencia de dientes</span>
            </el-radio>

            <el-checkbox-group class="margin-top" v-model="form.lackOfTeeth">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'lackofteethrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasLackOfTeeth"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Dientes temporales">
            <el-radio :label="false" v-model="form.hasTemporaryTeeth">
              <span class="radio-label">Sin dientes temporales</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasTemporaryTeeth">
              <span class="radio-label">Con dientes temporales</span>
            </el-radio>
            <el-checkbox-group class="margin-top" v-model="form.temporaryTeeth">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'temporaryteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'temporaryteethrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'tempraryteethleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'tempraryteethrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTemporaryTeeth"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Dientes que no se van a mover">
            <el-radio :label="false" v-model="form.hasFixedTeeth">
              <span class="radio-label">Todos los dientes se van a mover</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasFixedTeeth">
              <span class="radio-label"
                >Los siguientes dientes no se van a mover</span
              >
            </el-radio>

            <el-checkbox-group class="margin-top" v-model="form.fixedTeeth">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'fixedteethrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasFixedTeeth"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Dientes en los que no se pueden poner attachments">
            <el-radio :label="false" v-model="form.hasAttachmentsRestriction">
              <span class="radio-label"
                >Poner attachments en todos los dientes</span
              >
            </el-radio>
            <el-radio :label="true" v-model="form.hasAttachmentsRestriction">
              <span class="radio-label"
                >No poner atachments en los siguientes dientes</span
              >
            </el-radio>
            <el-checkbox-group
              class="margin-top"
              v-model="form.attachmentRestriction"
            >
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionrightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'attachmentrestrictionrightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasAttachmentsRestriction"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Tipo de perfil facial">
            <el-checkbox-group v-model="form.facialProfile">
              <el-checkbox label="0">
                <span class="radio-label">Mantener</span>
              </el-checkbox>
              <el-checkbox label="1">
                <span class="radio-label">Mejorar la protrusión maxilar</span>
              </el-checkbox>
              <el-checkbox label="2">
                <span class="radio-label"
                  >Mejorar la protrusión mandibular</span
                >
              </el-checkbox>
              <el-checkbox label="3">
                <span class="radio-label">Mejorar la retraccion maxilar</span>
              </el-checkbox>
              <el-checkbox label="4">
                <span class="radio-label"
                  >Mejorar la retraccion mandibular</span
                >
              </el-checkbox>
            </el-checkbox-group>
            <p class="custom-error" v-if="showFacialProfileError">
              Seleccione al menos una opción
            </p>
          </Fieldset>

          <Fieldset label="Relación sagital">
            <div class="lettered-group">
              <div
                @click="() => setValueOnClick('leftSagitalRelationship', '0')"
                :class="
                  `radio-lettered ${
                    form.leftSagitalRelationship === '0' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">I</p>
              </div>
              <div
                @click="() => setValueOnClick('rightSagitalRelationship', '0')"
                :class="
                  `radio-lettered ${
                    form.rightSagitalRelationship === '0' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">D</p>
              </div>
              <p class="radio-label">
                Mantener la relación canina y molar actual
              </p>
            </div>

            <div class="lettered-group">
              <div
                @click="() => setValueOnClick('leftSagitalRelationship', '1')"
                :class="
                  `radio-lettered ${
                    form.leftSagitalRelationship === '1' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">I</p>
              </div>
              <div
                @click="() => setValueOnClick('rightSagitalRelationship', '1')"
                :class="
                  `radio-lettered ${
                    form.rightSagitalRelationship === '1' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">D</p>
              </div>
              <p class="radio-label">
                Mantener la relación molar y solo mejorar la relación canina
              </p>
            </div>
            <div class="lettered-group">
              <div
                @click="() => setValueOnClick('leftSagitalRelationship', '2')"
                :class="
                  `radio-lettered ${
                    form.leftSagitalRelationship === '2' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">I</p>
              </div>
              <div
                @click="() => setValueOnClick('rightSagitalRelationship', '2')"
                :class="
                  `radio-lettered ${
                    form.rightSagitalRelationship === '2' ? 'selected' : ''
                  }`
                "
              >
                <p class="letter">D</p>
              </div>
              <p class="radio-label">
                Mejorar la relación canina y molar dejando ambas en Clase I
              </p>
            </div>
            <p class="custom-error" v-if="showSagitalRelationshipError">
              Seleccione una opción
            </p>
          </Fieldset>

          <Fieldset label="Solución para mejorar la relación sagital derecha">
            <el-checkbox-group v-model="form.rightSagitalRelationshipSolution">
              <el-checkbox label="ipr">
                <span class="radio-label">IPR</span>
              </el-checkbox>
              <el-checkbox label="extrude">
                <span class="radio-label"
                  >Extruir anteroinferiorElásticos Clase II / III</span
                >
              </el-checkbox>
              <el-checkbox label="distance">
                <span class="radio-label"
                  >Distalar molares (Uso de elásticos intermaxilares si es
                  necesario)</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Solución para mejorar la relación sagital izquierda">
            <el-checkbox-group v-model="form.leftSagitalRelationshipSolution">
              <el-checkbox label="ipr">
                <span class="radio-label">IPR</span>
              </el-checkbox>
              <el-checkbox label="extrude">
                <span class="radio-label"
                  >Extruir anteroinferiorElásticos Clase II / III</span
                >
              </el-checkbox>
              <el-checkbox label="distance">
                <span class="radio-label"
                  >Distalar molares (Uso de elásticos intermaxilares si es
                  necesario)</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Resalte">
            <el-radio label="show" v-model="form.standout">
              <span class="radio-label"
                >Mostrar el resalte resultante después de la simulación
                virtual</span
              >
            </el-radio>
            <el-radio label="keep" v-model="form.standout">
              <span class="radio-label">Mantener</span>
            </el-radio>
            <el-radio label="improve" v-model="form.standout">
              <span class="radio-label">Mejorar</span>
            </el-radio>
          </Fieldset>

          <Fieldset label="Mordida">
            <el-radio label="show" v-model="form.overbite">
              <span class="radio-label"
                >Mostrar la mordida resultante después de la simulación
                virtual</span
              >
            </el-radio>
            <el-radio label="keep" v-model="form.overbite">
              <span class="radio-label">Mantener</span>
            </el-radio>
            <el-radio label="fixopenbite" v-model="form.overbite">
              <span class="radio-label">Corregir la mordida abierta</span>
            </el-radio>
            <el-radio label="fixdeepbite" v-model="form.overbite">
              <span class="radio-label">Corregir la mordida profunda</span>
            </el-radio>
          </Fieldset>

          <Fieldset label="Solución para la mordida abierta">
            <el-checkbox-group v-model="form.openbiteSolution">
              <el-checkbox label="anterosuperior">
                <span class="radio-label">Extruir anterosuperior</span>
              </el-checkbox>
              <el-checkbox label="anteroinferior">
                <span class="radio-label">Extruir anteroinferior</span>
              </el-checkbox>
              <el-checkbox label="posterosuperiores">
                <span class="radio-label"
                  >Intruir sectores posterosuperiores (puede requerir el uso de
                  minitornillos)</span
                >
              </el-checkbox>
              <el-checkbox :label="form.otherOpenbiteSolution">
                <span class="radio-label other" ref="otherOpenbiteSolution">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherOpenbiteSolution"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Soluciones para la mordida profunda">
            <el-checkbox-group v-model="form.deepbiteSolution">
              <el-checkbox label="anterosuperior">
                <span class="radio-label"
                  >Intruir solo sector anterosuperior (puede requerir el uso de
                  minitornillos)</span
                >
              </el-checkbox>
              <el-checkbox label="anteroinferior">
                <span class="radio-label"
                  >Intruir solo sector anteroinferior (puede requerir el uso de
                  minitornillos)</span
                >
              </el-checkbox>
              <el-checkbox label="posterosuperiores">
                <span class="radio-label"
                  >Intruir sector anterosuperior y extruir sectores
                  posterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="posteroinferiores">
                <span class="radio-label"
                  >Intruir sector anteroinferior y extruir sectores
                  posteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="plane">
                <span class="radio-label">Plano de levante anterior</span>
              </el-checkbox>
              <el-checkbox :label="form.otherDeepbiteSolution">
                <span class="radio-label other" ref="otherDeepbiteSolution">
                  Otro
                  <el-input
                    type="textarea"
                    resize="none"
                    :rows="2"
                    class="other-input"
                    v-model="form.otherDeepbiteSolution"
                    :maxlength="140"
                  />
                </span>
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Plano de levante anterior">
            <el-checkbox-group v-model="form.anteriorLiftPlane">
              <el-checkbox label="centrales">
                <span class="radio-label"
                  >Rampas en cara palatina de incisivos centrales
                  superiores</span
                >
              </el-checkbox>
              <el-checkbox label="laterales">
                <span class="radio-label"
                  >Rampas en cara palatina de incisivos laterales
                  superiores</span
                >
              </el-checkbox>
              <el-checkbox label="caninos">
                <span class="radio-label"
                  >Rampas en cara palatina de caninos superiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Mordida cruzada anterior">
            <el-checkbox-group v-model="form.anteriorCrossbite">
              <el-checkbox label="keep">
                <span class="radio-label">No corregir</span>
              </el-checkbox>
              <el-checkbox label="anterosuperiores">
                <span class="radio-label"
                  >Protruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="anteroinferiores">
                <span class="radio-label"
                  >Retraer dientes anteroinferiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Oclusión borde a borde anterior">
            <el-checkbox-group v-model="form.anteriorEdgeToEdgeOcclusion">
              <el-checkbox label="keep">
                <span class="radio-label">No corregir</span>
              </el-checkbox>
              <el-checkbox label="bulgeanterosuperior">
                <span class="radio-label"
                  >Protruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="bulgeanteroinferior">
                <span class="radio-label"
                  >Retruir dientes anteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="extrudeanterosuperior">
                <span class="radio-label"
                  >Extruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="extrudeanteroinferior">
                <span class="radio-label"
                  >Extruir dientes anteroinferiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Mordida cruzada posterior">
            <el-checkbox-group v-model="form.posteriorCrossbite">
              <el-checkbox label="keep">
                <span class="radio-label">No corregir</span>
              </el-checkbox>
              <el-checkbox label="superior">
                <span class="radio-label"
                  >Corregir preferentemente expandiendo arcada superior</span
                >
              </el-checkbox>
              <el-checkbox label="inferior">
                <span class="radio-label"
                  >Corregir preferentemente constriñendo arcada inferior</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Línea media">
            <el-radio v-model="form.middleline" label="show">
              <span class="radio-label"
                >Mostrar la línea media resultante después de la simulación
                virtual</span
              >
            </el-radio>
            <el-radio v-model="form.middleline" label="keep">
              <span class="radio-label">Mantener la línea media</span>
            </el-radio>
            <el-radio v-model="form.middleline" label="improve">
              <span class="radio-label"
                >Mejorar la línea media (puede requerir IPR)</span
              >
            </el-radio>
          </Fieldset>

          <Fieldset label="Solución para mejorar la línea media">
            <el-checkbox-group v-model="form.middlelineSolution">
              <el-checkbox label="rsshort">
                <span class="radio-label"
                  >Línea media superior a la derecha del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="rslong">
                <span class="radio-label"
                  >Línea media superior a la derecha del paciente más de
                  2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lsshort">
                <span class="radio-label"
                  >Línea media superior a la izquierda del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lslong">
                <span class="radio-label"
                  >Línea media superior a la izquierda del paciente más de
                  2mm</span
                >
              </el-checkbox>
              <el-checkbox label="rishort">
                <span class="radio-label"
                  >Línea media inferior a la derecha del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="rilong">
                <span class="radio-label"
                  >Línea media inferior a la derecha del paciente más de
                  2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lishort">
                <span class="radio-label"
                  >Línea media inferior a la izquierda del paciente 1-2mm</span
                >
              </el-checkbox>
              <el-checkbox label="lilong">
                <span class="radio-label"
                  >Línea media inferior a la izquierda del paciente más de
                  2mm</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Corrección del espaciado">
            <el-checkbox-group v-model="form.spacingCorrection">
              <el-checkbox label="0">
                <span class="radio-label"
                  >Mostrar el espaciado resultante después de la simulación
                  virtual (sin espaciado)</span
                >
              </el-checkbox>
              <el-checkbox label="1">
                <span class="radio-label"
                  >Extruir dientes anterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="2">
                <span class="radio-label"
                  >Extruir dientes anterosuperiores y mesializar
                  posterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="3">
                <span class="radio-label"
                  >Mesializar dientes posterosuperiores</span
                >
              </el-checkbox>
              <el-checkbox label="4">
                <span class="radio-label"
                  >Extruir dientes anteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="5">
                <span class="radio-label"
                  >Extruir dientes anteroinferiores y mesializar
                  posteroinferiores</span
                >
              </el-checkbox>
              <el-checkbox label="6">
                <span class="radio-label"
                  >Mesializar dientes posteroinferiores</span
                >
              </el-checkbox>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Dejar espacio en los siguientes dientes">
            <el-radio :label="false" v-model="form.hasTeethSpace">
              <span class="radio-label">No dejar espacio</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasTeethSpace">
              <span class="radio-label"
                >Dejar espacio en los siguientes dientes:</span
              >
            </el-radio>
            <el-checkbox-group class="margin-top" v-model="form.teethSpace">
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspaceleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspacerightup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspaceleftdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`4.${n}`"
                        class="checkbox down"
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>

                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'teethspacerightdown'"
                      class="tooth down"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethSpace"
                        :label="`3.${n}`"
                        class="checkbox down"
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Extracción de piezas">
            <el-radio :label="false" v-model="form.hasTeethExtraction">
              <span class="radio-label">Ninguna extracción</span>
            </el-radio>
            <el-radio :label="true" v-model="form.hasTeethExtraction">
              <span class="radio-label"
                >Extracciones en los siguientes dientes</span
              >
            </el-radio>
            <el-checkbox-group
              class="margin-top"
              v-model="form.extractionTeeth"
            >
              <div class="teeth">
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      :key="n + 'extractionteethleftup'"
                      class="tooth up"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`1.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">1.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      class="tooth up"
                      :key="n + 'extractionteethrightup'"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`2.${n}`"
                        class="checkbox up"
                        name
                      >
                        <span class="number">2.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="teeth-row">
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      class="tooth down"
                      :key="n + 'extractionteethleftdown'"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`4.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">4.{{ 9 - n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="quart">
                    <div
                      v-for="n in 8"
                      class="tooth down"
                      :key="n + 'extractionteethrightdown'"
                    >
                      <el-checkbox
                        :disabled="!form.hasTeethExtraction"
                        :label="`3.${n}`"
                        class="checkbox down"
                        name
                      >
                        <span class="number">3.{{ n }}</span>
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </Fieldset>

          <Fieldset label="Resolver apiñamiento superior">
            <p class="multiple-checkbox-label">Expansión</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingExpansion"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0"
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1"
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2">No</el-radio>
            </el-radio-group>

            <p class="multiple-checkbox-label">Protruir sector anterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingBulge"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0"
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1"
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2">No</el-radio>
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR sector anterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingAnteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0"
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1"
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2">No</el-radio>
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR sector posterior derecha</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingRightPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">
              IPR sector posterior izquierda
            </p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.uppercrowdingLeftPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>
          </Fieldset>

          <Fieldset label="Resolver Apiñamiento inferior">
            <p class="multiple-checkbox-label">Expandir</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingExpansion"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">Proclinar</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingBulge"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR - anterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingAnteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR - derecha posterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingRightPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>

            <p class="multiple-checkbox-label">IPR - izquierda posterior</p>
            <el-radio-group
              class="margin-bottom"
              v-model="form.lowercrowdingLeftPosteriorIpr"
            >
              <el-radio-button class="radio-button" label="0"
                >Preferentemente</el-radio-button
              >
              <el-radio-button class="radio-button" label="1"
                >Según sea necesario</el-radio-button
              >
              <el-radio-button class="radio-button" label="2"
                >No</el-radio-button
              >

              <el-radio class="radio-button-replacement" label="0" border
                >Preferentemente</el-radio
              >
              <el-radio class="radio-button-replacement" label="1" border
                >Según sea necesario</el-radio
              >
              <el-radio class="radio-button-replacement" label="2" border
                >No</el-radio
              >
            </el-radio-group>
          </Fieldset>

          <Fieldset label="Enviar impresiones PVS">
            <el-radio label="pvsPrinting" v-model="form.typeOfImpressions">
              <span class="radio-label"
                >Impresión PVS (enviar por mensajería)</span
              >
              <div>
                <p>QuickSmile</p>
                <p>c/Casas de Miravete 24C 3º1B</p>
                <p>28031 Madrid</p>
              </div>

              <a
                :style="{ display: 'none' }"
                ref="shippingSheetLink"
                target="_blank"
                :href="shippingSheetPdf"
              />
              <el-button type="primary" @click="downloadShippingSheet">
                Hoja de envío
              </el-button>
            </el-radio>
          </Fieldset>

          <Fieldset label="Recoger impresiones PVS">
            <p class="cost">
              (Opción gratuita para recogidas dentro de la península)
            </p>
            <p>Dirección de recogida</p>
            <el-radio label="derivation" v-model="form.typeOfImpressions">
              <span class="radio-label"
                >Misma dirección de envio que el caso</span
              >
            </el-radio>
            <el-radio
              label="derivation-custom"
              v-model="form.typeOfImpressions"
            >
              <span class="radio-label">
                Otra dirección
              </span>
              <el-input
                class="derivation-custom other-input"
                type="textarea"
                resize="none"
                :rows="2"
                v-model="form.typeOfImpressionsAddress"
                :maxlength="140"
                :disabled="form.typeOfImpressions !== 'derivation-custom'"
              />
            </el-radio>
          </Fieldset>

          <p
            class="custom-error"
            v-if="
              this.showTypeOfImpressionsError && !this.form.typeOfImpressions
            "
          >
            Seleccione una opción
          </p>
          <p class="custom-error" v-else-if="this.showTypeOfImpressionsError">
            Debe escribir una dirección
          </p>
          <Fieldset label="Elegir tratamiento">
            <el-radio label="q81" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.q81 }}</span>
            </el-radio>
            <el-radio label="q82" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.q82 }}</span>
            </el-radio>
            <el-radio label="lite1" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qlite1 }}</span>
            </el-radio>
            <el-radio label="lite2" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qlite2 }}</span>
            </el-radio>
            <el-radio label="full1" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qfull1 }}</span>
            </el-radio>
            <el-radio label="full2" v-model="form.treatmentType">
              <span class="radio-label">{{ priceTexts.qfull2 }}</span>
            </el-radio>

            <p class="custom-error" v-if="showTreatmentTypeError">
              Seleccione una opción
            </p>
          </Fieldset>

          <Fieldset label="Instrucciones especiales">
            <el-input
              v-model="form.specialInstructions"
              type="textarea"
              resize="none"
              :rows="8"
            />
          </Fieldset>

          <Fieldset
            v-if="hasDiscounts"
            class="discounts"
            label="Usar cupones de descuento"
          >
            <el-form-item label>
              <el-radio label="none" v-model="discounts">
                <span class="radio-label">No usar ningún cupón</span>
              </el-radio>

              <!-- member get member -->
              <el-radio
                v-if="discountsMemberGetMember"
                label="memberGetMember"
                v-model="discounts"
              >
                <span class="radio-label"
                  >Usar cupón Member Get Member (-200€)</span
                >
              </el-radio>
              <p v-if="discountsMemberGetMember" class="quote">
                Dispone de {{ discountsMemberGetMember }} Member Get Member
              </p>

              <!-- yearly -->
              <el-radio
                v-if="discountsYearly"
                label="yearly"
                v-model="discounts"
              >
                <span class="radio-label"
                  >Usar cupón de personal de clínica (-15%)</span
                >
              </el-radio>
              <p v-if="discountsYearly" class="quote">
                Dispone de {{ discountsYearly }} de personal de clínica
              </p>
            </el-form-item>
          </Fieldset>
        </div>
      </el-form>
    </div>
    <div class="navigation">
      <el-button
        v-if="step !== 0"
        class="back"
        type="primary"
        @click="goToPreviousStep"
        >Atrás</el-button
      >
      <div v-else></div>

      <el-button
        v-if="showContinueButton"
        class="next"
        type="primary"
        @click="goToNextStep"
        >Guardar y continuar</el-button
      >
      <el-button
        v-else-if="!prefilled && step === 22"
        class="next"
        type="primary"
        @click="tryCreateCase"
        >Crear caso</el-button
      >
      <el-button
        v-else-if="prefilled && step === 22"
        class="next"
        type="primary"
        @click="updateCase"
        >Actualizar caso</el-button
      >
    </div>

    <el-dialog
      class="dialog"
      title="Crear caso"
      :visible.sync="isDialogVisible"
      :width="isSmallWindow ? '80%' : '30%'"
      :show-close="false"
    >
      <div v-if="!showFormValidationError" class="confirmation">
        <p>Si hace click en Confirmar se creará el caso ¿Está seguro/a?</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">Cancelar</el-button>
          <el-button type="primary" @click="() => updateCase(true)"
            >Confirmar</el-button
          >
        </span>
      </div>
      <div v-else class="confirmation">
        <p>
          Debes rellenar todos los campos. El caso se ha guardado como borrador.
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeFormValidationError"
            >Aceptar</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from './Fieldset'
import AddressForm from '@/components/AddressForm'
import AddressCard from '@/components/AddressCard'
import {
  findCaseShippingAddress,
  findCaseBillingAddress,
  isAddressIncluded
} from '@/utils'
import { GENDERS, PDF_DOWNLOAD_URL } from '@/constants'
export default {
  name: 'CaseForm',
  props: {
    membershipNumber: String,
    shippingAddresses: Array,
    billingAddresses: Array,
    prefilled: Object,
    currentCase: Object,
    discountsMemberGetMember: Number,
    discountsYearly: Number,
    discountFirstCase: Number,
    schoolRole: String
  },
  mounted () {
    this.loadPrefilledData()
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    this.$store.commit('clearCurrentCase')
  },
  computed: {
    hasDiscounts () {
      return (
        this.schoolRole !== 'STUDENT' &&
        (this.discountsMemberGetMember ||
          this.discountsYearly ||
          this.discountFirstCase)
      )
    },
    isSmallWindow () {
      return this.clientWidth < 768
    },
    isTreatmentTypeEmpty () {
      return !this.form.treatmentType
    },
    isTypeOfImpressionsEmpty () {
      return !this.form.typeOfImpressions
    },
    isSagitalRelationshipEmpty () {
      return !(
        this.form.rightSagitalRelationship && this.form.leftSagitalRelationship
      )
    },
    isFacialProfileEmpty () {
      return !this.form.facialProfile.length
    },
    showContinueButton () {
      const isShippingAddressStep = this.step === 4
      const isBillingAddressStep = this.step === 5
      const isShippingAddressSelected = !!this.shippingAddresses.length
      const isBillingAddressSelected = !!this.billingAddresses.length
      if (this.step === 22) {
        return false
      } else if (isShippingAddressStep && !isShippingAddressSelected) {
        return false
      } else if (isBillingAddressStep && !isBillingAddressSelected) {
        return false
      } else return true
    },
    isPatientPhotoUploaded () {
      return !!this.form.patientPhoto
    },
    priceTexts () {
      return {
        q81:
          this.schoolRole !== 'STUDENT'
            ? 'Q-12 (1 arcada) - 449€'
            : 'Q-12 (1 arcada)',
        q82:
          this.schoolRole !== 'STUDENT'
            ? 'Q-12 (2 arcadas) - 649€'
            : 'Q-12 (2 arcadas)',
        qlite1:
          this.schoolRole !== 'STUDENT'
            ? 'Q-22 (1 arcada) - 745€'
            : 'Q-22 (1 arcada)',
        qlite2:
          this.schoolRole !== 'STUDENT'
            ? 'Q-22 (2 arcadas) - 990€'
            : 'Q-22 (2 arcadas)',
        qfull1:
          this.schoolRole !== 'STUDENT'
            ? 'Q-Full (1 arcada) - 1.045€'
            : 'Q-Full (1 arcada)',
        qfull2:
          this.schoolRole !== 'STUDENT'
            ? 'Q-Full (2 arcadas) - 1.490€'
            : 'Q-Full (2 arcadas)'
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  data () {
    const validateOtherBiteType = (rule, value, callback) => {
      if (
        !value &&
        this.form.biteType !== 'clase1' &&
        this.form.biteType !== 'clase2' &&
        this.form.biteType !== 'clase3'
      ) {
        callback(new Error('Introduce un tipo de mordida'))
      } else {
        callback()
      }
    }
    return {
      clientWidth: window.innerWidth,
      isLoaded: false,
      caseId: this.$route.query.case_id,
      clickCounter: 0,
      isDialogVisible: false,
      submitFn: null,
      patientPhotoName: '',
      step: 0,
      genders: GENDERS,
      formTitle: '',
      customAddress: false,
      showFormValidationError: false,
      shippingAddressIndex:
        this.currentCase && this.shippingAddresses.length
          ? findCaseShippingAddress(this.currentCase, this.shippingAddresses)
          : 0,
      billingAddressIndex:
        this.currentCase && this.billingAddresses.length
          ? findCaseBillingAddress(this.currentCase, this.billingAddresses)
          : 0,
      updatingShippingAddress: null,
      updatingBillingAddress: null,
      showBillingForm: false,
      showShippingForm: false,
      upperIntraoralScannerName: null,
      lowerIntraoralScannerName: null,
      occlussionIntraoralScannerName: null,
      showFacialProfileError: null,
      showSagitalRelationshipError: null,
      showTypeOfImpressionsError: null,
      showTreatmentTypeError: null,
      showPhotoError: null,
      showRadiographyError: null,
      showIntraoralScannerError: null,
      showBillingAddressError: null,
      showShippingAddressError: null,
      discounts: 'none',
      form: {
        patientName: '',
        patientSurname: '',
        patientGender: '',
        patientBirthdate: '',
        patientPhoto: '',
        billingClinicName: '',
        billingStreetName1: '',
        billingCountry: '',
        billingProvince: '',
        billingPostalCode: '',
        billingCity: '',
        billingPhone: '',
        billingCif: '',
        billingEmail: '',
        shippingClinicName: '',
        shippingStreetName1: '',
        shippingCountry: '',
        shippingProvince: '',
        shippingPostalCode: '',
        shippingCity: '',
        shippingPhone: '',
        biteType: 'clase1',
        otherBiteType: '',
        patientDiagnostic: [],
        otherPatientDiagnostic: '',
        patientConcerns: [],
        otherPatientConcern: '',
        goals: [],
        otherGoal: '',
        arcadeToTreat: 'both',
        hasLackOfTeeth: false,
        lackOfTeeth: [],
        hasTemporaryTeeth: false,
        temporaryTeeth: [],
        hasFixedTeeth: false,
        fixedTeeth: [],
        hasAttachmentsRestriction: false,
        attachmentRestriction: [],
        facialProfile: [],
        rightSagitalRelationship: null,
        leftSagitalRelationship: null,
        rightSagitalRelationshipSolution: [],
        leftSagitalRelationshipSolution: [],
        standout: 'show',
        overbite: 'show',
        openbiteSolution: [],
        otherOpenbiteSolution: '',
        deepbiteSolution: [],
        otherDeepbiteSolution: '',
        anteriorLiftPlane: [],
        anteriorCrossbite: [],
        anteriorEdgeToEdgeOcclusion: [],
        posteriorCrossbite: [],
        middleline: 'show',
        middlelineSolution: [],
        spacingCorrection: [],
        hasTeethSpace: false,
        teethSpace: [],
        hasTeethExtraction: false,
        extractionTeeth: [],
        uppercrowdingExpansion: '0',
        uppercrowdingBulge: '0',
        uppercrowdingAnteriorIpr: '0',
        uppercrowdingRightPosteriorIpr: '0',
        uppercrowdingLeftPosteriorIpr: '0',
        lowercrowdingExpansion: '0',
        lowercrowdingBulge: '0',
        lowercrowdingAnteriorIpr: '0',
        lowercrowdingRightPosteriorIpr: '0',
        lowercrowdingLeftPosteriorIpr: '0',
        lateralFacialImage: '',
        frontalFacialImage: '',
        smileFacialImage: '',
        upperOcclusalImage: '',
        lowerOcclusalImage: '',
        rightIntraoralImage: '',
        centralIntraoralImage: '',
        leftIntraoralImage: '',
        xray1Image: '',
        xray2Image: '',
        typeOfImpressions: null,
        typeOfImpressionsAddress: '',
        upperIntraoralScanner: '',
        lowerIntraoralScanner: '',
        occlussionIntraoralScannerImage: '',
        treatmentType: null,
        specialInstructions: '',
        useDiscountMemberGetMember: false,
        useDiscountYearly: false,
        useDiscountFirstCase: false,
        create: false
      },
      rules: {
        patientName: [
          {
            required: true,
            message: 'Introduzca el nombre del paciente',
            trigger: 'blur'
          },
          { min: 2, message: 'Formato incorrecto', trigger: 'blur' },
          { max: 20, message: 'Formato incorrecto', trigger: 'blur' }
        ],
        patientSurname: [
          {
            required: true,
            message: 'Introduzca los apellidos del paciente',
            trigger: 'blur'
          },
          { min: 2, message: 'Formato incorrecto', trigger: 'blur' },
          { max: 40, message: 'Formato incorrecto', trigger: 'blur' }
        ],
        patientGender: [
          {
            required: true,
            message: 'Introduzca el género del paciente',
            trigger: 'blur'
          }
        ],
        patientBirthdate: [
          {
            required: true,
            message: 'Introduzca la fecha de nacimiento del paciente',
            trigger: 'blur'
          },
          { min: 10, message: 'Introduzca una fecha válida', trigger: 'blur' }
        ],
        patientPhoto: [
          {
            required: false,
            message: 'Seleccione la foto del paciente',
            trigger: 'blur'
          }
        ],
        addressClinicName: [
          {
            required: true,
            message: 'Introduzca el nombre de la clínica',
            trigger: 'blur'
          }
        ],
        addressType: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressCif: [
          {
            required: true,
            message: 'Introduzca el cif de la empresa',
            trigger: 'blur'
          }
        ],
        addressEmail: [
          { required: true, message: 'Introduzca el email', trigger: 'blur' }
        ],
        addressName1: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressCountry: [
          {
            required: true,
            message: 'Seleccione el país',
            trigger: 'blur'
          }
        ],
        addressProvince: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressPostalCode: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressCity: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressPhone: [
          {
            required: true,
            message: 'Introduce un número de teléfono',
            trigger: 'blur'
          }
        ],
        otherBiteType: [{ validator: validateOtherBiteType, trigger: 'blur' }]
      },
      shippingSheetPdf: PDF_DOWNLOAD_URL.MEASURES
    }
  },
  methods: {
    validateAddresses () {
      const isBillinAddressValid = this.validateBillingAddresses()
      const isShippingddressValid = this.validateShippingAddresses()
      // ensure both validations are called
      return isBillinAddressValid || isShippingddressValid
    },
    validateBillingAddresses () {
      const isSelectedBillingAddressDeleted = isAddressIncluded(
        this.billingAddresses,
        this.form.billingClinicName
      )
      const isValidBillingAddress =
        !this.form.billingClinicName || isSelectedBillingAddressDeleted
      this.showBillingAddressError = !isValidBillingAddress
      return !isValidBillingAddress
    },
    validateShippingAddresses () {
      const isSelectedShippingAddressDeleted = isAddressIncluded(
        this.shippingAddresses,
        this.form.shippingClinicName
      )
      const isValidShippingAddress =
        !this.form.shippingClinicName || isSelectedShippingAddressDeleted
      if (!isValidShippingAddress) {
        this.showShippingAddressError = true
      }
      return !isValidShippingAddress
    },
    handleResize (event) {
      this.clientWidth = window.innerWidth
    },
    validateIntraoralScanner () {
      if (this.form.typeOfImpressions === 'intraoralScanner') {
        this.showIntraoralScannerError = !(
          this.form.upperIntraoralScanner && this.form.lowerIntraoralScanner
        )
      } else {
        this.showIntraoralScannerError = false
      }
      return this.showIntraoralScannerError
    },
    validateRadiography () {
      this.showRadiographyError = !(
        this.form.xray1Image && this.form.xray2Image
      )
      return this.showRadiographyError
    },
    validatePhotos () {
      this.showPhotoError = [
        this.form.lateralFacialImage,
        this.form.frontalFacialImage,
        this.form.smileFacialImage,
        this.form.upperOcclusalImage,
        this.form.lowerOcclusalImage,
        this.form.rightIntraoralImage,
        this.form.centralIntraoralImage,
        this.form.leftIntraoralImage
      ].reduce((acc, val) => {
        return acc || !val
      }, false)
      return this.showPhotoError
    },
    validateTreatmentType () {
      if (this.isTreatmentTypeEmpty) {
        this.showTreatmentTypeError = true
      } else {
        this.showTreatmentTypeError = false
      }
      return this.showTreatmentTypeError
    },
    validateTypeOfImpressions () {
      if (
        this.isTypeOfImpressionsEmpty ||
        (this.form.typeOfImpressions === 'derivation-custom' &&
          !this.form.typeOfImpressionsAddress)
      ) {
        this.showTypeOfImpressionsError = true
      } else {
        this.showTypeOfImpressionsError = false
      }
      return this.showTypeOfImpressionsError
    },
    validateSagitalRelationship () {
      if (this.isSagitalRelationshipEmpty) {
        this.showSagitalRelationshipError = true
      } else {
        this.showSagitalRelationshipError = false
      }
      return this.showSagitalRelationshipError
    },
    validateFacialProfile () {
      if (this.isFacialProfileEmpty) {
        this.showFacialProfileError = true
      } else {
        this.showFacialProfileError = false
      }
      return this.showFacialProfileError
    },
    tryCreateCase () {
      if (!this.validateForm()) {
        this.showFormValidationError = true
      } else {
        this.showFormValidationError = false
      }
      this.isDialogVisible = true
    },
    closeFormValidationError () {
      this.closeDialog()
      this.updateCase()
    },
    setAddresses () {
      if (this.billingAddresses.length) {
        const billingAddress = this.billingAddresses[this.billingAddressIndex]
        if (billingAddress) {
          this.form.billingClinicName = billingAddress.clinicName
          this.form.billingStreetName1 = billingAddress.streetName1
          this.form.billingProvince = billingAddress.province
          this.form.billingCountry = billingAddress.country
          this.form.billingPostalCode = billingAddress.postalCode
          this.form.billingCity = billingAddress.city
          this.form.billingPhone = billingAddress.phone
          this.form.billingCif = billingAddress.cif
          this.form.billingEmail = billingAddress.email
        }
      }
      if (this.shippingAddresses.length) {
        const shippingAddress = this.shippingAddresses[
          this.shippingAddressIndex
        ]
        if (shippingAddress) {
          this.form.shippingClinicName = shippingAddress.clinicName
          this.form.shippingStreetName1 = shippingAddress.streetName1
          this.form.shippingProvince = shippingAddress.province
          this.form.shippingCountry = shippingAddress.country
          this.form.shippingPostalCode = shippingAddress.postalCode
          this.form.shippingCity = shippingAddress.city
          this.form.shippingPhone = shippingAddress.phone
        }
      }
    },
    loadPrefilledData () {
      if (this.currentCase && this.currentCase.patientName) {
        this.form.attachments = this.currentCase.attachments
        this.form.quickcheck = this.currentCase.quickcheck
        this.form.reference = this.currentCase.reference
        this.form.shippingTracking = this.currentCase.shippingTracking
        this.form.doctor = this.currentCase.doctor
        this.form.billingClinicName = this.currentCase.billingClinicName
        this.form.billingStreetName1 = this.currentCase.billingStreetName1
        this.form.billingProvince = this.currentCase.billingProvince
        this.form.billingCountry = this.currentCase.billingCountry
        this.form.billingPostalCode = this.currentCase.billingPostalCode
        this.form.billingCity = this.currentCase.billingCity
        this.form.billingPhone = this.currentCase.billingPhone
        this.form.billingCif = this.currentCase.billingCif
        this.form.billingEmail = this.currentCase.billingEmail
        this.form.shippingClinicName = this.currentCase.shippingClinicName
        this.form.shippingStreetName1 = this.currentCase.shippingStreetName1
        this.form.shippingProvince = this.currentCase.shippingProvince
        this.form.shippingCountry = this.currentCase.shippingCountry
        this.form.shippingPostalCode = this.currentCase.shippingPostalCode
        this.form.shippingCity = this.currentCase.shippingCity
        this.form.shippingPhone = this.currentCase.shippingPhone
        this.form.patientName = this.currentCase.patientName
        this.form.patientSurname = this.currentCase.patientSurname
        this.formTitle = `${this.form.patientName || ''} ${this.form
          .patientSurname || ''}`
        this.form.patientGender = this.currentCase.patientGender
        this.form.patientBirthdate = this.currentCase.patientBirthdate
        this.form.patientPhoto = this.currentCase.patientPhoto
        this.form.biteType = this.currentCase.biteType
        this.form.otherBiteType =
          this.currentCase.biteType &&
          ['clase1', 'clase2', 'clase3'].includes(this.currentCase.biteType)
            ? ''
            : this.currentCase.biteType
        this.form.patientDiagnostic = this.currentCase.patientDiagnostic
        this.form.otherPatientDiagnostic =
          this.currentCase.patientDiagnostic &&
          this.currentCase.patientDiagnostic.find(
            x =>
              ![
                'crowding',
                'diastemas',
                'posteriorcrossbite',
                'deepoverbite',
                'deephighlight',
                'bulge',
                'anteriorcrossbite',
                'openbite'
              ].includes(x)
          )
            ? this.currentCase.patientDiagnostic.find(
                x =>
                  ![
                    'crowding',
                    'diastemas',
                    'posteriorcrossbite',
                    'deepoverbite',
                    'deephighlight',
                    'bulge',
                    'anteriorcrossbite',
                    'openbite'
                  ].includes(x)
              )
            : ''
        this.form.patientConcerns = this.currentCase.patientConcerns
        this.form.otherPatientConcern =
          this.currentCase.patientConcerns &&
          this.currentCase.patientConcerns.find(
            x =>
              !['crowding', 'diastemas', 'bulge', '', 'crossbite'].includes(x)
          )
            ? this.currentCase.patientConcerns.find(
                x =>
                  !['crowding', 'diastemas', 'bulge', '', 'crossbite'].includes(
                    x
                  )
              )
            : ''
        this.form.goals = this.currentCase.goals
        this.form.otherGoal =
          this.currentCase.goals &&
          this.currentCase.goals.find(
            x =>
              ![
                'align',
                'closediastemas',
                'fixcrossbite',
                'improvebite'
              ].includes(x)
          )
            ? this.currentCase.goals.find(
                x =>
                  ![
                    'align',
                    'closediastemas',
                    'fixcrossbite',
                    'improvebite'
                  ].includes(x)
              )
            : ''
        this.form.arcadeToTreat = this.currentCase.arcadeToTreat
        this.form.hasLackOfTeeth = this.currentCase.hasLackOfTeeth
        this.form.lackOfTeeth = this.currentCase.lackOfTeeth
        this.form.hasTemporaryTeeth = this.currentCase.hasTemporaryTeeth
        this.form.temporaryTeeth = this.currentCase.temporaryTeeth
        this.form.hasFixedTeeth = this.currentCase.hasFixedTeeth
        this.form.fixedTeeth = this.currentCase.fixedTeeth
        this.form.hasAttachmentsRestriction = this.currentCase.hasAttachmentsRestriction
        this.form.attachmentRestriction = this.currentCase.attachmentRestriction
        this.form.facialProfile = this.currentCase.facialProfile
        this.form.rightSagitalRelationship = this.currentCase
          .rightSagitalRelationship
          ? Array.isArray(this.currentCase.rightSagitalRelationship)
            ? this.currentCase.rightSagitalRelationship[0]
            : this.currentCase.rightSagitalRelationship
          : null
        this.form.leftSagitalRelationship = this.currentCase
          .leftSagitalRelationship
          ? Array.isArray(this.currentCase.leftSagitalRelationship)
            ? this.currentCase.leftSagitalRelationship[0]
            : this.currentCase.leftSagitalRelationship
          : null
        this.form.rightSagitalRelationshipSolution = this.currentCase.rightSagitalRelationshipSolution
        this.form.leftSagitalRelationshipSolution = this.currentCase.leftSagitalRelationshipSolution
        this.form.standout = this.currentCase.standout
        this.form.overbite = this.currentCase.overbite
        this.form.openbiteSolution = this.currentCase.openbiteSolution
        this.form.otherOpenbiteSolution =
          this.currentCase.openbiteSolution &&
          this.currentCase.openbiteSolution.find(
            x =>
              ![
                'anterosuperior',
                'anteroinferior',
                'posterosuperiores'
              ].includes(x)
          )
            ? this.currentCase.openbiteSolution.find(
                x =>
                  ![
                    'anterosuperior',
                    'anteroinferior',
                    'posterosuperiores'
                  ].includes(x)
              )
            : ''
        this.form.deepbiteSolution = this.currentCase.deepbiteSolution
        this.form.otherDeepbiteSolution =
          this.currentCase.deepbiteSolution &&
          this.currentCase.deepbiteSolution.find(
            x =>
              ![
                'anterosuperior',
                'anteroinferior',
                'posterosuperiores',
                'posteroinferiores',
                'plane'
              ].includes(x)
          )
            ? this.currentCase.deepbiteSolution.find(
                x =>
                  ![
                    'anterosuperior',
                    'anteroinferior',
                    'posterosuperiores',
                    'posteroinferiores',
                    'plane'
                  ].includes(x)
              )
            : ''
        this.form.anteriorLiftPlane = this.currentCase.anteriorLiftPlane
        this.form.anteriorCrossbite = this.currentCase.anteriorCrossbite
        this.form.anteriorEdgeToEdgeOcclusion = this.currentCase.anteriorEdgeToEdgeOcclusion
        this.form.posteriorCrossbite = this.currentCase.posteriorCrossbite
        this.form.middleline = this.currentCase.middleline
        this.form.middlelineSolution = this.currentCase.middlelineSolution
        this.form.spacingCorrection = this.currentCase.spacingCorrection
        this.form.hasTeethSpace = this.currentCase.hasTeethSpace
        this.form.tteethSpace = this.currentCase.teethSpace
        this.form.hasTeethExtraction = this.currentCase.hasTeethExtraction
        this.form.extractionTeeth = this.currentCase.extractionTeeth
        this.form.uppercrowdingExpansion = this.currentCase.uppercrowdingExpansion
        this.form.uppercrowdingBulge = this.currentCase.uppercrowdingBulge
        this.form.uppercrowdingAnteriorIpr = this.currentCase.uppercrowdingAnteriorIpr
        this.form.uppercrowdingRightPosteriorIpr = this.currentCase.uppercrowdingRightPosteriorIpr
        this.form.uppercrowdingLeftPosteriorIpr = this.currentCase.uppercrowdingLeftPosteriorIpr
        this.form.lowercrowdingExpansion = this.currentCase.lowercrowdingExpansion
        this.form.lowercrowdingBulge = this.currentCase.lowercrowdingBulge
        this.form.lowercrowdingAnteriorIpr = this.currentCase.lowercrowdingAnteriorIpr
        this.form.lowercrowdingRightPosteriorIpr = this.currentCase.lowercrowdingRightPosteriorIpr
        this.form.lowercrowdingLeftPosteriorIpr = this.currentCase.lowercrowdingLeftPosteriorIpr
        this.form.lateralFacialImage = this.currentCase.lateralFacialImage
        this.form.frontalFacialImage = this.currentCase.frontalFacialImage
        this.form.smileFacialImage = this.currentCase.smileFacialImage
        this.form.upperOcclusalImage = this.currentCase.upperOcclusalImage
        this.form.lowerOcclusalImage = this.currentCase.lowerOcclusalImage
        this.form.rightIntraoralImage = this.currentCase.rightIntraoralImage
        this.form.centralIntraoralImage = this.currentCase.centralIntraoralImage
        this.form.leftIntraoralImage = this.currentCase.leftIntraoralImage
        this.form.typeOfImpressions = this.currentCase.typeOfImpressions
        this.form.typeOfImpressionsAddress = this.currentCase.typeOfImpressionsAddress
        this.form.upperIntraoralScanner = this.currentCase.upperIntraoralScanner
        this.form.lowerIntraoralScanner = this.currentCase.lowerIntraoralScanner
        this.form.occlusalIntraoralScanner = this.currentCase.occlusalIntraoralScanner
        this.form.treatmentType = this.currentCase.treatmentType
        this.form.specialInstructions = this.currentCase.specialInstructions
        this.form.useDiscountMemberGetMember = this.currentCase.useDiscountMemberGetMember
        this.form.useDiscountYearly = this.currentCase.useDiscountYearly
        this.form.useDiscountFirstCase = this.currentCase.useDiscountFirstCase
        this.form.xray1Image = this.currentCase.xray1Image
        this.form.xray2Image = this.currentCase.xray2Image
        this.shippingAddressIndex = findCaseShippingAddress(
          this.currentCase,
          this.shippingAddresses
        )
        this.billingAddressIndex = findCaseBillingAddress(
          this.currentCase,
          this.billingAddresses
        )
      }
    },
    async updateCase (isBeingCreated = false) {
      this.setAddresses()
      if (!this.showFormValidationError) {
        if (!this.form.patientName)
          this.form.patientName = this.currentCase.patientName
        if (!this.form.patientSurname)
          this.form.patientSurname = this.currentCase.patientSurname
        if (!this.form.patientGender)
          this.form.patientGender = this.currentCase.patientGener
        this.$store.dispatch('updateCase', {
          ...this.form,
          caseId: this.caseId,
          create: isBeingCreated
        })
      } else {
        this.showFormValidationError = true
      }
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    closeDialog () {
      this.isDialogVisible = false
    },
    async dispatchCreateCase () {
      this.$store.dispatch('createCase', {
        ...this.form,
        create: this.form.create
      })
      // TOOD: get id from vuex
      // this.caseId = res.data.createCase.id
    },
    createCase (submitFn) {
      if (this.isDialogVisible) {
        submitFn()
      }
      if (this.validateForm() && this.step === 22) {
        this.isDialogVisible = true
      }
    },
    async deleteAddress (id) {
      this.$emit('delete-address', id)
    },
    hideAddressForm (type) {
      if (type === 'SHIPPING') {
        this.showShippingForm = false
      } else if (type === 'BILLING') {
        this.showBillingForm = false
      }
    },
    onAddressCreated () {
      this.$emit('address-created')
      this.showShippingForm = false
      this.showBillingForm = false
    },
    onAddressUpdated (type) {
      this.$emit('address-updated')
      if (type === 'SHIPPING') {
        this.updatingShippingAddress = null
      } else if (type === 'BILLING') {
        this.updatingBillingAddress = null
      }
    },
    async showAddressForm (type) {
      if (type === 'SHIPPING') {
        this.showShippingForm = true
      } else if (type === 'BILLING') {
        this.showBillingForm = true
      }
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    removePatientPhoto () {
      this.form.patientPhoto = ''
    },
    clearUploads (names) {
      this.clickCounter += 1
      if (this.clickCounter % 2 === 0) {
        names.forEach(name => {
          this.form[name] = ''
        })
      }
    },
    goHome (argument) {
      this.$router.push({ name: 'CaseListing' })
    },
    writePatientName () {
      this.formTitle = `${this.form.patientName || ''} ${this.form
        .patientSurname || ''}`
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      }
      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''

      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
          this.form[fieldName] = downloadURL
          if (this.$refs[fieldName + 'Image']) {
            this.$refs[fieldName + 'Image'].style.display = 'auto'
            this.$refs[fieldName + 'Image'].src = downloadURL
          }
        })
      })
    },
    setValueOnClick (prop, value) {
      this.form[prop] = value
    },
    validateForm () {
      let error
      this.$refs.form.validateField(
        [
          'patientName',
          'patientSurname',
          'patientGender',
          'patientBirthdate',
          'patientPhoto',
          'otherBiteType',
          'lateralFacialImage',
          'frontalFacialImage',
          'smileFacialImage',
          'upperOcclusalImage',
          'lowerOcclusalImage',
          'rightIntraoralImage',
          'centralIntraoralImage',
          'leftIntraoralImage',
          'lowerIntraoralScanner',
          'upperIntraoralScanner'
        ],
        elementError => {
          if (elementError) {
            error = true
          }
        }
      )
      if (this.validateTreatmentType()) error = true
      if (this.validateTypeOfImpressions()) error = true
      if (this.validateSagitalRelationship()) error = true
      if (this.validateFacialProfile()) error = true
      if (this.validatePhotos()) error = true
      if (this.validateRadiography()) error = true
      if (this.validateIntraoralScanner()) error = true
      if (this.validateAddresses()) error = true
      if (!error) {
        return true
      } else {
        return false
      }
    },
    goToNextStep () {
      if (this.step === 0) {
        let error = false
        this.$refs.form.validateField(
          [
            'patientName',
            'patientSurname',
            'patientGender',
            'patientBirthdate',
            'patientPhoto'
          ],
          elementError => {
            if (elementError) {
              error = true
            }
          }
        )
        if (!error) {
          this.step += 1
          if (this.caseId) {
            this.updateCase()
          } else {
            this.dispatchCreateCase()
          }
        }
      } else if (this.step === 6) {
        let error = false
        this.$refs.form.validateField(['otherBiteType'], elementError => {
          if (elementError) {
            error = true
          }
        })
        if (!error) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 10) {
        this.validateFacialProfile()
        if (!this.showFacialProfileError) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 11) {
        this.validateSagitalRelationship()
        if (!this.showSagitalRelationshipError) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 3) {
        this.validateTypeOfImpressions()
        if (!this.showTypeOfImpressionsError) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 20) {
        this.validateTreatmentType()
        if (!this.showTreatmentTypeError) {
          if (this.hasDiscounts) {
            this.step += 1
          } else {
            this.step += 2
          }
          this.updateCase()
        }
      } else if (this.step === 1) {
        let error = false
        this.$refs.form.validateField(
          [
            'lateralFacialImage',
            'frontalFacialImage',
            'smileFacialImage',
            'upperOcclusalImage',
            'lowerOcclusalImage',
            'rightIntraoralImage',
            'centralIntraoralImage',
            'leftIntraoralImage'
          ],
          elementError => {
            if (elementError) {
              error = true
            }
          }
        )
        if (!error) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 2) {
        let error = false
        this.$refs.form.validateField(
          ['xray1Image', 'xray2Image'],
          elementError => {
            if (elementError) {
              error = true
            }
          }
        )
        if (!error) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 19) {
        let error = false
        this.$refs.form.validateField(
          ['lowerIntraoralScanner', 'upperIntraoralScanner'],
          elementError => {
            if (elementError) {
              error = true
            }
          }
        )
        if (!error) {
          this.step += 1
          this.updateCase()
        }
      } else if (this.step === 20) {
        this.step++
        this.updateCase()
      } else {
        this.step++
        this.updateCase()
      }
    },
    // TODO: Check if all subfields are valid before go
    goToPreviousStep () {
      if (this.step === 4 && this.customAddress) {
        this.customAddress = false
      } else if (this.step === 22) {
        if (this.hasDiscounts) {
          this.step -= 1
        } else {
          this.step -= 2
        }
      } else {
        this.step -= 1
      }
    }
  },
  watch: {
    'form.useDiscountMemberGetMember' () {
      if (
        this.form.useDiscountMemberGetMember &&
        this.discountsMemberGetMember
      ) {
        this.discounts = 'memberGetMember'
      }
    },
    'form.useDiscountYearly' () {
      if (this.form.useDiscountYearly && this.discountsYearly) {
        this.discounts = 'yearly'
      }
    },
    'form.useDiscountFirstCase' () {
      if (this.form.useDiscountFirstCase && this.useDiscountFirstCase) {
        this.discounts = 'firstCase'
      }
    },
    shippingAddresses (val, oldVal) {
      if (val.length < oldVal.length) {
        if (
          !isAddressIncluded(
            val && val.length ? val : [],
            this.form.shippingClinicName
          )
        ) {
          this.shippingAddressIndex = 0
        }
      }
    },
    billingAddresses (val, oldVal) {
      if (val.length < oldVal.length) {
        if (
          !isAddressIncluded(
            val && val.length ? val : [],
            this.form.billingClinicName
          )
        ) {
          this.billingAddressIndex = 0
        }
      }
    },
    discounts (val, oldVal) {
      if (val !== oldVal) {
        this.form.useDiscountMemberGetMember = false
        this.form.useDiscountYearly = false
        this.form.useDiscountFirstCase = false
        if (this.discounts === 'yearly') {
          this.form.useDiscountYearly = true
        } else if (this.discounts === 'memberGetMember') {
          this.form.useDiscountMemberGetMember = true
        } else if (this.discounts === 'firstCase') {
          this.form.useDiscountFirstCase = true
        }
      }
    },
    'currentCase.id' (val) {
      this.caseId = val
    },
    currentCase (val) {
      if (!this.isLoaded) {
        this.loadPrefilledData()
        this.isLoaded = true
      }
    },
    billingAddressIndex (addressIndex) {
      const address = this.billingAddresses[addressIndex]
      if (!address && this.billingAddresses !== 0) {
        this.billingAddressIndex = 0
      }
      if (address) {
        this.form = {
          ...this.form,
          billingClinicName: address.clinicName,
          billingStreetName1: address.streetName1,
          billingProvince: address.province,
          billingCountry: address.country,
          billingPostalCode: address.postalCode,
          billingCity: address.city,
          billingPhone: address.phone,
          billingEmail: address.email,
          billingCif: address.cif
        }
      }
    },
    shippingAddressIndex (addressIndex) {
      const address = this.shippingAddresses[addressIndex]
      if (!address && this.shippingAddresses !== 0) {
        this.shippingAddressIndex = 0
      }
      if (address) {
        this.form = {
          ...this.form,
          shippingClinicName: address.clinicName,
          shippingStreetName1: address.streetName1,
          shippingProvince: address.province,
          shippingCountry: address.country,
          shippingPostalCode: address.postalCode,
          shippingCity: address.city,
          shippingPhone: address.phone,
          shippingCif: address.cif
        }
      }
    },
    'form.otherBiteType': function (val) {
      this.form.biteType = val
    },
    'form.otherPatientDiagnostic': function (val) {
      const members = [
        'crowding',
        'diastemas',
        'posteriorcrossbite',
        'deepoverbite',
        'deephighlight',
        'bulge',
        'anteriorcrossbite',
        'openbite'
      ]
      this.form.patientDiagnostic = this.form.patientDiagnostic.filter(x =>
        members.includes(x)
      )
      this.form.patientDiagnostic.push(val)
      this.$refs.otherPatientDiagnostic.checked = true
    },
    'form.otherPatientConcern': function (val) {
      const members = ['crowding', 'diastemas', 'bulge', '', 'crossbite']
      this.form.patientConcerns = this.form.patientConcerns.filter(x =>
        members.includes(x)
      )
      this.form.patientConcerns.push(val)
      this.$refs.otherPatientConcern.checked = true
    },
    'form.otherGoal': function (val) {
      const members = ['align', 'closediastemas', 'fixcrossbite', 'improvebite']
      this.form.goals = this.form.goals.filter(x => members.includes(x))
      this.form.goals.push(val)
      this.$refs.otherGoal.checked = true
    },
    'form.otherOpenbiteSolution': function (val) {
      const members = ['anterosuperior', 'anteroinferior', 'posterosuperiores']
      this.form.openbiteSolution = this.form.openbiteSolution.filter(x =>
        members.includes(x)
      )
      this.form.openbiteSolution.push(val)
      this.$refs.otherOpenbiteSolution.checked = true
    },
    'form.otherDeepbiteSolution': function (val) {
      const members = [
        'anterosuperior',
        'anteroinferior',
        'posterosuperiores',
        'posteroinferiores',
        'plane'
      ]
      this.form.deepbiteSolution = this.form.deepbiteSolution.filter(x =>
        members.includes(x)
      )
      this.form.deepbiteSolution.push(val)
      this.$refs.otherDeepbiteSolution.checked = true
    }
  },
  components: {
    Fieldset,
    AddressForm,
    AddressCard
  }
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.radio-button-replacement {
  display: none !important;
}
.radio-button {
  display: inline;
}
.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  .dialog-footer {
    text-align: right;
  }
}
.custom-error {
  color: #f56c6c;
  font-size: 12px;
}
.custom-message {
  color: #f56c6c;
  font-size: 16px;
}
.intraoral-scanner-grid {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.xray-uploader {
  display: flex;
}
.el-radio {
  display: flex;
  margin-bottom: 8px;
}
.el-checkbox {
  margin-bottom: 8px;
}
.other-input {
  margin-left: 4px;
}
.radio-label {
  display: flex;
}
.other-input {
  .el-radio {
    .radio-label {
    }
  }
}
.column {
  display: flex;
  flex-direction: column;
}
.hidden {
  display: none;
}
.row {
  width: 100%;
}
.birthdate {
  width: 140px;
}
.patient-photo-upload {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 0 8px;
  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
  }
  &:hover {
    .cross {
      color: black;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
.el-icon-circle-plus {
  font-size: 40px;
  cursor: pointer;
}
.addresses {
  padding: 8px;
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
  max-width: 80vw;
  &.row {
    padding: 8px;
    display: flex;
    align-items: flex-end;
    overflow-x: auto;
    max-width: 80vw;
  }
  &.table {
    align-items: end;
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: auto auto;
    max-width: 80vw;
    padding: 8px;
    overflow-x: inherit;
  }
}
.address {
  .row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    font-size: 12px;
    color: #000000;
    font-weight: 300;
  }
}
.address {
  min-width: 300px;
  width: 300px;
  border-radius: 15px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: inline-block;
  margin: 0 12px 0 12px;
  padding: 16px;
  // &:hover,
  // &.selected-address {
  //   border: 1px solid black;
  //   cursor: pointer;
  // }
  .row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    font-size: 12px;
    color: #000000;
    font-weight: 300;
    .value {
      margin-left: 4px;
      font-weight: normal;
    }
    &.last {
      justify-content: flex-end;
      .update {
        font-size: 18px;
      }
      .delete {
        font-size: 18px;
        margin-right: 16px;
      }
    }
  }
}
.el-checkbox-group {
  display: flex;
  flex-direction: column;
}
.checkbox-group {
  display: flex;
  flex-direction: column;
}

.teeth {
  .teeth-row {
    display: flex;
    width: min-content;

    .quart {
      display: flex;
      .tooth {
        align-items: center;
        display: flex;
        margin: 0;
        margin-right: 8px;

        &.up {
          flex-direction: column;
        }

        .number {
          margin: -10px;
          font-weight: 500;
          font-size: 10px;
        }

        .checkbox {
          display: flex;

          &.up {
            flex-direction: column-reverse;
          }

          &.down {
            padding-top: 8px;
            flex-direction: column;
          }
        }
      }
    }

    .quart:first-of-type {
      border-right: 1px solid black;
      margin-right: 8px;
    }
  }
  .teeth-row:first-of-type {
    border-bottom: 1px solid black;
  }
}

// .teeth {
//   .teeth-row {
//     display: flex;
//     flex-direction: row;
//     margin-bottom: 8px;
//     .el-checkbox {
//       margin-bottom: 4px;
//     }
//     .tooth {
//       align-items: center;
//       display: flex;
//       margin: 0;
//       margin-right: 8px;
//       &.up {
//         flex-direction: column;
//       }
//       .number {
//         margin: -10px;
//         font-weight: 500;
//         font-size: 10px;
//       }
//       .checkbox {
//         display: flex;
//         &.up {
//           flex-direction: column-reverse;
//         }
//         &.down {
//           flex-direction: column;
//         }
//       }
//     }
//   }
// }
.lettered-group {
  display: flex;
  align-items: center;
  .radio-lettered {
    margin-right: 8px;
  }
}
.radio-lettered {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .letter {
    margin: 0;
    font-size: 16px;
    color: black;
  }
  &.selected {
    background-color: black;
    .letter {
      color: white;
    }
  }
}
.radio-label,
.checkbox-label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.uploader {
  font-size: 25px;
  padding: 4px 24px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.grid-uploader {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 24px;
}
.big-uploader {
  width: 208px;
  height: 104px;
  font-size: 25px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.big-uploader-text {
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 208px;
  height: 104px;
  font-size: 14px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  .text {
    padding: 16px;
  }
}
.margin {
  &-bottom {
    margin-bottom: 16px;
  }
  &-top {
    margin-top: 16px;
  }
  &-right {
    margin-right: 16px;
  }
  &-left {
    margin-left: 16px;
  }
}
.clinic-conditions {
  display: flex;
  flex-direction: column;
}
.selected {
  background-color: black !important;
  color: white !important;
}
.form-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .title {
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }
  .label {
    height: 21px;
    font-size: 18px;
    margin: 0 0 8px 0;
  }
  .help {
    font-size: 14px;
  }
  .select {
    width: 72px;
    height: 32px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #aaaaaa;
    background-color: #ffffff;
    &.address-type {
      margin-right: 16px;
    }
  }
  .discounts {
    .quote {
      font-size: 14px;
      font-style: italic;
      line-height: 1;
      padding-left: 36px;
    }
  }
  .step {
    display: flex;
    flex-direction: column;
    .wrapper {
      display: flex;
    }
    &.step-0 {
      width: 392px;
    }
    &.step-1 {
      width: 100%;
    }
    &.step-2 {
      width: 392px;
    }
    &.step-3 {
    }
    &.step-4 {
      width: 392px;
    }
    &.step-5 {
      width: 392px;
    }
    &.step-6 {
      width: 392px;
    }
    &.step-7 {
      width: 392px;
    }
    &.step-8 {
      width: 392px;
    }
    &.step-9 {
      width: 392px;
    }
    &.step-10 {
      width: 392px;
    }
    &.step-11 {
      width: 392px;
    }
    &.step-12 {
      width: 392px;
    }
    .warning {
      color: #c75359;
    }
    .label-secondary {
      margin: -8px 0 8px 0;
      font-size: 16px;
    }
    .cost {
      margin: 0;
      font-size: 14px;
      margin-top: -8px;
    }
    .derivation-custom {
      margin-top: 8px;
    }
  }
  .input {
    box-sizing: border-box;
    padding: 8px;
    margin: 0 0 16px 0;
    max-width: 392px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #aaaaaa;
    background-color: #ffffff;
    height: 32px;
    font-size: 14px;
    font-weight: 300;
    color: #616161;
    &.postal-code {
      width: 106px;
    }
  }
  .checkbox {
    border-radius: 10px;
  }
  .multiple-checkbox-label {
    height: 21px;
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 8px;
    color: #000000;
  }
  .checkbox-label {
    height: 21px;
    font-size: 18px;
    font-weight: 300;
    margin-left: 8px;
    color: #000000;
  }
  .upload {
    width: 72px;
    height: 32px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #aaaaaa;
    background-color: #ffffff;
    .image {
      width: 40px;
    }
  }
  .navigation {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
}
.image-name {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 8px;
}
@media (max-width: 768px) {
  .radio-button-replacement {
    display: flex !important;
    margin: 0 0 8px 0 !important;
  }
  .radio-button {
    display: none !important;
  }
  .form-container {
    padding: 8px;
    .form-wrapper {
      width: 100%;
      .form {
        .step {
          width: 100%;
          .el-checkbox,
          .el-radio {
            display: flex;
            .el-checkbox__label,
            .radio-label {
              white-space: break-spaces;
              &.other {
                white-space: initial;
              }
            }
          }
          .teeth {
            .teeth-row {
              flex-direction: column;
              margin-bottom: 24px;
              .quart {
                .tooth {
                  &.up {
                  }
                  .number {
                  }
                  .checkbox {
                    &.up {
                    }
                    &.down {
                    }
                  }
                }
              }
            }
          }
          .addresses {
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: initial;
            padding: 0;
            margin: 0;
            .address {
              margin: 0 0 8px 0;
              padding: 0;
            }
            .add-address {
              align-self: center;
              margin-bottom: 16px;
            }
          }
          .patient-photo-upload {
            width: auto;
          }
        }
      }
    }
    .navigation {
      margin-top: 8px;
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      .back {
        width: max-content;
      }
      .next {
        width: max-content;
      }
    }
  }
  .grid-uploader {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    .item {
      display: flex;
      justify-self: center;
      margin-bottom: 8px;
    }
  }
}
</style>
