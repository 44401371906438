<template>
  <div class="header">
    <div class="row-1">
      <router-link v-if="['/', '/perfil'].includes($route.path)" to="/lobby">
        <img class="home-logo" src="../assets/home.svg" />
      </router-link>

      <router-link
        v-else-if="$route.path === '/preferencias-clinicas' && !this.$route.query.doctor"
        to="/perfil"
      >
        <img class="home-logo" src="../assets/arrow-back.svg" />
      </router-link>

      <router-link
        v-else-if="$route.path === '/preferencias-clinicas' && !!this.$route.query.doctor"
        to="/admin"
      >
        <img class="home-logo" src="../assets/arrow-back.svg" />
      </router-link>

      <router-link v-else to="/">
        <img class="home-logo" src="../assets/arrow-back.svg" />
      </router-link>

    <div class="advertisement">
      <div class="wrapper">
        <h2 class="title">¡NUEVO SCP!</h2>
        <Countdown />
      </div>
      <a href="https://doctores.quicksmile.es/nuevo-scp/" class="info" target="_blank" rel="noopener noreferrer">
        <el-button
        type="primary"
        class="button"
        icon="el-icon-plus"
        >info</el-button
      >
        </a>
    </div>


      <span>
        <img class="logo-text" src="../assets/qs2.svg" />
        <img class="logo-small" src="../assets/qs1.svg" />
      </span>

      <div class="bar" :class="{ small: !isFull }">
        <img v-if="isFull" class="search" src="../assets/search-outline.svg" />
        <div v-if="isFull" class="search-input-wrapper">
          <input
            v-model="searchInput"
            class="search-input"
            placeholder="Buscar"
          />
        </div>
        <div v-if="isFull" class="vertical-line" />

        <el-dropdown placement="bottom" @command="handleNotification">
          <div>
            <p v-if="haveNotifications" class="notifications-number-container">
              <span class="number">{{
                newNotifications.length > 9 ? '9+' : newNotifications.length
              }}</span>
            </p>
            <img v-else class="bell" src="../assets/bell-outline.svg" />
          </div>
          <el-dropdown-menu class="notifications" slot="dropdown">
            <p v-if="!notifications.length" class="notification-empty">
              Todavía no tienes notificaciones
            </p>
            <el-dropdown-item
              class=""
              v-for="(notification, index) in notifications"
              :key="notification.createdAt + index"
              :command="notification.case"
            >
              <div class="notification" :class="{ new: !notification.seen }">
                <div class="notification-row">
                  <img class="image" :src="notification.image" />
                  <p class="name">{{ notification.patientName }}</p>
                </div>
                <div class="notification-row">
                  <p class="text">{{ notification.description }}</p>
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div class="vertical-line" />
        <p class="name">{{ doctorName }}</p>

        <router-link class="clickable" to="/perfil">
          Mi Perfil
        </router-link>

        <p @click="logout" class="clickable">
          Salir
        </p>

        <!-- <el-dropdown @command="handleArrowCommand">
        <el-link type="primary" :underline="false" >
          <img class="arrow" src="../assets/arrow-ios-downward-outline.svg">
        </el-link>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      </div>
    </div>

    <div v-if="isFull" class="row-2">
      <div class="left">
        <div class="list-icons">
          <img
            v-if="showSwitchIcons && isListCard"
            class="switch-icon"
            src="../assets/list-menu.svg"
            @click="toggleList"
          />
          <img
            v-else-if="showSwitchIcons"
            class="switch-icon"
            src="../assets/menu.svg"
            @click="toggleList"
          />
        </div>
      </div>
      <div class="route-buttons">
        <router-link v-if="isAdmin" class="route-wrapper" to="/admin">
          <el-button type="primary" class="button"
            >Panel de administración</el-button
          >
        </router-link>

        <router-link class="route-wrapper" to="/nuevo-caso">
          <el-button type="primary" class="button">Nuevo caso</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { onLogout } from '@/vue-apollo'
import { mapState } from 'vuex'
import Countdown from './Countdown.vue'

export default {
  name: 'Header',
  components: {
    Countdown
  },
  props: {
    isListCard: Boolean,
    showSwitchIcons: {
      type: Boolean,
      default: false
    },
    doctorName: String,
    doctorImage: String,
    type: String,
    value: String,
    notifications: Array
  },
  data () {
    return {
      bigScreen: false
    }
  },
  computed: {
    ...mapState({
      role: state => state.me.role
    }),
    isAdmin () {
      return this.role === 'ADMIN'
    },
    isFull () {
      return this.type === 'full'
    },
    searchInput: {
      get () {
        return this.$store.state.search
      },
      set (val) {
        this.search(val)
      }
    },
    newNotifications () {
      return this.notifications
        ? [
            ...new Set(
              this.notifications
                .filter(notification => !notification.seen)
                .map(x => JSON.stringify(x))
            )
          ].map(x => JSON.parse(x))
        : false
    },
    haveNotifications () {
      return this.newNotifications ? !!this.newNotifications.length : false
    }
  },
  methods: {
    // Using arrow function here will make this undefined
    search: debounce(function (val) {
      this.$store.dispatch('search', { search: val })
    }, 1300),
    setFilter (filter) {
      this.$emit('set-filter', filter)
    },
    toggleList () {
      this.$emit('toggle-list-view')
    },
    handleArrowCommand (command) {
      if (command === 'logout') {
        onLogout(this.$apolloProvider.clients.defaultClient)
      }
    },
    logout () {
      onLogout(this.$apolloProvider.clients.defaultClient)
    },
    handleNotification (command) {
      this.$router.push('/caso/' + command)
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  width: 24px;
}

.el-dropdown-menu {
  padding: 0;
}
.notifications-number-container {
  user-select: none;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #c50000;
  display: flex;
  align-items: center;
  justify-content: center;

  .number {
    font-weight: bold;
    color: white;
  }
}

.notification-empty {
  font-family: 'Avenir Book';
  padding: 0 8px;
}

.el-dropdown-menu__item {
  font-family: 'Avenir Book' !important;
  border-bottom: 1px solid #e0e0e0;
}

.notifications {
  overflow-y: scroll;
  max-height: 320px;

  .notification {
    max-width: 400px;
    width: max-content;
    display: flex;
    padding: 10px 0;
    flex-direction: column;

    &.new {
      background-color: #f0f0f0;
      width: 100%;
    }

    .notification-row {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 32px;
      .text {
        margin: 0;
      }

      .name {
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}

.header {
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #aaaaaa;
  flex-direction: column;
  box-sizing: border-box;

  .row-1 {
    display: grid;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    grid-template-columns: min-content auto auto 465px;

    .advertisement {
      display: flex;
      flex-direction: row;

      .wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 16px;


      .title {
        margin: 0;
        text-align: center;
      }
      }
      .info {
        align-content: center;
      }

    }

    .logo-small {
      margin-right: 24px;
      width: 38px;
    }

    .logo-text {
      display: none;
      height: 58px;
      // display: none;
    }

    .home-logo {
      height: 38px;
      margin-right: 16px;
    }
  }

  .row-2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .route-button-wrapper {
      width: 100%;

      .button {
        width: 100%;
        margin-bottom: 16px;
      }
    }

    .left {
      .list-icons {
        align-items: center;
        display: none;

        .switch-icon {
          cursor: pointer;
          width: 16px;
          height: 16px;
        }
      }
      .filters {
        .address-filter {
          margin-left: 16px;
        }

        .button {
          cursor: pointer;
        }
      }

      .list-outline {
        width: 24px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .row-1 {
    .bar {
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.151661);
      padding: 8px;
      border-radius: 10px;
      display: flex;
      height: 32px;
      box-sizing: border-box;
      flex-direction: row;
      align-items: center;
      width: max-content;
      justify-self: flex-end;

      &.small {
        width: min-content;
      }

      .search {
        margin-right: 8px;
        width: 15px;
        min-width: 15px;
        background: transparent;
      }

      .bell {
        width: 18px;
        height: 18px;
        min-width: 18px;
        vertical-align: middle;
      }

      .arrow {
        height: 24px;
        margin: 0 0 0;
        vertical-align: middle;
      }

      .vertical-line {
        width: 0;
        height: 19px;
        border-left: 1px solid #e4e4e4;
        margin: 0 8px;
      }

      .search-input-wrapper {
        width: 100%;
        position: relative;
        display: flex;

        .search-input {
          // flex: 1;
          display: block;
          max-width: 360px;
          width: 100%;
          border: none;
          outline: none;
          min-width: 0;
          background: transparent;
        }
      }

      .clickable {
        font-size: 14px;
        text-decoration: none;
        border-bottom: 1px solid black;
        font-weight: bold;

        min-width: max-content;
        color: black;
        margin-right: 16px;
        cursor: pointer;
      }

      .name {
        display: none;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        min-width: max-content;
        margin-right: 16px;
      }
    }
  }
  .logo-text {
    width: 264px;
  }
}

.content {
  padding: 16px;
}

.icon {
  background-color: black;
  width: 32px;
  height: 32px;
  justify-self: flex-start;
  align-self: flex-end;
}

.search {
  height: 40px;
  width: 461px;
  justify-self: flex-start;
  align-self: center;
}
.info {
  display: flex;
  justify-self: flex-end;
  align-self: center;
}

.doctor {
  margin-right: 16px;
  align-self: center;
  font-size: 24px;
  font-weight: 600;
}

.picture {
  // width: 112px;
  height: 112px;
  border-radius: 50%;
}

.invi {
  border: none;
  background-color: transparent;
  height: min-content;
}

@media (max-width: 1280px) {
  .header {
    .row-1 {
      grid-template-columns: auto 1fr auto auto;
    }
  }
}

@media (max-width: 860px) {
  .header {
    .row-1 {
      grid-template-columns: auto auto auto 1fr;
      .bar {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .header {
    .row-1 {
      .logo-small {
        display: none;
      }

      .logo-text {
        display: flex;
      }

      .bar {
        .name {
          display: flex;
        }
      }
    }

    .row-2 {
      .left {
        display: flex;

        .list-icons {
          display: flex;
        }

        .filters {
          display: flex;
          margin-left: 16px;

          .button {
            margin: 0 8px 0;
            padding: 0;
          }
        }
      }

      .route-buttons {
        .route-wrapper {
          text-align: right;
          width: min-content;
          .button {
            margin-left: 16px;
            width: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    .row-1 {
      .advertisement {
        .info {
          display: none;
        }
      }

    }
    .row-2 {
      display: flex;
      flex-direction: column-reverse;

      .route-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .left {
        align-self: center;
        display: flex;
        margin: 8px;

        .list-icons {
          display: none;
        }

        .filters {
          display: flex;
          margin-left: 16px;
          flex-direction: column;
          .address-filter {
            margin-top: 8px;
            margin-left: 0;
          }
          .button {
            margin: 0 8px 0;
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .header {
    .row-1 {
      .logo-small {
        display: none;
      }
      .advertisement {
        display: none;
      }
    }
  }
}
</style>
