<template>
  <Fieldset label="Línea media" class="middleline">
    <el-radio v-model="middlelineInternal" label="show" :disabled="disabled">
      <span class="radio-label"
        >Mostrar la línea media resultante después de la simulación
        virtual</span
      >
    </el-radio>
    <el-radio v-model="middlelineInternal" label="keep" :disabled="disabled">
      <span class="radio-label">Mantener la línea media</span>
    </el-radio>
    <el-radio v-model="middlelineInternal" label="improve" :disabled="disabled">
      <span class="radio-label"
        >Mejorar la línea media (puede requerir IPR)</span
      >
    </el-radio>
  </Fieldset>
</template>
<script>
import Fieldset from '../Fieldset.vue'

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    },
    middleline: {
      required: false
    }
  },
  computed: {
    middlelineInternal: {
      get () {
        return this.middleline || ''
      },
      set (val) {
        this.$emit('middleline', val)
      }
    }
  },
  components: {
    Fieldset
  }
}
</script>

<style lang="scss">
.middleline {
  .radio-label,
  .checkbox-label {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
  }
}
</style>
