<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />

    <form class="form" v-on:submit.prevent="saveClinicalPreferences">
      <p v-if="clinicalPreferencesDoctorName " class="title">Preferencias Clínicas ({{ clinicalPreferencesDoctorName }})</p>
      <p v-else class="title">Preferencias Clínicas</p>

      <Fieldset
        class="preference"
        label="IPR en el plan de tratamiento QuickCheck"
      >
        <el-radio v-model="ipr" :label="true"
          :disabled="isAdminView"
        >Si</el-radio>
        <el-radio v-model="ipr" :label="false"
          :disabled="isAdminView"
        >No</el-radio>
      </Fieldset>

      <Fieldset class="preference" label="Retrasar etapa de inicio de IPR">
        <el-radio v-model="iprDelay" :label="false"
          :disabled="isAdminView"
        >Sin retraso</el-radio>
        <el-radio v-model="iprDelay" :label="true"
          :disabled="isAdminView"
        >
          Retrasar hasta la etapa
          <el-select class="select" v-model="iprDelayNumber" size="mini"
            :disabled="isAdminView"
          >
            <el-option
              v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
              :key="n"
              :label="n"
              :value="n"
              >{{ n }}</el-option
            >
          </el-select>
        </el-radio>
      </Fieldset>

      <Fieldset class="preference" label="Retrasar colocación de attachments">
        <el-radio v-model="attachmentsDelay" :label="false"
          :disabled="isAdminView"
          >Sin retraso</el-radio
        >
        <el-radio v-model="attachmentsDelay" :label="true"
          :disabled="isAdminView"
        >
          Retrasar hasta la etapa
          <el-select
            class="select"
            v-model="attachmentsDelayNumber"
            size="mini"
            :disabled="isAdminView"
          >
            <el-option
              v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
              :key="n"
              :label="n"
              :value="n"
              >{{ n }}</el-option
            >
          </el-select>
        </el-radio>
      </Fieldset>

      <Fieldset class="preference" label="Expasión por arcada">
        <el-radio v-model="expansionForArcade" :label="0"
          :disabled="isAdminView"
        >&lt;= 2</el-radio>
        <el-radio v-model="expansionForArcade" :label="1"
          :disabled="isAdminView"
        >2mm - 4mm</el-radio>
        <el-radio v-model="expansionForArcade" :label="2"
          :disabled="isAdminView"
        >4mm - 6mm</el-radio>
        <el-radio v-model="expansionForArcade" :label="3"
          :disabled="isAdminView"
        >6mm - 8mm</el-radio>
        <el-radio v-model="expansionForArcade" :label="4"
          :disabled="isAdminView"
        >&gt; 8mm</el-radio>
      </Fieldset>

      <Fieldset class="preference" label="Pónticos para espacios abiertos">
        <div class="response">
          <p>
            Los pónticos de tamaño completo se colocan automaticamente en
            espacios anteriores mayores de 4mm y en espacios posterios mayores
            de 4mm. Si los dientes adyacentes se mueven (por ejemplo, en
            tratamientos de extracción), entonces se colocan pónticos de la
            mitad de tamaño. Los pónticos escalables de colocan automáticamente.
          </p>
          <p>
            En cualquier otro caso, por favor especifique uno o ambos de los
            siguientes:
          </p>
          <el-checkbox-group class="column" v-model="pontics"
          :disabled="isAdminView"
          >
            <el-checkbox :label="0"
              >No hay pónticos ni caso de extracción</el-checkbox
            >
            <el-checkbox :label="1"
              >Sin pónticos en espacios posteriores</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </Fieldset>

      <Fieldset class="preference" label="Expasión de la arcada">
        <el-radio v-model="expansionOfArcade" :label="0" class="radio"
          :disabled="isAdminView"
        >
          <span class="radio-label"
            >Aumentar el ancho de arcada entre caninos, premolares y
            molares</span
          >
        </el-radio>
        <el-radio v-model="expansionOfArcade" :label="1" class="radio"
          :disabled="isAdminView"
        >
          <span class="radio-label"
            >Aumentar el ancho de arcada solamente entre caninos y
            premolares</span
          >
        </el-radio>
        <el-radio v-model="expansionOfArcade" :label="2" class="radio"
          :disabled="isAdminView"
        >
          <span class="radio-label"
            >Aumentar el ancho de arcada entre caninos, premolares y
            molares</span
          >
        </el-radio>
        <el-radio v-model="expansionOfArcade" :label="3" class="radio"
          :disabled="isAdminView"
        >
          <span class="radio-label"
            >Crear y mantener formas de arcada, pero no expandirla</span
          >
        </el-radio>
      </Fieldset>

      <Fieldset
        class="preference"
        label="Nivelación de diente de arcada superior"
      >
        <el-radio v-model="arcadeLeveling" :label="0" class="radio"
          :disabled="isAdminView"
          ><span>Nivelar bordes incisales</span></el-radio
        >
        <el-radio v-model="arcadeLeveling" :label="1" class="radio"
          :disabled="isAdminView"
          ><span>Nivelar laterales con centrales</span></el-radio
        >
        <el-radio v-model="arcadeLeveling" :label="2" class="radio"
          :disabled="isAdminView"
          ><span>Laterales 0,5mm más gingivales que centrales</span></el-radio
        >
        <el-radio v-model="arcadeLeveling" :label="3" class="radio"
          :disabled="isAdminView"
          ><span>Nivelar márgenes gingivales</span></el-radio
        >
      </Fieldset>

      <Fieldset class="preference" label="Etapa de inicio de Precision Cuts">
        <el-select class="select" v-model="precisionCuts" size="mini" :disabled="isAdminView"
        >
          <el-option
            v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="n"
            :label="n"
            :value="n"
            >{{ n }}</el-option
          >
        </el-select>
      </Fieldset>

      <Fieldset class="preference" label="Notas">
        <el-input
          class="textarea"
          type="textarea"
          placeholder="Notas"
          v-model="notes"
          resize="none"
          rows="3"
          :disabled="isAdminView"
        />
      </Fieldset>

      <div v-if="!isAdminView" class="buttons">
        <router-link class="wrapper-button" to="/perfil">
          <el-button class="button left" type="secondary">Cancelar</el-button>
        </router-link>
        <el-button class="button" type="primary" native-type="submit"
          >Guardar</el-button
        >
      </div>
    </form>

    <Loading :active="loading" />

    <Footer class="footer-wrapper" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Fieldset from '@/components/Fieldset'
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import Footer from '@/components/Footer'

export default {
  name: 'ClinicalPreferences',
  components: {
    Header,
    Fieldset,
    Loading,
    Footer
  },
  data () {
    return {
      searchValue: '',
      newDoctorImage: '',
      ipr: false,
      iprDelay: false,
      iprDelayNumber: 2,
      attachmentsDelay: false,
      attachmentsDelayNumber: 2,
      expansionForArcade: 0,
      pontics: [],
      expansionOfArcade: 0,
      arcadeLeveling: 0,
      precisionCuts: 2,
      notes: '',
      isAdminView: !!this.$route.query.doctor
    }
  },
  beforeMount () {
    this.$store.dispatch('fetchClinicalPreferences', { username: this.$route.query.doctor })
  },
  beforeUnmount() {
    this.$store.commit('cleanClinicalPreferences')
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications,
      clinicalPreferences: state => state.me.clinicalPreferences,
      loading: state => state.loading,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      clinicalPreferencesUpdated: state => state.clinicalPreferencesUpdated,
      clinicalPreferencesDoctorName: state => state.clinicalPreferencesDoctorName 
    })
  },
  watch: {
    clinicalPreferencesUpdated (val) {
      if (val) {
        this.triggerSuccessNotification()
        this.$store.commit('clearClinicalPreferencesUpdated')
      }
    },
    clinicalPreferences: function (newVal) {
      if (this.clinicalPreferences) {
        this.ipr = this.clinicalPreferences.ipr
        this.iprDelay = !!this.clinicalPreferences.iprDelay
        this.iprDelayNumber = this.clinicalPreferences.iprDelay || 0
        this.attachmentsDelay = !!this.clinicalPreferences.attachmentsDelay
        this.attachmentsDelayNumber =
          this.clinicalPreferences.attachmentsDelay || 0
        this.expansionForArcade = this.clinicalPreferences.expansionForArcade
        this.pontics = this.clinicalPreferences.pontics
        this.expansionOfArcade = this.clinicalPreferences.expansionOfArcade
        this.arcadeLeveling = this.clinicalPreferences.arcadeLeveling
        this.precisionCuts = this.clinicalPreferences.precisionCuts
        this.notes = this.clinicalPreferences.notes
      }
    }
  },
  methods: {
    saveClinicalPreferences () {
      this.$store.dispatch('saveClinicalPreferences', {
        ipr: this.ipr,
        iprDelay: this.iprDelay ? this.iprDelayNumber : 0,
        attachmentsDelay: this.attachmentsDelay
          ? this.attachmentsDelayNumber
          : 0,
        expansionForArcade: this.expansionForArcade,
        pontics: this.pontics,
        expansionOfArcade: this.expansionOfArcade,
        arcadeLeveling: this.arcadeLeveling,
        precisionCuts: this.precisionCuts,
        notes: this.notes,
        onDone: this.onDone
      })
    },
    triggerSuccessNotification () {
      this.$notify({
        title: 'Éxito',
        message: 'Preferencias clínicas actualizadas',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-radio {
  margin: 8px 0 0 0;
}
.select {
  width: 60px;
}
.column {
  display: flex;
  flex-direction: column;
}

.textarea {
  max-width: 800px;
}

.container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: max-content;

  .form {
    .preference {
      box-sizing: border-box;
      background-color: white;
      padding: 0px 0 16px 32px;
      margin: 24px 0;

      .bold {
        font-weight: 800;
      }

      .response {
        padding: 0 0 0 30px;
      }
    }

    .title {
      height: 28px;
      padding: 0 32px;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
    }

    .buttons {
      text-align: right;
      margin-right: 24px;
      margin-bottom: 24px;

      .left {
        margin-right: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .el-radio {
    white-space: normal;
  }
  .container {
    .form {
      .title {
        padding: 0 0 0 12px;
      }

      .preference {
        margin: 24px 0 24px 0;
        padding: 12px 12px;

        .response {
          padding: 0;
        }

        .radio {
          display: flex;
          .radio-label {
            white-space: break-spaces;
          }
        }
      }

      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        margin: 16px 0 12px 0;
        padding: 0 12px;

        .wrapper-button {
          width: auto;
        }

        .button {
          width: 100%;
        }
      }
    }

    // .footer-wrapper {
    //   padding: 0 24px 24px 24px;
    // }
  }
}
</style>
