<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />
    <RefinementForm
      v-if="doctorName"
      :refinement="refinement"
      :membershipNumber="membershipNumber"
    />
    <Loading :active="loading" />

    <Footer class="footer-wrapper" />
    <Loading :active="loading" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import RefinementForm from '@/components/RefinementForm.vue'
import Loading from '@/components/Loading.vue'
import Footer from '@/components/Footer.vue'
import { mapState } from 'vuex'

export default {
  name: 'NewCase',
  components: {
    Header,
    Loading,
    Footer,
    RefinementForm
  },
  mounted () {
    this.$store.dispatch('fetchRefinement', { caseId: this.id })
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      currentCase: state => state.currentCase,
      membershipNumber: state => state.me.username,
      loading: state => state.isLoading
    }),
    refinement () {
      const refinementId = this.$route.query.refinement_id
      const refinement = refinementId
        ? this.currentCase.refinements.find(x => x.id === refinementId)
        : {}

      return refinement
    }
  },
  data () {
    return {
      id: this.$route.params.id
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-rows: 160px 1fr;
  min-height: 100vh;

  // .footer-wrapper {
  //   padding: 0 24px 24px 24px;
  // }
}
</style>
