<template>
  <div class="login">
    <div class="top">
      <el-link
        class="ln"
        type="primary"
        :underlined="true"
        @click="() => $router.push('/registro')"
        >Registro</el-link
      >
    </div>

    <div class="content">
      <img class="logo" src="../assets/qs1.svg" />
      <h2 class="title">Bienvenido de nuevo</h2>

      <ApolloMutation
        class="wrapper"
        :mutation="require('../graphql/Login.gql')"
        :variables="{
          login: this.login.toLowerCase(),
          password: this.password
        }"
        :update="updateToken"
      >
        <template v-slot="{ mutate, error }">
          <form class="form" v-on:submit.prevent="formValid && mutate()">
            <el-input
              class="field"
              v-model="login"
              placeholder="Correo electrónico"
              id="email"
            />
            <el-input
              class="field"
              type="password"
              v-model="password"
              placeholder="Contraseña"
              id="pass"
            />
            <el-link
              class="password-recovery"
              @click="$router.push({ name: 'PasswordRecovery' })"
              >He olvidado la contraseña</el-link
            >
            <p v-if="error" class="error">
              La contraseña introducida no es correcta
            </p>
            <el-button class="send" type="primary" native-type="submit"
              >Entrar</el-button
            >
          </form>
        </template>
      </ApolloMutation>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
export default {
  name: 'Login',
  data () {
    return {
      login: '',
      password: '',
      submited: false
    }
  },
  components: {
    Footer
  },
  computed: {
    formValid () {
      return true
    },
    formSubmited () {
      return this.submited
    }
  },
  methods: {
    updateToken (store, response) {
      // pass token and firebaseToken
      this.$store.dispatch('login', response.data.signin)
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  color: #f56c6c;
  margin: 0 0 16px 0;
}

.login {
  box-sizing: border-box;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  // padding: 24px;

  .top {
    width: fit-content;
    justify-self: flex-end;
    padding: 24px;

    .ln {
      text-decoration: none;
      color: black;
      font-size: 16px;
    }
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      height: 170px;
    }

    .title {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
    }

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 288px;
        align-self: center;
        justify-self: center;

        .field {
          margin-bottom: 16px;

          &:last-of-type {
            margin-bottom: 8px;
          }
        }

        .password-recovery {
          width: max-content;
          margin-bottom: 16px;
          display: block;
          color: black;
          font-size: 14px;
        }

        .send {
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
