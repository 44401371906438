<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />

    <CaseFormV2
      v-if="!currentCase.patientName || currentCase.v2"
      :currentCase="currentCase"
      :shippingAddresses="shippingAddresses"
      :billingAddresses="billingAddresses"
      :membershipNumber="membershipNumber"
      v-on:delete-address="id => deleteAddress(id)"
      :discountsYearly="discountsYearly"
      :discountsMemberGetMember="discountsMemberGetMember"
      :schoolRole="schoolRole"
    />
    <CaseForm
      v-else
      :currentCase="currentCase"
      :shippingAddresses="shippingAddresses"
      :billingAddresses="billingAddresses"
      :membershipNumber="membershipNumber"
      v-on:delete-address="id => deleteAddress(id)"
      :discountsYearly="discountsYearly"
      :discountsMemberGetMember="discountsMemberGetMember"
      :discountFirstCase="discountFirstCase"
      :schoolRole="schoolRole"
    />
    <Loading :active="loading" />

    <Footer class="footer-wrapper" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Header from '@/components/Header'
import CaseForm from '@/components/CaseForm.vue'
import CaseFormV2 from '@/components/CaseFormV2.vue'
import Loading from '@/components/Loading.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'NewCase',
  components: {
    Header,
    CaseForm,
    CaseFormV2,
    Loading,
    Footer
  },
  beforeMount () {
    this.$store.commit('clearCurrentCase')
  },
  mounted () {
    this.$store.dispatch('fetchNewCaseViewInformation', {
      caseId: this.$route.query.case_id
    })
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      currentCase: state => state.currentCase,
      membershipNumber: state => state.me.username,
      loading: state => state.isLoading,
      discountsMemberGetMember: state => state.me.discountsMemberGetMember,
      discountsYearly: state => state.me.discountsYearly,
      discountFirstCase: state => state.me.discountFirstCase,
      schoolRole: state => state.me.schoolRole
    }),
    ...mapGetters({
      billingAddresses: 'getBillingAddresses',
      shippingAddresses: 'getShippingAddresses'
    })
  },
  methods: {
    async deleteAddress (id) {
      this.$store.dispatch('deleteAddress', { id })
    },
  },
  data () {
    return {
      searchValue: ''
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-rows: 160px 1fr auto;
  min-height: 100vh;

  // .footer-wrapper {
  //   padding: 0 24px 24px 24px;
  // }
}
</style>
