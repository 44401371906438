<template>
  <div class="summary">
    <ModalAskForUpdateStatus
      v-if="askForUpdateToReviewStatus"
      @close="closeModal"
      @update-status="updateStatus"
      :elementToReview="askForUpdateToReviewStatus"
    />

    <el-form
      :model="form"
      :rules="rules"
      class="form"
      ref="form"
      :hide-required-asterisk="true"
    >
      <button :style="{ display: 'none' }" type="submit" ref="submit">
        submit
      </button>
      <div v-if="isAdmin">
        <el-button type="primary" @click="updateCase"
          >Actualizar caso</el-button
        >
      </div>

      <div class="fields">
        <Fieldset class="fieldset" v-if="isAdmin" label="Datos a rellenar">
          <el-switch
            class="full-switch"
            v-model="form.full"
            active-color="black"
            inactive-color="#808080"
            active-text="Completo"
            inactive-text="Incompleto"
          >
          </el-switch>

          <el-form-item
            label
            class="margin-bottom"
            ref="shippingTracking"
            prop="shippingTracking"
          >
            <el-input
              v-model.trim="form.shippingTracking"
              placeholder="Tracking de envío"
            />
          </el-form-item>

          <el-form-item
            label
            class="margin-bottom"
            ref="reference"
            prop="reference"
          >
            <el-input
              v-model.trim="form.reference"
              placeholder="Referencia del caso"
            />
          </el-form-item>

          <el-form-item
            label
            class="margin-bottom"
            ref="invoiceUrl"
            prop="invoiceUrl"
          >
            <el-input
              v-model.trim="form.invoiceUrl"
              placeholder="Url de la factura"
            />
          </el-form-item>

          <span
            v-for="(vto, index) of form.vtoHistory"
            :key="JSON.stringify(vto.date)"
            class="quickcheck-input"
          >
            <el-form-item label class="margin-bottom">
              <el-input v-model.trim="vto.vto" placeholder :disabled="true" />
            </el-form-item>
            <i class="el-icon-close" @click="deleteVto(index)"></i>
          </span>

          <el-form-item label class="margin-bottom">
            <el-input v-model.trim="newVto" placeholder="Nuevo vto" />
          </el-form-item>

          <span
            v-for="(quickcheck, index) of form.quickcheckHistory"
            :key="JSON.stringify(quickcheck.date)"
            class="quickcheck-input"
          >
            <el-form-item label class="margin-bottom">
              <el-input
                v-model.trim="quickcheck.quickcheck"
                placeholder
                :disabled="true"
              />
            </el-form-item>
            <i class="el-icon-close" @click="deleteQuickcheck(index)"></i>
          </span>

          <el-form-item label class="margin-bottom">
            <el-input
              v-model.trim="newQuickcheck"
              placeholder="Nuevo quickCheck"
            />
          </el-form-item>

          <el-form-item
            label
            class="margin-bottom"
            ref="attachments"
            prop="attachments"
          >
            <p class="label">Plantilla de attachments</p>
            <input
              :style="{}"
              ref="patientPhoto"
              v-on:change="e => onUploadImage(e, 'attachments')"
              type="file"
            />
            <el-input
              v-model.trim="form.attachments"
              placeholder="Plantilla de attachments"
              :disabled="true"
            />
          </el-form-item>

          <el-form-item label class="margin-bottom" ref="doctor" prop="doctor">
            <el-input v-model.trim="form.doctor" placeholder="Doctor id" />
          </el-form-item>
        </Fieldset>

        <Fieldset class="fieldset" label="Datos del paciente">
          <PatientName
            class="margin-bottom"
            v-model="form.patientName"
            @blur="writePatientName"
            :disabled="!isAdmin"
          />

          <PatientSurname
            class="margin-bottom"
            v-model="form.patientSurname"
            @blur="writePatientName"
            :disabled="!isAdmin"
          />

          <PatientGender
            class="margin-bottom"
            v-model="form.patientGender"
            :disabled="!isAdmin"
          />

          <p class="label">Fecha de nacimiento</p>
          <el-form-item label class="margin-bottom" prop="patientBirthdate">
            <el-input
              :disabled="!isAdmin"
              class
              v-model="form.patientBirthdate"
              placeholder="Selecciona una fecha"
            ></el-input>
          </el-form-item>

          <PatientPhoto
            v-model="form.patientPhoto"
            :membershipNumber="membershipNumber"
            @clearValidationError="validatePatientPhoto"
            :disabled="!isAdmin"
          />
        </Fieldset>

        <LoadPhotographies
          :membershipNumber="membershipNumber"
          :lateralFacialImage="form.lateralFacialImage"
          @lateralFacialImage="e => (form.lateralFacialImage = e)"
          :frontalFacialImage="form.frontalFacialImage"
          @frontalFacialImage="e => (form.frontalFacialImage = e)"
          :smileFacialImage="form.smileFacialImage"
          @smileFacialImage="e => (form.smileFacialImage = e)"
          :upperOcclusalImage="form.upperOcclusalImage"
          @upperOcclusalImage="e => (form.upperOcclusalImage = e)"
          :lowerOcclusalImage="form.lowerOcclusalImage"
          @lowerOcclusalImage="e => (form.lowerOcclusalImage = e)"
          :rightIntraoralImage="form.rightIntraoralImage"
          @rightIntraoralImage="e => (form.rightIntraoralImage = e)"
          :centralIntraoralImage="form.centralIntraoralImage"
          @centralIntraoralImage="e => (form.centralIntraoralImage = e)"
          :leftIntraoralImage="form.leftIntraoralImage"
          @leftIntraoralImage="e => (form.leftIntraoralImage = e)"
        />

        <Fieldset v-if="isAdmin" label="Cargar radiografías">
          <input
            :style="{ display: 'none' }"
            ref="xray1Image"
            v-on:change="e => onUploadImage(e, 'xray1Image')"
            type="file"
          />
          <input
            :style="{ display: 'none' }"
            ref="xray2Image"
            v-on:change="e => onUploadImage(e, 'xray2Image')"
            type="file"
          />

          <div class="grid-uploader">
            <div class="item">
              <img
                src="../assets/x_ray1.jpg"
                v-if="!form.xray1Image"
                @click="() => triggerUpload('xray1Image')"
                ref="xray1Icon"
                class="big-uploader"
              />
              <img
                v-show="form.xray1Image"
                :style="{
                  width: '208px',
                  height: '104px',
                  cursor: 'pointer'
                }"
                ref="xray1ImageImage"
                @click="() => triggerUpload('xray1Image')"
                :src="form.xray1Image"
              />
              <el-form-item label class prop="xray1Image">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.xray1Image"
                  ref="xray1ImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/x_ray2.jpg"
                v-if="!form.xray2Image"
                @click="() => triggerUpload('xray2Image')"
                ref="xray1Icon"
                class="big-uploader"
              />
              <img
                v-show="form.xray2Image"
                :style="{
                  width: '208px',
                  height: '104px',
                  cursor: 'pointer'
                }"
                ref="xray2ImageImage"
                @click="() => triggerUpload('xray2Image')"
                :src="form.xray2Image"
              />
              <el-form-item label class prop="xray2Image">
                <el-input
                  :style="{ display: 'none' }"
                  v-model="form.xray2Image"
                  ref="xray2ImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>
          </div>
        </Fieldset>

        <Fieldset class="fieldset fieldset-address" label="Dirección de envío">
          <AddressCard
            type="shipping"
            v-if="form.shippingClinicName"
            class="card"
            :clinicName="form.shippingClinicName"
            :streetName="form.shippingStreetName1"
            :city="form.shippingCity"
            :country="form.shippingCountry"
            :province="form.shippingProvince"
            :postalCode="form.shippingPostalCode"
            :phone="form.shippingPhone"
            :editable="false"
          />
        </Fieldset>

        <Fieldset
          class="fieldset fieldset-address"
          label="Dirección de facturación"
        >
          <AddressCard
            v-if="form.billingClinicName"
            type="billing"
            class="card"
            :clinicName="form.billingClinicName"
            :streetName="form.billingStreetName1"
            :city="form.billingCity"
            :province="form.billingProvince"
            :country="form.billingCountry"
            :postalCode="form.billingPostalCode"
            :phone="form.billingPhone"
            :cif="form.billingCif"
            :email="form.billingEmail"
            :editable="false"
          />
        </Fieldset>

        <RegistryTypeSelection
          :disabled="!isAdmin"
          :typeOfImpressions="form.typeOfImpressions"
          @typeOfImpressions="e => (form.typeOfImpressions = e)"
          :upperIntraoralScanner="form.upperIntraoralScanner"
          @upperIntraoralScanner="e => (form.upperIntraoralScanner = e)"
          :lowerIntraoralScanner="form.lowerIntraoralScanner"
          @lowerIntraoralScanner="e => (form.lowerIntraoralScanner = e)"
          :occlusalIntraoralScanner="form.occlusalIntraoralScanner"
          @occlusalIntraoralScanner="e => (form.occlusalIntraoralScanner = e)"
          :lowerIntraoralScannerName="form.lowerIntraoralScannerName"
          @lowerIntraoralScannerName="e => (form.lowerIntraoralScannerName = e)"
          :upperIntraoralScannerName="form.upperIntraoralScannerName"
          @upperIntraoralScannerName="e => (form.upperIntraoralScannerName = e)"
          :occlusalIntraoralScannerName="form.occlusalIntraoralScannerName"
          @occlusalIntraoralScannerName="
            e => (form.occlusalIntraoralScannerName = e)
          "
          :typeOfImpressionsAddress="form.typeOfImpressionsAddress"
          @typeOfImpressionsAddress="e => (form.typeOfImpressionsAddress = e)"
        />

        <Fieldset class="fieldset" label="Tipo de mordida (relación molar)">
          <el-form-item label prop="otherBiteType">
            <div class="column">
              <el-radio
                label="clase1"
                v-model="form.biteType"
                :disabled="!isAdmin"
              >
                <span class="radio-label">Clase I</span>
              </el-radio>
              <el-radio
                label="clase2"
                v-model="form.biteType"
                :disabled="!isAdmin"
              >
                <span class="radio-label">Clase II</span>
              </el-radio>
              <el-radio
                label="clase3"
                v-model="form.biteType"
                :disabled="!isAdmin"
              >
                <span class="radio-label">Clase III</span>
              </el-radio>
              <el-radio
                :label="form.otherBiteType"
                v-model="form.biteType"
                :disabled="!isAdmin"
              >
                <span class="radio-label">
                  <span class="radio-label">
                    <span>Otro</span>
                    <el-input
                      type="textarea"
                      :disabled="!isAdmin"
                      resize="none"
                      :rows="2"
                      class="other-input"
                      v-model="form.otherBiteType"
                      :maxlength="140"
                    />
                  </span>
                </span>
              </el-radio>
            </div>
          </el-form-item>
        </Fieldset>

        <Fieldset class="fieldset" label="Diagnósico del paciente">
          <el-checkbox-group
            class="checkbox-group"
            v-model="form.patientDiagnostic"
            :disabled="!isAdmin"
          >
            <el-checkbox label="crowding">
              <span class="radio-label">Apiñamiento</span>
            </el-checkbox>
            <el-checkbox label="diastemas">
              <span class="radio-label">Diastemas</span>
            </el-checkbox>
            <el-checkbox label="bulge">
              <span class="radio-label">Protrusión</span>
            </el-checkbox>
            <el-checkbox label="openbite">
              <span class="radio-label">Mordida abierta</span>
            </el-checkbox>
            <el-checkbox label="anteriorcrossbite">
              <span class="radio-label">Mordida cruzada anterior</span>
            </el-checkbox>
            <el-checkbox label="posteriorcrossbite">
              <span class="radio-label">Mordida cruzada posterior</span>
            </el-checkbox>
            <el-checkbox label="deephighlight">
              <span class="radio-label">Resalte profundo</span>
            </el-checkbox>
            <el-checkbox label="deepoverbite">
              <span class="radio-label">Mordida profunda</span>
            </el-checkbox>
            <el-checkbox :label="form.otherPatientDiagnostic">
              <span class="radio-label" ref="otherPatientDiagnostic">
                <span>Otro</span>
                <el-input
                  type="textarea"
                  resize="none"
                  :rows="2"
                  class="other-input"
                  v-model="form.otherPatientDiagnostic"
                  :maxlength="140"
                  :disabled="!isAdmin"
                />
              </span>
            </el-checkbox>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Inquietudes del paciente">
          <el-checkbox-group
            class="checkbox-group"
            v-model="form.patientConcerns"
            :disabled="!isAdmin"
          >
            <el-checkbox label="crowding">
              <span class="radio-label">Apiñamiento</span>
            </el-checkbox>
            <el-checkbox label="diastemas">
              <span class="radio-label">Diastemas</span>
            </el-checkbox>
            <el-checkbox label="bulge">
              <span class="radio-label">Protrusión</span>
            </el-checkbox>
            <el-checkbox label="crossbite">
              <span class="radio-label">Mordida cruzada</span>
            </el-checkbox>
            <el-checkbox :label="form.otherPatientConcern">
              <span class="radio-label" ref="otherPatientConcern">
                <span>Otro</span>
                <el-input
                  type="textarea"
                  resize="none"
                  :rows="2"
                  class="other-input"
                  v-model="form.otherPatientConcern"
                  :maxlength="140"
                  :disabled="!isAdmin"
                />
              </span>
            </el-checkbox>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Principales objetivos">
          <el-checkbox-group
            class="checkbox-group"
            v-model="form.goals"
            :disabled="!isAdmin"
          >
            <el-checkbox label="align">
              <span class="radio-label">Alinear</span>
            </el-checkbox>
            <el-checkbox label="closediastemas">
              <span class="radio-label">Cerrar diastemas</span>
            </el-checkbox>
            <el-checkbox label="improvebite">
              <span class="radio-label">Mejorar la mordida</span>
            </el-checkbox>
            <el-checkbox label="fixcrossbite">
              <span class="radio-label">Corregir mordida cruzada</span>
            </el-checkbox>
            <el-checkbox :label="form.otherGoal">
              <span class="radio-label" ref="otherGoal">
                <span>Otro</span>
                <el-input
                  type="textarea"
                  resize="none"
                  :rows="2"
                  class="other-input"
                  v-model="form.otherGoal"
                  :maxlength="140"
                  :disabled="!isAdmin"
                />
              </span>
            </el-checkbox>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Arcadas a corregir">
          <el-radio
            v-model="form.arcadeToTreat"
            label="both"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Ambas</span>
          </el-radio>
          <el-radio
            v-model="form.arcadeToTreat"
            label="higher"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Superior</span>
          </el-radio>
          <el-radio
            v-model="form.arcadeToTreat"
            label="lower"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Inferior</span>
          </el-radio>
        </Fieldset>

        <Fieldset class="fieldset" label="Ausencia de dientes">
          <el-radio
            :label="false"
            v-model="form.hasLackOfTeeth"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Todos los dientes presentes</span>
          </el-radio>
          <el-radio
            :label="true"
            v-model="form.hasLackOfTeeth"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Ausencia de dientes</span>
          </el-radio>

          <el-checkbox-group
            class="margin-top"
            v-model="form.lackOfTeeth"
            :disabled="!isAdmin"
          >
            <div class="teeth">
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'lackofteethleftup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasLackOfTeeth || !isAdmin"
                      :label="`1.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">1.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'lackofteethrightup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasLackOfTeeth || !isAdmin"
                      :label="`2.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">2.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'lackofteethleftdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasLackOfTeeth || !isAdmin"
                      :label="`4.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">4.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'lackofteethrightdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasLackOfTeeth || !isAdmin"
                      :label="`3.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">3.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Dientes temporales">
          <el-radio
            :label="false"
            v-model="form.hasTemporaryTeeth"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Sin dientes temporales</span>
          </el-radio>
          <el-radio
            :label="true"
            v-model="form.hasTemporaryTeeth"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Con dientes temporales</span>
          </el-radio>
          <el-checkbox-group
            class="margin-top"
            v-model="form.temporaryTeeth"
            :disabled="!isAdmin"
          >
            <div class="teeth">
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'temporaryteethleftup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasTemporaryTeeth || !isAdmin"
                      :label="`1.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">1.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'temporaryteethrightup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasTemporaryTeeth || !isAdmin"
                      :label="`2.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">2.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'tempraryteethleftdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasTemporaryTeeth || !isAdmin"
                      :label="`4.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">4.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>

                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'tempraryteethrightdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasTemporaryTeeth || !isAdmin"
                      :label="`3.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">3.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Dientes que no se van a mover">
          <el-radio
            :label="false"
            v-model="form.hasFixedTeeth"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Todos los dientes se van a mover</span>
          </el-radio>
          <el-radio
            :label="true"
            v-model="form.hasFixedTeeth"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Los siguientes dientes no se van a mover</span
            >
          </el-radio>

          <el-checkbox-group
            class="margin-top"
            v-model="form.fixedTeeth"
            :disabled="!isAdmin"
          >
            <div class="teeth">
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'fixedteethleftup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasFixedTeeth || !isAdmin"
                      :label="`1.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">1.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>

                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'fixedteethrightup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasFixedTeeth || !isAdmin"
                      :label="`2.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">2.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'fixedteethleftdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasFixedTeeth || !isAdmin"
                      :label="`4.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">4.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>

                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'fixedteethrightdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasFixedTeeth || !isAdmin"
                      :label="`3.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">3.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset
          class="fieldset"
          label="Dientes en los que no se pueden poner attachments"
        >
          <el-radio
            :label="false"
            v-model="form.hasAttachmentsRestriction"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Poner attachments en todos los dientes</span
            >
          </el-radio>
          <el-radio
            :label="true"
            v-model="form.hasAttachmentsRestriction"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >No poner atachments en los siguientes dientes</span
            >
          </el-radio>
          <el-checkbox-group
            class="margin-top"
            v-model="form.attachmentRestriction"
          >
            <div class="teeth">
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'attachmentrestrictionleftup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasAttachmentsRestriction || !isAdmin"
                      :label="`1.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">1.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'attachmentrestrictionrightup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasAttachmentsRestriction || !isAdmin"
                      :label="`2.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">2.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'attachmentrestrictionleftdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasAttachmentsRestriction || !isAdmin"
                      :label="`4.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">4.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'attachmentrestrictionrightdown'"
                    class="tooth down"
                  >
                    <el-checkbox
                      :disabled="!form.hasAttachmentsRestriction || !isAdmin"
                      :label="`3.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">3.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Tipo de perfil facial">
          <el-checkbox-group v-model="form.facialProfile" :disabled="!isAdmin">
            <el-checkbox label="0">
              <span class="radio-label" :disabled="!isAdmin">Mantener</span>
            </el-checkbox>
            <el-checkbox label="1">
              <span class="radio-label" :disabled="!isAdmin"
                >Mejorar la protrusión maxilar</span
              >
            </el-checkbox>
            <el-checkbox label="2">
              <span class="radio-label" :disabled="!isAdmin"
                >Mejorar la protrusión mandibular</span
              >
            </el-checkbox>
            <el-checkbox label="3">
              <span class="radio-label" :disabled="!isAdmin"
                >Mejorar la retraccion maxilar</span
              >
            </el-checkbox>
            <el-checkbox label="4">
              <span class="radio-label" :disabled="!isAdmin"
                >Mejorar la retraccion mandibular</span
              >
            </el-checkbox>
          </el-checkbox-group>
        </Fieldset>

        <SagitalRelationship
          class="margin-bottom"
          :rightSagitalRelationship="form.rightSagitalRelationship"
          @rightSagitalRelationship="e => (form.rightSagitalRelationship = e)"
          :leftSagitalRelationship="form.leftSagitalRelationship"
          @leftSagitalRelationship="e => (form.leftSagitalRelationship = e)"
          :disabled="!isAdmin"
        />

        <SagitalRelationshipSolution
          class="margin-top"
          :rightSagitalRelationshipSolution="
            form.rightSagitalRelationshipSolution
          "
          @rightSagitalRelationshipSolution="
            e => (form.rightSagitalRelationshipSolution = e)
          "
          :leftSagitalRelationshipSolution="
            form.leftSagitalRelationshipSolution
          "
          @leftSagitalRelationshipSolution="
            e => (form.leftSagitalRelationshipSolution = e)
          "
          :sagitalRelationshipSolutionExtra="
            form.sagitalRelationshipSolutionExtra
          "
          @sagitalRelationshipSolutionExtra="
            e => (form.sagitalRelationshipSolutionExtra = e)
          "
          :disabled="!isAdmin"
        />

        <Fieldset class="fieldset" label="Resalte">
          <el-radio label="show" v-model="form.standout" :disabled="!isAdmin">
            <span class="radio-label"
              >Mostrar el resalte resultante después de la simulación
              virtual</span
            >
          </el-radio>
          <el-radio label="keep" v-model="form.standout" :disabled="!isAdmin">
            <span class="radio-label">Mantener</span>
          </el-radio>
          <el-radio
            label="improve"
            v-model="form.standout"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Mejorar</span>
          </el-radio>
        </Fieldset>

        <Overbite
          class="fieldset"
          :overbite="form.overbite"
          @overbite="e => (form.overbite = e)"
          :openbiteSolution="form.openbiteSolution"
          @openbiteSolution="e => (form.openbiteSolution = e)"
          :otherOpenbiteSolution="form.otherOpenbiteSolution"
          @otherOpenbiteSolution="e => (form.otherOpenbiteSolution = e)"
          :anteriorLiftPlane="form.anteriorLiftPlane"
          @anteriorLiftPlane="e => (form.anteriorLiftPlane = e)"
          :deepbiteSolution="form.deepbiteSolution"
          @deepbiteSolution="e => (form.deepbiteSolution = e)"
          :otherDeepbiteSolution="form.otherDeepbiteSolution"
          @otherDeepbiteSolution="e => (form.otherDeepbiteSolution = e)"
          :disabled="!isAdmin"
          :useBigTitle="true"
        />

        <ImproveMaloclusions
          class="fieldset"
          :improveMaloclusions="form.improveMaloclusions"
          @improveMaloclusions="e => (form.improveMaloclusions = e)"
          :anteriorEdgeToEdgeOcclusion="form.anteriorEdgeToEdgeOcclusion"
          @anteriorEdgeToEdgeOcclusion="
            e => (form.anteriorEdgeToEdgeOcclusion = e)
          "
          :anteriorCrossbite="form.anteriorCrossbite"
          @anteriorCrossbite="e => (form.anteriorCrossbite = e)"
          :posteriorCrossbite="form.posteriorCrossbite"
          @posteriorCrossbite="e => (form.posteriorCrossbite = e)"
          :disabled="!isAdmin"
        />

        <Middleline
          :middleline="form.middleline"
          @middleline="e => (form.middleline = e)"
          :disabled="!isAdmin"
        />

        <MiddlelineSolution
          v-if="form.middleline === 'improve'"
          :lowerMiddlelineSolution="form.lowerMiddlelineSolution"
          @lowerMiddlelineSolution="e => (form.lowerMiddlelineSolution = e)"
          :upperMiddlelineSolution="form.upperMiddlelineSolution"
          @upperMiddlelineSolution="e => (form.upperMiddlelineSolution = e)"
          :disabled="!isAdmin"
        />

        <TeethSpace
          :teethSpace="form.teethSpaceNew"
          @teethSpace="e => (form.teethSpaceNew = e)"
          :hasTeethSpace="form.hasTeethSpace"
          @hasTeethSpace="e => (form.hasTeethSpace = e)"
          :disabled="!isAdmin"
        />

        <Fieldset class="fieldset" label="Extracción de piezas">
          <el-radio
            :label="false"
            v-model="form.hasTeethExtraction"
            :disabled="!isAdmin"
          >
            <span class="radio-label">Ninguna extracción</span>
          </el-radio>
          <el-radio
            :label="true"
            v-model="form.hasTeethExtraction"
            :disabled="!isAdmin"
          >
            <span class="radio-label"
              >Extracciones en los siguientes dientes</span
            >
          </el-radio>
          <el-checkbox-group class="margin-top" v-model="form.extractionTeeth">
            <div class="teeth">
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    :key="n + 'extractionteethleftup'"
                    class="tooth up"
                  >
                    <el-checkbox
                      :disabled="!form.hasTeethExtraction || !isAdmin"
                      :label="`1.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">1.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    class="tooth up"
                    :key="n + 'extractionteethrightup'"
                  >
                    <el-checkbox
                      :disabled="!form.hasTeethExtraction || !isAdmin"
                      :label="`2.${n}`"
                      class="checkbox up"
                      name
                    >
                      <span class="number">2.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div class="teeth-row">
                <div class="quart">
                  <div
                    v-for="n in 8"
                    class="tooth down"
                    :key="n + 'extractionteethleftdown'"
                  >
                    <el-checkbox
                      :disabled="!form.hasTeethExtraction || !isAdmin"
                      :label="`4.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">4.{{ 9 - n }}</span>
                    </el-checkbox>
                  </div>
                </div>
                <div class="quart">
                  <div
                    v-for="n in 8"
                    class="tooth down"
                    :key="n + 'extractionteethrightdown'"
                  >
                    <el-checkbox
                      :disabled="!form.hasTeethExtraction || !isAdmin"
                      :label="`3.${n}`"
                      class="checkbox down"
                      name
                    >
                      <span class="number">3.{{ n }}</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Resolver apiñamiento superior">
          <p class="multiple-checkbox-label">Expansión</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.uppercrowdingExpansion"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              label="0"
              border
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              label="1"
              border
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              label="2"
              border
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">Protruir sector anterior</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.uppercrowdingBulge"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              label="0"
              border
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              label="1"
              border
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              label="2"
              border
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">IPR sector anterior</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.uppercrowdingAnteriorIpr"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              label="0"
              border
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              label="1"
              border
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              label="2"
              border
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">IPR sector posterior derecha</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.uppercrowdingRightPosteriorIpr"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">IPR sector posterior izquierda</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.uppercrowdingLeftPosteriorIpr"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Resolver Apiñamiento inferior">
          <p class="multiple-checkbox-label">Expandir</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.lowercrowdingExpansion"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">Proclinar</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.lowercrowdingBulge"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">IPR - anterior</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.lowercrowdingAnteriorIpr"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">IPR - derecha posterior</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.lowercrowdingRightPosteriorIpr"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>

          <p class="multiple-checkbox-label">IPR - izquierda posterior</p>
          <el-radio-group
            class="margin-bottom"
            v-model="form.lowercrowdingLeftPosteriorIpr"
          >
            <el-radio-button class="radio-button" label="0" :disabled="!isAdmin"
              >Preferentemente</el-radio-button
            >
            <el-radio-button class="radio-button" label="1" :disabled="!isAdmin"
              >Según sea necesario</el-radio-button
            >
            <el-radio-button class="radio-button" label="2" :disabled="!isAdmin"
              >No</el-radio-button
            >

            <el-radio
              class="radio-button-replacement"
              border
              label="0"
              :disabled="!isAdmin"
              >Preferentemente</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="1"
              :disabled="!isAdmin"
              >Según sea necesario</el-radio
            >
            <el-radio
              class="radio-button-replacement"
              border
              label="2"
              :disabled="!isAdmin"
              >No</el-radio
            >
          </el-radio-group>
        </Fieldset>

        <Fieldset class="fieldset" label="Tratamiento">
          <el-radio
            label="q81"
            :disabled="!isAdmin"
            v-model="form.treatmentType"
          >
            <span v-if="currentCase.isPost15N" class="radio-label"
              >Q-12 (1 arcada) - 449€</span
            >
            <span v-else class="radio-label">Q-8 (1 arcada) - 449€</span>
          </el-radio>
          <el-radio
            label="q82"
            :disabled="!isAdmin"
            v-model="form.treatmentType"
          >
            <span v-if="currentCase.isPost15N" class="radio-label"
              >Q-12 (2 arcadas) - 649€</span
            >
            <span v-else class="radio-label">Q-8 (2 arcadas) - 649€</span>
          </el-radio>
          <el-radio
            label="lite1"
            :disabled="!isAdmin"
            v-model="form.treatmentType"
          >
            <span v-if="currentCase.isPost15N" class="radio-label"
              >Q-22 (1 arcada) - 745€</span
            >
            <span v-else class="radio-label">Q-Lite (1 arcada) - 745€</span>
          </el-radio>
          <el-radio
            label="lite2"
            :disabled="!isAdmin"
            v-model="form.treatmentType"
          >
            <span v-if="currentCase.isPost15N" class="radio-label"
              >Q-22 (2 arcadas) - 990€</span
            >
            <span v-else class="radio-label">Q-Lite (2 arcadas) - 990€</span>
          </el-radio>
          <el-radio
            label="full1"
            :disabled="!isAdmin"
            v-model="form.treatmentType"
          >
            <span class="radio-label">Q-Full (1 arcada) - 1.045€</span>
          </el-radio>
          <el-radio
            label="full2"
            :disabled="!isAdmin"
            v-model="form.treatmentType"
          >
            <span class="radio-label">Q-Full (2 arcadas) - 1.490€</span>
          </el-radio>
        </Fieldset>

        <Fieldset class="fieldset" label="Instrucciones especiales">
          <el-input
            v-model="form.specialInstructions"
            :disabled="!isAdmin"
            type="textarea"
            resize="none"
            :rows="8"
          />
        </Fieldset>
        <Fieldset class="fieldset" v-if="usedDiscount" label="Cupón gastado">
          <p class="discount-text">
            Para este caso se ha utilizado un cupón del tipo
            <span class="bold">{{ usedDiscount }}.</span>
          </p>
        </Fieldset>
      </div>
    </el-form>
    <div v-if="isAdmin">
      <el-button class="submit-update" type="primary" @click="updateCase"
        >Actualizar caso</el-button
      >
    </div>
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from './Fieldset'
import AddressCard from '@/components/AddressCard'
import PatientName from '@/components/form/PatientName'
import PatientSurname from '@/components/form/PatientSurname'
import PatientGender from '@/components/form/PatientGender'
import PatientPhoto from '@/components/form/PatientPhoto'
import RegistryTypeSelection from '@/components/form/RegistryTypeSelection'
import SagitalRelationship from '@/components/form/SagitalRelationship'
import SagitalRelationshipSolution from '@/components/form/SagitalRelationshipSolution'
import Overbite from '@/components/form/Overbite'
import ImproveMaloclusions from '@/components/form/ImproveMaloclusions'
import MiddlelineSolution from '@/components/form/MiddlelineSolution'
import TeethSpace from '@/components/form/TeethSpace'
import LoadPhotographies from '@/components/form/LoadPhotographies'
import Middleline from '@/components/form/Middleline'

import ModalAskForUpdateStatus from '@/components/ModalAskForUpdateStatus'
import { GENDERS, PDF_DOWNLOAD_URL } from '@/constants'

export default {
  name: 'CaseSummaryV2',
  props: {
    isAdmin: Boolean,
    membershipNumber: String,
    currentCase: Object,
    addresses: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const validateOtherBiteType = (rule, value, callback) => {
      if (
        !value &&
        this.form.biteType !== 'clase1' &&
        this.form.biteType !== 'clase2' &&
        this.form.biteType !== 'clase3'
      ) {
        callback(new Error('Introduce un tipo de mordida'))
      } else {
        callback()
      }
    }

    const validateLowerIntraoralScanner = (rule, value, callback) => {
      if (!value && this.form.typeOfImpressions === 'intraoralScanner') {
        callback(new Error('Suba el escáner intraoral inferior'))
      } else {
        callback()
      }
    }

    const validateUpperIntraoralScanner = (rule, value, callback) => {
      if (!value && this.form.typeOfImpressions === 'intraoralScanner') {
        callback(new Error('Suba el escáner intraoral derecho'))
      } else {
        callback()
      }
    }

    return {
      shippingSheetPdf: PDF_DOWNLOAD_URL.MEASURES,
      quickcheckHistory:
        this.currentCase && this.currentCase.quickcheckHistory
          ? this.currentCase.quickcheckHistory.map(x => ({
              date: x.date,
              quickcheck: x.quickcheck
            }))
          : [],
      vtoHistory:
        this.currentCase && this.currentCase.vtoHistory
          ? this.currentCase.vtoHistory.map(x => ({
              date: x.date,
              vto: x.vto
            }))
          : [],
      step: 0,
      genders: GENDERS,
      patientPhotoName: '',
      lowerIntraoralScannerName: '',
      upperIntraoralScannerName: '',
      occlusalIntraoralScannerName: '',
      newQuickcheck: '',
      newVto: '',
      form: {
        attachments: this.currentCase.attachments || '',
        quickcheckHistory:
          this.currentCase && this.currentCase.quickcheckHistory
            ? this.currentCase.quickcheckHistory.map(x => ({
                date: x.date,
                quickcheck: x.quickcheck
              }))
            : [],
        vtoHistory:
          this.currentCase && this.currentCase.vtoHistory
            ? this.currentCase.vtoHistory.map(x => ({
                date: x.date,
                vto: x.vto
              }))
            : [],
        reference: this.currentCase.reference || '',
        invoiceUrl: this.currentCase.invoiceUrl || '',
        shippingTracking: this.currentCase.shippingTracking || '',
        doctor:
          this.currentCase && this.currentCase.doctor
            ? this.currentCase.doctor.username
            : '',

        billingClinicName: this.currentCase.billingClinicName,
        billingStreetName1: this.currentCase.billingStreetName1,
        billingProvince: this.currentCase.billingProvince,
        billingCountry: this.currentCase.billingCountry,
        billingPostalCode: this.currentCase.billingPostalCode,
        billingCity: this.currentCase.billingCity,
        billingPhone: this.currentCase.billingPhone,
        billingCif: this.currentCase.billingCif,
        billingEmail: this.currentCase.billingEmail,
        shippingClinicName: this.currentCase.shippingClinicName,
        shippingStreetName1: this.currentCase.shippingStreetName1,
        shippingProvince: this.currentCase.shippingProvince,
        shippingCountry: this.currentCase.shippingCountry,
        shippingPostalCode: this.currentCase.shippingPostalCode,
        shippingCity: this.currentCase.shippingCity,
        shippingPhone: this.currentCase.shippingPhone,

        patientName: this.currentCase.patientName,
        patientSurname: this.currentCase.patientSurname,
        patientGender: this.currentCase.patientGender,
        patientBirthdate: this.currentCase.patientBirthdate,
        patientPhoto: this.currentCase.patientPhoto,
        biteType: this.currentCase.biteType,
        otherBiteType: ['clase1', 'clase2', 'clase3'].includes(
          this.currentCase.biteType
        )
          ? ''
          : this.currentCase.biteType,
        patientDiagnostic: this.currentCase
          ? this.currentCase.patientDiagnostic
          : '',
        otherPatientDiagnostic: this.currentCase.patientDiagnostic
          ? this.currentCase.patientDiagnostic.find(
              x =>
                ![
                  'crowding',
                  'diastemas',
                  'posteriorcrossbite',
                  'deepoverbite',
                  'deephighlight',
                  'bulge',
                  'anteriorcrossbite',
                  'openbite'
                ].includes(x)
            )
            ? this.currentCase.patientDiagnostic.find(
                x =>
                  ![
                    'crowding',
                    'diastemas',
                    'posteriorcrossbite',
                    'deepoverbite',
                    'deephighlight',
                    'bulge',
                    'anteriorcrossbite',
                    'openbite'
                  ].includes(x)
              )
            : ''
          : '',
        patientConcerns: this.currentCase.patientConcerns,
        otherPatientConcern: this.currentCase.patientConcerns
          ? this.currentCase.patientConcerns.find(
              x =>
                !['crowding', 'diastemas', 'bulge', '', 'crossbite'].includes(x)
            )
            ? this.currentCase.patientConcerns.find(
                x =>
                  !['crowding', 'diastemas', 'bulge', '', 'crossbite'].includes(
                    x
                  )
              )
            : ''
          : '',
        goals: this.currentCase.goals,
        otherGoal: this.currentCase.goals
          ? this.currentCase.goals.find(
              x =>
                ![
                  'align',
                  'closediastemas',
                  'fixcrossbite',
                  'improvebite'
                ].includes(x)
            )
            ? this.currentCase.goals.find(
                x =>
                  ![
                    'align',
                    'closediastemas',
                    'fixcrossbite',
                    'improvebite'
                  ].includes(x)
              )
            : ''
          : '',
        arcadeToTreat: this.currentCase.arcadeToTreat,
        hasLackOfTeeth: this.currentCase.hasLackOfTeeth,
        lackOfTeeth: this.currentCase.lackOfTeeth,
        hasTemporaryTeeth: this.currentCase.hasTemporaryTeeth,
        temporaryTeeth: this.currentCase.temporaryTeeth,
        hasFixedTeeth: this.currentCase.hasFixedTeeth,
        fixedTeeth: this.currentCase.fixedTeeth,
        hasAttachmentsRestriction: this.currentCase.hasAttachmentsRestriction,
        attachmentRestriction: this.currentCase.attachmentRestriction,
        facialProfile: this.currentCase.facialProfile,
        rightSagitalRelationship: this.currentCase.rightSagitalRelationship
          ? Array.isArray(this.currentCase.rightSagitalRelationship)
            ? this.currentCase.rightSagitalRelationship[0]
            : this.currentCase.rightSagitalRelationship
          : null,
        leftSagitalRelationship: this.currentCase.leftSagitalRelationship
          ? Array.isArray(this.currentCase.leftSagitalRelationship)
            ? this.currentCase.leftSagitalRelationship[0]
            : this.currentCase.leftSagitalRelationship
          : null,
        rightSagitalRelationshipSolution: this.currentCase
          .rightSagitalRelationshipSolution,
        leftSagitalRelationshipSolution: this.currentCase
          .leftSagitalRelationshipSolution,
        sagitalRelationshipSolutionExtra: this.currentCase
          .sagitalRelationshipSolutionExtra,
        standout: this.currentCase.standout,
        overbite: this.currentCase.overbite,
        improveMaloclusions: this.currentCase.improveMaloclusions,
        openbiteSolution: this.currentCase.openbiteSolution,
        improveMaloclusions: this.currentCase.improveMaloclusions,
        otherOpenbiteSolution: this.currentCase.openbiteSolution
          ? this.currentCase.openbiteSolution.find(
              x =>
                ![
                  'anterosuperior',
                  'anteroinferior',
                  'posterosuperiores'
                ].includes(x)
            )
            ? this.currentCase.openbiteSolution.find(
                x =>
                  ![
                    'anterosuperior',
                    'anteroinferior',
                    'posterosuperiores'
                  ].includes(x)
              )
            : ''
          : '',
        deepbiteSolution: this.currentCase.deepbiteSolution,
        otherDeepbiteSolution: this.currentCase.deepbiteSolution
          ? this.currentCase.deepbiteSolution.find(
              x =>
                ![
                  'anterosuperior',
                  'anteroinferior',
                  'posterosuperiores',
                  'posteroinferiores',
                  'plane'
                ].includes(x)
            )
            ? this.currentCase.deepbiteSolution.find(
                x =>
                  ![
                    'anterosuperior',
                    'anteroinferior',
                    'posterosuperiores',
                    'posteroinferiores',
                    'plane'
                  ].includes(x)
              )
            : ''
          : '',
        anteriorLiftPlane: this.currentCase.anteriorLiftPlane,
        anteriorCrossbite: this.currentCase.anteriorCrossbite,
        anteriorEdgeToEdgeOcclusion: this.currentCase
          .anteriorEdgeToEdgeOcclusion,
        posteriorCrossbite: this.currentCase.posteriorCrossbite,
        middleline: this.currentCase.middleline,
        middlelineSolution: this.currentCase.middlelineSolution,
        lowerMiddlelineSolution: this.currentCase.lowerMiddlelineSolution,
        upperMiddlelineSolution: this.currentCase.upperMiddlelineSolution,
        spacingCorrection: this.currentCase.spacingCorrection,
        hasTeethSpace: this.currentCase.hasTeethSpace,
        teethSpace: this.currentCase.teethSpace,
        teethSpaceNew: this.currentCase.teethSpaceNew,
        hasTeethExtraction: this.currentCase.hasTeethExtraction,
        extractionTeeth: this.currentCase.extractionTeeth,
        uppercrowdingExpansion: this.currentCase.uppercrowdingExpansion,
        uppercrowdingBulge: this.currentCase.uppercrowdingBulge,
        uppercrowdingAnteriorIpr: this.currentCase.uppercrowdingAnteriorIpr,
        uppercrowdingRightPosteriorIpr: this.currentCase
          .uppercrowdingRightPosteriorIpr,
        uppercrowdingLeftPosteriorIpr: this.currentCase
          .uppercrowdingLeftPosteriorIpr,
        lowercrowdingExpansion: this.currentCase.lowercrowdingExpansion,
        lowercrowdingBulge: this.currentCase.lowercrowdingBulge,
        lowercrowdingAnteriorIpr: this.currentCase.lowercrowdingAnteriorIpr,
        lowercrowdingRightPosteriorIpr: this.currentCase
          .lowercrowdingRightPosteriorIpr,
        lowercrowdingLeftPosteriorIpr: this.currentCase
          .lowercrowdingLeftPosteriorIpr,
        lateralFacialImage: this.currentCase.lateralFacialImage,
        frontalFacialImage: this.currentCase.frontalFacialImage,
        smileFacialImage: this.currentCase.smileFacialImage,
        upperOcclusalImage: this.currentCase.upperOcclusalImage,
        lowerOcclusalImage: this.currentCase.lowerOcclusalImage,
        rightIntraoralImage: this.currentCase.rightIntraoralImage,
        centralIntraoralImage: this.currentCase.centralIntraoralImage,
        leftIntraoralImage: this.currentCase.leftIntraoralImage,
        typeOfImpressions: this.currentCase.typeOfImpressions,
        typeOfImpressionsAddress: this.currentCase.typeOfImpressionsAddress,
        upperIntraoralScanner: this.currentCase.upperIntraoralScanner,
        lowerIntraoralScanner: this.currentCase.lowerIntraoralScanner,
        occlusalIntraoralScanner: this.currentCase.occlusalIntraoralScanner,
        xray1Image: this.currentCase.xray1Image,
        xray2Image: this.currentCase.xray2Image,
        treatmentType: this.currentCase.treatmentType,
        specialInstructions: this.currentCase.specialInstructions,
        useDiscountMemberGetMember: this.currentCase.useDiscountMemberGetMember,
        useDiscountYearly: this.currentCase.useDiscountYearly,
        useDiscountFirstCase: this.currentCase.useDiscountFirstCase,
        full: this.currentCase.full
      },
      rules: {
        // step 1
        patientName: [
          {
            required: true,
            message: 'Introduzca el nombre del paciente',
            trigger: 'blur'
          },
          { min: 2, message: 'Formato incorrecto', trigger: 'blur' },
          { max: 20, message: 'Formato incorrecto', trigger: 'blur' }
        ],
        patientSurname: [
          {
            required: true,
            message: 'Introduzca los apellidos del paciente',
            trigger: 'blur'
          },
          { min: 2, message: 'Formato incorrecto', trigger: 'blur' },
          { max: 40, message: 'Formato incorrecto', trigger: 'blur' }
        ],
        patientGender: [
          {
            required: true,
            message: 'Introduzca el género del paciente',
            trigger: 'blur'
          }
        ],
        patientBirthdate: [
          {
            required: true,
            message: 'Introduzca la fecha de nacimiento del paciente',
            trigger: 'blur'
          }
        ],
        patientPhoto: [
          {
            required: true,
            message: 'Seleccione la foto del paciente',
            trigger: 'blur'
          }
        ],
        addressClinicName: [
          {
            required: true,
            message: 'Introduzca el nombre de la clínica',
            trigger: 'blur'
          }
        ],
        addressType: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressCif: [
          {
            required: true,
            message: 'Introduzca el cif de la empresa',
            trigger: 'blur'
          }
        ],
        addressEmail: [
          { required: true, message: 'Introduzca el email', trigger: 'blur' }
        ],
        addressName1: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressProvince: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressCountry: [
          {
            required: true,
            message: 'Seleccione el país',
            trigger: 'blur'
          }
        ],
        addressPostalCode: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressCity: [
          {
            required: true,
            message: 'Seleccione el tipo de vía',
            trigger: 'blur'
          }
        ],
        addressPhone: [
          {
            required: true,
            message: 'Introduce un número de teléfono',
            trigger: 'blur'
          }
        ],
        otherBiteType: [{ validator: validateOtherBiteType, trigger: 'blur' }],
        lateralFacialImage: [
          {
            required: true,
            message: 'Seleccione la foto facial lateral',
            trigger: 'blur'
          }
        ],
        frontalFacialImage: [
          {
            required: true,
            message: 'Seleccione la foto facial frontal',
            trigger: 'blur'
          }
        ],
        smileFacialImage: [
          {
            required: true,
            message: 'Seleccione la foto facial de la sonrisa',
            trigger: 'blur'
          }
        ],
        upperOcclusalImage: [
          {
            required: true,
            message: 'Seleccione la foto oclusal superior',
            trigger: 'blur'
          }
        ],
        lowerOcclusalImage: [
          {
            required: true,
            message: 'Seleccione la foto oclusal inferior',
            trigger: 'blur'
          }
        ],
        rightIntraoralImage: [
          {
            required: true,
            message: 'Seleccione la foto intraoral derecha',
            trigger: 'blur'
          }
        ],
        centralIntraoralImage: [
          {
            required: true,
            message: 'Seleccione la foto intraoral central',
            trigger: 'blur'
          }
        ],
        leftIntraoralImage: [
          {
            required: true,
            message: 'Seleccione la foto intraoral izquierda',
            trigger: 'blur'
          }
        ],
        upperIntraoralScanner: [
          { validator: validateUpperIntraoralScanner, trigger: 'blur' }
        ],
        lowerIntraoralScanner: [
          { validator: validateLowerIntraoralScanner, trigger: 'blur' }
        ]
      },
      askForUpdateToReviewStatus: false
    }
  },
  methods: {
    validatePatientPhoto () {
      this.$refs.form.validateField('patientPhoto')
    },
    closeModal () {
      this.askForUpdateToReviewStatus = false
    },
    updateStatus (reviewStatus) {
      this.$emit('change-case-state', reviewStatus)
      this.askForUpdateToReviewStatus = false
    },
    deleteQuickcheck (index) {
      this.form.quickcheckHistory.splice(index, 1)
      this.updateCase()
    },
    deleteVto (index) {
      this.form.vtoHistory.splice(index, 1)
      this.updateCase()
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    async updateCase () {
      if (this.newQuickcheck) {
        this.form.quickcheckHistory.push({
          quickcheck: this.newQuickcheck,
          date: new Date()
        })
      }

      if (this.newVto) {
        this.form.vtoHistory.push({
          vto: JSON.parse(JSON.stringify(this.newVto)),
          date: new Date()
        })
      }

      await this.$store.dispatch('updateCase', {
        reload: true,
        create: false,
        caseId: this.currentCase.id,
        attachments: this.form.attachments,
        quickcheckHistory: this.form.quickcheckHistory,
        vtoHistory: this.form.vtoHistory,
        reference: this.form.reference,
        invoiceUrl: this.form.invoiceUrl,
        shippingTracking: this.form.shippingTracking,
        doctor: this.form.doctor,

        patientName: this.form.patientName,
        patientSurname: this.form.patientSurname,
        patientGender: this.form.patientGender,
        patientBirthdate: this.form.patientBirthdate,
        patientPhoto: this.form.patientPhoto,

        billingClinicName: this.form.billingClinicName,
        billingStreetName1: this.form.billingStreetName1,
        billingProvince: this.form.billingProvince,
        billingCountry: this.form.billingCountry,
        billingPostalCode: this.form.billingPostalCode,
        billingCity: this.form.billingCity,
        billingPhone: this.form.billingPhone,
        billingCif: this.form.billingCif,
        billingEmail: this.form.billingEmail,
        shippingClinicName: this.form.shippingClinicName,
        shippingStreetName1: this.form.shippingStreetName1,
        shippingProvince: this.form.shippingProvince,
        shippingCountry: this.form.shippingCountry,
        shippingPostalCode: this.form.shippingPostalCode,
        shippingCity: this.form.shippingCity,
        shippingPhone: this.form.shippingPhone,

        biteType: this.form.biteType,
        patientDiagnostic: this.form.patientDiagnostic,
        otherPatientDiagnostic: this.form.otherPatientDiagnostic,
        patientConcerns: this.form.patientConcerns,
        goals: this.form.goals,
        arcadeToTreat: this.form.arcadeToTreat,
        hasLackOfTeeth: this.form.hasLackOfTeeth,
        lackOfTeeth: this.form.lackOfTeeth,
        hasTemporaryTeeth: this.form.hasTemporaryTeeth,
        temporaryTeeth: this.form.temporaryTeeth,
        hasFixedTeeth: this.form.hasFixedTeeth,
        fixedTeeth: this.form.fixedTeeth,
        hasAttachmentsRestriction: this.form.hasAttachmentsRestriction,
        attachmentRestriction: this.form.attachmentRestriction,
        facialProfile: this.form.facialProfile,
        rightSagitalRelationship: this.form.rightSagitalRelationship,
        leftSagitalRelationship: this.form.leftSagitalRelationship,
        rightSagitalRelationshipSolution: this.form
          .rightSagitalRelationshipSolution,
        leftSagitalRelationshipSolution: this.form
          .leftSagitalRelationshipSolution,
        sagitalRelationshipSolutionExtra: this.form
          .sagitalRelationshipSolutionExtra,
        standout: this.form.standout,
        overbite: this.form.overbite,
        improveMaloclusions: this.form.improveMaloclusions,
        openbiteSolution: this.form.openbiteSolution,
        deepbiteSolution: this.form.deepbiteSolution,
        anteriorLiftPlane: this.form.anteriorLiftPlane,
        anteriorCrossbite: this.form.anteriorCrossbite,
        anteriorEdgeToEdgeOcclusion: this.form.anteriorEdgeToEdgeOcclusion,
        posteriorCrossbite: this.form.posteriorCrossbite,
        middleline: this.form.middleline,
        middlelineSolution: this.form.middlelineSolution,
        lowerMiddlelineSolution: this.form.lowerMiddlelineSolution,
        upperMiddlelineSolution: this.form.upperMiddlelineSolution,
        spacingCorrection: this.form.spacingCorrection,
        hasTeethSpace: this.form.hasTeethSpace,
        teethSpace: this.form.teethSpace,
        teethSpaceNew: this.form.teethSpaceNew,
        hasTeethExtraction: this.form.hasTeethExtraction,
        extractionTeeth: this.form.extractionTeeth,
        uppercrowdingExpansion: this.form.uppercrowdingExpansion,
        uppercrowdingBulge: this.form.uppercrowdingBulge,
        uppercrowdingAnteriorIpr: this.form.uppercrowdingAnteriorIpr,
        uppercrowdingRightPosteriorIpr: this.form
          .uppercrowdingRightPosteriorIpr,
        uppercrowdingLeftPosteriorIpr: this.form.uppercrowdingLeftPosteriorIpr,
        lowercrowdingExpansion: this.form.lowercrowdingExpansion,
        lowercrowdingBulge: this.form.lowercrowdingBulge,
        lowercrowdingAnteriorIpr: this.form.lowercrowdingAnteriorIpr,
        lowercrowdingRightPosteriorIpr: this.form
          .lowercrowdingRightPosteriorIpr,
        lowercrowdingLeftPosteriorIpr: this.form.lowercrowdingLeftPosteriorIpr,
        lateralFacialImage: this.form.lateralFacialImage,
        frontalFacialImage: this.form.frontalFacialImage,
        smileFacialImage: this.form.smileFacialImage,
        upperOcclusalImage: this.form.upperOcclusalImage,
        lowerOcclusalImage: this.form.lowerOcclusalImage,
        rightIntraoralImage: this.form.rightIntraoralImage,
        centralIntraoralImage: this.form.centralIntraoralImage,
        leftIntraoralImage: this.form.leftIntraoralImage,
        typeOfImpressions: this.form.typeOfImpressions,
        upperIntraoralScanner: this.form.upperIntraoralScanner,
        lowerIntraoralScanner: this.form.lowerIntraoralScanner,
        occlusalIntraoralScanner: this.form.occlusalIntraoralScanner,
        treatmentType: this.form.treatmentType,
        specialInstructions: this.form.specialInstructions,

        xray1Image: this.form.xray1Image,
        xray2Image: this.form.xray2Image,
        useDiscountMemberGetMember: this.form.useDiscountMemberGetMember,
        useDiscountYearly: this.form.useDiscountYearly,
        useDiscountFirstCase: this.form.useDiscountFirstCase,
        full: this.form.full
      })

      if (this.newQuickcheck) {
        this.askForUpdateToReviewStatus = 'quickcheck'
      }

      if (this.newVto) {
        this.askForUpdateToReviewStatus = 'vto'
      }

      this.newQuickcheck = ''
      this.newVto = ''
    },
    clearUploads (names) {
      names.forEach(name => {
        this.form[name] = ''
      })
    },
    writePatientName () {
      this.formTitle = `${this.patientName || ''} ${this.patientSurname || ''}`
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },

    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''

      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.form[fieldName] = downloadURL

            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    },
    setValueOnClick (prop, value) {
      this.form[prop] = value
    }
  },
  watch: {
    'currentCase.full' () {
      this.form.full = this.currentCase.full
    },
    'currentCase.attachments' () {
      this.form.attachments = this.currentCase.attachments
    },
    'currentCase.quickcheckHistory' () {
      this.form.quickcheckHistory = this.currentCase.quickcheckHistory.map(
        ({ quickcheck, date }) => ({ quickcheck, date })
      )
    },
    'currentCase.vtoHistory' () {
      this.form.vtoHistory = this.currentCase.vtoHistory.map(
        ({ vto, date }) => ({ vto, date })
      )
    },
    'currentCase.reference' () {
      this.form.reference = this.currentCase.reference
    },
    'currentCase.invoiceUrl' () {
      this.form.invoiceUrl = this.currentCase.invoiceUrl
    },
    'currentCase.shippingTracking' () {
      this.form.shippingTracking = this.currentCase.shippingTracking
    },
    'currentCase.doctor' () {
      this.form.doctor = this.currentCase.doctor.username
    },
    'currentCase.billingClinicName' () {
      this.form.billingClinicName = this.currentCase.billingClinicName
    },
    'currentCase.billingCountry' () {
      this.form.billingCountry = this.currentCase.billingCountry
    },
    'currentCase.billingStreetName1' () {
      this.form.billingStreetName1 = this.currentCase.billingStreetName1
    },
    'currentCase.billingProvince' () {
      this.form.billingProvince = this.currentCase.billingProvince
    },
    'currentCase.billingPostalCode' () {
      this.form.billingPostalCode = this.currentCase.billingPostalCode
    },
    'currentCase.billingCity' () {
      this.form.billingCity = this.currentCase.billingCity
    },
    'currentCase.billingPhone' () {
      this.form.billingPhone = this.currentCase.billingPhone
    },
    'currentCase.billingCif' () {
      this.form.billingCif = this.currentCase.billingCif
    },
    'currentCase.billingEmail' () {
      this.form.billingEmail = this.currentCase.billingEmail
    },
    'currentCase.shippingClinicName' () {
      this.form.shippingClinicName = this.currentCase.shippingClinicName
    },
    'currentCase.shippingCountry' () {
      this.form.shippingCountry = this.currentCase.shippingCountry
    },
    'currentCase.shippingStreetName1' () {
      this.form.shippingStreetName1 = this.currentCase.shippingStreetName1
    },
    'currentCase.shippingProvince' () {
      this.form.shippingProvince = this.currentCase.shippingProvince
    },
    'currentCase.shippingPostalCode' () {
      this.form.shippingPostalCode = this.currentCase.shippingPostalCode
    },
    'currentCase.shippingCity' () {
      this.form.shippingCity = this.currentCase.shippingCity
    },
    'currentCase.shippingPhone' () {
      this.form.shippingPhone = this.currentCase.shippingPhone
    },
    'currentCase.patientName' () {
      this.form.patientName = this.currentCase.patientName
    },
    'currentCase.patientSurname' () {
      this.form.patientSurname = this.currentCase.patientSurname
    },
    'currentCase.patientGender' () {
      this.form.patientGender = this.currentCase.patientGender
    },
    'currentCase.patientBirthdate' () {
      this.form.patientBirthdate = this.currentCase.patientBirthdate
    },
    'currentCase.patientPhoto' () {
      this.form.patientPhoto = this.currentCase.patientPhoto
    },
    'currentCase.biteType' () {
      this.form.biteType = this.currentCase.biteType
    },
    'currentCase.otherBiteType' () {
      this.form.otherBiteType = ['clase1', 'clase2', 'clase3'].includes(
        this.currentCase.biteType
      )
        ? ''
        : this.currentCase.biteType
    },
    'currentCase.patientDiagnostic' () {
      this.form.patientDiagnostic = this.currentCase.patientDiagnostic
    },
    'currentCase.otherPatientDiagnostic' () {
      this.form.otherPatientDiagnostic = this.currentCase.patientDiagnostic.find(
        x =>
          ![
            'crowding',
            'diastemas',
            'posteriorcrossbite',
            'deepoverbite',
            'deephighlight',
            'bulge',
            'anteriorcrossbite',
            'openbite'
          ].includes(x)
      )
        ? this.currentCase.patientDiagnostic.find(
            x =>
              ![
                'crowding',
                'diastemas',
                'posteriorcrossbite',
                'deepoverbite',
                'deephighlight',
                'bulge',
                'anteriorcrossbite',
                'openbite'
              ].includes(x)
          )
        : ''
    },
    'currentCase.patientConcerns' () {
      this.form.patientConcerns = this.currentCase.patientConcerns
    },
    'currentCase.otherPatientConcern' () {
      this.form.otherPatientConcern = this.currentCase.patientConcerns.find(
        x => !['crowding', 'diastemas', 'bulge', '', 'crossbite'].includes(x)
      )
        ? this.currentCase.patientConcerns.find(
            x =>
              !['crowding', 'diastemas', 'bulge', '', 'crossbite'].includes(x)
          )
        : ''
    },
    'currentCase.goals' () {
      this.form.goals = this.currentCase.goals
    },
    'currentCase.otherGoal' () {
      this.form.otherGoal = this.currentCase.goals.find(
        x =>
          !['align', 'closediastemas', 'fixcrossbite', 'improvebite'].includes(
            x
          )
      )
        ? this.currentCase.goals.find(
            x =>
              ![
                'align',
                'closediastemas',
                'fixcrossbite',
                'improvebite'
              ].includes(x)
          )
        : ''
    },
    'currentCase.arcadeToTreat' () {
      this.form.arcadeToTreat = this.currentCase.arcadeToTreat
    },
    'currentCase.hasLackOfTeeth' () {
      this.form.hasLackOfTeeth = this.currentCase.hasLackOfTeeth
    },
    'currentCase.lackOfTeeth' () {
      this.form.lackOfTeeth = this.currentCase.lackOfTeeth
    },
    'currentCase.hasTemporaryTeeth' () {
      this.form.hasTemporaryTeeth = this.currentCase.hasTemporaryTeeth
    },
    'currentCase.temporaryTeeth' () {
      this.form.temporaryTeeth = this.currentCase.temporaryTeeth
    },
    'currentCase.hasFixedTeeth' () {
      this.form.hasFixedTeeth = this.currentCase.hasFixedTeeth
    },
    'currentCase.fixedTeeth' () {
      this.form.fixedTeeth = this.currentCase.fixedTeeth
    },
    'currentCase.hasAttachmentsRestriction' () {
      this.form.hasAttachmentsRestriction = this.currentCase.hasAttachmentsRestriction
    },
    'currentCase.attachmentRestriction' () {
      this.form.attachmentRestriction = this.currentCase.attachmentRestriction
    },
    'currentCase.facialProfile' () {
      this.form.facialProfile = this.currentCase.facialProfile
    },
    'currentCase.rightSagitalRelationship' () {
      this.form.rightSagitalRelationship = this.currentCase.rightSagitalRelationship
    },
    'currentCase.leftSagitalRelationship' () {
      this.form.leftSagitalRelationship = this.currentCase.leftSagitalRelationship
    },
    'currentCase.rightSagitalRelationshipSolution' () {
      this.form.rightSagitalRelationshipSolution = this.currentCase.rightSagitalRelationshipSolution
    },
    'currentCase.leftSagitalRelationshipSolution' () {
      this.form.leftSagitalRelationshipSolution = this.currentCase.leftSagitalRelationshipSolution
    },
    'currentCase.sagitalRelationshipSolutionExtra' () {
      this.form.sagitalRelationshipSolutionExtra = this.currentCase.sagitalRelationshipSolutionExtra
    },
    'currentCase.standout' () {
      this.form.standout = this.currentCase.standout
    },
    'currentCase.overbite' () {
      this.form.overbite = this.currentCase.overbite
    },
    'currentCase.openbiteSolution' () {
      this.form.openbiteSolution = this.currentCase.openbiteSolution
    },
    'currentCase.improveMaloclusions' () {
      this.form.improveMaloclusions = this.currentCase.improveMaloclusions
    },
    'currentCase.otherOpenbiteSolution' () {
      this.form.otherOpenbiteSolution = this.currentCase.openbiteSolution.find(
        x =>
          !['anterosuperior', 'anteroinferior', 'posterosuperiores'].includes(x)
      )
        ? this.currentCase.openbiteSolution.find(
            x =>
              ![
                'anterosuperior',
                'anteroinferior',
                'posterosuperiores'
              ].includes(x)
          )
        : ''
    },
    'currentCase.deepbiteSolution' () {
      this.form.deepbiteSolution = this.currentCase.deepbiteSolution
    },
    'currentCase.otherDeepbiteSolution' () {
      this.form.otherDeepbiteSolution = this.currentCase.deepbiteSolution.find(
        x =>
          ![
            'anterosuperior',
            'anteroinferior',
            'posterosuperiores',
            'posteroinferiores',
            'plane'
          ].includes(x)
      )
        ? this.currentCase.deepbiteSolution.find(
            x =>
              ![
                'anterosuperior',
                'anteroinferior',
                'posterosuperiores',
                'posteroinferiores',
                'plane'
              ].includes(x)
          )
        : ''
    },
    'currentCase.anteriorLiftPlane' () {
      this.form.anteriorLiftPlane = this.currentCase.anteriorLiftPlane
    },
    'currentCase.anteriorCrossbite' () {
      this.form.anteriorCrossbite = this.currentCase.anteriorCrossbite
    },
    'currentCase.anteriorEdgeToEdgeOcclusion' () {
      this.form.anteriorEdgeToEdgeOcclusion = this.currentCase.anteriorEdgeToEdgeOcclusion
    },
    'currentCase.posteriorCrossbite' () {
      this.form.posteriorCrossbite = this.currentCase.posteriorCrossbite
    },
    'currentCase.middleline' () {
      this.form.middleline = this.currentCase.middleline
    },
    'currentCase.middlelineSolution' () {
      this.form.middlelineSolution = this.currentCase.middlelineSolution
    },
    'currentCase.lowerMiddlelineSolution' () {
      this.form.lowerMiddlelineSolution = this.currentCase.lowerMiddlelineSolution
    },
    'currentCase.upperMiddlelineSolution' () {
      this.form.upperMiddlelineSolution = this.currentCase.upperMiddlelineSolution
    },
    'currentCase.spacingCorrection' () {
      this.form.spacingCorrection = this.currentCase.spacingCorrection
    },
    'currentCase.hasTeethSpace' () {
      this.form.hasTeethSpace = this.currentCase.hasTeethSpace
    },
    'currentCase.teethSpace' () {
      this.form.teethSpace = this.currentCase.teethSpace
    },
    'currentCase.teethSpaceNew' () {
      this.form.teethSpaceNew = this.currentCase.teethSpaceNew
    },
    'currentCase.hasTeethExtraction' () {
      this.form.hasTeethExtraction = this.currentCase.hasTeethExtraction
    },
    'currentCase.extractionTeeth' () {
      this.form.extractionTeeth = this.currentCase.extractionTeeth
    },
    'currentCase.uppercrowdingExpansion' () {
      this.form.uppercrowdingExpansion = this.currentCase.uppercrowdingExpansion
    },
    'currentCase.uppercrowdingBulge' () {
      this.form.uppercrowdingBulge = this.currentCase.uppercrowdingBulge
    },
    'currentCase.uppercrowdingAnteriorIpr' () {
      this.form.uppercrowdingAnteriorIpr = this.currentCase.uppercrowdingAnteriorIpr
    },
    'currentCase.uppercrowdingRightPosteriorIpr' () {
      this.form.uppercrowdingRightPosteriorIpr = this.currentCase.uppercrowdingRightPosteriorIpr
    },
    'currentCase.uppercrowdingLeftPosteriorIpr' () {
      this.form.uppercrowdingLeftPosteriorIpr = this.currentCase.uppercrowdingLeftPosteriorIpr
    },
    'currentCase.lowercrowdingExpansion' () {
      this.form.lowercrowdingExpansion = this.currentCase.lowercrowdingExpansion
    },
    'currentCase.lowercrowdingBulge' () {
      this.form.lowercrowdingBulge = this.currentCase.lowercrowdingBulge
    },
    'currentCase.lowercrowdingAnteriorIpr' () {
      this.form.lowercrowdingAnteriorIpr = this.currentCase.lowercrowdingAnteriorIpr
    },
    'currentCase.lowercrowdingRightPosteriorIpr' () {
      this.form.lowercrowdingRightPosteriorIpr = this.currentCase.lowercrowdingRightPosteriorIpr
    },
    'currentCase.lowercrowdingLeftPosteriorIpr' () {
      this.form.lowercrowdingLeftPosteriorIpr = this.currentCase.lowercrowdingLeftPosteriorIpr
    },
    'currentCase.lateralFacialImage' () {
      this.form.lateralFacialImage = this.currentCase.lateralFacialImage
    },
    'currentCase.frontalFacialImage' () {
      this.form.frontalFacialImage = this.currentCase.frontalFacialImage
    },
    'currentCase.smileFacialImage' () {
      this.form.smileFacialImage = this.currentCase.smileFacialImage
    },
    'currentCase.upperOcclusalImage' () {
      this.form.upperOcclusalImage = this.currentCase.upperOcclusalImage
    },
    'currentCase.lowerOcclusalImage' () {
      this.form.lowerOcclusalImage = this.currentCase.lowerOcclusalImage
    },
    'currentCase.rightIntraoralImage' () {
      this.form.rightIntraoralImage = this.currentCase.rightIntraoralImage
    },
    'currentCase.centralIntraoralImage' () {
      this.form.centralIntraoralImage = this.currentCase.centralIntraoralImage
    },
    'currentCase.leftIntraoralImage' () {
      this.form.leftIntraoralImage = this.currentCase.leftIntraoralImage
    },
    'currentCase.typeOfImpressions' () {
      this.form.typeOfImpressions = this.currentCase.typeOfImpressions
    },
    'currentCase.typeOfImpressionsAddress' () {
      this.form.typeOfImpressionsAddress = this.currentCase.typeOfImpressionsAddress
    },
    'currentCase.upperIntraoralScanner' () {
      this.form.upperIntraoralScanner = this.currentCase.upperIntraoralScanner
    },
    'currentCase.lowerIntraoralScanner' () {
      this.form.lowerIntraoralScanner = this.currentCase.lowerIntraoralScanner
    },
    'currentCase.occlusalIntraoralScanner' () {
      this.form.occlusalIntraoralScanner = this.currentCase.occlusalIntraoralScanner
    },
    'currentCase.treatmentType' () {
      this.form.treatmentType = this.currentCase.treatmentType
    },
    'currentCase.specialInstructions' () {
      this.form.specialInstructions = this.currentCase.specialInstructions
    },
    'currentCase.xray1Image' () {
      this.form.xray1Image = this.currentCase.xray1Image
    },
    'currentCase.xray2Image' () {
      this.form.xray2Image = this.currentCase.xray2Image
    },
    'currentCase.useDiscountMemberGetMember' () {
      this.form.useDiscountMemberGetMember = this.currentCase.useDiscountMemberGetMember
    },
    'currentCase.useDiscountYearly' () {
      this.form.useDiscountYearly = this.currentCase.useDiscountYearly
    },
    'currentCase.useDiscountFirstCase' () {
      this.form.useDiscountFirstCase = this.currentCase.useDiscountFirstCase
    },
    addresses (addresses) {
      const shippingAddress = addresses
        ? addresses.find(address => address.type === 'SHIPPING')
        : null
      this.form = shippingAddress
        ? {
            ...this.form,
            shippingClinicName: shippingAddress.clinicName,
            shippingStreetName1: shippingAddress.streetName1,
            shippingProvince: shippingAddress.province,
            shippingCountry: shippingAddress.country,
            shippingPostalCode: shippingAddress.postalCode,
            shippingCity: shippingAddress.city,
            shippingPhone: shippingAddress.phone,
            shippingCif: shippingAddress.cif
          }
        : this.form

      const billingAddress = addresses
        ? addresses.find(address => address.type === 'BILLING')
        : null
      this.form = billingAddress
        ? {
            ...this.form,
            billingClinicName: billingAddress.clinicName,
            billingStreetName1: billingAddress.streetName1,
            billingProvince: billingAddress.province,
            billingCountry: billingAddress.country,
            billingPostalCode: billingAddress.postalCode,
            billingCity: billingAddress.city,
            billingPhone: billingAddress.phone,
            billingEmail: billingAddress.email,
            billingCif: billingAddress.cif
          }
        : this.form
    },
    billingAddress (addressIndex) {
      const address = this.billingAddresses[addressIndex]
      this.form = {
        ...this.form,
        billingClinicName: address.clinicName,
        billingStreetName1: address.streetName1,
        billingProvince: address.province,
        billingCountry: address.country,
        billingPostalCode: address.postalCode,
        billingCity: address.city,
        billingPhone: address.phone,
        billingCif: address.cif,
        billingEmail: address.email
      }
    },
    shippingAddress (addressIndex) {
      const address = this.shippingAddresses[addressIndex]
      this.form = {
        ...this.form,
        shippingClinicName: address.clinicName,
        shippingStreetName1: address.streetName1,
        shippingProvince: address.province,
        shippingCountry: address.country,
        shippingPostalCode: address.postalCode,
        shippingCity: address.city,
        shippingPhone: address.phone,
        shippingCif: address.cif
      }
    },
    prefilled () {
      this.loadPrefilledData()
    },
    'form.otherBiteType': function (val) {
      this.form.biteType = val
    },
    'form.otherPatientDiagnostic': function (val) {
      const members = [
        'crowding',
        'diastemas',
        'posteriorcrossbite',
        'deepoverbite',
        'deephighlight',
        'bulge',
        'anteriorcrossbite',
        'openbite'
      ]
      this.form.patientDiagnostic = this.form.patientDiagnostic.filter(x =>
        members.includes(x)
      )
      this.form.patientDiagnostic.push(val)
      this.$refs.otherPatientDiagnostic.checked = true
    },
    'form.otherPatientConcern': function (val) {
      const members = ['crowding', 'diastemas', 'bulge', '', 'crossbite']
      this.form.patientConcerns = this.form.patientConcerns.filter(x =>
        members.includes(x)
      )
      this.form.patientConcerns.push(val)
      this.$refs.otherPatientConcern.checked = true
    },
    'form.otherGoal': function (val) {
      const members = ['align', 'closediastemas', 'fixcrossbite', 'improvebite']
      this.form.goals = this.form.goals.filter(x => members.includes(x))
      this.form.goals.push(val)
      this.$refs.otherGoal.checked = true
    },
    'form.otherOpenbiteSolution': function (val) {
      const members = ['anterosuperior', 'anteroinferior', 'posterosuperiores']
      this.form.openbiteSolution = this.form.openbiteSolution.filter(x =>
        members.includes(x)
      )
      this.form.openbiteSolution.push(val)
      this.$refs.otherOpenbiteSolution.checked = true
    },
    'form.otherDeepbiteSolution': function (val) {
      const members = [
        'anterosuperior',
        'anteroinferior',
        'posterosuperiores',
        'posteroinferiores',
        'plane'
      ]
      this.form.deepbiteSolution = this.form.deepbiteSolution.filter(x =>
        members.includes(x)
      )
      this.form.deepbiteSolution.push(val)
      this.$refs.otherDeepbiteSolution.checked = true
    },
    otherPatientDiagnostic: function (val) {
      const members = [
        'crowding',
        'diastemas',
        'posteriorcrossbite',
        'deepoverbite',
        'deephighlight',
        'bulge',
        'anteriorcrossbite',
        'openbite'
      ]
      this.form.patientDiagnostic = this.form.patientDiagnostic.filter(x =>
        members.includes(x)
      )
      this.form.patientDiagnostic.push(val)
      this.$refs.otherPatientDiagnostic.checked = true
    },
    otherPatientConcern: function (val) {
      const members = ['crowding', 'diastemas', 'bulge', '', 'crossbite']
      this.patientConcerns = this.patientConcerns.filter(x =>
        members.includes(x)
      )
      this.patientConcerns.push(val)
      this.$refs.otherPatientConcern.checked = true
    },
    otherGoal: function (val) {
      const members = ['align', 'closediastemas', 'fixcrossbite', 'improvebite']
      this.goals = this.goals.filter(x => members.includes(x))
      this.goals.push(val)
      this.$refs.otherGoal.checked = true
    },
    otherOpenbiteSolution: function (val) {
      const members = ['anterosuperior', 'anteroinferior', 'posterosuperiores']
      this.openbiteSolution = this.openbiteSolution.filter(x =>
        members.includes(x)
      )
      this.openbiteSolution.push(val)
      this.$refs.otherOpenbiteSolution.checked = true
    },
    otherDeepbiteSolution: function (val) {
      const members = [
        'anterosuperior',
        'anteroinferior',
        'posterosuperiores',
        'posteroinferiores',
        'plane'
      ]
      this.deepbiteSolution = this.deepbiteSolution.filter(x =>
        members.includes(x)
      )
      this.deepbiteSolution.push(val)
      this.$refs.otherDeepbiteSolution.checked = true
    }
  },
  computed: {
    usedDiscount () {
      if (this.form.useDiscountYearly) {
        return 'Descuento personal de clínica (-15%)'
      } else if (this.form.useDiscountMemberGetMember) {
        return 'Member Get Member (-200€)'
      } else if (this.form.useDiscountFirstCase) {
        return 'Descuento en su primer caso (-50%)'
      } else {
        return null
      }
    }
  },
  components: {
    AddressCard,
    Fieldset,
    ModalAskForUpdateStatus,
    PatientName,
    PatientSurname,
    PatientGender,
    PatientPhoto,
    LoadPhotographies,
    RegistryTypeSelection,
    SagitalRelationship,
    SagitalRelationshipSolution,
    Overbite,
    ImproveMaloclusions,
    MiddlelineSolution,
    TeethSpace,
    Middleline
  }
}
</script>

<style lang="scss" scoped>
.field {
  max-width: 392px;
}
.full-switch {
  margin-bottom: 16px;
}

.discount-text {
  margin-top: 0;
}

.radio-button-replacement {
  display: none !important;
}
.radio-button {
  display: inline;
}

.summary {
  display: flex;
  align-items: center;
  flex-direction: column;

  .label-secondary {
    margin: -8px 0 8px 0;
    font-size: 16px;
  }

  .cost {
    margin: 0;
    font-size: 14px;
    margin-top: -8px;
  }

  .form {
    .fields {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      align-items: center;
      // width: 392px;

      .fieldset {
        width: 392px;

        &.big {
          width: fit-content;
        }
      }

      .quickcheck-input {
        display: flex;
        flex-direction: row;

        .el-icon-close {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .derivation-custom {
        .radio-label {
          white-space: initial;
        }
      }
      .teeth {
        .teeth-row {
          display: flex;
          width: min-content;

          .quart {
            display: flex;
            .tooth {
              align-items: center;
              display: flex;
              margin: 0;
              margin-right: 8px;

              &.up {
                flex-direction: column;
              }

              .number {
                margin: -10px;
                font-weight: 500;
                font-size: 10px;
              }

              .checkbox {
                display: flex;

                &.up {
                  flex-direction: column-reverse;
                }

                &.down {
                  padding-top: 8px;
                  flex-direction: column;
                }
              }
            }
          }

          .quart:first-of-type {
            border-right: 1px solid black;
            margin-right: 8px;
          }
        }
        .teeth-row:first-of-type {
          border-bottom: 1px solid black;
        }
      }

      .wrapper {
        display: flex;
      }
    }
  }
}

.intraoral-scanner-grid {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.xray-uploader {
  display: flex;
}

.el-radio {
  display: flex;
  margin-bottom: 4px;
}

.other-input {
  margin-left: 4px;
}
.radio-label {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.el-icon-circle-plus {
  font-size: 40px;
  cursor: pointer;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.lettered-group {
  display: flex;
  align-items: center;
  .radio-lettered {
    margin-right: 8px;
  }
}

.radio-lettered {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .letter {
    margin: 0;
    font-size: 16px;
    color: black;
  }

  &.selected {
    background-color: black;

    .letter {
      color: white;
    }

    &.disabled {
      background-color: #cccccc !important;
      border-color: #aaa;
      .letter {
        color: #616161;
      }
    }
  }

  &.disabled {
    background-color: #edf2fc !important;
    border-color: #aaa;
    .letter {
      color: #616161;
    }
  }
}

.radio-label,
.checkbox-label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.uploader {
  font-size: 25px;
  padding: 4px 24px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.grid-uploader {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 24px;
}

.big-uploader {
  width: 208px;
  height: 104px;
  font-size: 25px;
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.margin {
  &-bottom {
    margin-bottom: 16px;
  }

  &-top {
    margin-top: 16px;
  }

  &-right {
    margin-right: 16px;
  }

  &-left {
    margin-left: 16px;
  }
}

.clinic-conditions {
  display: flex;
  flex-direction: column;
}

.selected {
  background-color: black !important;
  color: white !important;
}

.title {
  margin: 0;
  margin-top: 24px;
  font-size: 36px;
  font-weight: 500;
}

.label {
  height: 21px;
  font-size: 18px;
  margin: 0 0 8px 0;
}

.help {
  font-size: 14px;
}

.select {
  width: 72px;
  height: 32px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #aaaaaa;
  background-color: #ffffff;

  &.address-type {
    margin-right: 16px;
  }
}

.input {
  box-sizing: border-box;
  padding: 8px;
  margin: 0 0 16px 0;
  max-width: 392px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #aaaaaa;
  background-color: #ffffff;
  height: 32px;
  font-size: 14px;
  font-weight: 300;
  color: #616161;

  &.postal-code {
    width: 106px;
  }
}

.checkbox {
  border-radius: 10px;
}

.multiple-checkbox-label {
  height: 21px;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 8px;
  color: #000000;
}

.checkbox-label {
  height: 21px;
  font-size: 18px;
  font-weight: 300;
  margin-left: 8px;
  color: #000000;
}

.upload {
  width: 72px;
  height: 32px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #aaaaaa;
  background-color: #ffffff;
  .image {
    width: 40px;
  }
}

.column {
  display: flex;
  flex-direction: column;
}
.link {
  color: black;
}

.patient-photo-upload {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 0 8px;

  &:hover {
    .cross {
      color: black;
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.el-checkbox,
.el-radio {
  display: flex;
  .el-checkbox__label,
  .radio-label {
    white-space: initial;
  }
}

.submit-update {
  margin-top: 8px;
}

@media (max-width: 768px) {
  .radio-button-replacement {
    display: flex !important;
    margin: 0 0 8px 0 !important;
  }

  .radio-button {
    display: none !important;
  }

  .summary {
    // width: 100%;
    display: initial;
    .form {
      // width: 100%;

      .fields {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        // width: 392px;

        .teeth {
          .teeth-row {
            flex-direction: column;
            margin-bottom: 24px;
          }
        }

        .fieldset-address {
          display: flex;

          .card {
            align-self: center;
            max-width: 96%;
          }
        }

        .derivation-custom {
          .radio-label {
            white-space: initial;
          }
        }
        .wrapper {
          display: flex;
        }
      }
    }
  }

  .grid-uploader {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    .item {
      display: flex;
      justify-self: center;
      margin-bottom: 8px;
    }
  }
}
</style>
