<template>
  <div class="container">
    <Header
      :type="headerType"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
      v-on:toggle-list-view="onToggleListView"
      v-on:set-filter="setFilter"
      :showSwitchIcons="!!cases.length"
      :isListCard="!isSmallWindow && isListCard"
    />

    <div v-if="isEmptySearch" class="list-card empty">
      <div class="empty">
        <img src="../assets/empty_case_list.jpg" class="empty-image noselect" />
        <p class="empty-text">Ningún caso coincide con su búsqueda.</p>
      </div>
    </div>

    <div v-else-if="isEmptyCases && !loading" class="list-card empty">
      <div class="empty">
        <img src="../assets/empty_case_list.jpg" class="empty-image noselect" />
        <p class="empty-text">Todavía no tiene ningún caso.</p>
      </div>
    </div>
    <div v-if="showCardListing" class="list-card">
      <CaseCard
        v-for="project in cases"
        class="case"
        :key="project.id"
        :fullName="project.patientFullName"
        :date="project.patientBirthdate"
        :id="project.id"
        :treatmentType="project.computedTreatmentType"
        :status="project.lastStatus"
        :patientPhoto="project.patientPhoto"
        :notes="project.notes"
        :createdAt="project.computedCreatedAt"
        :reference="project.reference"
        :unreadNotifications="project.unreadNotifications"
        :quickcheck="project.quickcheck"
        :vto="project.vto"
        :role="role"
        :doctorName="isAdmin ? project.doctorName : null"
        :created="project.created"
        :caseType="project.caseType"
        v-on:delete="id => openDeleteDraftConfirmation(id)"
      />
    </div>
    <el-pagination
      v-if="showCardListing && showPagination"
      class="pagination"
      background
      :current-page.sync="currentPage"
      :pageSize="pageSize"
      layout="prev, pager, next"
      :pager-count="pagerCount"
      :total="totalCases"
    >
    </el-pagination>

    <CaseList
      v-if="showListListing"
      :isAdmin="isAdmin"
      :cases="formatedCases"
      :currentPage="currentPage"
      v-on:change-page="updateCurrentPage"
      v-on:delete="id => openDeleteDraftConfirmation(id)"
      v-on:sort="handleSort"
      v-on:update-current-page="updateCurrentPage"
    />

    <Loading :active="loading" />
    <AdModal v-if="!isSmallWindow" />

    <el-dialog
      class="dialog"
      title="Eliminar borrador"
      :visible.sync="isDeleteDraftConfirmationOpen"
      :width="isSmallWindow ? '80%' : '30%'"
      :show-close="false"
    >
      <div class="confirmation">
        <p>
          Si hace click en Confirmar se eliminará el borrador ¿Está seguro/a?
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDeleteDraftConfirmation">Cancelar</el-button>
          <el-button type="primary" @click="deleteDraft">Confirmar</el-button>
        </span>
      </div>
    </el-dialog>
    <Footer class="footer-wrapper" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  PAGINATION_PAGER_NUMBER,
  PAGINATION_ITEMS_PER_PAGE,
  DEFAULT_CASE_VIEW,
  PAGINATION_PAGER_NUMBER_SMALL
} from '@/constants'
import Footer from '@/components/Footer'
export default {
  name: 'CaseListing',
  components: {
    CaseCard: () => import('@/components/CaseCard'),
    CaseList: () => import('@/components/CaseList'),
    Header: () => import('@/components/Header'),
    Loading: () => import('@/components/Loading'),
    Footer,
    AdModal: () => import('@/components/AdModal')
  },
  mounted () {
    this.$store.dispatch('fetchCases', {
      filter: this.caseFilter,
      sort: this.sort,
      sortField: this.sortField
    })
    this.pollData()
    this.isLoading = true
    window.addEventListener('resize', this.handleResize)
  },
  watch: {
    showListListing () {
      this.sort = ''
      this.sortField = ''
    }
  },
  computed: {
    isSmallWindow () {
      return this.clientWidth < 768
    },
    pagerCount () {
      return this.isSmallWindow
        ? PAGINATION_PAGER_NUMBER_SMALL
        : PAGINATION_PAGER_NUMBER
    },
    ...mapState({
      cases: state => state.cases || [],
      totalCases: state => state.totalCases,
      role: state => state.me.role,
      notifications: state => state.notifications,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      loading: state => state.isLoading,
      search: state => state.search
    }),
    currentPage: {
      set (val) {
        if (val !== this.currentPage) {
          this.updateCurrentPage(val)
        }
      },
      get () {
        return this.$store.state.casesCurrentPage
      }
    },
    isEmptySearch () {
      const selectedFilters = this.$store.state.selectedStageFilters
      const hasAllFilters = selectedFilters.length === 12
      const hasNoFilter = selectedFilters.length === 0
      const isFiltering =
        hasAllFilters ||
        hasNoFilter ||
        (!selectedFilters.includes('IN_REFINEMENT_TYPE') &&
          !selectedFilters.includes('IN_TREATMENT_TYPE'))

      return !this.cases.length && (this.search || !isFiltering)
    },
    isEmptyCases () {
      return !this.cases.length && !this.search
    },
    showCardListing () {
      return (
        !this.isEmptySearch &&
        !this.isEmptyCases &&
        (this.isSmallWindow || !this.isListCard)
      )
    },
    showListListing () {
      return !this.isEmptySearch && !this.isEmptyCases && !this.showCardListing
    },
    showPagination () {
      return this.totalCases > this.pageSize
    },
    isAdmin () {
      return this.role === 'ADMIN'
    },
    headerType () {
      const simpleHeaderRoutes = [
        'ClinicalPreferences',
        'Case',
        'Information',
        'NewCase',
        'CreateAddress'
      ]
      return simpleHeaderRoutes.includes(this.$route.name) ? 'simple' : 'full'
    },
    formatedCases () {
      return this.cases
        ? this.cases.map(x => {
            return {
              ...x,
              creationDate: x.created ? x.createdAt : null,
              doctorName: this.isAdmin ? x.doctorName : null,
              name: `${x.patientName} ${x.patientSurname}`,
              reference: x.reference,
              treatmentType: x.computedTreatmentType || '-',
              photo: x.patientPhoto
            }
          })
        : []
    }
  },
  methods: {
    handleSort (props) {
      this.sort = props.sort
      this.sortField = props.sortField

      this.$store.dispatch('fetchCases', props)
    },
    setFilter (filter) {
      if (this.caseFilter !== filter) {
        this.caseFilter = filter
        this.$store.commit('setFilter', { filter })
        this.$store.dispatch('fetchCases', {
          loading: true,
          filter: filter,
          clearCurrentPage: true,
          sort: this.sort,
          sortField: this.sortField
        })
      }
    },
    deleteDraft () {
      this.closeDeleteDraftConfirmation()
      this.$store.dispatch('deleteDraft', { id: this.deleteDraftId })
    },
    closeDeleteDraftConfirmation () {
      this.isDeleteDraftConfirmationOpen = false
    },
    openDeleteDraftConfirmation (id) {
      this.deleteDraftId = id
      this.isDeleteDraftConfirmationOpen = true
    },
    handleResize (event) {
      this.clientWidth = window.innerWidth
    },
    updateCurrentPage (page) {
      this.$store.dispatch('setCasesCurrentPage', {
        page,
        filter: this.caseFilter,
        sort: this.sort,
        sortField: this.sortField
      })
    },
    pollData () {
      // TODO: ask the api for changes
      // this.polling = setInterval(() => {
      //   this.$store.dispatch('fetchCases', {
      //     loading: false,
      //     filter: this.caseFilter,
      //     sort: this.sort,
      //     sortField: this.sortField
      //   })
      // }, 30000)
    },
    onToggleListView () {
      localStorage.setItem('isListCard', JSON.stringify(!this.isListCard))
      this.isListCard = !this.isListCard
    }
  },
  beforeDestroy () {
    this.$store.commit('setSearch', { search: '' })
    // clearInterval(this.polling)
    window.removeEventListener('resize', this.handleResize)
  },
  data () {
    const localStorageCaseView = JSON.parse(localStorage.getItem('isListCard'))
    const cardViewMap = { LIST: true, CARD: false }

    return {
      sort: '',
      sortField: '',
      clientWidth: window.innerWidth,
      polling: null,
      pageSize: PAGINATION_ITEMS_PER_PAGE,
      isListCard:
        localStorageCaseView === null
          ? cardViewMap[DEFAULT_CASE_VIEW]
          : localStorageCaseView,
      isDeleteDraftConfirmationOpen: false,
      deleteDraftId: null,
      caseFilter: null
    }
  }
}
</script>

<style scoped lang="scss">
.text1 {
  font-family: 'Avenir Light';
  font-size: 20px;
}
.text2 {
  font-family: 'Avenir Roman';
  font-size: 20px;
}
.text3 {
  font-family: 'Avenir Book';
  font-size: 20px;
}

.pagination {
  text-align: center;
  margin: 0 0 24px 0;
}

.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: max-content;
  min-height: 100vh;

  .list-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, 296px);
    grid-auto-rows: 246px;
    grid-gap: 16px;
    justify-content: center;
    padding: 16px 0;

    .case {
      // display: inline-block;
      // margin-bottom: 24px;
      // margin-right: 16px;
    }

    &.empty {
      display: flex;
      align-items: center;

      .empty-image {
        width: 25vw;
        margin-bottom: 16px;
      }
      .empty-text {
        font-weight: bold;
        text-align: center;
      }
    }

    &.empty {
      overflow: hidden;
    }
  }

  // .footer-wrapper {
  // padding: 0 24px 24px 24px;
  // }
}

@media (max-width: 540px) {
  .container {
    .list-card {
      padding: 16px 0;
      .case {
        margin-right: 0;
      }
    }
  }
}
</style>
