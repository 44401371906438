<template>
  <el-form-item prop="patientGender">
    <el-select
      v-on="$listeners"
      v-bind="$attrs"
      :value="value"
      placeholder="Género"
    >
      <el-option
        v-for="gender in genders"
        :key="gender"
        :label="gender"
        :value="gender"
      />
    </el-select>
  </el-form-item>
</template>

<script>
import { GENDERS } from '@/constants'

export default {
  props: {
    value: {
      required: false
    }
  },
  data () {
    return {
      genders: GENDERS
    }
  }
}
</script>
