<template>
  <div class="discounts">
    <div class="search-container">
      <label for="search">Buscar factura</label>
      <input
        class="search"
        name="search"
        v-model="searchText"
        placeholder="Introduzca nombre del paciente o id del caso"
      />
    </div>
    <div class="table-wrapper">
      <data-tables
        :data="invoices"
        :current-page="localPage"
        height="auto"
        class="table"
        :pageSize="15"
        :header-cell-class-name="() => 'header-cell'"
        header
        cell-class-name="cell"
        empty-text="-"
        :pagination-props="paginationProps"
      >
        <el-table-column
          align="center"
          prop="reference"
          label="ID del paciente"
        />

        <el-table-column
          align="center"
          prop="fullName"
          label="Nombre del paciente"
        />

        <el-table-column
          align="center"
          prop="billingClinicName"
          label="Razón social del caso"
        />

        <el-table-column
          align="center"
          prop="invoiceUrl"
          label="Descargar factura"
        >
          <template slot-scope="scope">
            <a
              class="table-link"
              target="_blank"
              rel="noopener noreferrer"
              :href="scope.row.invoiceUrl"
            >
              <el-button type="primary" size="mini">Descargar</el-button>
            </a>
          </template>
        </el-table-column>
      </data-tables>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'AdminPanelDiscounts',
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  computed: {
    paginationProps () {
      return {
        layout: 'prev, pager, next, ->, slot',
        total: this.invoices.length,
        pageSizes: [15]
      }
    },
    searchText: {
      get () {
        return this.$store.state.invoiceSearch
      },
      set (val) {
        this.search(val)
      }
    }
  },
  data () {
    return {
      localPage: 1
    }
  },
  methods: {
    search: debounce(function (val) {
      this.$store.dispatch('searchInvoices', { invoiceSearch: val })
    }, 1000)
  },
  beforeDestroy () {
    this.$store.commit('clearInvoiceSearch')
  }
}
</script>

<style lang="scss" scoped>
.discounts {
  .title {
    padding-left: 24px;
  }

  .search-container {
    padding-left: 32px;
    margin-bottom: 16px;
    .search {
      margin-left: 16px;
      width: 300px;
    }
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .table {
      width: 90%;
    }
  }
}
</style>
