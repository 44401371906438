<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { loginIntoFirebase, STORAGE_TOKEN } from './vue-apollo'
import { onLogout } from '@/vue-apollo'
import { isRouteGuarded } from './router'

export default {
  name: 'app',
  beforeMount () {
    const token = localStorage.getItem(STORAGE_TOKEN)
    if (isRouteGuarded(this.$route.name)) {
      loginIntoFirebase(token)
      .catch(e => {
        console.log('ERROR login into storage', e)
        onLogout(this.$apolloProvider.clients.defaultClient)
      })
    }
  }

}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

@font-face {
  font-family: 'Avenir Light';
  src: url('~@/assets/fonts/AvenirLTStd-Light.otf');
}
@font-face {
  font-family: 'Avenir Roman';
  src: url('~@/assets/fonts/AvenirLTStd-Roman.otf');
}
@font-face {
  font-family: 'Avenir Book';
  src: url('~@/assets/fonts/AvenirLTStd-Book.otf');
}

.el-tabs__content {
  overflow: inherit !important;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

#app {
  background-color: #fafafa;
  display: grid;
  grid-template-rows: 1fr auto;
}

* {
  font-family: 'Avenir Book', 'Roboto', sans-serif !important;
}

.hidden {
  display: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
</style>
