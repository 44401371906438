var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Fieldset',{attrs:{"label":"Dejar espacio en los siguientes dientes"}},[_c('el-radio',{attrs:{"label":false,"disabled":_vm.disabled},model:{value:(_vm.hasTeethSpaceInternal),callback:function ($$v) {_vm.hasTeethSpaceInternal=$$v},expression:"hasTeethSpaceInternal"}},[_c('span',{staticClass:"radio-label"},[_vm._v("No dejar espacio")])]),_c('el-radio',{attrs:{"label":true,"disabled":_vm.disabled},model:{value:(_vm.hasTeethSpaceInternal),callback:function ($$v) {_vm.hasTeethSpaceInternal=$$v},expression:"hasTeethSpaceInternal"}},[_c('span',{staticClass:"radio-label"},[_vm._v("Dejar espacio en los siguientes dientes:")])]),_c('div',{staticClass:"teeth-double"},[_c('Fieldset',{attrs:{"label":""}},[_c('div',{staticClass:"teeth-row",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('div',{staticClass:"quart",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('div',{staticClass:"sub"},_vm._l((7),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up"},[_c('el-input',{staticClass:"mini-input",class:{
                  marked:
                    _vm.teethSpaceInternal[n - 1] &&
                    _vm.teethSpaceInternal[n - 1] !== ((9 - n) + "-" + (8 - n))
                },attrs:{"placeholder":((9 - n) + "-" + (8 - n)),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"3"},model:{value:(_vm.teethSpaceInternal[n - 1]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n - 1, $$v)},expression:"teethSpaceInternal[n - 1]"}})],1)}),0),_c('div',{staticClass:"sub"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up br mb pt",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('el-input',{staticClass:"mini-input",class:{
                  marked:
                    _vm.teethSpaceInternal[n + 6] &&
                    _vm.teethSpaceInternal[n + 6] !== ("1." + (9 - n))
                },attrs:{"placeholder":("1." + (9 - n)),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 6]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 6, $$v)},expression:"teethSpaceInternal[n + 6]"}})],1)}),0)]),_c('div',{staticClass:"quart",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('div',{staticClass:"sub"},_vm._l((7),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up"},[_c('el-input',{staticClass:"mini-input",class:{
                  disabled: !_vm.hasTeethSpaceInternal,
                  marked:
                    _vm.teethSpaceInternal[n + 14] &&
                    _vm.teethSpaceInternal[n + 14] !== (n + "-" + (n + 1))
                },attrs:{"placeholder":(n + "-" + (n + 1)),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 14]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 14, $$v)},expression:"teethSpaceInternal[n + 14]"}})],1)}),0),_c('div',{staticClass:"sub"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up br pt",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('el-input',{staticClass:"mini-input",class:{
                  marked:
                    _vm.teethSpaceInternal[n + 21] &&
                    _vm.teethSpaceInternal[n + 21] !== ("2." + n)
                },attrs:{"placeholder":("2." + n),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 21]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 21, $$v)},expression:"teethSpaceInternal[n + 21]"}})],1)}),0)])]),_c('div',{staticClass:"teeth-row",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('div',{staticClass:"quart",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('div',{staticClass:"sub"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up br mt pb",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('el-input',{staticClass:"mini-input",class:{
                  marked:
                    _vm.teethSpaceInternal[n + 29] &&
                    _vm.teethSpaceInternal[n + 29] !== ("4." + (9 - n))
                },attrs:{"placeholder":("4." + (9 - n)),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 29]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 29, $$v)},expression:"teethSpaceInternal[n + 29]"}})],1)}),0),_c('div',{staticClass:"sub"},_vm._l((7),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up"},[_c('el-input',{staticClass:"mini-input",class:{
                  disabled: !_vm.hasTeethSpaceInternal,
                  marked:
                    _vm.teethSpaceInternal[n + 37] &&
                    _vm.teethSpaceInternal[n + 37] !== ((9 - n) + "-" + (8 - n))
                },attrs:{"placeholder":((9 - n) + "-" + (8 - n)),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 37]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 37, $$v)},expression:"teethSpaceInternal[n + 37]"}})],1)}),0)]),_c('div',{staticClass:"quart"},[_c('div',{staticClass:"sub"},_vm._l((8),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up br mt pb",class:{ disabled: !_vm.hasTeethSpaceInternal }},[_c('el-input',{staticClass:"mini-input",class:{
                  marked:
                    _vm.teethSpaceInternal[n + 44] &&
                    _vm.teethSpaceInternal[n + 44] !== ("3." + n)
                },attrs:{"placeholder":("3." + n),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 44]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 44, $$v)},expression:"teethSpaceInternal[n + 44]"}})],1)}),0),_c('div',{staticClass:"sub"},_vm._l((7),function(n){return _c('div',{key:n + 'teethspaceleftup',staticClass:"tooth up"},[_c('el-input',{staticClass:"mini-input",class:{
                  disabled: !_vm.hasTeethSpaceInternal,
                  marked:
                    _vm.teethSpaceInternal[n + 52] &&
                    _vm.teethSpaceInternal[n + 52] !== (n + "-" + (1 + n))
                },attrs:{"placeholder":(n + "-" + (1 + n)),"size":"mini","disabled":!_vm.hasTeethSpaceInternal || _vm.disabled,"maxlength":"4"},model:{value:(_vm.teethSpaceInternal[n + 52]),callback:function ($$v) {_vm.$set(_vm.teethSpaceInternal, n + 52, $$v)},expression:"teethSpaceInternal[n + 52]"}})],1)}),0)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }