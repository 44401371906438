<template>
  <div class="preferences">
    <Fieldset
      class="preference"
      label="IPR en el plan de tratamiento QuickCheck"
    >
      <el-radio v-model="ipr" :label="true" disabled>Si</el-radio>
      <el-radio v-model="ipr" :label="false" disabled>No</el-radio>
    </Fieldset>

    <Fieldset class="preference" label="Retrasar etapa de inicio de IPR">
      <el-radio v-model="iprDelay" :label="false" disabled
        >Sin retraso</el-radio
      >
      <el-radio v-model="iprDelay" :label="true" disabled
        >Retrasar hasta la etapa
        <el-select class="select" v-model="iprDelayNumber" size="mini" disabled>
          <el-option
            v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="n"
            :label="n"
            :value="n"
            >{{ n }}</el-option
          >
        </el-select>
      </el-radio>
    </Fieldset>
    <Fieldset class="preference" label="Retrasar colocación de attachments">
      <el-radio v-model="attachmentsDelay" :label="false" disabled
        >Sin retraso</el-radio
      >
      <el-radio v-model="attachmentsDelay" :label="true" disabled
        >Retrasar hasta la etapa
        <el-select
          class="select"
          v-model="attachmentsDelayNumber"
          size="mini"
          disabled
        >
          <el-option
            v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="n"
            :label="n"
            :value="n"
            >{{ n }}</el-option
          >
        </el-select>
      </el-radio>
    </Fieldset>

    <Fieldset class="preference" label="Expasión por arcada">
      <el-radio v-model="expansionForArcade" :label="0" disabled
        >&lt;= 2</el-radio
      >
      <el-radio v-model="expansionForArcade" :label="1" disabled
        >2mm - 4mm</el-radio
      >
      <el-radio v-model="expansionForArcade" :label="2" disabled
        >4mm - 6mm</el-radio
      >
      <el-radio v-model="expansionForArcade" :label="3" disabled
        >6mm - 8mm</el-radio
      >
      <el-radio v-model="expansionForArcade" :label="4" disabled
        >&gt; 8mm</el-radio
      >
    </Fieldset>

    <Fieldset class="preference" label="Pónticos para espacios abiertos">
      <div class="response">
        <p>
          Los pónticos de tamaño completo se colocan automaticamente en espacios
          anteriores mayores de 4mm y en espacios posterios mayores de 4mm. Si
          los dientes adyacentes se mueven (por ejemplo, en tratamientos de
          extracción), entonces se colocan pónticos de la mitad de tamaño. Los
          pónticos escalables de colocan automáticamente.
        </p>
        <p>
          En cualquier otro caso, por favor especifique uno o ambos de los
          siguientes:
        </p>
        <el-checkbox-group class="column" v-model="pontics" disabled>
          <el-checkbox :label="0"
            >No hay pónticos ni caso de extracción</el-checkbox
          >
          <el-checkbox :label="1"
            >Sin pónticos en espacios posteriores</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </Fieldset>

    <Fieldset class="preference" label="Expasión de la arcada">
      <el-radio v-model="expansionOfArcade" :label="0" disabled
        >Aumentar el ancho de arcada entre caninos, premolares y
        molares</el-radio
      >
      <el-radio v-model="expansionOfArcade" :label="1" disabled
        >Aumentar el ancho de arcada solamente entre caninos y
        premolares</el-radio
      >
      <el-radio v-model="expansionOfArcade" :label="2" disabled
        >Aumentar el ancho de arcada entre caninos, premolares y
        molares</el-radio
      >
      <el-radio v-model="expansionOfArcade" :label="3" disabled
        >Crear y mantener formas de arcada, pero no expandirla</el-radio
      >
    </Fieldset>

    <Fieldset
      class="preference"
      label="Nivelación de diente de arcada superior"
    >
      <el-radio v-model="arcadeLeveling" :label="0" disabled
        >Nivelar bordes incisales</el-radio
      >
      <el-radio v-model="arcadeLeveling" :label="1" disabled
        >Nivelar laterales con centrales</el-radio
      >
      <el-radio v-model="arcadeLeveling" :label="2" disabled
        >Laterales 0,5mm más gingivales que centrales</el-radio
      >
      <el-radio v-model="arcadeLeveling" :label="3" disabled
        >Nivelar márgenes gingivales</el-radio
      >
    </Fieldset>

    <Fieldset class="preference" label="Etapa de inicio de Precision Cuts">
      <el-select class="select" v-model="precisionCuts" size="mini" disabled>
        <el-option
          v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :key="n"
          :label="n"
          :value="n"
          >{{ n }}</el-option
        >
      </el-select>
    </Fieldset>

    <Fieldset class="preference" label="Notas">
      <el-input
        class="textarea"
        type="textarea"
        placeholder="Notas"
        v-model="notes"
        resize="none"
        rows="3"
        disabled
      />
    </Fieldset>
  </div>
</template>

<script>
import Fieldset from '@/components/Fieldset'

export default {
  name: 'ClinicalPreferences',
  components: {
    Fieldset
  },

  data () {
    return {
      iprDelayNumber: this.iprDelay || 0,
      attachmentsDelayNumber: this.attachmentsDelay || 0
    }
  },
  props: {
    ipr: {
      required: true
    },
    iprDelay: {
      required: true
    },
    attachmentsDelay: {
      required: true
    },
    expansionForArcade: {
      required: true
    },
    pontics: {
      required: true
    },
    expansionOfArcade: {
      required: true
    },
    arcadeLeveling: {
      required: true
    },
    precisionCuts: {
      required: true
    },
    notes: {
      required: true
    }
  }
}
</script>

<style>
.preferences {
  width: min-content;
}
</style>
