var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/qs1.svg")}}),_c('p',{staticClass:"title"},[_vm._v("Introduce tu nueva contraseña")]),_c('ApolloMutation',{staticClass:"wrapper",attrs:{"mutation":require('../graphql/ResetPassword.gql'),"variables":{
        password: this.password,
        repeatPassword: this.repeatPassword,
        userId: this.userId,
        token: this.token
      }},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var mutate = ref.mutate;
      var loading = ref.loading;
      var error = ref.error;
return [(error)?_c('p',[_vm._v("An error occurred: "+_vm._s(error))]):_vm._e(),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return mutate()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"field",attrs:{"type":"password","placeholder":"Nueva contraseña","id":"pass"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repeatPassword),expression:"repeatPassword"}],staticClass:"field",attrs:{"type":"password","placeholder":"Repite contraseña","id":"pass"},domProps:{"value":(_vm.repeatPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.repeatPassword=$event.target.value}}}),_c('div',[_c('el-button',{staticClass:"send",attrs:{"type":"primary","native-type":"submit"}},[_vm._v("Enviar")])],1)])]}}])})],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }