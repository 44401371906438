<template>
  <div class="form-container">
    <div class="form-wrapper">
      <el-form
        :model="form"
        ref="form"
        :hide-required-asterisk="true"
        class="form"
      >
        <button :style="{ display: 'none' }" type="submit" ref="submit">
          submit
        </button>

        <p v-if="step === 5" class="title">Revisión final</p>
        <p class="title">Refinamiento</p>

        <div v-if="step === 0" class="step step-0">
          <RefinementGoals v-model="form.goals" />
        </div>

        <div v-if="step === 1" class="step step-1">
          <FieldUnfitImages
            :unfit1Image="form.unfit1Image"
            :unfit2Image="form.unfit2Image"
            v-on:input-unfit-1="x => (form.unfit1Image = x)"
            v-on:input-unfit-2="x => (form.unfit2Image = x)"
          />
        </div>

        <div v-if="step === 2" class="step step-2">
          <LoadPhotographies
            label="Cargar fotografías (Las fotografías intraorales son obligatorias)"
            :membershipNumber="membershipNumber"
            :lateralFacialImage="form.lateralFacialImage"
            @lateralFacialImage="e => (form.lateralFacialImage = e)"
            :frontalFacialImage="form.frontalFacialImage"
            @frontalFacialImage="e => (form.frontalFacialImage = e)"
            :smileFacialImage="form.smileFacialImage"
            @smileFacialImage="e => (form.smileFacialImage = e)"
            :upperOcclusalImage="form.upperOcclusalImage"
            @upperOcclusalImage="e => (form.upperOcclusalImage = e)"
            :lowerOcclusalImage="form.lowerOcclusalImage"
            @lowerOcclusalImage="e => (form.lowerOcclusalImage = e)"
            :rightIntraoralImage="form.rightIntraoralImage"
            @rightIntraoralImage="e => (form.rightIntraoralImage = e)"
            :centralIntraoralImage="form.centralIntraoralImage"
            @centralIntraoralImage="e => (form.centralIntraoralImage = e)"
            :leftIntraoralImage="form.leftIntraoralImage"
            @leftIntraoralImage="e => (form.leftIntraoralImage = e)"
          />
        </div>

        <div v-if="step === 3" class="step step-3">
          <Fieldset label="Cargar radiografías">
            <input
              :style="{ display: 'none' }"
              ref="xray1Image"
              v-on:change="e => onUploadImage(e, 'xray1Image')"
              type="file"
            />
            <input
              :style="{ display: 'none' }"
              ref="xray2Image"
              v-on:change="e => onUploadImage(e, 'xray2Image')"
              type="file"
            />

            <div class="grid-uploader">
              <div class="item">
                <img
                  src="../assets/x_ray1.jpg"
                  v-if="!form.xray1Image"
                  @click="() => triggerUpload('xray1Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray1Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray1ImageImage"
                  @click="() => triggerUpload('xray1Image')"
                  :src="form.xray1Image"
                />
                <el-form-item label class prop="xray1Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray1Image"
                    ref="xray1ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>

              <div class="item">
                <img
                  src="../assets/x_ray2.jpg"
                  v-if="!form.xray2Image"
                  @click="() => triggerUpload('xray2Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray2Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray2ImageImage"
                  @click="() => triggerUpload('xray2Image')"
                  :src="form.xray2Image"
                />
                <el-form-item label class prop="xray2Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray2Image"
                    ref="xray2ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
            </div>
          </Fieldset>
        </div>

        <div v-if="step === 4" class="step step-4">
          <RegistryTypeSelection
            :typeOfImpressions="form.typeOfImpressions"
            @typeOfImpressions="e => (form.typeOfImpressions = e)"
            :upperIntraoralScanner="form.upperIntraoralScanner"
            @upperIntraoralScanner="e => (form.upperIntraoralScanner = e)"
            :lowerIntraoralScanner="form.lowerIntraoralScanner"
            @lowerIntraoralScanner="e => (form.lowerIntraoralScanner = e)"
            :occlusalIntraoralScanner="form.occlusalIntraoralScanner"
            @occlusalIntraoralScanner="e => (form.occlusalIntraoralScanner = e)"
            :lowerIntraoralScannerName="form.lowerIntraoralScannerName"
            @lowerIntraoralScannerName="
              e => (form.lowerIntraoralScannerName = e)
            "
            :upperIntraoralScannerName="form.upperIntraoralScannerName"
            @upperIntraoralScannerName="
              e => (form.upperIntraoralScannerName = e)
            "
            :occlusalIntraoralScannerName="form.occlusalIntraoralScannerName"
            @occlusalIntraoralScannerName="
              e => (form.occlusalIntraoralScannerName = e)
            "
            :typeOfImpressionsAddress="form.typeOfImpressionsAddress"
            @typeOfImpressionsAddress="e => (form.typeOfImpressionsAddress = e)"
          />
        </div>

        <div v-if="step === 5" class="step">
          <Fieldset label="Objetivos el refinamiento">
            <el-input
              v-model="form.goals"
              rows="8"
              type="textarea"
              resize="none"
            />
          </Fieldset>

          <FieldUnfitImages
            :unfit1Image="form.unfit1Image"
            :unfit2Image="form.unfit2Image"
            v-on:input-unfit-1="x => (form.unfit1Image = x)"
            v-on:input-unfit-2="x => (form.unfit2Image = x)"
          />

          <LoadPhotographies
            label="Cargar fotografías (Las fotografías intraorales son obligatorias)"
            :membershipNumber="membershipNumber"
            :lateralFacialImage="form.lateralFacialImage"
            @lateralFacialImage="e => (form.lateralFacialImage = e)"
            :frontalFacialImage="form.frontalFacialImage"
            @frontalFacialImage="e => (form.frontalFacialImage = e)"
            :smileFacialImage="form.smileFacialImage"
            @smileFacialImage="e => (form.smileFacialImage = e)"
            :upperOcclusalImage="form.upperOcclusalImage"
            @upperOcclusalImage="e => (form.upperOcclusalImage = e)"
            :lowerOcclusalImage="form.lowerOcclusalImage"
            @lowerOcclusalImage="e => (form.lowerOcclusalImage = e)"
            :rightIntraoralImage="form.rightIntraoralImage"
            @rightIntraoralImage="e => (form.rightIntraoralImage = e)"
            :centralIntraoralImage="form.centralIntraoralImage"
            @centralIntraoralImage="e => (form.centralIntraoralImage = e)"
            :leftIntraoralImage="form.leftIntraoralImage"
            @leftIntraoralImage="e => (form.leftIntraoralImage = e)"
          />

          <Fieldset label="Cargar radiografías">
            <input
              :style="{ display: 'none' }"
              ref="xray1Image"
              v-on:change="e => onUploadImage(e, 'xray1Image')"
              type="file"
            />
            <input
              :style="{ display: 'none' }"
              ref="xray2Image"
              v-on:change="e => onUploadImage(e, 'xray2Image')"
              type="file"
            />

            <div class="grid-uploader">
              <div class="item">
                <img
                  src="../assets/x_ray1.jpg"
                  v-if="!form.xray1Image"
                  @click="() => triggerUpload('xray1Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray1Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray1ImageImage"
                  @click="() => triggerUpload('xray1Image')"
                  :src="form.xray1Image"
                />
                <el-form-item label class prop="xray1Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray1Image"
                    ref="xray1ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>

              <div class="item">
                <img
                  src="../assets/x_ray2.jpg"
                  v-if="!form.xray2Image"
                  @click="() => triggerUpload('xray2Image')"
                  ref="xray1Icon"
                  class="big-uploader"
                />
                <img
                  v-show="form.xray2Image"
                  :style="{
                    width: '208px',
                    height: '104px',
                    cursor: 'pointer'
                  }"
                  ref="xray2ImageImage"
                  @click="() => triggerUpload('xray2Image')"
                  :src="form.xray2Image"
                />
                <el-form-item label class prop="xray2Image">
                  <el-input
                    :style="{ display: 'none' }"
                    v-model="form.xray2Image"
                    ref="xray2ImageUrl"
                    type="text"
                    placeholder="url"
                  />
                </el-form-item>
              </div>
            </div>
          </Fieldset>

          <RegistryTypeSelection
            :typeOfImpressions="form.typeOfImpressions"
            @typeOfImpressions="e => (form.typeOfImpressions = e)"
            :upperIntraoralScanner="form.upperIntraoralScanner"
            @upperIntraoralScanner="e => (form.upperIntraoralScanner = e)"
            :lowerIntraoralScanner="form.lowerIntraoralScanner"
            @lowerIntraoralScanner="e => (form.lowerIntraoralScanner = e)"
            :occlusalIntraoralScanner="form.occlusalIntraoralScanner"
            @occlusalIntraoralScanner="e => (form.occlusalIntraoralScanner = e)"
            :lowerIntraoralScannerName="form.lowerIntraoralScannerName"
            @lowerIntraoralScannerName="
              e => (form.lowerIntraoralScannerName = e)
            "
            :upperIntraoralScannerName="form.upperIntraoralScannerName"
            @upperIntraoralScannerName="
              e => (form.upperIntraoralScannerName = e)
            "
            :occlusalIntraoralScannerName="form.occlusalIntraoralScannerName"
            @occlusalIntraoralScannerName="
              e => (form.occlusalIntraoralScannerName = e)
            "
            :typeOfImpressionsAddress="form.typeOfImpressionsAddress"
            @typeOfImpressionsAddress="e => (form.typeOfImpressionsAddress = e)"
          />
        </div>
      </el-form>
    </div>
    <div class="navigation">
      <el-button v-if="step !== 0" type="primary" @click="goToPreviousStep"
        >Atrás</el-button
      >
      <div v-else></div>

      <el-button v-if="step === 5" type="primary" @click="tryCreateRefinement"
        >Crear refinamiento</el-button
      >
      <el-button v-else type="primary" @click="goToNextStep"
        >Guardar y continuar</el-button
      >
    </div>

    <el-dialog
      class="dialog"
      title="Crear refinamiento"
      :visible.sync="isDialogVisible"
      :width="isSmallWindow ? '80%' : '30%'"
      :show-close="false"
    >
      <div v-if="!showFormValidationError" class="confirmation">
        <p>
          Si hace click en Confirmar se creará el refinamiento ¿Está seguro/a?
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">Cancelar</el-button>
          <el-button type="primary" @click="() => updateRefinement(true)"
            >Confirmar</el-button
          >
        </span>
      </div>

      <div v-else class="confirmation">
        <p>
          Debes rellenar todos los campos. El refinamiento se ha guardado como
          borrador.
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeFormValidationError"
            >Aceptar</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import { mapState } from 'vuex'
import Fieldset from '@/components/Fieldset'
import RefinementGoals from '@/components/RefinementGoals'
import FieldUnfitImages from '@/components/FieldUnfitImages'
import LoadPhotographies from '@/components/form/LoadPhotographies'
import RegistryTypeSelection from '@/components/form/RegistryTypeSelection'
import { PDF_DOWNLOAD_URL } from '@/constants'
export default {
  name: 'RefinementForm',
  props: {
    membershipNumber: {
      type: String,
      required: false
    },
    refinement: {
      type: Object,
      required: false
    }
  },
  data () {
    const refinementId = this.$route.query.refinement_id
    return {
      shippingSheetPdf: PDF_DOWNLOAD_URL.MEASURES,
      clientWidth: window.innerWidth,
      showPhotoError: null,
      showFormValidationError: null,
      refinementId: refinementId,
      clickCounter: 0,
      step: 0,
      isDialogVisible: false,
      lowerIntraoralScannerName: null,
      upperIntraoralScannerName: null,
      form: {
        id: refinementId,
        attachments: '',
        case: this.$route.params.id,
        created: false,
        goals: '',
        unfit1Image: '',
        unfit2Image: '',
        xray1Image: '',
        xray2Image: '',
        lateralFacialImage: '',
        frontalFacialImage: '',
        smileFacialImage: '',
        upperOcclusalImage: '',
        lowerOcclusalImage: '',
        rightIntraoralImage: '',
        centralIntraoralImage: '',
        leftIntraoralImage: '',
        typeOfImpressions: 'intraoralScanner',
        typeOfImpressionsAddress: '',
        ...this.refinement
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      createdRefinementId: state => state.createdRefinementId
    }),
    isSmallWindow () {
      return this.clientWidth < 768
    }
  },
  watch: {
    createdRefinementId (val) {
      if (val) {
        this.form.id = val
        this.refinementId = val
      }
    }
  },
  methods: {
    closeFormValidationError () {
      this.closeDialog()
      this.updateRefinement()
    },
    handleResize (event) {
      this.clientWidth = window.innerWidth
    },
    tryCreateRefinement () {
      if (this.validateForm()) {
        this.showFormValidationError = false
        this.isDialogVisible = true
      } else {
        this.showFormValidationError = true
        this.isDialogVisible = true
      }
    },
    validateForm () {
      let error
      this.$refs.form.validateField(
        [
          'upperOcclusalImage',
          'lowerOcclusalImage',
          'rightIntraoralImage',
          'centralIntraoralImage',
          'leftIntraoralImage'
        ],
        elementError => {
          if (elementError) {
            error = true
          }
        }
      )
      if (this.validatePhotos()) {
        error = true
      }

      if (!error) {
        return true
      } else {
        return false
      }
    },
    validatePhotos () {
      this.showPhotoError = [
        this.form.upperOcclusalImage,
        this.form.lowerOcclusalImage,
        this.form.rightIntraoralImage,
        this.form.centralIntraoralImage,
        this.form.leftIntraoralImage
      ].reduce((acc, val) => {
        return acc || !val
      }, false)
      return this.showPhotoError
    },
    closeDialog () {
      this.isDialogVisible = false
    },
    openDialog () {
      this.isDialogVisible = true
    },
    async updateRefinement (isCreating) {
      this.form.created = !!isCreating
      if (this.refinementId) {
        await this.$store.dispatch('updateRefinement', { form: this.form })
      } else {
        this.$store.dispatch('createRefinement', { form: this.form })
      }

      if (isCreating) {
        this.$router.push(`/caso/${this.form.case}`)
      }
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    clearUploads (names) {
      this.clickCounter += 1
      if (this.clickCounter % 2 === 0) {
        names.forEach(name => {
          this.form[name] = ''
        })
      }
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]
      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      }

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.form[fieldName] = downloadURL

            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    goToNextStep () {
      this.step += 1
      this.updateRefinement()
    },
    goToPreviousStep () {
      this.step -= 1
    }
  },
  components: {
    FieldUnfitImages,
    Fieldset,
    RefinementGoals,
    LoadPhotographies,
    RegistryTypeSelection
  }
}
</script>

<style lang="scss" scoped>
.link {
  color: black;
}

.step {
  width: 392px;

  &.step-2,
  &.step-3,
  &.step-4 {
    width: 100%;
  }
}

.el-radio {
  display: flex;
  margin-bottom: 8px;
}

.radio-label {
  margin-right: 8px;
  display: flex;
}

.form-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .title {
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }

  .navigation {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .grid-uploader {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 24px;
  }

  .uploader {
    font-size: 25px;
    padding: 4px 24px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .big-uploader {
    width: 208px;
    height: 104px;
    font-size: 25px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .big-uploader-text {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 208px;
    height: 104px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .text {
      padding: 16px;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .dialog-footer {
    text-align: right;
  }
}

.intraoral-scanner-grid {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.custom-error {
  margin-top: 0;
  color: #f56c6c;
  font-size: 12px;
}

.custom-message {
  color: #f56c6c;
  max-width: 300px;
  color: #f56c6c;
}

.margin {
  &-bottom {
    margin-bottom: 16px;
  }

  &-top {
    margin-top: 16px;
  }

  &-right {
    margin-right: 16px;
  }

  &-left {
    margin-left: 16px;
  }
}

.patient-photo-upload {
  // width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 0 8px;
  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 120px;
  }

  &:hover {
    .cross {
      color: black;
      cursor: pointer;
      // font-weight: bold;
    }
  }
}

@media (max-width: 768px) {
  .radio-button-replacement {
    display: flex !important;
    margin: 0 0 8px 0 !important;
  }

  .radio-button {
    display: none !important;
  }

  .form-container {
    padding: 8px;
    .form-wrapper {
      width: 100%;
      .form {
        .step {
          width: 100%;

          .el-checkbox,
          .el-radio {
            display: flex;
            .el-checkbox__label,
            .radio-label {
              white-space: break-spaces;
            }
          }

          .teeth {
            .teeth-row {
              flex-direction: column;
              margin-bottom: 24px;
              .quart {
                .tooth {
                  &.up {
                  }

                  .number {
                  }

                  .checkbox {
                    &.up {
                    }

                    &.down {
                    }
                  }
                }
              }
            }
          }

          .addresses {
            display: flex;
            flex-direction: column;
            max-width: initial;
            padding: 0;
            margin: 0;

            .address {
              margin: 0 0 8px 0;
              padding: 0;
            }
            .add-address {
              align-self: center;
              margin-bottom: 16px;
            }
          }

          .patient-photo-upload {
            width: auto;
          }
        }
      }
    }
    .navigation {
      margin-top: 8px;
      // display: grid;
      // grid-template-columns: 1fr 1fr;

      .back {
        width: max-content;
      }

      .next {
        width: max-content;
      }
    }
    .grid-uploader {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;

      .item {
        display: flex;
        justify-self: center;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
