<template>
  <div class="container">
    <Header
      type="simple"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
      :notifications="notifications"
    />
    <div>
      <el-tabs
        class="major-tabs"
        v-if="(refinementTabs && refinementTabs.length) || retention"
        v-model="majorTab"
        type="card"
      >
        <el-tab-pane label="Tratamiento" name="TREATMENT"></el-tab-pane>
        <el-tab-pane
          v-for="(refinement, index) in refinementTabs"
          :key="`Refinamiento ${index}_${majorTab}`"
          :label="
            `Refinamiento ${index + 1} ${
              !refinement.created ? '(Borrador)' : ''
            }`
          "
          :name="`REFINEMENT_${index}`"
        ></el-tab-pane>
        <el-tab-pane
          v-if="retention && retention.created"
          label="Retención final"
          name="RETENTION"
        ></el-tab-pane>
        <el-tab-pane
          v-else-if="retention"
          label="Retención final (Borrador)"
          name="RETENTION"
        ></el-tab-pane>
      </el-tabs>
      <div class="current-case">
        <div class="row">
          <div class="patient">
            <img v-if="patientPhoto" class="photo" :src="patientPhoto" />
            <img v-else class="photo" src="../assets/qs1.svg" />

            <div class="info">
              <p class="name">
                {{ patientName }}
                <span v-if="isAdmin" class="owner">({{ caseOwner }})</span>
              </p>
              <p class="field">
                ID del paciente:
                <span class="bold">{{
                  currentCase.reference
                    ? currentCase.reference
                    : 'Todavía no está disponible'
                }}</span>
              </p>
              <p class="field">
                Fecha de inicio: <span class="bold">{{ createdAt }}</span>
              </p>
              <p class="field">
                Fecha de finalización: <span class="bold">{{ finishAt }}</span>
              </p>
              <p class="field">
                Tratamiento: <span class="bold">{{ treatmentType }}</span>
              </p>
            </div>
          </div>

          <div class="btns">
            <el-button
              v-if="isAdmin"
              class="btn"
              @click="toggleClearNotificationsModal"
            >
              Limpiar notificationes
            </el-button>

            <div class="quickcheck-wrapper" v-if="quickcheckUrl">
              <a
                class="hidden"
                :href="quickcheckUrl"
                target="_blank"
                rel="noopener noreferrer"
                ref="quickcheck"
                >QuickCheck</a
              >
              <el-button
                class="quickcheck"
                type="primary"
                @click="clickElement('quickcheck')"
                >QuickCheck</el-button
              >
            </div>
            <div class="quickcheck-wrapper" v-else-if="vtoUrl">
              <a
                class="hidden"
                :href="vtoUrl"
                target="_blank"
                rel="noopener noreferrer"
                ref="vto"
                >VTO</a
              >
              <el-button
                class="quickcheck"
                type="primary"
                @click="clickElement('vto')"
                >VTO</el-button
              >
            </div>
          </div>
        </div>

        <el-dialog
          title="Actualizar estado"
          :visible.sync="isDialogVisible"
          width="30%"
          :show-close="false"
        >
          <div v-if="isAdmin && nextState == 11" class="dialog">
            <span>¿Está seguro/a de dar por finalizado el tratamiento?</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">Cancelar</el-button>
              <el-button
                type="primary"
                @click="() => confirmChangeCaseState(nextState)"
                >Confirmar</el-button
              >
            </span>
          </div>

          <div v-else-if="isAdmin" class="dialog">
            <span
              >Vas a cambiar al estado
              <span class="case-name">{{ nextStateName }}</span> ¿Está
              seguro/a?</span
            >
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">Cancelar</el-button>
              <el-button
                type="primary"
                @click="() => confirmChangeCaseState(nextState)"
                >Confirmar</el-button
              >
            </span>
          </div>

          <div v-else>
            <div v-if="nextState == '4' && !currentRefinement" class="dialog">
              <span
                >Va a cambiar al estado a
                <span class="case-name">'{{ nextStateName }}'</span>: Se le
                enviará la factura con las instrucciones de pago a su email
                próximamente y el caso pasará a producción. ¿Está
                seguro/a?</span
              >
              <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">Cancelar</el-button>
                <el-button
                  type="primary"
                  @click="() => confirmChangeCaseState(nextState)"
                  >Confirmar</el-button
                >
              </span>
            </div>

            <div v-else-if="nextState == 5" class="dialog">
              <span
                >Vas a cambiar al estado
                <span class="case-name">{{ nextStateName }}</span
                >. Este proceso tiene un cargo de 149,00€·</span
              >
              <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">Cancelar</el-button>
                <el-button
                  type="primary"
                  @click="() => confirmChangeCaseState(nextState)"
                  >Confirmar</el-button
                >
              </span>
            </div>

            <div v-else-if="nextState == 11" class="dialog">
              <span>¿Está seguro/a de dar por finalizado el tratamiento?</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">Cancelar</el-button>
                <el-button
                  type="primary"
                  @click="() => confirmChangeCaseState(nextState)"
                  >Confirmar</el-button
                >
              </span>
            </div>

            <div v-else class="dialog">
              <span
                >Vas a cambiar al estado
                <span class="case-name">{{ nextStateName }}</span> ¿Está
                seguro/a?</span
              >
              <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">Cancelar</el-button>
                <el-button
                  type="primary"
                  @click="() => confirmChangeCaseState(nextState)"
                  >Confirmar</el-button
                >
              </span>
            </div>
          </div>
        </el-dialog>
        <CaseStages
          v-if="currentStatus"
          :isAdmin="isAdmin"
          :currentStage="parseInt(currentStatus)"
          :caseType="
            majorTab === 'TREATMENT'
              ? 'treatment'
              : majorTab === 'RETENTION'
              ? 'retention'
              : 'refinement'
          "
          @click="stage => openDialog(stage)"
        />
        <div
          v-if="currentStatus == 0 || currentStatus == 1"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            Los registros están siendo evaluados por nuestro personal técnico,
            contactaremos con usted en caso de incidencia.
          </p>
        </div>

        <div v-if="currentStatus == 2" class="current-stage">
          <p class="title">Información del estado actual</p>
          <p class="text">
            La simulación del caso está siendo desarrollada, le contactaremos
            para revisar el QuickCheck en los próximos días.
          </p>
        </div>

        <div v-if="currentStatus == 12" class="current-stage">
          <p class="title">Información del estado actual</p>
          <div>
            <p class="text">
              1 - Revise la simulación digital del caso haciendo click en el
              siguiente botón
            </p>
            <el-button
              class="quickcheck"
              type="primary"
              @click="clickElement('vto')"
              >VTO</el-button
            >
          </div>

          <div>
            <p class="text">
              2 - Remítanos sus comentarios a través del chat que encontrará en
              la pestaña situada debajo.
            </p>
          </div>

          <div>
            <p class="text">
              3 - Una vez esté satisfecho/a con la simulación, pulse el botón
              "APROBAR VTO", para pasar a secuenciación.
            </p>
            <el-button type="primary" @click="() => openDialog('13')"
              >APROBAR VTO</el-button
            >
          </div>
        </div>
        <!-- If user is not student: students can't go to production -->
        <div
          v-if="currentStatus == 3 && schoolRole !== 'STUDENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <div>
            <p class="text">
              1 - Revise la simulación digital del caso haciendo click en el
              siguiente botón
            </p>
            <el-button
              class="quickcheck"
              type="primary"
              @click="clickElement('quickcheck')"
              >QuickCheck</el-button
            >
          </div>

          <div>
            <p class="text">
              2 - Remítanos sus comentarios a través del chat que encontrará en
              la pestaña situada debajo.
            </p>
          </div>

          <div>
            <p class="text">
              3 - Una vez esté satisfecho/a con la simulación, pulse el botón
              "APROBAR", para abonar el tratamiento y pasarlo a producción.
            </p>
            <el-button type="primary" @click="() => openDialog('4')"
              >APROBAR</el-button
            >
          </div>
        </div>

        <div
          v-if="currentStatus == 3 && schoolRole === 'STUDENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <div>
            <p class="text">
              Debe esperar a que su profesor apruebe el caso para pasarlo a
              producción.
            </p>
          </div>
        </div>

        <div
          v-if="currentStatus == 4 && majorTab === 'TREATMENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            El caso pasará a producción una vez sea abonado.
            <span v-if="currentCase.useDiscountYearly"
              >Para este caso se ha utilizado un cupón del tipo
              <span class="bold"> personal de clinica (-15%).</span></span
            >
            <span v-if="currentCase.useDiscountMemberGetMember"
              >Para este caso se ha utilizado un cupón del tipo
              <span class="bold">Member Get Member (-200€).</span></span
            >
            <span v-if="currentCase.useDiscountFirstCase"
              >Para este caso se ha utilizado un cupón del tipo
              <span class="bold"
                >descuento en su primer case (-50%).</span
              ></span
            >
          </p>
          <!-- <el-button type="primary" @click="goToCheckout('pay')">ABONAR TRATAMIENTO</el-button> -->

          <!--Ejemplo de formulario de pago sin envío de datos de tarjeta:-->
          <!-- <form name="from" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" ref="formPayment">
          <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
          <input type="hidden" name="Ds_MerchantParameters" :value="merchantParameters"/>
          <input type="hidden" name="Ds_Signature" :value="signature"/>
          </form> -->
        </div>

        <div
          v-if="currentStatus == 4 && majorTab !== 'TREATMENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            El refinamiento ha sido aceptado.
          </p>
        </div>

        <div
          v-if="currentStatus == 5 && majorTab !== 'TREATMENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            <!-- CANCELADO -->
            El caso ha sido cancelado.
          </p>
        </div>
        <div
          v-if="currentStatus == 5 && majorTab === 'TREATMENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            <!-- CANCELADO -->
            El tratamiento ha sido cancelado.
          </p>
        </div>

        <div v-if="currentStatus == 6" class="current-stage">
          <p class="title">Información del estado actual</p>
          <p class="text">
            El caso está en producción, le avisaremos cuando se envíe a la
            dirección indicada.
          </p>
        </div>

        <div v-if="currentStatus == 7" class="current-stage">
          <p class="title">Información del estado actual</p>
          <p v-if="currentCase.shippingTracking" class="text">
            El caso ha sido enviado, puede comprobar el seguimiento haciendo
            click en el siguiente botón.
          </p>
          <a
            v-if="currentCase.shippingTracking"
            :href="currentCase.shippingTracking"
            target="_blank"
          >
            <el-link class="shipping-tracking-link"
              >Comprobar el estado del envío</el-link
            >
          </a>

          <p v-if="!currentCase.shippingTracking" class="text">
            El caso ha sido enviado. Lo recibirá en las próximas 24 horas
            laborales.
          </p>
        </div>

        <div
          v-if="currentStatus == 9 && majorTab === 'TREATMENT'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            El caso se encuentra en tratamiento.
          </p>
          <div class="buttons">
            <el-button
              v-if="areAlignmentsMissing"
              type="primary"
              @click="sendMissingAlignmentsEmail"
            >
              Producir alineadores restantes
            </el-button>

            <el-button
              v-if="
                !refinementDraftId &&
                  (!retention || (retention && !retention.created))
              "
              type="primary"
              @click="$router.push(`/refinamiento/${$route.params.id}`)"
              class="button"
              >Comenzar refinamiento</el-button
            >
            <el-button
              v-else-if="!retention || (retention && !retention.created)"
              type="primary"
              @click="
                $router.push(
                  `/refinamiento/${$route.params.id}?refinement_id=${refinementDraftId}`
                )
              "
              class="button"
              >Continuar refinamiento</el-button
            >
            <el-button
              v-if="!retention"
              type="primary"
              @click="() => $router.push(`/retencion/${$route.params.id}`)"
              class="button"
              >Comenzar retención final</el-button
            >
          </div>
        </div>

        <div
          v-if="
            currentStatus == 9 &&
              majorTab !== 'TREATMENT' &&
              majorTab !== 'RETENTION'
          "
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            El caso se encuentra en refinamiento.
          </p>
          <div class="buttons">
            <el-button
              v-if="
                !refinementDraftId &&
                  (!retention || (retention && !retention.created))
              "
              type="primary"
              @click="$router.push(`/refinamiento/${$route.params.id}`)"
              >Comenzar refinamiento</el-button
            >
            <el-button
              v-else-if="!retention || (retention && !retention.created)"
              type="primary"
              @click="
                $router.push(
                  `/refinamiento/${$route.params.id}?refinement_id=${refinementDraftId}`
                )
              "
              >Continuar refinamiento</el-button
            >
            <el-button
              v-if="!retention"
              type="primary"
              @click="() => $router.push(`/retencion/${$route.params.id}`)"
              >Comenzar retención final</el-button
            >
          </div>
        </div>

        <div
          v-if="currentStatus == 9 && majorTab === 'RETENTION'"
          class="current-stage"
        >
          <p class="title">Información del estado actual</p>
          <p class="text">
            El caso se encuentra en retención final.
          </p>

          <div v-if="currentStatus == 10" class="current-stage">
            <p class="title">Información del estado actual</p>
            <div class="buttons">
              <el-button
                v-if="
                  !refinementDraftId &&
                    (!retention || (retention && !retention.created))
                "
                type="primary"
                @click="$router.push(`/refinamiento/${$route.params.id}`)"
                class="button"
                >Comenzar refinamiento</el-button
              >
              <el-button
                v-else-if="!retention || (retention && !retention.created)"
                type="primary"
                @click="
                  $router.push(
                    `/refinamiento/${$route.params.id}?refinement_id=${refinementDraftId}`
                  )
                "
                class="button"
                >Continuar refinamiento</el-button
              >
              <el-button
                v-if="!retention"
                type="primary"
                @click="() => $router.push(`/retencion/${$route.params.id}`)"
                class="button"
                >Comenzar retención final</el-button
              >
            </div>
          </div>
        </div>

        <div v-if="currentStatus == 11" class="current-stage">
          <p class="title">Información del estado actual</p>
          <p class="text">El caso ha finalizado.</p>
        </div>
        <el-tabs v-model="minorTab" class="minor-tabs">
          <el-tab-pane label="Chat" name="chat" class="chat-panel">
            <div class="chat">
              <p class="title">Chat</p>
              <Chat
                :messages="localMessages"
                yesterdayLiteral="Ayer"
                todayLiteral="Hoy"
                placeholder=""
                v-on:send="pushMessage"
              />
            </div>
            <div v-if="isAdmin" class="admin-notes">
              <p class="title">Notas de administrador</p>
              <div class="notes-wrapper">
                <AdminNotes
                  class="admin-notes-text"
                  :notes="currentCase.adminNotes"
                  :id="$route.params.id"
                  v-on:save="saveAdminNotes"
                />
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="majorTab === 'TREATMENT'"
            label="Resumen del caso"
            :name="`SUMMARY_${majorTab}`"
            class="summary"
            :class="{ scroll: showAdminFiles }"
          >
            <div :class="{ 'scroll-1': showAdminFiles }">
              <el-button
                v-if="isAdmin"
                @click="adminFilesClicked = !adminFilesClicked"
                type="primary"
                class="button-admin-files"
                :class="{ open: adminFilesClicked }"
                >Mostrar archivos</el-button
              >
              <CaseSummaryV2
                v-if="currentCase && currentCase.v2"
                :isAdmin="isAdmin"
                :currentCase="currentCase"
                :addresses="addresses"
                v-on:change-case-state="changeCaseState"
              />

              <CaseSummary
                v-else-if="currentCase"
                :isAdmin="isAdmin"
                :currentCase="currentCase"
                :addresses="addresses"
                v-on:change-case-state="changeCaseState"
              />

              <div class="preferences-wrapper"
                v-if="isAdmin && clinicalPreferences"
              >
                <p class="preferences-title">Preferencias clínicas</p>

                <ClinicalPreferences
                  :ipr="clinicalPreferences.ipr"
                  :iprDelay="clinicalPreferences.iprDelay"
                  :attachmentsDelay="clinicalPreferences.attachmentsDelay"
                  :expansionForArcade="clinicalPreferences.expansionForArcade"
                  :pontics="clinicalPreferences.pontics"
                  :expansionOfArcade="clinicalPreferences.expansionOfArcade"
                  :arcadeLeveling="clinicalPreferences.arcadeLeveling"
                  :precisionCuts="clinicalPreferences.precisionCuts"
                  :notes="clinicalPreferences.notes"
                />
              </div>
            </div>
            <div :class="{ 'scroll-2': showAdminFiles }">
              <AdminFiles v-if="showAdminFiles" :files="files" />
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-else-if="majorTab === 'RETENTION' && retention"
            label="Resumen de la retención final"
            :name="`SUMMARY_${majorTab}`"
            class="summary"
          >
            <div>
              <RetentionSummaryV2
                v-if="retention.v2"
                :retention="retention"
                :isAdmin="isAdmin"
                :treatmentType="treatmentType"
              />

              <RetentionSummary
                v-else
                :retention="retention"
                :isAdmin="isAdmin"
                :treatmentType="treatmentType"
              />
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-else-if="currentRefinement"
            label="Resumen del refinamiento"
            :name="`SUMMARY_${majorTab}`"
            class="summary"
            :class="{ scroll: showAdminFiles }"
          >
            <div :class="{ 'scroll-1': showAdminFiles }">
              <el-button
                @click="adminFilesClicked = !adminFilesClicked"
                type="primary"
                class="button-admin-files refinement"
                :class="{ open: adminFilesClicked }"
                >Mostrar archivos</el-button
              >
              <RefinementSummaryV2
                v-if="currentRefinement && currentRefinement.v2"
                :refinement="currentRefinement"
                :isAdmin="isAdmin"
                v-on:change-case-state="changeCaseState"
                :membershipNumber="membershipNumber"
              />
              <RefinementSummary
                v-else
                :refinement="currentRefinement"
                :isAdmin="isAdmin"
                v-on:change-case-state="changeCaseState"
                :membershipNumber="membershipNumber"
              />
            </div>

            <div :class="{ 'scroll-2': showAdminFiles }">
              <AdminFiles v-if="showAdminFiles" :files="files" />
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="attachments"
            label="Plantilla de attachments"
            :name="`ATTACHMENTS_${majorTab}`"
          >
            <a v-if="attachments" :href="attachments" target="_blank">
              <el-button type="primary"
                >Descargar plantilla de attachments</el-button
              >
            </a>
            <p v-else>
              Todavía no esta disponible la plantilla de attachments. En breve
              podrá descargarla desde aquí.
            </p>
          </el-tab-pane>

          <el-tab-pane
            v-if="
              this.majorTab !== 'RETENTION' ||
                (this.majorTab === 'RETENTION' &&
                  this.retention.finalRetentionType === 'newMeasures')
            "
            label="Archivos"
            :name="`FILES_${majorTab}`"
          >
            <div class="files">
              <p class="title">
                Pincha en cada una para verla en alta definición
              </p>

              <div
                v-if="majorTab !== 'TREATMENT'"
                class="xray-refinement"
                ref="xray"
              >
                <a
                  v-if="files.unfit1Image"
                  :href="files.unfit1Image"
                  target="_blank"
                >
                  <img class="image" :src="files.unfit1Image" />
                </a>
                <img v-else class="image" src="../assets/unfit.jpg" />

                <a
                  v-if="files.unfit2Image"
                  :href="files.unfit2Image"
                  target="_blank"
                >
                  <img class="image" :src="files.unfit2Image" />
                </a>
                <img v-else class="image" src="../assets/unfit.jpg" />
              </div>

              <div class="xray">
                <a
                  v-if="files.xray1Image"
                  :href="files.xray1Image"
                  target="_blank"
                >
                  <img
                    class="image respect-height"
                    :style="imageWidth"
                    :src="files.xray1Image"
                  />
                </a>
                <img v-else class="image" src="../assets/x_ray1.jpg" />

                <a
                  v-if="files.xray2Image"
                  :href="files.xray2Image"
                  target="_blank"
                >
                  <img
                    class="image respect-width"
                    :style="imageWidth"
                    :src="files.xray2Image"
                  />
                </a>
                <img v-else class="image" src="../assets/x_ray2.jpg" />
              </div>

              <div class="photo">
                <a
                  v-if="files.lateralFacialImage"
                  :href="files.lateralFacialImage"
                  target="_blank"
                >
                  <img
                    v-show="files.lateralFacialImage"
                    ref="smallest"
                    class="image"
                    :src="files.lateralFacialImage"
                    @click="clickElement('lateralFacialImage')"
                  />
                </a>
                <img
                  v-else
                  class="image"
                  src="../assets/foto_facial_lateral.png"
                />

                <a
                  v-if="files.smileFacialImage"
                  :href="files.smileFacialImage"
                  target="_blank"
                >
                  <img
                    v-if="files.smileFacialImage"
                    class="image"
                    :src="files.smileFacialImage"
                  />
                </a>
                <img
                  v-else
                  class="image"
                  src="../assets/foto_facial_frontal.png"
                />

                <a
                  v-if="files.frontalFacialImage"
                  :href="files.frontalFacialImage"
                  target="_blank"
                >
                  <img
                    v-if="files.frontalFacialImage"
                    class="image"
                    :src="files.frontalFacialImage"
                    @click="clickElement('facialFrontal')"
                  />
                </a>
                <img
                  v-else
                  class="image"
                  src="../assets/foto_facial_frontal.png"
                />
              </div>

              <div class="mouth">
                <a
                  class="row1 column2"
                  :href="files.upperOcclusalImage"
                  target="_blank"
                >
                  <img
                    v-if="files.upperOcclusalImage"
                    class="image"
                    :src="files.upperOcclusalImage"
                  />
                  <img
                    v-else
                    class="image"
                    src="../assets/foto_facial_frontal.png"
                  />
                </a>

                <a
                  class="row2 column1"
                  :href="files.leftIntraoralImage"
                  target="_blank"
                >
                  <img
                    v-if="files.leftIntraoralImage"
                    class="image"
                    :src="files.leftIntraoralImage"
                  />
                  <img
                    v-else
                    class="image"
                    src="../assets/foto_intraoral_izquierda.png"
                  />
                </a>

                <a
                  class="row2 column2"
                  :href="files.centralIntraoralImage"
                  target="_blank"
                >
                  <img
                    v-if="files.centralIntraoralImage"
                    class="image"
                    :src="files.centralIntraoralImage"
                  />
                  <img
                    v-else
                    class="image"
                    src="../assets/foto_intraoral_frontal.png"
                  />
                </a>

                <a
                  class="row2 column3"
                  :href="files.rightIntraoralImage"
                  target="_blank"
                >
                  <img
                    v-if="files.rightIntraoralImage"
                    class="image"
                    :src="files.rightIntraoralImage"
                  />
                  <img
                    v-else
                    class="image"
                    src="../assets/foto_intraoral_derecha.png"
                  />
                </a>

                <a
                  class="row3 column2"
                  :href="files.lowerOcclusalImage"
                  target="_blank"
                >
                  <img
                    v-if="files.lowerOcclusalImage"
                    class="image"
                    :src="files.lowerOcclusalImage"
                  />
                  <img
                    v-else
                    class="image"
                    src="../assets/foto_oclusal_inferior.png"
                  />
                </a>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="
              (quickcheckHistory && quickcheckHistory.length) ||
                (vtoHistory && vtoHistory.length)
            "
            label="Historial de VTOs y QuickChecks"
          >
            <QuickcheckRecord
              :quickchecks="quickcheckHistory"
              :vtos="vtoHistory"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <ClearNotificationsModal
      :visible="showClearNotificationsModal"
      v-on:cancel="toggleClearNotificationsModal"
      v-on:confirm="clearNotifications"
    />
    <Loading :active="loading" />
    <PaymentAlert />

    <Footer class="footer-wrapper" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import CaseStages from '@/components/CaseStages'
import CaseSummary from '@/components/CaseSummary'
import CaseSummaryV2 from '@/components/CaseSummaryV2'
import Chat from '@/components/Chat'
import ClinicalPreferences from '@/components/ClinicalPreferences'
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import PaymentAlert from '@/components/PaymentAlert'
import QuickcheckRecord from '@/components/QuickcheckRecord'
import RefinementSummary from '@/components/RefinementSummary'
import Footer from '@/components/Footer'
import AdminNotes from '@/components/AdminNotes'
import AdminFiles from '@/components/AdminFiles'
import RetentionSummary from '@/components/RetentionSummary'
import RefinementSummaryV2 from '@/components/RefinementSummaryV2'
import RetentionSummaryV2 from '@/components/RetentionSummaryV2'

import { addYears, formatDate, getNameOfStage, getCaseType } from '@/utils'
import ClearNotificationsModal from '../components/ClearNotificationsModal.vue'

export default {
  name: 'Case',
  components: {
    CaseStages,
    CaseSummary,
    CaseSummaryV2,
    Chat,
    ClinicalPreferences,
    Header,
    Loading,
    PaymentAlert,
    QuickcheckRecord,
    RefinementSummary,
    Footer,
    AdminNotes,
    AdminFiles,
    RetentionSummary,
    ClearNotificationsModal,
    RefinementSummaryV2,
    RetentionSummaryV2
  },
  async beforeMount () {
    this.$store.dispatch('fetchCase', { caseId: this.$route.params.id })
    // TODO: ask the api for changes
    // this.pollInterval = setInterval(() => {
    //   this.$store.dispatch('fetchCase', {
    //     caseId: this.$route.params.id,
    //     hideLoading: true
    //   })
    // }, 30000)
  },
  beforeDestroy () {
    this.$store.commit('clearCurrentCase')
    // clearInterval(this.pollInterval)
  },
  watch: {
    updateCaseNotificationError (val) {
      if (val) {
        this.$notify({
          title: val.title,
          message: val.message,
          type: val.type
        })
        this.$store.dispatch('clearUpdateCaseNotificationError')
      }
    },
    currentCase (val) {
      if (val && !this.isAdmin) {
        this.$store.dispatch('updateNotifications', { id: val.id })
      }
    },
    majorTab (val) {
      if (val === 'RETENTION' && !this.retention.created) {
        this.$router.push(`/retencion/${this.currentCase.id}`)
      }
      this.minorTab = 'chat'
    },
    refinements (val, oldVal) {
      const createdRefinements = val
        ? val.filter(refinement => refinement.created)
        : []

      if (createdRefinements.length) {
        const refinementId =
          createdRefinements[createdRefinements.length - 1].id
        const refinementIndex = val.findIndex(x => x.id === refinementId)
        const tabName = `REFINEMENT_${refinementIndex}`

        if (!oldVal || !oldVal.length) {
          this.majorTab = tabName
        }
      }
    },
    retention (val, oldVal) {
      if (val && val.created) {
        if (val && !oldVal) {
          this.majorTab = 'RETENTION'
        }
      }
    },
    currentRefinement () {
      if (this.currentRefinement && !this.currentRefinement.created) {
        this.$router.push(
          `/refinamiento/${this.currentCase.id}?refinement_id=${this.currentRefinement.id}`
        )
      }
    },
    messages (messages, oldMessages) {
      if (
        (messages.length && !this.localMessages.length) ||
        messages.length !== oldMessages.length
      ) {
        this.localMessages = messages
      }
    }
  },
  mounted () {
    this.mounted = true
    const createdRefinements = this.refinements
      ? this.refinements.filter(refinement => refinement.created)
      : []

    if (this.retention && this.retention.created) {
      this.majorTab = 'RETENTION'
    } else if (createdRefinements.length) {
      const refinementId = createdRefinements[createdRefinements.length - 1].id
      const refinementIndex = this.refinements.findIndex(
        x => x.id === refinementId
      )
      const tabName = `REFINEMENT_${refinementIndex}`
      this.majorTab = tabName
    }
  },
  updated () {
    this.smallestImageWidth = this.$refs.smallest
      ? this.$refs.smallest.clientWidth
      : 'max-content'
  },
  computed: {
    // get smallest width to asign same width to all files
    imageWidth () {
      return `width: ${this.smallestImageWidth}px;`
    },
    ...mapGetters({
      createdAt: 'getCurrentCaseCreationDate',
      finishAt: 'getCurrentCaseFinishDate'
    }),
    ...mapState({
      currentCase: state => state.currentCase,
      notifications: state => state.notifications,
      role: state => state.me.role,
      loading: state => state.isLoading,
      addresses: state => state.addresses,
      patientName: state =>
        `${state.currentCase.patientName || ''} ${state.currentCase
          .patientSurname || ''}`,
      doctorEmail: state => state.me.email,
      addr: state => state.me.addresses,
      doctorImage: state => state.me.image,
      doctorName: state => state.me.fullName,
      patientPhoto: state => state.currentCase.patientPhoto,
      clinicalPreferences: state => state.currentCase.doctor.clinicalPreferences,
      caseOwner: state =>
        `${state.currentCase.doctor.name} ${state.currentCase.doctor.firstSurname}`,
      refinements: state => state.currentCase.refinements,
      updateCaseNotificationError: state => state.updateCaseNotificationError,
      retention: state => state.currentCase.retention,
      adminNotes: state => state.currentCase.adminNotes,
      schoolRole: state => state.me.schoolRole,
      membershipNumber: state => state.me.membershipNumber
    }),
    // majorTab: {
    //   set (val) {
    //     this.$store.commit('setTab', { majorTab: val })
    //   },
    //   get () {
    //     return this.$store.state.caseView.majorTab
    //   },
    // },
    // minorTab: {
    //   set (val) {
    //     this.$store.commit('setActiveTab', { minorTab: val })
    //   },
    //   get () {
    //     return this.$store.state.caseView.activeTab
    //   },
    // },
    currentStatus () {
      if (this.majorTab === 'TREATMENT') {
        return this.currentCase.status
      } else if (this.majorTab === 'RETENTION') {
        return this.currentCase.retention.status
      } else {
        return this.currentRefinement.status
      }
    },
    refinementTabs () {
      return this.refinements && this.refinements.length
        ? this.refinements.filter(
            refinement =>
              (this.currentCase && this.currentCase.status !== '11') ||
              refinement.created
          )
        : []
    },
    attachments () {
      if (this.majorTab === 'TREATMENT') {
        return this.currentCase.attachments
      } else {
        return this.currentRefinement && this.currentRefinement.attachments
      }
    },
    quickcheckHistory () {
      if (this.majorTab === 'TREATMENT') {
        return this.currentCase && this.currentCase.quickcheckHistory
          ? this.currentCase.quickcheckHistory
          : []
      } else if (this.majorTab === 'RETENTION') {
        return this.currentCase &&
          this.currentCase.retention &&
          this.currentCase.retention.quickcheckHistory
          ? this.currentCase.retention.quickcheckHistory
          : []
      } else {
        return this.currentRefinement &&
          this.currentRefinement.quickcheckHistory
          ? this.currentRefinement.quickcheckHistory
          : []
      }
    },
    vtoHistory () {
      if (this.majorTab === 'TREATMENT') {
        return this.currentCase && this.currentCase.vtoHistory
          ? this.currentCase.vtoHistory
          : []
      } else if (this.majorTab === 'RETENTION') {
        return this.currentCase &&
          this.currentCase.retention &&
          this.currentCase.retention.vtoHistory
          ? this.currentCase.retention.vtoHistory
          : []
      } else {
        return this.currentRefinement && this.currentRefinement.vtoHistory
          ? this.currentRefinement.vtoHistory
          : []
      }
    },
    files () {
      const defaultCase = {
        xray1Image: '',
        xray2Image: '',
        lateralFacialImage: '',
        smileFacialImage: '',
        frontalFacialImage: '',
        upperOcclusalImage: '',
        leftIntraoralImage: '',
        centralIntraoralImage: '',
        rightIntraoralImage: '',
        lowerOcclusalImage: ''
      }

      if (this.majorTab === 'RETENTION') {
        return this.retention
      } else if (this.majorTab === 'TREATMENT') {
        return this.currentCase
      } else {
        return this.currentRefinement || defaultCase
      }
    },
    refinementDraftId () {
      const refinementDraft = this.refinements.find(x => !x.created)

      return refinementDraft ? refinementDraft.id : ''
    },
    currentRefinement () {
      if (this.majorTab.includes('REFINEMENT')) {
        return this.refinements[
          String(this.majorTab).replace('REFINEMENT_', '')
        ]
      } else {
        return null
      }
    },
    signature () {
      return this.currentCase.signature
    },
    merchantParameters () {
      return this.currentCase.merchantParameters
    },
    nextStateName () {
      return getNameOfStage(this.nextState, getCaseType(this.currentCase))
    },
    treatmentType () {
      return this.currentCase ? this.currentCase.computedTreatmentType : ''
    },
    isAdmin () {
      return this.role === 'ADMIN'
    },
    quickcheck () {
      return this.quickcheckHistory && this.quickcheckHistory.length
        ? this.quickcheckHistory[this.quickcheckHistory.length - 1]
        : null
    },
    quickcheckUrl () {
      return this.quickcheck ? this.quickcheck.quickcheck : ''
    },
    vto () {
      return this.vtoHistory && this.vtoHistory.length
        ? this.vtoHistory[this.vtoHistory.length - 1]
        : null
    },
    vtoUrl () {
      return this.vto ? this.vto.vto : ''
    },
    messages () {
      return this.currentCase.messages
        ? this.currentCase.messages.map(message => ({
            text: message.text,
            participant: message.author !== 'ADMIN' ? 'internal' : 'external',
            id: message.id,
            date: message.createdAt
          }))
        : []
    },
    showAdminFiles () {
      return (
        this.isAdmin &&
        this.adminFilesClicked &&
        [
          this.files.unfit1Image,
          this.files.unfit2Image,
          this.files.xray1Image,
          this.files.xray2Image,
          this.files.lateralFacialImage,
          this.files.smileFacialImage,
          this.files.frontalFacialImage,
          this.files.upperOcclusalImage,
          this.files.leftIntraoralImage,
          this.files.centralIntraoralImage,
          this.files.rightIntraoralImage,
          this.files.lowerOcclusalImage
        ].find(x => x)
      )
    },
    areAlignmentsMissing () {
      return this.currentCase && !this.currentCase.full
    }
  },
  methods: {
    toggleClearNotificationsModal () {
      this.showClearNotificationsModal = !this.showClearNotificationsModal
    },
    clearNotifications () {
      if (this.showClearNotificationsModal) {
        this.toggleClearNotificationsModal()
      }
      this.$store.dispatch('updateNotifications', {
        id: this.$route.params.id
      })
    },
    async pushMessage (message) {
      this.$store.dispatch('pushMessage', { message })
      if (this.isAdmin) {
        this.clearNotifications()
      }
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    formatDate,
    addYears,
    closeDialog () {
      this.isDialogVisible = false
    },
    confirmChangeCaseState () {
      this.isDialogVisible = false
      this.changeCaseState(this.nextState)
    },
    openDialog (stateNumber) {
      const currentStatus = parseInt(this.currentStatus)
      if (
        this.role === 'ADMIN' ||
        [3, 4, 5, 9, 10, 11, 12].includes(currentStatus)
      ) {
        this.nextState = stateNumber
        this.isDialogVisible = true
      }
    },
    clickElement (ref) {
      this.$refs[ref].click()
    },
    goToCheckout (kind) {
      this.$refs.formPayment.submit()
    },
    async changeCaseState (state) {
      this.$store.dispatch('changeCaseState', {
        state,
        currentTab: this.majorTab
      })
    },
    saveAdminNotes (notes) {
      this.$store.dispatch('saveAdminNotes', {
        id: this.$route.params.id,
        notes
      })
    },
    async sendMissingAlignmentsEmail () {
      await this.$store.dispatch('sendMissingAlignmentsEmail', {
        id: this.$route.params.id
      })

      this.$notify({
        title: 'Éxito',
        message: 'El caso vuelve a estar En Producción',
        type: 'success'
      })
    }
  },
  data () {
    return {
      smallestImageWidth: 'max-content',
      pollInterval: null,
      minorTab: 'chat',
      majorTab: 'TREATMENT',
      localMessages: [],
      nextState: null,
      isDialogVisible: false,
      id: this.$route.params.id,
      activeName: 'first',
      newMessage: '',
      participants: [],
      titleImageUrl:
        'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#000',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#fff',
          text: '#000'
        },
        userInput: {
          bg: '#fff',
          text: '#000'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      adminFilesClicked: false,
      showClearNotificationsModal: false
    }
  }
}
</script>

<style lang="scss">
.sc-message--text,
.sc-message--file-text {
  white-space: pre-wrap;
}

.shipping-tracking-link {
  font-size: 18px;
}

.download-link {
  text-decoration: none;
  color: white;
}

.preferences-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 24px 0;


  .preferences-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}

.summary {
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  .button-admin-files {
    margin-bottom: 40px;

    &.retention,
    &.refinement {
      margin-bottom: 0;
    }

    &.open {
      margin-left: 120px;
    }
  }

  &.scroll {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr auto;

    .scroll-2 {
      direction: rtl;
      resize: horizontal;
      width: 300px;

      .image {
        height: auto;
        width: 100%;
      }
    }

    .scroll-1,
    .scroll-2 {
      height: 100vh;
      overflow-y: scroll;
    }
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .dialog-footer {
    margin-top: 32px;
  }

  .case-name {
    font-weight: bold;
  }
}

.bold {
  font-weight: bold;
}

.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  .major-tabs {
    max-width: 98vw;
  }
  .list {
    padding: 16px;

    .case {
      display: inline-block;
      margin-bottom: 24px;
      margin-right: 16px;
    }

    .empty {
      margin-top: 16px;
    }
  }

  // .footer-wrapper {
  // padding: 0 24px 24px 24px;
  // }
}

.quickcheck {
  height: 40px;
}

.sc-launcher {
  display: none;
}

// .anexo {
//   width: 120px;
//   cursor: pointer;
// }

.current-case {
  padding: 16px;

  .row {
    display: flex;
    justify-content: space-between;

    .btns {
      display: flex;

      .btn {
        height: min-content;
        margin-right: 8px;
        width: min-content;
      }
    }
  }

  .minor-tabs {
    .chat-panel {
      display: flex;

      .notes-wrapper {
        padding: 8px 32px 8px 32px;
      }

      .admin-notes-text {
        margin-top: 8px;
        width: 100%;
        height: 464px;
        resize: none;
        max-width: 700px;
      }
    }
  }

  .patient {
    display: flex;
    .photo {
      width: 152px;
      height: 152px;
      border-radius: 50%;
    }

    .info {
      margin-left: 40px;

      * {
        margin: 0;
      }

      .name {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
        color: #000000;

        .owner {
          font-weight: bold;
        }
      }

      .field {
        .bold {
          font-weight: 500;
        }

        margin-bottom: 8px;
        font-size: 20px;
      }
    }
  }

  .current-stage {
    padding-bottom: 56px;
    border-bottom: 1px solid #e0e0e0;

    .title {
      font-weight: bold;
      font-size: 24px;
    }

    .text {
      font-size: 16px;

      .disclaimer {
        font-size: 14px;
        font-style: italic;
      }
    }
  }

  .case-data {
    margin-bottom: 54px;

    .title {
      margin-bottom: 24px;
      font-size: 24px;
      font-weight: 500;
    }

    .content {
      display: flex;
      margin-right: 24px;

      .column {
        margin-right: 24px;

        .subtitle {
          margin-bottom: 16px;
          font-size: 20px;
          font-weight: 500;
        }

        .address {
          padding: 16px;
          width: 256px;
          height: 256px;
          border-radius: 15px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
          background-color: #ffffff;
          font-weight: 300;

          .field {
            display: flex;
            align-items: center;
            height: 32px;
            font-size: 12px;
            margin-bottom: 8px;

            .bold {
              font-weight: normal;
              margin-left: 4px;
            }
          }

          .edit {
            height: 24px;
            font-size: 16px;
            font-weight: 500;
            text-align: right;
            color: #000000;
          }
        }

        .credit-card {
          width: 352px;
          height: 205px;
          border-radius: 20px;
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
          background-image: linear-gradient(
            to bottom,
            #ffffff,
            rgba(233, 233, 233, 0.9) 83%,
            #d1d1d1
          );
        }
      }
    }
  }

  .files {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      font-weight: bold;
    }

    .xray {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 16px;

      margin-bottom: 32px;
    }

    .xray-refinement {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-bottom: 32px;
    }

    .photo {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;

      margin-bottom: 32px;
    }

    .image {
      width: max-content;
      height: auto;
      border-radius: 0;
      width: 100%;

      &.respect-width {
        width: 100% !important;
        height: auto;
      }

      &.respect-height {
        height: auto;
        width: auto;
      }
    }

    .mouth {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto auto auto;

      .row1 {
        grid-row-start: 1;
        grid-row-end: 1;
      }
      .row2 {
        grid-row-start: 2;
        grid-row-end: 2;
      }
      .row3 {
        grid-row-start: 3;
        grid-row-end: 3;
      }
      .column1 {
        grid-column-start: 1;
        grid-column-end: 1;
      }
      .column2 {
        grid-column-start: 2;
        grid-column-end: 2;
      }
      .column3 {
        grid-column-start: 3;
        grid-column-end: 3;
      }
    }
  }

  .title {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
  }

  .chat {
    .window {
      .sc-message--content {
        &.sent {
          padding: 0 45px 0 0;
        }
      }
      .sc-chat-window {
        border-radius: 10px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        position: initial;
        width: 536px;
        height: 352px;

        .sc-header {
          display: none;
        }

        .sc-message-list {
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;

          .sc-message {
            width: 100%;
          }

          .sc-message--text {
            border: 1px solid black;
          }
        }
        .sc-user-input--text {
          width: 400px;
        }
      }
    }
  }

  .timeline {
    padding: 0;
  }
}

.shipping-sheet-link {
  .el-link--inner {
    font-size: 18px !important;
  }
}

@media (max-width: 1200px) {
  .minor-tabs {
    .chat-panel {
      display: flex;
      flex-direction: column;

      .title {
      }

      .admin-notes {
      }

      .admin-notes-text {
        margin-top: 8px;
        width: 90%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .current-case {
    .row {
      display: flex;
      flex-direction: column;

      .patient {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          margin: 0;
          text-align: center;
        }
      }

      .btn,
      .quickcheck-wrapper {
        margin-top: 16px;
        text-align: center;
      }
    }

    .minor-tabs {
      max-width: 98vw;
      .chat-panel {
        display: flex;

        .admin-notes-text {
          margin-top: 8px;
          width: 90%;
          height: 100%;
        }
      }
    }

    .current-stage {
      padding-bottom: 56px;
      border-bottom: 1px solid #e0e0e0;

      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;

        .button {
          white-space: normal;
          margin: 0;
        }
      }
      .title {
      }

      .text {
        .disclaimer {
        }
      }
    }

    .case-data {
      margin-bottom: 54px;

      .title {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 500;
      }

      .content {
        display: flex;
        margin-right: 24px;

        .column {
          margin-right: 24px;

          .subtitle {
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 500;
          }

          .address {
            padding: 16px;
            width: 256px;
            height: 256px;
            border-radius: 15px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
            background-color: #ffffff;
            font-weight: 300;

            .field {
              display: flex;
              align-items: center;
              height: 32px;
              font-size: 12px;
              margin-bottom: 8px;

              .bold {
                font-weight: normal;
                margin-left: 4px;
              }
            }

            .edit {
              height: 24px;
              font-size: 16px;
              font-weight: 500;
              text-align: right;
              color: #000000;
            }
          }

          .credit-card {
            width: 352px;
            height: 205px;
            border-radius: 20px;
            box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
            background-image: linear-gradient(
              to bottom,
              #ffffff,
              rgba(233, 233, 233, 0.9) 83%,
              #d1d1d1
            );
          }
        }
      }
    }

    .files {
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        font-weight: bold;
      }

      .xray {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;

        margin-bottom: 32px;
      }

      .photo {
        display: flex;
        flex-direction: column;
      }

      .image {
        width: 250px;
        height: auto;
      }

      .mouth {
        display: flex;
        flex-direction: column;

        .row1 {
          grid-row-start: 1;
          grid-row-end: 1;
        }
        .row2 {
          grid-row-start: 2;
          grid-row-end: 2;
        }
        .row3 {
          grid-row-start: 3;
          grid-row-end: 3;
        }
        .column1 {
          grid-column-start: 1;
          grid-column-end: 1;
        }
        .column2 {
          grid-column-start: 2;
          grid-column-end: 2;
        }
        .column3 {
          grid-column-start: 3;
          grid-column-end: 3;
        }
      }
    }

    .chat {
      .title {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 500;
      }

      .window {
        .sc-message--content {
          &.sent {
            padding: 0 45px 0 0;
          }
        }
        .sc-chat-window {
          border-radius: 10px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
          background-color: #ffffff;
          position: initial;
          width: 536px;
          height: 352px;

          .sc-header {
            display: none;
          }

          .sc-message-list {
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;

            .sc-message {
              width: 100%;
            }

            .sc-message--text {
              border: 1px solid black;
            }
          }
          .sc-user-input--text {
            width: 400px;
          }
        }
      }
    }
  }
}
</style>
