<template>
  <div class="summary">
    <ModalAskForUpdateStatus
      v-if="askForUpdateToReviewStatus"
      @close="closeModal"
      @update-status="updateStatus"
      :elementToReview="askForUpdateToReviewStatus"
    />
    <el-form
      class="form"
      :model="form"
      ref="form"
      :hide-required-asterisk="true"
    >
      <el-button v-if="isAdmin" type="primary" @click="updateRefinement"
        >Actualizar refinamiento</el-button
      >

      <p class="title">Refinamiento</p>
      <div class="fields">
        <span v-if="isAdmin">
          <span
            v-for="(vto, index) of form.vtoHistory"
            :key="vto.vto"
            class="quickcheck-input"
          >
            <el-form-item label="" class="margin-bottom">
              <el-input
                v-model.trim="vto.vto"
                placeholder=""
                :disabled="true"
              />
            </el-form-item>
            <i class="el-icon-close" @click="deleteVto(index)"></i>
          </span>
        </span>

        <el-form-item v-if="isAdmin" label="" class="margin-bottom">
          <el-input v-model.trim="newVto" placeholder="Nuevo vto" />
        </el-form-item>

        <span v-if="isAdmin">
          <span
            v-for="(quickcheck, index) of form.quickcheckHistory"
            :key="quickcheck.quickCheck"
            class="quickcheck-input"
          >
            <el-form-item label="" class="margin-bottom">
              <el-input
                v-model.trim="quickcheck.quickcheck"
                placeholder=""
                :disabled="true"
              />
            </el-form-item>
            <i class="el-icon-close" @click="deleteQuickcheck(index)"></i>
          </span>
        </span>

        <el-form-item v-if="isAdmin" label="" class="margin-bottom">
          <el-input
            v-model.trim="newQuickcheck"
            placeholder="Nuevo quickCheck"
          />
        </el-form-item>

        <el-form-item
          v-if="isAdmin"
          label=""
          class="margin-bottom"
          ref="attachments"
          prop="attachments"
        >
          <p class="label">Plantilla de attachments</p>
          <input
            v-on:change="e => onUploadImage(e, 'attachments')"
            type="file"
          />
          <el-input
            v-model.trim="form.attachments"
            placeholder="Plantilla de attachments"
            :disabled="true"
          />
        </el-form-item>

        <Fieldset class="fieldset" label="Objetivos el refinamiento">
          <el-input
            v-model="form.goals"
            rows="8"
            type="textarea"
            resize="none"
            :disabled="!isAdmin"
          />
        </Fieldset>

        <FieldUnfitImages
          :isAdmin="isAdmin"
          :unfit1Image="form.unfit1Image"
          :unfit2Image="form.unfit2Image"
          v-on:input-unfit-1="x => (form.unfit1Image = x)"
          v-on:input-unfit-2="x => (form.unfit2Image = x)"
        />

        <LoadPhotographies
          class="big"
          :membershipNumber="membershipNumber"
          :lateralFacialImage="form.lateralFacialImage"
          @lateralFacialImage="e => (form.lateralFacialImage = e)"
          :frontalFacialImage="form.frontalFacialImage"
          @frontalFacialImage="e => (form.frontalFacialImage = e)"
          :smileFacialImage="form.smileFacialImage"
          @smileFacialImage="e => (form.smileFacialImage = e)"
          :upperOcclusalImage="form.upperOcclusalImage"
          @upperOcclusalImage="e => (form.upperOcclusalImage = e)"
          :lowerOcclusalImage="form.lowerOcclusalImage"
          @lowerOcclusalImage="e => (form.lowerOcclusalImage = e)"
          :rightIntraoralImage="form.rightIntraoralImage"
          @rightIntraoralImage="e => (form.rightIntraoralImage = e)"
          :centralIntraoralImage="form.centralIntraoralImage"
          @centralIntraoralImage="e => (form.centralIntraoralImage = e)"
          :leftIntraoralImage="form.leftIntraoralImage"
          @leftIntraoralImage="e => (form.leftIntraoralImage = e)"
        />

        <Fieldset class="fieldset big" label="Cargar radiografías">
          <input
            class="none"
            ref="xray1Image"
            v-on:change="e => onUploadImage(e, 'xray1Image')"
            type="file"
          />
          <input
            class="none"
            ref="xray2Image"
            v-on:change="e => onUploadImage(e, 'xray2Image')"
            type="file"
          />

          <div class="grid-uploader">
            <div class="item">
              <img
                src="../assets/x_ray1.jpg"
                v-if="!form.xray1Image"
                @click="() => isAdmin && triggerUpload('xray1Image')"
                ref="xray1Icon"
                class="big-uploader"
              />
              <img
                v-show="form.xray1Image"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="xray1ImageImage"
                @click="() => isAdmin && triggerUpload('xray1Image')"
                :src="form.xray1Image"
              />
              <el-form-item label class prop="xray1Image">
                <el-input
                  class="none"
                  v-model="form.xray1Image"
                  ref="xray1ImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>

            <div class="item">
              <img
                src="../assets/x_ray2.jpg"
                v-if="!form.xray2Image"
                @click="() => isAdmin && triggerUpload('xray2Image')"
                ref="xray1Icon"
                class="big-uploader"
              />
              <img
                v-show="form.xray2Image"
                class="big-uploader"
                :class="{ clickable: isAdmin }"
                ref="xray2ImageImage"
                @click="() => isAdmin && triggerUpload('xray2Image')"
                :src="form.xray2Image"
              />
              <el-form-item label class prop="xray2Image">
                <el-input
                  class="none"
                  v-model="form.xray2Image"
                  ref="xray2ImageUrl"
                  type="text"
                  placeholder="url"
                />
              </el-form-item>
            </div>
          </div>
        </Fieldset>

        <RegistryTypeSelection
          :disabled="!isAdmin"
          :typeOfImpressions="form.typeOfImpressions"
          @typeOfImpressions="e => (form.typeOfImpressions = e)"
          :upperIntraoralScanner="form.upperIntraoralScanner"
          @upperIntraoralScanner="e => (form.upperIntraoralScanner = e)"
          :lowerIntraoralScanner="form.lowerIntraoralScanner"
          @lowerIntraoralScanner="e => (form.lowerIntraoralScanner = e)"
          :occlusalIntraoralScanner="form.occlusalIntraoralScanner"
          @occlusalIntraoralScanner="e => (form.occlusalIntraoralScanner = e)"
          :lowerIntraoralScannerName="form.lowerIntraoralScannerName"
          @lowerIntraoralScannerName="e => (form.lowerIntraoralScannerName = e)"
          :upperIntraoralScannerName="form.upperIntraoralScannerName"
          @upperIntraoralScannerName="e => (form.upperIntraoralScannerName = e)"
          :occlusalIntraoralScannerName="form.occlusalIntraoralScannerName"
          @occlusalIntraoralScannerName="
            e => (form.occlusalIntraoralScannerName = e)
          "
          :typeOfImpressionsAddress="form.typeOfImpressionsAddress"
          @typeOfImpressionsAddress="e => (form.typeOfImpressionsAddress = e)"
        />
      </div>
    </el-form>
    <el-button
      v-if="isAdmin"
      class="submit"
      type="primary"
      @click="updateRefinement"
      >Actualizar refinamiento</el-button
    >
  </div>
</template>

<script>
import { uploadBytes, getDownloadURL } from "firebase/storage";
import Fieldset from '@/components/Fieldset'
import FieldUnfitImages from '@/components/FieldUnfitImages'
import ModalAskForUpdateStatus from '@/components/ModalAskForUpdateStatus'
import LoadPhotographies from '@/components/form/LoadPhotographies'
import RegistryTypeSelection from '@/components/form/RegistryTypeSelection'

import { PDF_DOWNLOAD_URL } from '@/constants'

export default {
  name: 'RefinementSummary',
  props: {
    refinement: {
      type: Object,
      required: false
    },
    isAdmin: {
      type: Boolean,
      required: false
    },
    // TODO
    membershipNumber: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      shippingSheetPdf: PDF_DOWNLOAD_URL.MEASURES,
      clickCounter: 0,
      isDialogVisible: false,
      lowerIntraoralScannerName: '',
      upperIntraoralScannerName: '',
      newQuickcheck: '',
      newVto: '',
      form: {
        attachments: '',
        ...this.refinement,
        quickcheckHistory:
          this.refinement && this.refinement.quickcheckHistory
            ? this.refinement.quickcheckHistory.map(x => ({
                date: x.date,
                quickcheck: x.quickcheck
              }))
            : [],
        vtoHistory:
          this.refinement && this.refinement.vtoHistory
            ? this.refinement.vtoHistory.map(x => ({
                date: x.date,
                vto: x.vto
              }))
            : [],
        case: this.$route.params.id
      },
      askForUpdateToReviewStatus: false
    }
  },
  methods: {
    closeModal () {
      this.askForUpdateToReviewStatus = false
    },
    updateStatus (reviewStatus) {
      this.$emit('change-case-state', reviewStatus)
      this.askForUpdateToReviewStatus = false
    },
    deleteQuickcheck (index) {
      this.form.quickcheckHistory.splice(index, 1)
      this.updateRefinement()
    },
    deleteVto (index) {
      this.form.vtoHistory.splice(index, 1)
      this.updateRefinement()
    },
    closeDialog () {
      this.isDialogVisible = false
    },
    tryUpdateRefinement () {
      this.isDialogVisible = true
    },
    updateRefinement () {
      delete this.form.created

      if (this.newQuickcheck) {
        this.form.quickcheckHistory.push({
          quickcheck: this.newQuickcheck,
          date: new Date()
        })
      }

      if (this.newVto) {
        this.form.vtoHistory.push({ vto: this.newVto, date: new Date() })
      }

      this.$store.dispatch('updateRefinement', {
        reload: true,
        form: this.form
      })

      if (this.newQuickcheck) {
        this.askForUpdateToReviewStatus = 'quickcheck'
      }

      if (this.newVto) {
        this.askForUpdateToReviewStatus = 'vto'
      }

      this.newQuickcheck = ''
      this.newVto = ''
    },
    removePhoto (img) {
      this.form[img] = ''
      this[img + 'Name'] = null
    },
    clearUploads (names) {
      this.clickCounter += 1
      if (this.clickCounter % 2 === 0) {
        names.forEach(name => {
          this.form[name] = ''
        })
      }
    },
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    },
    async onUploadImage (event, fieldName) {
      const file = event.target.files[0]

      if (fieldName === 'patientPhoto') {
        this.patientPhotoName = file.name
      } else if (fieldName === 'lowerIntraoralScanner') {
        this.lowerIntraoralScannerName = file.name
      } else if (fieldName === 'upperIntraoralScanner') {
        this.upperIntraoralScannerName = file.name
      } else if (fieldName === 'occlussionIntraoralScanner') {
        this.occlussionIntraoralScannerName = file.name
      } else if (fieldName === 'attachments') {
        this.attachments = file.name
      }

      const extensions = file.name.match(/\.[0-9a-z]+$/i)
      const extension = extensions ? extensions[0] : ''
      uploadBytes(this.$firebase(this.membershipNumber, fieldName + Date.now() + extension), file)
      .then( (snapshot) => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
            this.form[fieldName] = downloadURL

            if (this.$refs[fieldName + 'Image']) {
              this.$refs[fieldName + 'Image'].style.display = 'auto'
              this.$refs[fieldName + 'Image'].src = downloadURL
            }
          })
        }
      )
    },
    triggerUpload (name) {
      this.$refs[name].click()
    },
    goToNextStep () {
      this.step += 1
      this.updateRefinement()
    },
    goToPreviousStep () {
      this.step -= 1
    }
  },
  watch: {
    refinement (val) {
      this.form = {
        ...this.form,
        ...val,
        quickcheckHistory:
          val && val.quickcheckHistory
            ? val.quickcheckHistory.map(x => ({
                date: x.date,
                quickcheck: x.quickcheck
              }))
            : [],
        vtoHistory:
          val && val.vtoHistory
            ? val.vtoHistory.map(x => ({ date: x.date, vto: x.vto }))
            : []
      }
    }
  },
  components: {
    FieldUnfitImages,
    Fieldset,
    ModalAskForUpdateStatus,
    LoadPhotographies,
    RegistryTypeSelection
  }
}
</script>

<style lang="scss" scoped>
.none {
  display: none;
}

.submit {
  margin-top: 32px;
}

.clickable {
  cursor: pointer;
}

.step {
  width: 392px;
}

.radio-label {
  display: flex;
}

.radio-label,
.checkbox-label {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.el-checkbox,
.el-radio {
  display: flex;

  .el-checkbox__label,
  .radio-label {
    white-space: break-spaces;
  }
}

.summary {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .form {
    .fields {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      // width: 392px;

      .fieldset {
        width: 392px;

        &.big {
          width: fit-content;
        }
      }

      .quickcheck-input {
        display: flex;
        flex-direction: row;

        .el-icon-close {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }

    .derivation-custom {
      .radio-label {
        white-space: initial;
      }
    }
  }

  .title {
    margin: 0;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    font-size: 36px;
    font-weight: 500;
  }

  .navigation {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .grid-uploader {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 24px;
  }

  .uploader {
    font-size: 25px;
    padding: 4px 24px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  }

  .big-uploader {
    width: 208px;
    height: 104px;
    font-size: 25px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  }

  .big-uploader-text {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 208px;
    height: 104px;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .text {
      padding: 16px;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .dialog-footer {
    text-align: right;
  }
}

.intraoral-scanner-grid {
  display: flex;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.custom-error {
  color: #f56c6c;
  font-size: 12px;
}

.margin {
  &-bottom {
    margin-bottom: 16px;
  }

  &-top {
    margin-top: 16px;
  }

  &-right {
    margin-right: 16px;
  }

  &-left {
    margin-left: 16px;
  }
}

@media (max-width: 768px) {
  .radio-button-replacement {
    display: flex !important;
    margin: 0 0 8px 0 !important;
  }

  .radio-button {
    display: none !important;
  }

  .summary {
    // width: 100%;

    .form {
      // width: 100%;

      .fields {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        // width: 392px;

        .derivation-custom {
          .radio-label {
            white-space: initial;
          }
        }

        .teeth {
          .teeth-row {
            flex-direction: column;
            margin-bottom: 24px;
          }
        }

        .fieldset-address {
          display: flex;

          .card {
            align-self: center;
            max-width: 96%;
          }
        }

        .wrapper {
          display: flex;
        }
      }
    }

    .grid-uploader {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;

      .item {
        display: flex;
        justify-self: center;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
