<template>
  <div class="chat">
    <div class="message-list" ref="messageList">
      <div
        v-for="(msg, index) in messages"
        :key="msg.id"
        class="msg-row"
        :ref="`message-${index}`"
        :data-index="index"
      >
        <div v-if="isDifferentDate(index)" class="date">
          {{ formatDate(msg.date) }}
        </div>
        <div class="message" :class="[msg.participant]">
          <div v-if="msg.participant === 'internal'" class="time">
            {{ formatTime(msg.date) }}
          </div>
          <div class="c-text" :class="[msg.participant]">
            <p>{{ msg.text }}</p>
          </div>
          <div v-if="msg.participant === 'external'" class="time">
            {{ formatTime(msg.date) }}
          </div>
        </div>
      </div>
    </div>
    <form class="chat-message-bar" v-on:submit.prevent="onSubmit">
      <textarea v-model="internalMessage" class="hidden" />
      <div
        tabindex="0"
        contenteditable="true"
        class="msg-user-input"
        ref="messageInput"
        @click="setFocus"
        @keyup.prevent.shift.13="addCarriageReturn"
        @input="write"
        @keyup.prevent.exact.13="onSubmit"
      >
        {{ message }}
      </div>
      <button class="send">Enviar</button>
    </form>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import 'dayjs/locale/es'

dayjs.extend(calendar)

export default {
  name: 'Chat',
  props: {
    todayLiteral: {
      type: String
    },
    yesterdayLiteral: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Write a message...'
    },
    messages: {
      type: Array
    }
  },

  mounted () {
    this.scrollMessageListDown()
  },
  updated () {
    this.scrollMessageListDown()
  },

  data () {
    return {
      isMessageSent: false,
      internalMessage: '',
      message: this.placeholder
    }
  },
  watch: {
    messages (val, oldVal) {
      if (val.length !== oldVal.length) {
        this.scrollMessageListDown()
      }
    }
  },
  methods: {
    goToInputEnd (input) {
      input.scrollLeft = input.scrollWidth
      input.setSelectionRange(input.value.length, input.value.length)
    },
    scrollMessageListDown () {
      this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight
    },
    formatTime (time) {
      return dayjs(time).format('HH:mm')
    },
    formatDate (date) {
      dayjs().calendar(dayjs(date))
      return dayjs(date)
        .locale('en')
        .calendar(undefined, {
          sameDay: this.todayLiteral ? `[${this.todayLiteral}]` : '[Today]',
          lastDay: this.yesterdayLiteral
            ? `[${this.yesterdayLiteral}]`
            : '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
    },
    isDifferentDate (index) {
      return (
        index === 0 ||
        this.formatDate(this.messages[index].date) !==
          this.formatDate(this.messages[index - 1].date)
      )
    },
    addCarriageReturn (event) {
      event.preventDefault()
    },
    setFocus () {
      if (this.message === this.placeholder) {
        this.message = ''
      }
    },
    write (input) {
      if (this.isMessageSent) {
        this.message = ''
        input.target.innerText = input.target.innerText.replace(
          this.placeholder,
          ''
        )
        this.isMessageSent = false
        setAtEndOfContentEditable(this.$refs.messageInput)
      }

      this.internalMessage = input.target.innerText
    },
    onSubmit (e) {
      if (this.internalMessage.trim()) {
        this.$emit('send', this.internalMessage.trim())
        this.$refs.messageInput.innerText = this.placeholder
        this.isMessageSent = true
        this.message = ''
        this.scrollMessageListDown()
      }
    }
  }
}

// https://stackoverflow.com/questions/1181700/set-cursor-position-on-contenteditable-div
const voidNodeTags = [
  'AREA',
  'BASE',
  'BR',
  'COL',
  'EMBED',
  'HR',
  'IMG',
  'INPUT',
  'KEYGEN',
  'LINK',
  'MENUITEM',
  'META',
  'PARAM',
  'SOURCE',
  'TRACK',
  'WBR',
  'BASEFONT',
  'BGSOUND',
  'FRAME',
  'ISINDEX'
]

function setAtEndOfContentEditable (contentEditableElement) {
  while (
    getLastChildElement(contentEditableElement) &&
    canContainText(getLastChildElement(contentEditableElement))
  ) {
    contentEditableElement = getLastChildElement(contentEditableElement)
  }

  var range, selection
  if (document.createRange) {
    // Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange()
    range.selectNodeContents(contentEditableElement)
    range.collapse(false)
    selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
  } else if (document.selection) {
    // IE 8 and lower
    range = document.body.createTextRange()
    range.moveToElementText(contentEditableElement)
    range.collapse(false)
    range.select()
  }
}

function canContainText (node) {
  // eslint-disable-next-line
  if (node.nodeType == 1) {
    // is an element node
    return !voidNodeTags.includes(node.nodeName)
  } else {
    // is not an element node
    return false
  }
}

function getLastChildElement (el) {
  var lc = el.lastChild
  // eslint-disable-next-line
  while (lc && lc.nodeType != 1) {
    if (lc.previousSibling) lc = lc.previousSibling
    else break
  }
  return lc
}
</script>

<style scoped lang="scss">
.chat {
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 480px auto;
  justify-content: center;
  padding: 8px 32px;
  width: 700px;

  .date {
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    padding: 8px;
  }

  .message-list {
    background-color: #f1f1f1;
    border-radius: 10px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 8px;

    .message {
      padding: 4px 0;
      display: flex;
      align-content: flex-end;

      .internal,
      .external {
        display: flex;
        padding: 16px 16px 16px 16px;
        border: 1px solid black;
        display: flex;
        border-radius: 28px;

        p {
          padding: 0;
          margin: 0;
        }
      }

      &.internal {
        justify-content: flex-end;

        .c-text {
        }
      }

      &.external {
        justify-content: flex-start;

        .c-text {
          background: black;
          color: white;
        }
      }

      .c-text {
        max-width: 300px;
        white-space: pre-wrap;
        word-break: break-word;
      }

      .time {
        display: flex;
        align-self: end;
        font-size: 12px;
        color: #aaa;
        font-weight: 300;
        margin: 0 8px;
      }
    }
  }

  .chat-message-bar {
    border-radius: 10px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    padding: 8px 16px 8px 16px;
    max-height: 400px;
    min-height: 30px;

    .msg-user-input {
      resize: none;
      border: none;
      outline: none;
      border-bottom-left-radius: 10px;
      box-sizing: border-box;
      padding: 4px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.33;
      white-space: pre-wrap;
      word-wrap: break-word;
      color: #565867;
      -webkit-font-smoothing: antialiased;
      overflow: scroll;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .hidden {
      display: none;
    }

    .send {
      cursor: pointer;
      border: none;
      background: transparent;
      font-weight: bold;
      outline: none;
      padding-left: 16px;
      border-left: 1px solid #aaa;
      margin: 0;

      &:active {
        border: none;
        color: black;
        outline: none;
      }

      &:focus {
        color: grey;
      }

      &::-moz-focus-inner {
        border: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .chat {
    grid-template-columns: 1fr;
    width: auto;

    .message-list {
      background-color: #f1f1f1;
      border-radius: 10px;
      border: 1px solid black;
      display: flex;
      flex-direction: column;
      margin: 8px 0;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 8px;

      .message {
        padding: 4px 0;
        display: flex;
        align-content: flex-end;

        .internal,
        .external {
          display: flex;
          padding: 16px 16px 16px 16px;
          border: 1px solid black;
          display: flex;
          border-radius: 28px;

          p {
            padding: 0;
            margin: 0;
          }
        }

        &.internal {
          justify-content: flex-end;

          .c-text {
          }
        }

        &.external {
          justify-content: flex-start;

          .c-text {
            background: black;
            color: white;
          }
        }

        .c-text {
          max-width: 300px;
          white-space: pre-wrap;
          word-break: break-all;
        }

        .time {
          display: flex;
          align-self: end;
          font-size: 12px;
          color: #aaa;
          font-weight: 300;
          margin: 0 8px;
        }
      }
    }

    .chat-message-bar {
      border-radius: 10px;
      border: 1px solid black;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr auto;
      padding: 8px 16px 8px 16px;

      .msg-user-input {
        resize: none;
        border: none;
        outline: none;
        border-bottom-left-radius: 10px;
        box-sizing: border-box;
        padding: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.33;
        white-space: pre-wrap;
        word-wrap: break-word;
        color: #565867;
        -webkit-font-smoothing: antialiased;
        overflow: scroll;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .hidden {
        display: none;
      }

      .send {
        cursor: pointer;
        border: none;
        background: transparent;
        font-weight: bold;
        outline: none;
        padding-left: 16px;
        border-left: 1px solid #aaa;
        margin: 0;

        &:active {
          border: none;
          color: black;
          outline: none;
        }

        &:focus {
          color: grey;
        }

        &::-moz-focus-inner {
          border: 0;
        }
      }
    }
  }
}
</style>
