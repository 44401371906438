<template>
  <div>
    <a
      :style="{ display: 'none' }"
      ref="shippingSheetLink"
      target="_blank"
      :href="shippingSheetPdf"
    />
    <el-button type="primary" @click="downloadShippingSheet">
      Hoja de envío
    </el-button>
  </div>
</template>

<script>
import { PDF_DOWNLOAD_URL } from '@/constants'

export default {
  data () {
    return {
      shippingSheetPdf: PDF_DOWNLOAD_URL.MEASURES
    }
  },
  methods: {
    downloadShippingSheet () {
      this.$refs.shippingSheetLink.click()
    }
  }
}
</script>
