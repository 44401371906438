<template>
  <div class="signup">
    <div class="top">
      <el-link
        class="ln"
        type="secondary"
        @click="() => $router.push('/inicio-sesion')"
      >
        Inicia sesión
      </el-link>
    </div>

    <div v-if="step === 0" class="content">
      <img class="logo" src="../assets/qs1.svg" />

      <div class="greeting">
        <p class="title">Bienvenido, crea tu cuenta</p>
        <p class="info">Introduce tu número de colegiado</p>
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        class="form"
        ref="membershipForm"
        :hide-required-asterisk="true"
        @submit.native.prevent="goToNextStep"
      >
        <el-form-item label="" class="item-wrapper" prop="membershipNumber">
          <el-input
            class="input"
            v-model="ruleForm.membershipNumber"
            placeholder="Número de colegiado"
          />
        </el-form-item>
        <p class="not-allowed" v-if="showNotAllowedMessage">
          <span class="bold">¡Atención!</span> Para continuar el registro es
          necesario haber superado con éxito nuestra prueba de certificación.
        </p>
        <el-button class="next" type="primary" @click="goToNextStep"
          >Continuar</el-button
        >
      </el-form>
    </div>

    <div v-if="step === 1" class="content">
      <div v-if="formSubmited">
        <p>Te has registrado con éxito. Haz click aquí para logarte.</p>
        <router-link to="/login">Login</router-link>
      </div>

      <div v-else class="content">
        <img class="logo-text" src="../assets/qs2.svg" />
        <ApolloMutation
          class="form"
          :mutation="require('../graphql/Signup.gql')"
          :variables="{
            email: this.ruleForm.email.toLowerCase(),
            name: this.ruleForm.name,
            specialism: this.ruleForm.specialism,
            password: this.ruleForm.password,
            firstSurname: this.ruleForm.firstSurname,
            username: this.ruleForm.membershipNumber,
            gdpr: this.ruleForm.gdpr
          }"
          :update="updateToken"
        >
          <template v-slot="{ mutate, loading, error }">
            <p v-if="error">
              {{ error.message.replace('GraphQL error:', '') }}
            </p>
            <p class="welcome">Bienvenido, {{ ruleForm.membershipNumber }}</p>
            <p class="fill">Por favor, rellene el siguiente formulario</p>
            <el-form
              :model="ruleForm"
              :rules="rules"
              class="form"
              ref="ruleForm"
              :hide-required-asterisk="true"
              @submit.native.prevent="isFormValid() && mutate()"
            >
              <el-form-item label="" prop="name" class="item-wrapper">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="Nombre"
                ></el-input>
              </el-form-item>

              <el-form-item label="" class="item-wrapper" prop="firstSurname">
                <el-input
                  v-model="ruleForm.firstSurname"
                  placeholder="Apellidos"
                  id="firstSurname"
                />
              </el-form-item>

              <el-form-item label="" class="item-wrapper" prop="specialism">
                <el-select
                  class="select"
                  v-model="ruleForm.specialism"
                  placeholder="Especialidad"
                  id="specialism"
                >
                  <el-option
                    v-for="specialism in specialisms"
                    :key="specialism"
                    :label="specialism"
                    :value="specialism"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="" class="item-wrapper" prop="email">
                <el-input
                  class=""
                  v-model="ruleForm.email"
                  placeholder="Correo electrónico"
                  id="email"
                />
              </el-form-item>

              <el-form-item label="" class="item-wrapper" prop="password">
                <el-input
                  class=""
                  v-model="ruleForm.password"
                  placeholder="Contraseña"
                  type="password"
                  id="pass"
                />
              </el-form-item>
              <el-form-item label="" class="item-wrapper" prop="repeatPassword">
                <el-input
                  class=""
                  v-model="ruleForm.repeatPassword"
                  placeholder="Repetir contraseña"
                  type="password"
                  id="repeat-pass"
                />
              </el-form-item>
              <el-form-item label="" class="item-wrapper" prop="gdpr">
                <el-checkbox class="checkbox" v-model="ruleForm.gdpr">
                  <p class="checkbox-label">
                    He leído y acepto los
                    <a
                      class="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://quicksmile.es/tycdoctores/"
                      >términos y condiciones de contratación</a
                    >.
                  </p>
                </el-checkbox>
              </el-form-item>
              <div>
                <el-button class="next" type="primary" @click="submitForm"
                  >Enviar</el-button
                >
                <button
                  :style="{ display: 'none' }"
                  ref="submit"
                  type="submit"
                />
              </div>
            </el-form>
          </template>
        </ApolloMutation>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import IS_DOCTOR_ALLOWED from '../graphql/IsDoctorAllowed.gql'
import Footer from '@/components/Footer'

export default {
  name: 'Signup',
  components: {
    Footer
  },
  data () {
    const checkGdpr = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Debe aceptar los términos y condiciones'))
      } else {
        callback()
      }
    }

    return {
      showNotAllowedMessage: false,
      specialisms: [
        'Ortodoncia',
        'Periodoncia',
        'Endodoncia',
        'Rehabilitación oral',
        'Implantología',
        'Odontología preventiva',
        'Odontopediatría',
        'Otro'
      ],
      ruleForm: {
        name: '',
        membershipNumber: '',
        firstSurname: '',
        specialism: '',
        email: '',
        password: '',
        repeatPassword: '',
        gdpr: false
      },
      step: 0,
      submited: false,

      rules: {
        membershipNumber: [
          {
            required: true,
            message: 'Introduzca su número de colegiado',
            trigger: 'blur'
          }
        ],
        name: [
          { required: true, message: 'Introduzca su nombre', trigger: 'blur' }
        ],
        firstSurname: [
          { required: true, message: 'Introduzca su apellido', trigger: 'blur' }
        ],
        specialism: [
          {
            required: true,
            message: 'Introduzca su especialidad',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Introduzca su correo electrónico',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'Introduzca una contraseña',
            trigger: 'blur'
          },
          {
            min: 8,
            message: 'Introduzca una contraseña de al menos 8 caracteres',
            trigger: 'blur'
          },
          {
            max: 42,
            message: 'Introduzca una contraseña de 42 caracteres máximo',
            trigger: 'blur'
          }
        ],
        repeatPassword: [
          { required: true, message: 'Repita la contraseña', trigger: 'blur' },
          { validator: this.isSamePassword, trigger: 'blur' }
        ],
        gdpr: [{ validator: checkGdpr, trigger: 'blur' }]
      }
    }
  },

  computed: {
    formSubmited () {
      return this.submited
    }
  },
  methods: {
    // validate password are equal
    isSamePassword (rule, value, cb) {
      if (value !== this.ruleForm.password) {
        cb(new Error('Las contraseñas deben coincidir'))
      } else {
        cb()
      }
    },
    isFormValid () {
      let error = false
      this.$refs.ruleForm.validateField(
        [
          'membershipNumber',
          'name',
          'firstSurname',
          'specialism',
          'email',
          'password',
          'repeatPassword',
          'gdpr'
        ],
        elementError => {
          if (elementError) {
            error = true
          }
        }
      )

      return !error
    },
    async submitForm () {
      if (this.isFormValid()) {
        this.$refs.submit.click()
      }
    },
    async goToNextStep () {
      let error = false
      this.$refs.membershipForm.validateField(
        ['membershipNumber'],
        elementError => {
          if (elementError) {
            error = true
          }
        }
      )

      if (!error) {
        const result = await this.$apollo.mutate({
          mutation: IS_DOCTOR_ALLOWED,
          variables: {
            collegiateNumber: this.ruleForm.membershipNumber
          }
        })
        if (!result.data.isDoctorAllowed) {
          this.showNotAllowedMessage = true
        } else {
          this.step += 1
        }
      }
    },
    updateToken (store, response) {
      // pass token and firebaseToken
      this.$store.dispatch('login', response.data.signup)
    }
  }
}
</script>

<style scoped lang="scss">
.signup {
  box-sizing: border-box;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .top {
    width: fit-content;
    justify-self: flex-end;
    padding: 24px;

    .ln {
      font-size: 16px;
      text-decoration: none;
      color: black;
    }
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo {
      height: 170px;
    }

    .logo-text {
      width: 270px;
    }

    .greeting {
      .title {
        font-size: 26px;
      }

      .info {
        // margin: 0 0 8px 0;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 288px;
      align-self: center;
      justify-self: center;

      .welcome {
        font-size: 26px;
        margin: 16px 0 8px 0;
      }

      .fill {
        margin: 0 0 16px 0;
      }

      .item-wrapper {
        width: 100%;

        .checkbox {
          display: flex;

          .checkbox-label {
            white-space: break-spaces;
            margin: 0;
          }
        }

        .select {
          width: 100%;
        }
        .field {
        }
      }
      .next {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}

.link {
  color: black;
}

.not-allowed {
  margin: 0 0 16px 0;
  .bold {
    font-weight: bold;
  }

  .link {
    font-weight: bold;
    color: black;
  }
}
</style>
